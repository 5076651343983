* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}
.main-panel {
	background: #fafafa;
}
.form-area {
	height: auto;
	background: #fff;
	overflow-y: auto;
	overscroll-behavior-x: auto;
	padding: 0px !important;
	border-radius: 10px;
	border: 1px solid #e9eff7;
}
.form-area--height {
	height: calc(100vh - 90px) !important;
}
.form-area--height75 {
	height: calc(100vh - 175px) !important;
}
.editororderdetail--height {
	height: calc(100vh - 192px);
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}
.padding-top-2 {
	padding-top: 2px !important;
}
.new-wordspace-area {
	display: grid !important;
	width: fit-content;
	/* grid-template-columns: 4fr 6fr 4fr; */
	gap: 4px;
	@media (min-width: 1100px) {
		grid-template-columns: 4fr 12fr 6fr;
	}
}

.new-wordspace-area .folderlist {
	position: relative;
	border-left: 1px solid #efefef;
}
.card .lazyload-wrapper {
	width: 100%;
}
.card > div > img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.dropdown-toggle,
.dropdown {
	padding-right: 0px !important;
}
.dropdown .dropdown-menu {
	min-width: 200px;
}
.nav-link {
	margin-right: 4px !important;
}
.dropdown .dropdown-menu {
	transform: translate(-55px, 60px) !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	padding-right: 0px !important;
}
.row-multiple-select--coordinator {
	padding: 11px 10px 11px;
}
.custom-row-workspace > div {
	padding-top: 15px;
	flex-grow: 1 !important;
}
.order-details {
	margin-bottom: 18px;
}

@media (max-width: 1024px) {
	.card-header p {
		font-size: 11px !important;
		line-height: 15px !important;
		margin-bottom: 5px;
	}
	.order-details p {
		margin-bottom: 5px !important;
	}
	.order-details {
		padding: 5px 0 !important;
	}
	.order-details {
		padding: 5px 0 !important;
	}
}

@media (max-width: 1300px) {
	.treeviewlist.custom-viewlist .first-treeview li {
		padding-left: 15px;
	}
	.treeviewlist.custom-viewlist li {
		padding: 15px 1px 10px 0;
		font-size: 12px;
	}
}
.ordercard {
	padding-right: 3px;
	margin-top: 0.3em;
}
.treeviewlist {
	overflow: auto;
	overflow-x: hidden;
	height: calc(100vh - 192px);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}
/* .card > div > img {
	width: 100px;
	height: auto;
	object-fit: contain;
} */

.image-settings-folder--select {
	text-align: center;
	display: block;
	border: 1px solid #e9e9e9;
	padding: 8px;
	border-radius: 5px;
	margin: 0px 10px 8px 10px;
	font-size: 12px;
	@media (min-width: 1100px) {
		font-size: 15px;
	}
}

.custom-row-workspace__batcharea {
	padding-right: 3px;
	width: 22vw;
	/* width: auto; */
	@media (min-width: 1100px) {
		padding-right: 12px;
	}
}
/* new changes */
.navbar-expand-lg .navbar-nav {
	display: flex;
	gap: 0px;
	justify-content: center;
	align-items: center;
}
.nav-profile-text {
	margin-left: 8px !important;
}
.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}
.sidebar .nav .nav-item.active {
	background: #222222 !important;
}
.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	gap: 24px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}
.sidebar--select-sm {
	height: calc(100vh - 260px) !important;
	padding-bottom: 35px !important;
}
/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
/* .modal-image-icons__area {
	padding: 18px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 25px;
} */
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}
.nav-tabs .nav-link:hover {
	/* border-color: #fff #fff #fff !important; */
	border-radius: 0px;
}
.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	padding: 0px 0px 0px 8px;
	font-size: 13px;
	color: #f00;
	margin-top: -42%;
	margin-left: -3%;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 10px 0px 6px;
}
.o-flex {
	display: grid;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 204px);
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 240px) !important;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	/* padding-top: 15px !important; */
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}
/* .dropdown-duplicate-image--modal {
	flex-direction: column;
	padding-top: 10px;
} */
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}
.Toastify__toast--success::before {
	width: 31px;
}
.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	height: calc(100vh - 88px);
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
}

.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
.nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 10px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
}
.nim__main-area {
	grid-area: m;
	width: -webkit-fill-available;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	border-bottom: 8px solid #ffff;
	position: relative;
	overflow: auto;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	gap: 8px;
	align-items: center;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
	margin: auto;
}
.zoom_container__input:focus {
	border: 1px solid;
}

.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}

.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	gap: 24px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}

/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
/* .modal-image-icons__area {
	padding: 18px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 25px;
} */
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}

.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	display: block;
	/* width: 100%; */
	padding: 15px 0px 10px;
	font-size: 13px;
	color: #f00;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 11px 0px 6px;
}
.o-flex {
	display: grid;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 204px);
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 240px) !important;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	/* padding-top: 15px !important; */
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}

.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}
 {
	width: 8px;
}
.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	height: calc(100vh - 88px) !important;
}
.modal-image--order {
	height: calc(100vh - 88px) !important;
}
.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
/* .nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 10px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
} */
.nim__main-area {
	grid-area: m;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	gap: 8px;
	align-items: center;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
}
.zoom_container__input:focus {
	border: 1px solid;
}
.overflow__none {
	overflow: hidden !important;
}
.co-section-title h2 {
	font-weight: 500;
	font-size: 20px;
	line-height: 35px;
	color: #0b0b0b;
}
.co-section-title--spacing {
	padding: 10px 10px 10px 15px;
}
.co-section-title__mb0 {
	margin-bottom: 0px;
}
.dropdown--duplicate--coo__align {
	display: flex;
	justify-content: center;
	padding: 0px 6px;
}
.sidebar__header--margin .form-group {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}
.folderlist {
	padding-top: 0px !important;
}
.modal__title {
	position: fixed;
	top: auto;
	background: #ffffff;
	padding: 15px 24px 13px;
	border-radius: 5px 5px 0px 0px;
	border-bottom: 1px solid #dddddd;
	top: 0;
	position: relative;
	width: 100%;
}
.modal__height {
	height: calc(100vh - 48px);
	overflow-x: hidden !important;
}
.duplicate-settings-modal--count {
	height: calc(100vh - 280px);
	overflow-y: auto;
	overflow-x: hidden;
}
.modal-footer {
	padding: 2px 5px 1px !important;
	background: rgb(249, 249, 249) !important;
	border-top: 1px solid rgb(249, 249, 249) !important;
}
.new-modal-footer {
	padding: 5px 24px !important;
}
.modal-body .form-group {
	margin-bottom: 10px !important;
}
/********************* remove this *******************/
/* .coordibox .card {
	width: 30%;
	display: none;
} */
/* ****************************** */

.modal-image-icons {
	max-width: fit-content;
	display: flex;
	justify-content: center;
	height: auto;
	border-radius: 8px;
	margin-bottom: auto;
	justify-content: start;
	width: 100%;
}
.modal-image-icons__area {
	/* padding: 18px 0px; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}
.modal-image-icons__each {
	padding: 9px 0px;
	width: 60px;
	text-align: center;
	margin-bottom: 1px;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
.modal-image-icons__each:hover {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-image-icons__each--active {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.settingsProfile-dropdown > div {
	min-height: 45px !important;
	min-width: auto;
}
@media (min-width: 1300px) {
	.settingsProfile-dropdown > div {
		min-height: 45px !important;
		min-width: 180px;
	}
}
.box2 > button {
	flex-grow: 1;
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 190px) !important;
	padding: 10px 15px 0px;
	scrollbar-width: thin;
}
.form-group {
	margin-bottom: 8px !important;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}

.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal--image > div {
	flex-grow: 1;
	/* max-height: calc(159vh - 575px); */
	max-height: calc(100vh - 89px);
}
.align-center {
	cursor: crosshair;
	transform: none;
	scale: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	gap: 8px;
	align-items: center;
	font-size: 14px;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.btn__save {
	border: 1px solid #125ecb;
	background-color: transparent !important;
	color: #125ecb;
	/* gap: 15px !important; */
	padding: 11px 34px;
	border-radius: 5px;
	font-size: 13px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.o-flex {
	display: grid;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.magnifier-holder--img {
	justify-content: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	display: flex;
	height: 2.7rem !important;
	width: 3em;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}
.image-zoom {
	display: flex;
	align-items: center;
	margin-left: 30px;
	border: 1px solid #efefef;
	border-radius: 4px;
	width: 82px;
	background-color: #f8f8f8;
	justify-content: center;
}
.first-treeview--span {
	margin-left: 5px;
}
@media (min-width: 1200px) {
	.first-treeview--span {
		margin-left: 15px;
	}
}
.editor_ws_image {
	flex-grow: 1 !important;
}
.workbody {
	background: #fff !important;
	padding: 0px !important;
	overflow: hidden;
}
.rightbar {
	/* padding: 0px !important; */
}
.editororderdetails {
	height: calc(100vh - 190px) !important;
	scrollbar-width: thin;
	padding-bottom: 15px;
}

.custom-row-workspace {
	padding: 0px 0px 0px 15px;
}
/* .new-wordspace-area>div{
	margin-top: 5px;
} */

.qc-form-area {
	display: flex;
	justify-content: space-between;
	gap: 15px;
}
.qc-form-area__select {
	min-width: auto;
}
@media (min-width: 1150px) {
	.qc-form-area__select {
		min-width: 200px;
	}
}
/* new changes */
.navbar-expand-lg .navbar-nav {
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
}
.nav-profile-text {
	margin-left: 8px !important;
}
.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}
.sidebar .nav .nav-item.active {
	background: #222 !important;
}
.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	gap: 24px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}
.sidebar--select-sm {
	height: calc(100vh - 260px) !important;
	/* padding: 23px !important; */
	padding-bottom: 35px !important;
}
/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
	position: relative;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
.modal-image-icons__area {
	/* padding: 18px 0px; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}
.modal-image-icons__each {
	padding: 9px 0px;
	width: 60px;
	text-align: center;
	margin-bottom: 1px;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
.modal-image-icons__each:hover {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-image-icons__each--active {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
	position: relative;
	z-index: 10; /* Ensure the input is clickable */
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}
.nav-tabs .nav-link:hover {
	/* border-color: #fff #fff #fff !important; */
	border-radius: 0px;
}
.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	display: block;
	/* width: 100%; */
	padding: 15px 0px 10px;
	font-size: 13px;
	color: #f00;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 11px 0px 6px;
}
.o-flex {
	display: grid;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 220px) !important;
}
@media (min-width: 1200px) {
	.order-confirm-h--editing-description {
		height: calc(100vh - 204px) !important;
	}
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 190px) !important;
}
.sidebar-settings-image--image-modal2 {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 165px) !important;
}
.sidebar-settings-image--comment-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 140px) !important;
	background-color: #f8f8f8;
	padding: 16px;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	padding-top: 7px !important;
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}
/* .dropdown-duplicate-image--modal {
	flex-direction: column;
	padding-top: 10px;
} */
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}

.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	/* height: calc(100vh - 88px) !important; */
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
}
.modal--image > div {
	flex-grow: 1;
	/* max-height: calc(159vh - 575px); */
	max-height: calc(100vh - 89px);
}
.modal-image--order {
	height: calc(100vh - 88px) !important;
}
.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
.nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 8px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
}
.nim__main-area {
	grid-area: m;
	width: -webkit-fill-available;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	border-bottom: 8px solid #ffff;
	position: relative;
	overflow: auto;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	gap: 8px;
	align-items: center;
	font-size: 14px;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
	margin: auto;
}
.zoom_container__input:focus {
	border: 1px solid;
}
.auth-form__titlearea {
	text-align: center;
}
.auth-form__title {
	font-size: 30px;
	font-weight: 600;
	margin-top: 40px;
	@media (min-width: 1100px) {
		font-size: 36px;
	}
}
.auth-form__subtitle {
	font-size: 16px !important;
	color: #3b3b3b !important;
}
.new-auth-form {
	margin-top: 35px !important;
	box-shadow: #79797929 0px 0px 35px !important;
	margin-bottom: 30px !important;
}
.new-auth-form label {
	font-weight: 400 !important;
	color: #0b0b0b !important;
	font-size: 15px !important;
}
.new-auth-form .form-control {
	height: 48px;
	font-size: 16px !important;
}
input::placeholder,
textarea::placeholder {
	font-size: 13px !important;
}
.new-auth-form input::placeholder,
.new-auth-form textarea::placeholder {
	font-size: 15px !important;
	font-weight: 400 !important;
	color: #838383 !important;
}
.new-auth-form input[type="text"] {
	font-size: 16px !important;
}
.new-auth-form .form-group {
	margin-bottom: 25px !important;
}
.btn__signup {
	padding: 14px 10px !important;
	font-size: 14px !important;
}
.auth-form__content {
	padding: 0px 45px !important;
}
.auto-form__flex {
	display: flex;
	justify-content: space-between;
}
.zoom_container__input {
	font-size: 16px !important;
	margin-left: 30px !important;
}
.magnifier-holder--img {
	cursor: pointer;
}
.magnifier-holder--img img {
	width: 20px;
}

.image-zoom {
	display: flex;
	align-items: center;
	margin-left: 30px;
	border: 1px solid #efefef;
	border-radius: 4px;
	width: 82px;
	background-color: #f8f8f8;
}
.image-zoom__input {
	width: 50px;
	height: 42px;
	padding: 13px 0px 11px 13px;
	background-color: transparent !important;
	border: none;
	font-size: 16px;
	color: #0b0b0b;
}
.sidebar__flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #dddddd87;
}

.commentcard {
	margin-bottom: 25px;
	padding: 16px;
	border: 1px solid #ddd;
	border-radius: 12px;
}
.commentcard .commentcard__mt {
	margin-top: 15px;
}
.commentcard .commentcard__mt:first-child {
	margin-top: 0px;
}
.commentcard--bg {
	background-color: #fff;
	padding: 16px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	border: none;
}
.commentcard__profile {
	display: flex;
	align-items: center;
	gap: 8px;
}
@media (min-width: 1100px) {
	.commentcard__profile {
		gap: 12px;
	}
	.commentcard {
		padding: 20px;
	}
}
.commentcard__input {
	width: 100%;
	border: 1px solid #8f8f8f;
	border-radius: 5px;
	height: 42px;
	padding: 0px 15px;
	margin-top: 10px;
	color: #353535 !important;
}
.commentcard__input:focus {
	border-color: rgba(0, 0, 0, 1) !important;
}
@media (min-width: 1100px) {
	.commentcard__input {
		height: 45px;
	}
}
.commentcard__input::placeholder {
	font-size: 14px !important;
	color: #797979 !important;
}
.commentcard__dp {
	width: 40px;
	height: 40px;
	background-color: #ddd;
	border-radius: 50px;
}
.commentcard__name {
	font-size: 12px;
}
.commentcard__time {
	font-size: 11px;
	color: #918d8d;
	line-height: 1.6;
}
@media (min-width: 1100px) {
	.commentcard__name {
		font-size: 14px;
	}
	.commentcard__time {
		font-size: 12px;
		line-height: 1.5;
	}
}
.commentcard__btn-area {
	margin-top: 12px;
	display: flex;
	gap: 15px;
	align-items: center;
	justify-content: end;
	font-size: 15px;
}
@media (min-width: 1100px) {
	.commentcard__btn-area {
		margin-top: 16px;
	}
}

.commentcard__btn-area .next-btn {
	font-size: 14px;
	font-weight: 500;
}
.simplecomment {
	margin-top: 28px;
}
.commentcard__dropdown {
	display: flex;
	justify-content: space-between;
	/* padding-right: 20px; */
}
.commentcard--bg .commentcard__dropdown {
	padding-right: 0px;
}

.commentcard__comment {
	font-size: 14px;
	margin-top: 10px;
}
.commentcard--bg .commentcard__comment {
	margin-bottom: 10px;
}
.commentcard__dropdown-area {
	display: none;
	min-width: 105px;
	min-height: 62px;
	background-color: #fff;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	position: absolute;
	right: 15px;
	top: 5px;
	padding: 5px 15px;
}
.commentcard__dropdownlist {
	display: flex;
	gap: 8px;
	font-size: 12px;
	align-items: center;
	margin-top: 6px;
	margin-bottom: 6px;
}
.commentcard__dropdownlist:hover {
	cursor: pointer;
}
.commentcard__dropdown-area--visible {
	display: block;
}
.commentcard__dropdownlist svg {
	margin-top: -2px;
}
.thumbnailimage {
	display: flex;
	justify-content: center;
	align-items: center;
}
.thumbnailimage > p {
	position: absolute;
	bottom: 36%;
	font-size: 16px !important;
}
.align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.first-treeview--inner {
	margin-left: 20px;
}
.conform-order-message {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 310px);
	text-align: center;
}
.conform-order-message__content {
	color: #4d4c4c;
	font-size: 20px;
	max-width: 650px;
	margin: 0 auto;
	padding: 0px 50px;
	line-height: 1.5;
}
.conform-order-message__h3 {
	color: #4d4c4c;
	font-size: 18px;
	line-height: 1.5;
	border-bottom: 1px solid #ddd;
	padding-bottom: 15px;
	margin-bottom: 10px;
}
@media (min-width: 1200px) {
	.conform-order-message__h3 {
		padding-bottom: 25px;
		margin-bottom: 20px;
	}
}
.conform-order-message__checkbox {
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
}
.conform-order-message__checkbox-p {
	color: #4d4c4c;
}

.item-upload-popup {
	max-width: 318px;
	background: #f7f9fc;
	position: absolute;
	width: 320px;
	right: 15px;
	bottom: 0;
	max-height: calc(100vh - 350px);
	height: auto;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	font-size: 14px;
	padding: 15px 20px;
	padding-bottom: 0px;
	z-index: 1;
	box-shadow: 0 -1px 10px #4800c917;
}
.item-upload-popup__title-area {
	display: flex;
	justify-content: space-between;
	gap: 15px;
	padding-bottom: 10px;
	/* margin-bottom: 10px; */
}
.item-upload-popup__flex {
	display: flex;
	gap: 20px;
	align-items: center;
}
.item-upload-popup__item-list {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	padding-right: 5px;
}
.item-upload-popup__flex2 {
	display: flex;
	gap: 10px;
	/* align-items: center; */
}
.item-upload-popup__item-area {
	overflow-x: hidden;
	scrollbar-width: thin;
	max-height: calc(100vh - 410px);
	height: auto;
	padding-bottom: 10px;
	border-top: 1px solid #ddd;
}
.pan_container {
	height: -webkit-fill-available;
	position: relative;
	overflow: hidden;
	scroll-behavior: smooth;
}
.privious-btn {
	border: 1px solid #0001c7 !important;
	background-color: transparent !important;
	color: #0001c7 !important;
	gap: 15px !important;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.editororderdetails_1 {
	width: 100%;
	/* position: fixed; */
	/* overflow: hidden; */
}
.sidebar--select {
	width: 100%;
	max-width: 100%;
	height: calc(100vh - 280px);
	position: relative;
	padding-top: 6px;
}
