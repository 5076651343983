.btns.sent {
  width: 83%;
}

.btns.sent .sent {
  font-weight: bold;
  color: #64b05e;
}

.duplicate-settings-error-box {
  border: 1px solid #D7C3C8;
  background-color: #FBF7F8;
  color: #97505E;
  border-radius: 5px;
  padding: 0px 21px 0px 16px;
  height: 3rem;
  line-height: 1.3rem;
  margin-top: -3px;
  font-size: 0.88em;
  font-weight: 400;
  /* width: auto; */
}

