.navbar {
	/* padding: 5px; */
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 60px;
	z-index: 1030;
	background: #0b0b0b;
	transition: background 0.25s ease;
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-ms-transition: background 0.25s ease;
}
.navbar-brand {
	padding: 0 15px;
}
#profileDropdown:hover {
	background: #0b0b0b;
	transition: background 0.25s ease;
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-ms-transition: background 0.25s ease;
}
@media (max-width: 991px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}
}
.navbar .navbar-brand-wrapper .navbar-brand {
	color: #27367f;
	font-size: 1.5rem;
	line-height: 48px;
	margin-right: 0;
	padding: 0.25rem 0;
	width: 100%;
}
.nav-profile-img {
	column-gap: 14px;
}
.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
	color: #1b2658;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
	width: calc(260px - 120px);
	max-width: 100%;
	height: 28px;
	margin: auto;
	vertical-align: middle;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
	display: none;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
	width: calc(70px - 50px);
	max-width: 100%;
	height: 28px;
	margin: auto;
}
.navbar .navbar-menu-wrapper {
	transition: width 0.25s ease;
	-webkit-transition: width 0.25s ease;
	-moz-transition: width 0.25s ease;
	-ms-transition: width 0.25s ease;
	color: #9c9fa6;
	padding-left: 24px;
	padding-right: 24px;
	width: calc(100% - 260px);
	height: 70px;
}
.user-name {
	font-weight: 700;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
	max-width: 10rem;
	overflow: auto;
	white-space: nowrap;
}
.user-logout {
	font-weight: 700;
	font-size: 26px;
	line-height: 19px;
	color: #ffffff;
}
.user-designation {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
}
/* Dropdowns */
.dropdown-toggle:after {
	margin-left: 46px;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-bottom: 0;
	font: normal normal normal 14px/1 FontAwesome;
	content: "\f107";
	width: auto;
	height: auto;
	vertical-align: baseline;
	font-size: 0.75rem;
}

.dropdown .dropdown-menu {
	margin-top: 0.75rem;
	font-size: 0.875rem;
	-webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
	box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
	margin-left: -6.8rem;
    /* position: absolute; */
    inset: -5px 46px auto 0 !important;
    transform: translate(70px, 45px);
    /* background: black !important; */
    border-radius: 12px !important;
    color: #c0bcbc;
}
.dropdown .dropdown-menu .dropdown-item {
	font-size: 1rem;
	padding: 0.25rem 1.5rem;
	/* color:white!important; */
}
.dropdown .dropdown-menu .dropdown-item:active {
	background: initial;
}
.dropdown .dropdown-menu .dropdown-item:hover {
	background-color: #f8f9fa;
	color: black!important;
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.navbar .navbar-menu-wrapper .navbar-toggler {
	border: 0;
	color: inherit;
	height: 70px;
	border-radius: 0px;
	padding-left: 5px;
	padding-right: 20px;
}
.navbar .navbar-menu-wrapper .navbar-toggler:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
	font-size: 1.5rem;
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
		display: none;
	}
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
		padding-left: 15px;
		padding-right: 11px;
		border-right: none;
	}
}
.navbar .navbar-menu-wrapper .search-field .input-group input {
	font-size: 0.875rem;
	padding: 0.5rem;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group i {
	font-size: 17px;
	margin-right: 0;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
	background: transparent;
}
.notify {
	position: relative;
}
.count-symbol,
.count-number {
	position: absolute;
	border-radius: 100%;
}
.count-symbol {
	top: 15px;
	right: 8px;
	width: 10px;
	height: 10px;
	background: #ff0000;
}
.count-number {
	min-width: 14px;
	height: 14px;
	font-size: 0.5rem;
	color: #ffffff;
	bottom: 16px;
	right: -5px;
	line-height: 1;
	text-align: center;
}
.navbar .navbar-menu-wrapper .count-indicator:after {
	display: none;
}
.navbar .navbar-menu-wrapper .navbar-nav {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.nav-link {
	color: inherit;
	font-size: 0.875rem;
	margin-right: 1.5rem;
	margin-right: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 767px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}
}
.notify i {
	font-size: 24px;
	color: #fff;
}
.dropdown-toggle:after {
	color: #fff;
	font-size: 18px;
}
.dropdown-menu {
	border: none;
	border-radius: 5px;
	-webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	position: absolute;
	font-size: 0.9rem;
	margin-top: 0;
	padding: 0;
}
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	right: auto;
	left: 0;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	margin-bottom: 0;
	padding: 11px 13px;
	cursor: pointer;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	font-size: 17px;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	.ellipsis {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-left: 10px;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-divider {
	margin: 0;
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
		position: static;
	}
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
		left: 20px;
		right: 20px;
		top: 70px;
		width: calc(100% - 40px);
	}
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
	padding-left: 20px;
	padding-right: 5px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
	margin-right: 0;
	margin-left: 0;
}
.nav-profile-img {
	position: relative;
	width: 40px;
	height: 40px;
}
.nav-profile-img img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}
.nav-profile-img .availability-status {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	border: 2px solid #ffffff;
	bottom: 5px;
	right: -5px;
}
.nav-profile-img .availability-status.online {
	background: #1bcfb4;
}
.nav-profile-img .availability-status.offline {
	background: #fe7c96;
}
.nav-profile-img .availability-status.busy {
	background: #fed713;
}
.nav-profile-text {
	margin-left: 1.25rem;
	width: auto !important;
}
.rtl.nav-profile-text {
	margin-left: 0;
	margin-right: 1.25rem;
}

@media (max-width: 767px) {
	.nav-profile-text {
		display: none;
	}
}
.dropdown-toggle:after {
	line-height: 2;
}
@media (min-width: 992px) {
	.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: auto;
	}
	.rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: 0;
		margin-right: auto;
	}
}

@media (max-width: 991px) {
	.navbar {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar .navbar-brand-wrapper {
		width: 75px;
	}
	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
		display: none;
	}
	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
		display: inline-block;
	}
	.navbar-collapse {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0.5rem;
	}
}

@media (max-width: 480px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}
	.navbar .navbar-brand-wrapper .brand-logo-mini {
		padding-top: 0px;
	}
}

/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#da8cff),
		to(#9a55ff)
	);
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#e7ebf0),
		to(#868e96)
	);
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#84d9d2),
		to(#07cdae)
	);
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#90caf9),
		color-stop(99%, #047edf)
	);
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#f6e384),
		to(#ffd500)
	);
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ffbf96),
		to(#fe7096)
	);
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#f4f4f4),
		to(#e4e4e9)
	);
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}
