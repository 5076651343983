@import "./rootColor.css";

.fonts {
	font-family: "Heebo", sans-serif;
	line-height: 19px;
	color: var(--black);
	font-weight: 500;
	white-space: normal;
	vertical-align: middle;
}
body.modal-open {
	position: fixed;
	width: -webkit-fill-available;
}
body {
	-webkit-user-drag: none;
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.6;
}
