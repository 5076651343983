/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + Badge Mixins
    + Button Mixins
    + Miscellaneous Mixins
    + BlockQuote Mixins
    + Cards Mixins
    + Color Functions Mixins
    + Tooltips
    + popovers
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Backgrounds
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
  * Components
    + Badges
    + Boostrap Progress
    + Buttons
    + Breadcrumbs
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Preview
    + Tables
    + Todo List
  * Landing screens
    + Auth
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------*/
@import "./Components/Input/index.css"; /* Import the Input Component CSS */
@import "./Components/Span/index.css"; /* Import the Span Tag CSS */
@import "./Components/Ul/index.css"; /* Import the UL/LI CSS */
@import "./Components/Button/index.css"; /* Import Button CSS */
@import "./Components/Responsive/Coordinator/index.css"; /* Import Responsive/Coordinator CSS */
@import "./Components/ToastAlert/index.css"; /* Import Toast Alert CSS */
@import "./MediaQuery/mobile__style.css"; /* Import Mobile  CSS */
@import "./MediaQuery/main_style/main_style.css"; /*Import Media Query for main layout CSS*/
@import "./new-updates.css";
hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
}
ul {
	padding-left: 0;
}
li {
	list-style-type: none;
}
h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}
hr:not([size]) {
	height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

h1,
.h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	h1,
	.h1 {
		font-size: 2.5rem;
	}
}

h2,
.h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	h2,
	.h2 {
		font-size: 2rem;
	}
}

h3,
.h3 {
	font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
	h3,
	.h3 {
		font-size: 1.75rem;
	}
}

@media (min-width: 1200px) {
}

h5,
.h5 {
	font-size: 1.25rem;
}

h6,
.h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol {
	padding-left: 2rem;
}

ol,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small,
.small {
	font-size: 0.875em;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #0d6efd;
	text-decoration: none;
}
a:hover {
	color: #0a58ca;
	text-decoration: none;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr /* rtl:ignore */;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
}
pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
}
a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}
kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #6c757d;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}
select:disabled {
	opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

textarea {
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}
@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem;
	}
}
legend + * {
	clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::file-selector-button {
	font: inherit;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}
.email-validated {
	display: contents;
}
.loader-login {
	margin-left: auto;
	margin-right: auto;
}
.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-1 {
		font-size: 5rem;
	}
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-2 {
		font-size: 4.5rem;
	}
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-3 {
		font-size: 4rem;
	}
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-4 {
		font-size: 3.5rem;
	}
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-5 {
		font-size: 3rem;
	}
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-6 {
		font-size: 2.5rem;
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}
.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}
.blockquote > :last-child {
	margin-bottom: 0;
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #6c757d;
}
.blockquote-footer::before {
	content: "\2014\00A0";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 0.875em;
	color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto; */
}

@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container,
	.container-sm,
	.container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}

.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: #ecf1f7;
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #ebedf2;
}
.table > :not(caption) > * > * {
	padding: 0.5rem 0.5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	-webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
	vertical-align: inherit;
}
.table > thead {
	position: sticky;
	top: 0;
	vertical-align: bottom;
}
.table > :not(:first-child) {
	border-top: 2px solid currentColor;
}

.caption-top {
	caption-side: top;
}

.table-sm > :not(caption) > * > * {
	padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
	border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
	border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
	border-top-width: 0;
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
	--bs-table-accent-bg: #ecf1f7;
	color: var(--bs-table-hover-color);
}

.table-primary {
	--bs-table-bg: #cfe2ff;
	--bs-table-striped-bg: #c5d7f2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bacbe6;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bacbe6;
}

.table-secondary {
	--bs-table-bg: #e2e3e5;
	--bs-table-striped-bg: #d7d8da;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #cbccce;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #cbccce;
}

.table-success {
	--bs-table-bg: #d1e7dd;
	--bs-table-striped-bg: #c7dbd2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bcd0c7;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bcd0c7;
}

.table-info {
	--bs-table-bg: #cff4fc;
	--bs-table-striped-bg: #c5e8ef;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #badce3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #badce3;
}

.table-warning {
	--bs-table-bg: #fff3cd;
	--bs-table-striped-bg: #f2e7c3;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e6dbb9;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e6dbb9;
}

.table-danger {
	--bs-table-bg: #f8d7da;
	--bs-table-striped-bg: #eccccf;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfc2c4;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfc2c4;
}

.table-light {
	--bs-table-bg: #f8f9fa;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfe0e1;
}

.table-dark {
	--bs-table-bg: #212529;
	--bs-table-striped-bg: #2c3034;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #373b3e;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #fff;
	color: #fff;
	border-color: #373b3e;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.my-modal {
	width: 90vw; /* Occupy the 90% of the screen width */
	max-width: 90vw;
}
.flex-row--reverse {
	flex-flow: row-reverse;
}
.datalist {
	max-height: 20em;
	border: 0 none;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	height: 135px;
	border-color: #0d6efd;
	border: 1px solid rgba(0, 0, 0, 0.25);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
}
.search--icons {
	position: absolute;
	z-index: 1;
	margin: 6px;
}
@media (max-width: 575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1399.98px) {
	.table-responsive-xxl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.form-label {
	margin-bottom: 0.5rem;
}

.col-form-label {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1;
}

.col-form-label-lg {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	font-size: 1.25rem;
}

.col-form-label-sm {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 0.875rem;
}

.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #6c757d;
}

.form-control,
.form-select {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	font-size: 13px;
}

.table tr {
	border-bottom: 1px solid #ebedf2;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b !important;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.94rem 0;
	margin-bottom: 0;
	line-height: 1;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	min-height: 2.575rem;
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	-webkit-margin-end: 0.81rem;
	margin-inline-end: 0.81rem;
}
.form-control-sm::-webkit-file-upload-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	-webkit-margin-end: 0.81rem;
	margin-inline-end: 0.81rem;
}

.form-control-lg {
	min-height: 3.175rem;
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	-webkit-margin-end: 1.94rem;
	margin-inline-end: 1.94rem;
}
.form-control-lg::-webkit-file-upload-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	-webkit-margin-end: 1.94rem;
	margin-inline-end: 1.94rem;
}

textarea.form-control {
	min-height: 2.875rem;
}

textarea.form-control-sm {
	min-height: 2.575rem;
}

textarea.form-control-lg {
	min-height: 3.175rem;
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: 0.94rem;
}
.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer;
}
.form-control-color::-moz-color-swatch {
	height: 1em;
	border-radius: 2px;
}
.form-control-color::-webkit-color-swatch {
	height: 1em;
	border-radius: 2px;
}

.form-select {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-select {
		-webkit-transition: none;
		transition: none;
	}
}
.form-select:focus {
	border-color: #86b7fe;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
	padding-right: 0.75rem;
	background-image: none;
}
.form-select:disabled {
	background-color: #e9ecef;
}
.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #212529;
}

.form-select-sm {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.form-select-lg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}
.form-check .form-check-input {
	float: left;
	margin-left: -1.5em;
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: 0.25em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, 0.25);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}
.form-check-input[type="checkbox"] {
	border-radius: 0.25em;
}
.form-check-input[type="radio"] {
	border-radius: 50%;
}
.form-check-input:active {
	-webkit-filter: brightness(90%);
	filter: brightness(90%);
}
.form-check-input:focus {
	border-color: #86b7fe;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
	background-color: #0d6efd;
	border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
	background-color: #0d6efd;
	border-color: #0d6efd;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
	opacity: 0.5;
}

.form-switch {
	padding-left: 2.5em;
}
.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	-webkit-transition: background-position 0.15s ease-in-out;
	transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-switch .form-check-input {
		-webkit-transition: none;
		transition: none;
	}
}
.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: 0.65;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.form-range:focus {
	outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
	-webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
	border: 0;
}
.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-webkit-transition: background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-webkit-slider-thumb {
		-webkit-transition: none;
		transition: none;
	}
}
.form-range::-webkit-slider-thumb:active {
	background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-moz-transition: background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	-moz-appearance: none;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-moz-range-thumb {
		-moz-transition: none;
		transition: none;
	}
}
.form-range::-moz-range-thumb:active {
	background-color: #b6d4fe;
}
.form-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range:disabled {
	pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.form-floating {
	position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
}
.form-floating > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 0.75rem;
	pointer-events: none;
	border: 1px solid transparent;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: opacity 0.1s ease-in-out,
		-webkit-transform 0.1s ease-in-out;
	transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
		-webkit-transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-floating > label {
		-webkit-transition: none;
		transition: none;
	}
}
.form-floating > .form-control {
	padding: 1rem 0.75rem;
}
.form-floating > .form-control::-webkit-input-placeholder {
	color: transparent;
}
.form-floating > .form-control::-moz-placeholder {
	color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
	color: transparent;
}
.form-floating > .form-control::-ms-input-placeholder {
	color: transparent;
}
.form-floating > .form-control::placeholder {
	color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-select {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	opacity: 0.65;
	-webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
	opacity: 0.65;
	-webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
	z-index: 3;
}
.input-group .btn {
	position: relative;
	z-index: 2;
}
.input-group .btn:focus {
	z-index: 3;
}

.input-group-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 2px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
	padding-right: 3rem;
}

.input-group:not(.has-validation)
	> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group.has-validation
	> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
		.valid-feedback
	):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #198754;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(25, 135, 84, 0.9);
	border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #198754;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #198754;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
	border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
	border-color: #198754;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
	border-color: #198754;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
	background-color: #198754;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
	-webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
	z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
	z-index: 3;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #dc3545;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.9);
	border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #dc3545;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
	border-color: #dc3545;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
	border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
	background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
	color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
	z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
	z-index: 3;
}

.fade {
	-webkit-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
	.fade {
		-webkit-transition: none;
		transition: none;
	}
}
.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	-webkit-transition: height 0.35s ease;
	transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing {
		-webkit-transition: none;
		transition: none;
	}
}
.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	-webkit-transition: width 0.35s ease;
	transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing.collapse-horizontal {
		-webkit-transition: none;
		transition: none;
	}
}

.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}
.dropdown-toggle::after {
	/* display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; */
	display: flex;
	margin-top: 9px;
	/* margin-left: 23px !important;
	margin-right: 23px !important; */

	vertical-align: text-top;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid rgba(0, 0, 0, 0);
	border-bottom: 0;
	border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.125rem;
}

.dropdown-menu-start {
	--bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0;
}

.dropdown-menu-end {
	--bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto;
}

@media (min-width: 576px) {
	.dropdown-menu-sm-start {
		--bs-position: start;
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-sm-end {
		--bs-position: end;
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 768px) {
	.dropdown-menu-md-start {
		--bs-position: start;
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-end {
		--bs-position: end;
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 992px) {
	.dropdown-menu-lg-start {
		--bs-position: start;
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-end {
		--bs-position: end;
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1200px) {
	.dropdown-menu-xl-start {
		--bs-position: start;
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-end {
		--bs-position: end;
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1400px) {
	.dropdown-menu-xxl-start {
		--bs-position: start;
	}
	.dropdown-menu-xxl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xxl-end {
		--bs-position: end;
	}
	.dropdown-menu-xxl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-toggle::after {
	vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropstart .dropdown-toggle::after {
	display: none;
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropstart .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #ebedf2;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #343a40;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #0d6efd;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #343a40;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #343a40;
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
	color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-toolbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.btn-toolbar .input-group {
	width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
	margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 1.875rem;
	padding-left: 1.875rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.6075rem;
	padding-left: 0.6075rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 2.25rem;
	padding-left: 2.25rem;
}

.btn-group-vertical {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
	width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
	margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #0d6efd;
	text-decoration: none;
	-webkit-transition: color 0.15s ease-in-out,
		background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.nav-link {
		-webkit-transition: none;
		transition: none;
	}
}
.nav-link:hover,
.nav-link:focus {
	color: #0a58ca;
}
.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #ebedf2;
}
.nav-tabs .nav-link {
	margin-bottom: -1px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	background: none;
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #ebedf2 #ebedf2 #ebedf2;
	isolation: isolate;
}
.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #343a40;
	background-color: #ffffff;
	border-color: #ebedf2 #ebedf2 #ffffff;
}
.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	background: none;
	border: 0;
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: inherit;
	flex-wrap: inherit;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.navbar-brand {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
}

.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-nav .dropdown-menu {
	position: static;
}

.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	-webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
	transition: -webkit-box-shadow 0.15s ease-in-out;
	transition: box-shadow 0.15s ease-in-out;
	transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.navbar-toggler {
		-webkit-transition: none;
		transition: none;
	}
}
.navbar-toggler:hover {
	text-decoration: none;
}
.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.25rem;
	box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto;
}
.active-staff {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;

	display: flex;
	align-items: center;

	color: #125ecb;
}
table,
th,
td {
	width: "145px";
	textalign: "left";
}
@media (min-width: 576px) {
	.navbar-expand-sm {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-sm .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
	.navbar-expand-sm .offcanvas-header {
		display: none;
	}
	.navbar-expand-sm .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none;
	}
	.navbar-expand-sm .offcanvas-top,
	.navbar-expand-sm .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-sm .offcanvas-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-md .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
	.navbar-expand-md .offcanvas-header {
		display: none;
	}
	.navbar-expand-md .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none;
	}
	.navbar-expand-md .offcanvas-top,
	.navbar-expand-md .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-md .offcanvas-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-lg .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
	.navbar-expand-lg .offcanvas-header {
		display: none;
	}
	.navbar-expand-lg .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none;
	}
	.navbar-expand-lg .offcanvas-top,
	.navbar-expand-lg .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-lg .offcanvas-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 1200px) {
	.navbar-expand-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xl .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none;
	}
	.navbar-expand-xl .offcanvas-top,
	.navbar-expand-xl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xl .offcanvas-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 1400px) {
	.navbar-expand-xxl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xxl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xxl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xxl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none;
	}
	.navbar-expand-xxl .offcanvas-top,
	.navbar-expand-xxl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xxl .offcanvas-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

.navbar-expand {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.navbar-expand .navbar-nav {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
	overflow: visible;
}
.navbar-expand .navbar-collapse {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}
.navbar-expand .navbar-toggler {
	display: none;
}
.navbar-expand .offcanvas-header {
	display: none;
}
.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	visibility: visible !important;
	background-color: transparent;
	border-right: 0;
	border-left: 0;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: none;
	transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
	height: auto;
	border-top: 0;
	border-bottom: 0;
}
.navbar-expand .offcanvas-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
	width: auto;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff;
}

.accordion-button {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	-webkit-transition: color 0.15s ease-in-out,
		background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, border-radius 0.15s ease,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button {
		-webkit-transition: none;
		transition: none;
	}
}
.accordion-button:not(.collapsed) {
	color: #0c63e4;
	background-color: #e7f1ff;
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.accordion-button::after {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button::after {
		-webkit-transition: none;
		transition: none;
	}
}
.accordion-button:hover {
	z-index: 2;
}
.accordion-button:focus {
	z-index: 3;
	border-color: #86b7fe;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
	margin-bottom: 0;
}

.accordion-item {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
	border-top: 0;
}
.accordion-item:last-of-type {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-body {
	padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
	border-width: 0;
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
	border-top: 0;
}
.accordion-flush .accordion-item:last-child {
	border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
	border-radius: 0;
}

.breadcrumb {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 0.56rem 1.13rem;
	margin-bottom: 1rem;
	font-size: 0.875rem;
	list-style: none;
	background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/")
		/* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
	color: #495057;
}

.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	justify-content: end;
}

.page-link {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 19px;
	color: #5a616b;
	font-weight: 500;
	text-decoration: none;
	background-color: #fff;
	border: 0;
	-webkit-transition: color 0.15s ease-in-out,
		background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.page-link {
		font-size: 13px;
		line-height: 19px;
		color: #5a616b;
		-webkit-transition: none;
		transition: none;
	}
}
.page-link:hover {
	z-index: 2;
	color: #0a58ca;
	background-color: #e9ecef;
	border-color: #dee2e6;
}
.page-link:focus {
	z-index: 3;
	color: #0a58ca;
	background-color: #e9ecef;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}

.page-item.active .page-link {
	/* z-index: 3; */
	color: #fff;
	background: #0b0b0b;
	border-radius: 6px;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
}

.page-link {
	padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: 0.75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
}
.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3rem;
}
.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

.alert-primary {
	color: #6d4199;
	background-color: #f0e2ff;
	border-color: #e9d3ff;
}
.alert-primary .alert-link {
	color: #57347a;
}

.alert-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
	border-color: #edebeb;
}
.alert-secondary .alert-link {
	color: #3e3d3d;
}

.alert-success {
	color: #0b5348;
	background-color: #d1f5f0;
	border-color: #bbf1e9;
}
.alert-success .alert-link {
	color: #09423a;
}

.alert-info {
	color: #0f5388;
	background-color: #d1e8f9;
	border-color: #badcf7;
}
.alert-info .alert-link {
	color: #0c426d;
}

.alert-warning {
	color: #665608;
	background-color: #fff7d0;
	border-color: #fff3b8;
}
.alert-warning .alert-link {
	color: #524506;
}

.alert-danger {
	color: #984a5a;
	background-color: #ffe5ea;
	border-color: #ffd8e0;
}
.alert-danger .alert-link {
	color: #7a3b48;
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe;
}
.alert-light .alert-link {
	color: #4f5050;
}

.alert-dark {
	color: #252d37;
	background-color: #d8dbde;
	border-color: #c5c9ce;
}
.alert-dark .alert-link {
	color: #1e242c;
}

@-webkit-keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}

.progress {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}
/* 
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } } */

.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	-webkit-animation: 1s linear infinite progress-bar-stripes;
	animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		-webkit-animation: none;
		animation: none;
	}
}

.list-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section;
}
.list-group-numbered > li::before {
	content: counters(section, ".") ". ";
	counter-increment: section;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}
.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}
.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd;
}
.list-group-item + .list-group-item {
	border-top-width: 0;
}
.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-horizontal {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

@media (min-width: 576px) {
	.list-group-horizontal-sm {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 768px) {
	.list-group-horizontal-md {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 992px) {
	.list-group-horizontal-lg {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1200px) {
	.list-group-horizontal-xl {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1400px) {
	.list-group-horizontal-xxl {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

.list-group-flush {
	border-radius: 0;
}
.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-primary {
	color: #6d4199;
	background-color: #f0e2ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #6d4199;
	background-color: #d8cbe6;
}
.list-group-item-primary.list-group-item-action.active {
	color: #ffffff;
	background-color: #6d4199;
	border-color: #6d4199;
}

.list-group-item-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #4e4c4c;
	background-color: #dbdada;
}
.list-group-item-secondary.list-group-item-action.active {
	color: #ffffff;
	background-color: #4e4c4c;
	border-color: #4e4c4c;
}

.list-group-item-success {
	color: #0b5348;
	background-color: #d1f5f0;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #0b5348;
	background-color: #bcddd8;
}
.list-group-item-success.list-group-item-action.active {
	color: #ffffff;
	background-color: #0b5348;
	border-color: #0b5348;
}

.list-group-item-info {
	color: #0f5388;
	background-color: #d1e8f9;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #0f5388;
	background-color: #bcd1e0;
}
.list-group-item-info.list-group-item-action.active {
	color: #ffffff;
	background-color: #0f5388;
	border-color: #0f5388;
}

.list-group-item-warning {
	color: #665608;
	background-color: #fff7d0;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #665608;
	background-color: #e6debb;
}
.list-group-item-warning.list-group-item-action.active {
	color: #ffffff;
	background-color: #665608;
	border-color: #665608;
}

.list-group-item-danger {
	color: #984a5a;
	background-color: #ffe5ea;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #984a5a;
	background-color: #e6ced3;
}
.list-group-item-danger.list-group-item-action.active {
	color: #ffffff;
	background-color: #984a5a;
	border-color: #984a5a;
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
	color: #ffffff;
	background-color: #636464;
	border-color: #636464;
}

.list-group-item-dark {
	color: #252d37;
	background-color: #d8dbde;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #252d37;
	background-color: #c2c5c8;
}
.list-group-item-dark.list-group-item-action.active {
	color: #ffffff;
	background-color: #252d37;
	border-color: #252d37;
}

.btn-close-white {
	-webkit-filter: invert(1) grayscale(100%) brightness(200%);
	filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.toast.showing {
	opacity: 0;
}
.toast:not(.show) {
	display: none;
}

.toast-container {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 100%;
	pointer-events: none;
}
.toast-container > :not(:last-child) {
	margin-bottom: 0.75rem;
}

.toast-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
	margin-right: -0.375rem;
	margin-left: 0.75rem;
}

.toast-body {
	padding: 0.75rem;
	word-wrap: break-word;
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.75rem;
	word-wrap: break-word;
	opacity: 0;
}
.tooltip.show {
	opacity: 0.9;
}
.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
	bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
	top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.4rem 0.75rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.375rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0 /* rtl:ignore */;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}
.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
	top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 1rem 1rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel.pointer-event {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}
.carousel-inner::after {
	display: block;
	clear: both;
	content: "";
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-item {
		-webkit-transition: none;
		transition: none;
	}
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
	opacity: 0;
	-webkit-transition-property: opacity;
	transition-property: opacity;
	-webkit-transform: none;
	transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	-webkit-transition: opacity 0s 0.6s;
	transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-fade .active.carousel-item-start,
	.carousel-fade .active.carousel-item-end {
		-webkit-transition: none;
		transition: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	-webkit-transition: opacity 0.15s ease;
	transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-control-prev,
	.carousel-control-next {
		-webkit-transition: none;
		transition: none;
	}
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}
.carousel-indicators [data-bs-target] {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	-webkit-transition: opacity 0.6s ease;
	transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-indicators [data-bs-target] {
		-webkit-transition: none;
		transition: none;
	}
}
.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
	-webkit-filter: invert(1) grayscale(100);
	filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000;
}

.carousel-dark .carousel-caption {
	color: #000;
}

@-webkit-keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg) /* rtl:ignore */;
		transform: rotate(360deg) /* rtl:ignore */;
	}
}

@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg) /* rtl:ignore */;
		transform: rotate(360deg) /* rtl:ignore */;
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: 0.75s linear infinite spinner-border;
	animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: 0.75s linear infinite spinner-grow;
	animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
	.spinner-border,
	.spinner-grow {
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s;
	}
}

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1045;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	-webkit-transition: -webkit-transform 0.3s ease-in-out;
	transition: -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.offcanvas {
		-webkit-transition: none;
		transition: none;
	}
}

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.offcanvas-backdrop.fade {
	opacity: 0;
}
.offcanvas-backdrop.show {
	opacity: 0.5;
}

.offcanvas-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin-top: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.offcanvas-body {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding: 1rem 1rem;
	overflow-y: auto;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.offcanvas.show {
	-webkit-transform: none;
	transform: none;
}

.placeholder {
	display: inline-block;
	min-height: 1em;
	vertical-align: middle;
	cursor: wait;
	background-color: currentColor;
	opacity: 0.5;
}
.placeholder.btn::before {
	display: inline-block;
	content: "";
}

.placeholder-xs {
	min-height: 0.6em;
}

.placeholder-sm {
	min-height: 0.8em;
}

.placeholder-lg {
	min-height: 1.2em;
}

.placeholder-glow .placeholder {
	-webkit-animation: placeholder-glow 2s ease-in-out infinite;
	animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}

@keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}

.placeholder-wave {
	-webkit-mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	-webkit-mask-size: 200% 100%;
	mask-size: 200% 100%;
	-webkit-animation: placeholder-wave 2s linear infinite;
	animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0%;
		mask-position: -200% 0%;
	}
}

@keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0%;
		mask-position: -200% 0%;
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.link-primary {
	color: #b66dff;
}
.link-primary:hover,
.link-primary:focus {
	color: #c58aff;
}

.link-secondary {
	color: #c3bdbd;
}
.link-secondary:hover,
.link-secondary:focus {
	color: #cfcaca;
}

.link-success {
	color: #1bcfb4;
}
.link-success:hover,
.link-success:focus {
	color: #49d9c3;
}

.link-info {
	color: #198ae3;
}
.link-info:hover,
.link-info:focus {
	color: #47a1e9;
}

.link-warning {
	color: #fed713;
}
.link-warning:hover,
.link-warning:focus {
	color: #fedf42;
}

.link-danger {
	color: #fe7c96;
}
.link-danger:hover,
.link-danger:focus {
	color: #fe96ab;
}

.link-light {
	color: #f8f9fa;
}
.link-light:hover,
.link-light:focus {
	color: #f9fafb;
}

.link-dark {
	color: #3e4b5b;
}
.link-dark:hover,
.link-dark:focus {
	color: #323c49;
}

.ratio {
	position: relative;
	width: 100%;
}
.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: "";
}
.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%;
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020;
}

@media (min-width: 576px) {
	.sticky-sm-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 768px) {
	.sticky-md-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 992px) {
	.sticky-lg-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1200px) {
	.sticky-xl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1400px) {
	.sticky-xxl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.hstack {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

.vstack {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.vr {
	display: inline-block;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: 0.25;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.float-start {
	float: left !important;
}

.float-end {
	float: right !important;
}

.float-none {
	float: none !important;
}

.opacity-0 {
	opacity: 0 !important;
}

.opacity-25 {
	opacity: 0.25 !important;
}

.opacity-50 {
	opacity: 0.5 !important;
}

.opacity-75 {
	opacity: 0.75 !important;
}

.opacity-100 {
	opacity: 1 !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex,
.page-header,
.loader-demo-box,
.list-wrapper ul li,
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.d-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.shadow {
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
	-webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
	-webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}

.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}

.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}

.translate-middle {
	-webkit-transform: translate(-50%, -50%) !important;
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	-webkit-transform: translateX(-50%) !important;
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	-webkit-transform: translateY(-50%) !important;
	transform: translateY(-50%) !important;
}

.border,
.loader-demo-box {
	border: 1px solid #ebedf2 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top {
	border-top: 1px solid #ebedf2 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end {
	border-right: 1px solid #ebedf2 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #ebedf2 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start {
	border-left: 1px solid #ebedf2 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #b66dff !important;
}

.border-secondary,
.loader-demo-box {
	border-color: #c3bdbd !important;
}

.border-success {
	border-color: #1bcfb4 !important;
}

.border-info {
	border-color: #198ae3 !important;
}

.border-warning {
	border-color: #fed713 !important;
}

.border-danger {
	border-color: #fe7c96 !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #3e4b5b !important;
}

.border-white {
	border-color: #ffffff !important;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.vw-100 {
	width: 100vw !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mh-100 {
	max-height: 100% !important;
}

/* .vh-100 {
	height: 100vh !important;
} */

.min-vh-100 {
	min-height: 100vh !important;
}

.flex-fill {
	-webkit-box-flex: 1 !important;
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}

.flex-row {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}

.flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.flex-row-reverse {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: reverse !important;
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: reverse !important;
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	-webkit-box-flex: 0 !important;
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}

.flex-grow-1 {
	-webkit-box-flex: 1 !important;
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}

.gap-0 {
	gap: 0 !important;
}

.gap-1 {
	gap: 0.25rem !important;
}

.gap-2 {
	gap: 0.5rem !important;
}

.gap-3 {
	gap: 1rem !important;
}

.gap-4 {
	gap: 1.5rem !important;
}

.gap-5 {
	gap: 3rem !important;
}

.justify-content-start,
.list-wrapper ul li {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between,
.page-header {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.justify-content-evenly {
	-webkit-box-pack: space-evenly !important;
	-ms-flex-pack: space-evenly !important;
	justify-content: space-evenly !important;
}

.align-items-start {
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.align-items-end {
	-webkit-box-align: end !important;
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.align-items-center,
.page-header,
.loader-demo-box,
.list-wrapper ul li,
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.align-items-baseline {
	-webkit-box-align: baseline !important;
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}

.align-items-stretch {
	-webkit-box-align: stretch !important;
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}

.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}

.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}

.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}

.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}

.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}

.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}

.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}

.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}

.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}

.order-first {
	-webkit-box-ordinal-group: 0 !important;
	-ms-flex-order: -1 !important;
	order: -1 !important;
}

.order-0 {
	-webkit-box-ordinal-group: 1 !important;
	-ms-flex-order: 0 !important;
	order: 0 !important;
}

.order-1 {
	-webkit-box-ordinal-group: 2 !important;
	-ms-flex-order: 1 !important;
	order: 1 !important;
}

.order-2 {
	-webkit-box-ordinal-group: 3 !important;
	-ms-flex-order: 2 !important;
	order: 2 !important;
}

.order-3 {
	-webkit-box-ordinal-group: 4 !important;
	-ms-flex-order: 3 !important;
	order: 3 !important;
}

.order-4 {
	-webkit-box-ordinal-group: 5 !important;
	-ms-flex-order: 4 !important;
	order: 4 !important;
}

.order-5 {
	-webkit-box-ordinal-group: 6 !important;
	-ms-flex-order: 5 !important;
	order: 5 !important;
}

.order-last {
	-webkit-box-ordinal-group: 7 !important;
	-ms-flex-order: 6 !important;
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0,
.demo-modal .modal-dialog {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo .circle-progress {
	margin-top: 0.5rem !important;
}

.mt-3,
.template-demo > .btn,
.template-demo > .btn-toolbar {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0,
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo > .dropdown {
	margin-right: 0.5rem !important;
}

.me-3,
.template-demo > .btn,
.template-demo > .btn-toolbar {
	margin-right: 1rem !important;
}

.me-4,
.rtl .navbar .navbar-menu-wrapper .search-field {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto,
.rtl .list-wrapper .remove {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3,
.template-demo .circle-progress-block {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0,
.rtl .preview-list .preview-item .preview-thumbnail .preview-icon i,
.rtl .list-wrapper .remove,
.rtl .navbar .navbar-menu-wrapper .search-field {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2,
.btn-toolbar .btn-group + .btn-group {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto,
.list-wrapper .remove {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2,
.template-demo .circle-progress-block {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3,
.card-revenue-table .revenue-item {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0,
.card-revenue-table .revenue-item:first-child {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0,
.card-revenue-table .revenue-item:last-child {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
	font-size: 1.25rem !important;
}

.fs-6 {
	font-size: 1rem !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}

.image_loader {
	top: 50% !important;
}

.loader_download {
	position: relative;
	top: 17.5%;
	left: 46%;
}

.assgin_qc {
	position: relative;
	top: 17.5%;
	left: 130%;
}

.upload_loader {
	margin-left: 18px;
}

/* rtl:begin:remove */
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary,
.list-wrapper .completed .remove {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary,
.list-wrapper .remove {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white,
.navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-secondary .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
	--bs-text-opacity: 1;
	color: #9c9fa6 !important;
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important;
}

.text-opacity-25 {
	--bs-text-opacity: 0.25;
}

.text-opacity-50 {
	--bs-text-opacity: 0.5;
}

.text-opacity-75 {
	--bs-text-opacity: 0.75;
}

.text-opacity-100 {
	--bs-text-opacity: 1;
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-primary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-secondary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-success-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-warning-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-body-bg-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent !important;
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
	--bs-bg-opacity: 1;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.user-select-all {
	-webkit-user-select: all !important;
	-moz-user-select: all !important;
	-ms-user-select: all !important;
	user-select: all !important;
}

.user-select-auto {
	-webkit-user-select: auto !important;
	-moz-user-select: auto !important;
	-ms-user-select: auto !important;
	user-select: auto !important;
}

.user-select-none {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.rounded,
.loader-demo-box {
	border-radius: 0.25rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.rounded-1 {
	border-radius: 0.2rem !important;
}

.rounded-2 {
	border-radius: 0.25rem !important;
}

.rounded-3 {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}

.rounded-end {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
	border-bottom-left-radius: 0.25rem !important;
	border-top-left-radius: 0.25rem !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}
.modal-body-body---delete {
	display: flex;
	gap: 10px;
	margin: 0px;
	width: max-content;
}
.add-work--title {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
}
.add-work--content {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
}
.modal-content {
	width: max-content !important;
	max-height: 44em;
	min-height: 44em;
}
@media (min-width: 576px) {
	.float-sm-start {
		float: left !important;
	}
	.float-sm-end {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-grid {
		display: grid !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-sm-none {
		display: none !important;
	}
	.flex-sm-fill {
		-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-sm-row {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-sm-column {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-sm-grow-0 {
		-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.flex-sm-wrap-branch {
		flex-wrap: wrap !important;
		flex-direction: row-reverse;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
		flex-shrink: 1 !important;
		/* justify-content: ; */
		/* width: 90%; */
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.gap-sm-0 {
		gap: 0 !important;
	}
	.gap-sm-1 {
		gap: 0.25rem !important;
	}
	.gap-sm-2 {
		gap: 0.5rem !important;
	}
	.gap-sm-3 {
		gap: 1rem !important;
	}
	.gap-sm-4 {
		gap: 1.5rem !important;
	}
	.gap-sm-5 {
		gap: 3rem !important;
	}
	.justify-content-sm-start {
		-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-sm-between {
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.justify-content-sm-evenly {
		-webkit-box-pack: space-evenly !important;
		-ms-flex-pack: space-evenly !important;
		justify-content: space-evenly !important;
	}
	.align-items-sm-start {
		-webkit-box-align: start !important;
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-sm-baseline {
		-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.order-sm-first {
		-webkit-box-ordinal-group: 0 !important;
		-ms-flex-order: -1 !important;
		order: -1 !important;
	}
	.order-sm-0 {
		-webkit-box-ordinal-group: 1 !important;
		-ms-flex-order: 0 !important;
		order: 0 !important;
	}
	.order-sm-1 {
		-webkit-box-ordinal-group: 2 !important;
		-ms-flex-order: 1 !important;
		order: 1 !important;
	}
	.order-sm-2 {
		-webkit-box-ordinal-group: 3 !important;
		-ms-flex-order: 2 !important;
		order: 2 !important;
	}
	.order-sm-3 {
		-webkit-box-ordinal-group: 4 !important;
		-ms-flex-order: 3 !important;
		order: 3 !important;
	}
	.order-sm-4 {
		-webkit-box-ordinal-group: 5 !important;
		-ms-flex-order: 4 !important;
		order: 4 !important;
	}
	.order-sm-5 {
		-webkit-box-ordinal-group: 6 !important;
		-ms-flex-order: 5 !important;
		order: 5 !important;
	}
	.order-sm-last {
		-webkit-box-ordinal-group: 7 !important;
		-ms-flex-order: 6 !important;
		order: 6 !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.me-sm-0 {
		margin-right: 0 !important;
	}
	.me-sm-1 {
		margin-right: 0.25rem !important;
	}
	.me-sm-2 {
		margin-right: 0.5rem !important;
	}
	.me-sm-3 {
		margin-right: 1rem !important;
	}
	.me-sm-4 {
		margin-right: 1.5rem !important;
	}
	.me-sm-5 {
		margin-right: 3rem !important;
	}
	.me-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ms-sm-0 {
		margin-left: 0 !important;
	}
	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}
	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}
	.ms-sm-3 {
		margin-left: 1rem !important;
	}
	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}
	.ms-sm-5 {
		margin-left: 3rem !important;
	}
	.ms-sm-auto {
		margin-left: auto !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pe-sm-0 {
		padding-right: 0 !important;
	}
	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pe-sm-3 {
		padding-right: 1rem !important;
	}
	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pe-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-0 {
		padding-left: 0 !important;
	}
	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}
	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}
	.ps-sm-3 {
		padding-left: 1rem !important;
	}
	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}
	.ps-sm-5 {
		padding-left: 3rem !important;
	}
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.float-md-start {
		float: left !important;
	}
	.float-md-end {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-grid {
		display: grid !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-md-none {
		display: none !important;
	}
	.flex-md-fill {
		-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-row {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-grow-0 {
		-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.gap-md-0 {
		gap: 0 !important;
	}
	.gap-md-1 {
		gap: 0.25rem !important;
	}
	.gap-md-2 {
		gap: 0.5rem !important;
	}
	.gap-md-3 {
		gap: 1rem !important;
	}
	.gap-md-4 {
		gap: 1.5rem !important;
	}
	.gap-md-5 {
		gap: 3rem !important;
	}
	.justify-content-md-start {
		-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.justify-content-md-evenly {
		-webkit-box-pack: space-evenly !important;
		-ms-flex-pack: space-evenly !important;
		justify-content: space-evenly !important;
	}
	.align-items-md-start {
		-webkit-box-align: start !important;
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.order-md-first {
		-webkit-box-ordinal-group: 0 !important;
		-ms-flex-order: -1 !important;
		order: -1 !important;
	}
	.order-md-0 {
		-webkit-box-ordinal-group: 1 !important;
		-ms-flex-order: 0 !important;
		order: 0 !important;
	}
	.order-md-1 {
		-webkit-box-ordinal-group: 2 !important;
		-ms-flex-order: 1 !important;
		order: 1 !important;
	}
	.order-md-2 {
		-webkit-box-ordinal-group: 3 !important;
		-ms-flex-order: 2 !important;
		order: 2 !important;
	}
	.order-md-3 {
		-webkit-box-ordinal-group: 4 !important;
		-ms-flex-order: 3 !important;
		order: 3 !important;
	}
	.order-md-4 {
		-webkit-box-ordinal-group: 5 !important;
		-ms-flex-order: 4 !important;
		order: 4 !important;
	}
	.order-md-5 {
		-webkit-box-ordinal-group: 6 !important;
		-ms-flex-order: 5 !important;
		order: 5 !important;
	}
	.order-md-last {
		-webkit-box-ordinal-group: 7 !important;
		-ms-flex-order: 6 !important;
		order: 6 !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.me-md-0 {
		margin-right: 0 !important;
	}
	.me-md-1 {
		margin-right: 0.25rem !important;
	}
	.me-md-2 {
		margin-right: 0.5rem !important;
	}
	.me-md-3 {
		margin-right: 1rem !important;
	}
	.me-md-4 {
		margin-right: 1.5rem !important;
	}
	.me-md-5 {
		margin-right: 3rem !important;
	}
	.me-md-auto {
		margin-right: auto !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ms-md-0 {
		margin-left: 0 !important;
	}
	.ms-md-1 {
		margin-left: 0.25rem !important;
	}
	.ms-md-2 {
		margin-left: 0.5rem !important;
	}
	.ms-md-3 {
		margin-left: 1rem !important;
	}
	.ms-md-4 {
		margin-left: 1.5rem !important;
	}
	.ms-md-5 {
		margin-left: 3rem !important;
	}
	.ms-md-auto {
		margin-left: auto !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pe-md-0 {
		padding-right: 0 !important;
	}
	.pe-md-1 {
		padding-right: 0.25rem !important;
	}
	.pe-md-2 {
		padding-right: 0.5rem !important;
	}
	.pe-md-3 {
		padding-right: 1rem !important;
	}
	.pe-md-4 {
		padding-right: 1.5rem !important;
	}
	.pe-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.ps-md-0 {
		padding-left: 0 !important;
	}
	.ps-md-1 {
		padding-left: 0.25rem !important;
	}
	.ps-md-2 {
		padding-left: 0.5rem !important;
	}
	.ps-md-3 {
		padding-left: 1rem !important;
	}
	.ps-md-4 {
		padding-left: 1.5rem !important;
	}
	.ps-md-5 {
		padding-left: 3rem !important;
	}
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.float-lg-start {
		float: left !important;
	}
	.float-lg-end {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-grid {
		display: grid !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.flex-lg-fill {
		-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-row {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-grow-0 {
		-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.gap-lg-0 {
		gap: 0 !important;
	}
	.gap-lg-1 {
		gap: 0.25rem !important;
	}
	.gap-lg-2 {
		gap: 0.5rem !important;
	}
	.gap-lg-3 {
		gap: 1rem !important;
	}
	.gap-lg-4 {
		gap: 1.5rem !important;
	}
	.gap-lg-5 {
		gap: 3rem !important;
	}
	.justify-content-lg-start {
		-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.justify-content-lg-evenly {
		-webkit-box-pack: space-evenly !important;
		-ms-flex-pack: space-evenly !important;
		justify-content: space-evenly !important;
	}
	.align-items-lg-start {
		-webkit-box-align: start !important;
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.order-lg-first {
		-webkit-box-ordinal-group: 0 !important;
		-ms-flex-order: -1 !important;
		order: -1 !important;
	}
	.order-lg-0 {
		-webkit-box-ordinal-group: 1 !important;
		-ms-flex-order: 0 !important;
		order: 0 !important;
	}
	.order-lg-1 {
		-webkit-box-ordinal-group: 2 !important;
		-ms-flex-order: 1 !important;
		order: 1 !important;
	}
	.order-lg-2 {
		-webkit-box-ordinal-group: 3 !important;
		-ms-flex-order: 2 !important;
		order: 2 !important;
	}
	.order-lg-3 {
		-webkit-box-ordinal-group: 4 !important;
		-ms-flex-order: 3 !important;
		order: 3 !important;
	}
	.order-lg-4 {
		-webkit-box-ordinal-group: 5 !important;
		-ms-flex-order: 4 !important;
		order: 4 !important;
	}
	.order-lg-5 {
		-webkit-box-ordinal-group: 6 !important;
		-ms-flex-order: 5 !important;
		order: 5 !important;
	}
	.order-lg-last {
		-webkit-box-ordinal-group: 7 !important;
		-ms-flex-order: 6 !important;
		order: 6 !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.me-lg-0 {
		margin-right: 0 !important;
	}
	.me-lg-1 {
		margin-right: 0.25rem !important;
	}
	.me-lg-2 {
		margin-right: 0.5rem !important;
	}
	.me-lg-3 {
		margin-right: 1rem !important;
	}
	.me-lg-4 {
		margin-right: 1.5rem !important;
	}
	.me-lg-5 {
		margin-right: 3rem !important;
	}
	.me-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ms-lg-0 {
		margin-left: 0 !important;
	}
	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}
	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}
	.ms-lg-3 {
		margin-left: 1rem !important;
	}
	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}
	.ms-lg-5 {
		margin-left: 3rem !important;
	}
	.ms-lg-auto {
		margin-left: auto !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pe-lg-0 {
		padding-right: 0 !important;
	}
	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pe-lg-3 {
		padding-right: 1rem !important;
	}
	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pe-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-0 {
		padding-left: 0 !important;
	}
	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}
	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}
	.ps-lg-3 {
		padding-left: 1rem !important;
	}
	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}
	.ps-lg-5 {
		padding-left: 3rem !important;
	}
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-start {
		float: left !important;
	}
	.float-xl-end {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-grid {
		display: grid !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.flex-xl-fill {
		-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-row {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-grow-0 {
		-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.gap-xl-0 {
		gap: 0 !important;
	}
	.gap-xl-1 {
		gap: 0.25rem !important;
	}
	.gap-xl-2 {
		gap: 0.5rem !important;
	}
	.gap-xl-3 {
		gap: 1rem !important;
	}
	.gap-xl-4 {
		gap: 1.5rem !important;
	}
	.gap-xl-5 {
		gap: 3rem !important;
	}
	.justify-content-xl-start {
		-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.justify-content-xl-evenly {
		-webkit-box-pack: space-evenly !important;
		-ms-flex-pack: space-evenly !important;
		justify-content: space-evenly !important;
	}
	.align-items-xl-start {
		-webkit-box-align: start !important;
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.order-xl-first {
		-webkit-box-ordinal-group: 0 !important;
		-ms-flex-order: -1 !important;
		order: -1 !important;
	}
	.order-xl-0 {
		-webkit-box-ordinal-group: 1 !important;
		-ms-flex-order: 0 !important;
		order: 0 !important;
	}
	.order-xl-1 {
		-webkit-box-ordinal-group: 2 !important;
		-ms-flex-order: 1 !important;
		order: 1 !important;
	}
	.order-xl-2 {
		-webkit-box-ordinal-group: 3 !important;
		-ms-flex-order: 2 !important;
		order: 2 !important;
	}
	.order-xl-3 {
		-webkit-box-ordinal-group: 4 !important;
		-ms-flex-order: 3 !important;
		order: 3 !important;
	}
	.order-xl-4 {
		-webkit-box-ordinal-group: 5 !important;
		-ms-flex-order: 4 !important;
		order: 4 !important;
	}
	.order-xl-5 {
		-webkit-box-ordinal-group: 6 !important;
		-ms-flex-order: 5 !important;
		order: 5 !important;
	}
	.order-xl-last {
		-webkit-box-ordinal-group: 7 !important;
		-ms-flex-order: 6 !important;
		order: 6 !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.me-xl-0 {
		margin-right: 0 !important;
	}
	.me-xl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xl-3 {
		margin-right: 1rem !important;
	}
	.me-xl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xl-5 {
		margin-right: 3rem !important;
	}
	.me-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ms-xl-0 {
		margin-left: 0 !important;
	}
	.ms-xl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xl-3 {
		margin-left: 1rem !important;
	}
	.ms-xl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xl-5 {
		margin-left: 3rem !important;
	}
	.ms-xl-auto {
		margin-left: auto !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pe-xl-0 {
		padding-right: 0 !important;
	}
	.pe-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xl-3 {
		padding-right: 1rem !important;
	}
	.pe-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-0 {
		padding-left: 0 !important;
	}
	.ps-xl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xl-3 {
		padding-left: 1rem !important;
	}
	.ps-xl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xl-5 {
		padding-left: 3rem !important;
	}
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

@media (min-width: 1400px) {
	.float-xxl-start {
		float: left !important;
	}
	.float-xxl-end {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-grid {
		display: grid !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-xxl-none {
		display: none !important;
	}
	.flex-xxl-fill {
		-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xxl-row {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xxl-column {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xxl-grow-0 {
		-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.flex-xxl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.gap-xxl-0 {
		gap: 0 !important;
	}
	.gap-xxl-1 {
		gap: 0.25rem !important;
	}
	.gap-xxl-2 {
		gap: 0.5rem !important;
	}
	.gap-xxl-3 {
		gap: 1rem !important;
	}
	.gap-xxl-4 {
		gap: 1.5rem !important;
	}
	.gap-xxl-5 {
		gap: 3rem !important;
	}
	.justify-content-xxl-start {
		-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.justify-content-xxl-evenly {
		-webkit-box-pack: space-evenly !important;
		-ms-flex-pack: space-evenly !important;
		justify-content: space-evenly !important;
	}
	.align-items-xxl-start {
		-webkit-box-align: start !important;
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xxl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xxl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.order-xxl-first {
		-webkit-box-ordinal-group: 0 !important;
		-ms-flex-order: -1 !important;
		order: -1 !important;
	}
	.order-xxl-0 {
		-webkit-box-ordinal-group: 1 !important;
		-ms-flex-order: 0 !important;
		order: 0 !important;
	}
	.order-xxl-1 {
		-webkit-box-ordinal-group: 2 !important;
		-ms-flex-order: 1 !important;
		order: 1 !important;
	}
	.order-xxl-2 {
		-webkit-box-ordinal-group: 3 !important;
		-ms-flex-order: 2 !important;
		order: 2 !important;
	}
	.order-xxl-3 {
		-webkit-box-ordinal-group: 4 !important;
		-ms-flex-order: 3 !important;
		order: 3 !important;
	}
	.order-xxl-4 {
		-webkit-box-ordinal-group: 5 !important;
		-ms-flex-order: 4 !important;
		order: 4 !important;
	}
	.order-xxl-5 {
		-webkit-box-ordinal-group: 6 !important;
		-ms-flex-order: 5 !important;
		order: 5 !important;
	}
	.order-xxl-last {
		-webkit-box-ordinal-group: 7 !important;
		-ms-flex-order: 6 !important;
		order: 6 !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xxl-0 {
		margin-top: 0 !important;
	}
	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xxl-3 {
		margin-top: 1rem !important;
	}
	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xxl-5 {
		margin-top: 3rem !important;
	}
	.mt-xxl-auto {
		margin-top: auto !important;
	}
	.me-xxl-0 {
		margin-right: 0 !important;
	}
	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xxl-3 {
		margin-right: 1rem !important;
	}
	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xxl-5 {
		margin-right: 3rem !important;
	}
	.me-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xxl-auto {
		margin-bottom: auto !important;
	}
	.ms-xxl-0 {
		margin-left: 0 !important;
	}
	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xxl-3 {
		margin-left: 1rem !important;
	}
	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xxl-5 {
		margin-left: 3rem !important;
	}
	.ms-xxl-auto {
		margin-left: auto !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xxl-0 {
		padding-top: 0 !important;
	}
	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xxl-3 {
		padding-top: 1rem !important;
	}
	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xxl-5 {
		padding-top: 3rem !important;
	}
	.pe-xxl-0 {
		padding-right: 0 !important;
	}
	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xxl-3 {
		padding-right: 1rem !important;
	}
	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xxl-0 {
		padding-left: 0 !important;
	}
	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xxl-3 {
		padding-left: 1rem !important;
	}
	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xxl-5 {
		padding-left: 3rem !important;
	}
	.text-xxl-start {
		text-align: left !important;
	}
	.text-xxl-end {
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.fs-1 {
		font-size: 2.5rem !important;
	}
	.fs-2 {
		font-size: 2rem !important;
	}
	.fs-3 {
		font-size: 1.75rem !important;
	}
	.fs-4 {
		font-size: 1.5rem !important;
	}
}

@media print {
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-grid {
		display: grid !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.d-print-none {
		display: none !important;
	}
}

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
.list-wrapper ul li .form-check,
.list-wrapper ul li .form-check .form-check-label {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
}

/* Animation Mixins */
@-webkit-keyframes dropdownAnimation {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
		-webkit-transform: translate3d(0, 0px, 0);
		transform: translate3d(0, 0px, 0);
	}
}
@keyframes dropdownAnimation {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
		-webkit-transform: translate3d(0, 0px, 0);
		transform: translate3d(0, 0px, 0);
	}
}

.dropdownAnimation,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
	-webkit-animation-name: dropdownAnimation;
	animation-name: dropdownAnimation;
	-webkit-animation-duration: 0.25s;
	animation-duration: 0.25s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

.infinite-spin {
	-webkit-animation-name: spin;
	animation-name: spin;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

.form-control,
.form-control:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.form-control {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control:focus {
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

a,
div,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p,
span {
	text-shadow: none;
}

[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
select::-moz-focus-inner {
	outline: 0;
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
	outline-width: 0;
	outline-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline-style: none;
}

textarea {
	resize: none;
	overflow-x: hidden;
}

.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: pointer;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
	outline: 0;
	outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
	outline: 0;
	outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
	outline: 0;
}

a:focus,
input:focus {
	border-color: transparent;
	outline: none;
}

/* Fonts */
/* @font-face {
  font-family: 'ubuntu-light';
  src: url("../fonts/Ubuntu/Ubuntu-Light.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Light.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Light.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-regular';
  src: url("../fonts/Ubuntu/Ubuntu-Regular.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Regular.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-medium';
  src: url("../fonts/Ubuntu/Ubuntu-Medium.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Medium.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-bold';
  src: url("../fonts/Ubuntu/Ubuntu-Bold.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Bold.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype"); } */

.text-twitter {
	color: #2caae1;
}

.text-facebook {
	color: #3b579d;
}

.text-google {
	color: #dc4a38;
}

.text-linkedin {
	color: #0177b5;
}

.text-pinterest {
	color: #cc2127;
}

.text-youtube {
	color: #e52d27;
}

.text-github {
	color: #333333;
}

.text-behance {
	color: #1769ff;
}

.text-dribbble {
	color: #ea4c89;
}

.text-reddit {
	color: #ff4500;
}

.bg-twitter {
	background: #2caae1;
}

.bg-facebook {
	background: #3b579d;
}

.bg-google {
	background: #dc4a38;
}

.bg-linkedin {
	background: #0177b5;
}

.bg-pinterest {
	background: #cc2127;
}

.bg-youtube {
	background: #e52d27;
}

.bg-github {
	background: #333333;
}

.bg-behance {
	background: #1769ff;
}

.bg-dribbble {
	background: #ea4c89;
}

.bg-reddit {
	background: #ff4500;
}

/* Typography */
body {
	font-size: 14px;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

p {
	/* font-size: 0.875rem; */
	font-size: 14px;
	margin-bottom: 0;
}

h1,
.h1,
.h1 {
	font-size: 2.19rem;
}

h2,
.h2,
.h2 {
	font-size: 1.88rem;
}

h3,
.h3,
.h3 {
	font-size: 1.56rem;
}

h5,
.h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6,
.h6 {
	font-size: 0.9375rem;
}

p {
	font-size: 0.9375rem;
	line-height: 1.5;
}
a {
	text-decoration: none;
}
.display-1 {
	font-size: 3.75rem;
}
@media (max-width: 991px) {
	.display-1 {
		font-size: 3rem;
	}
}

.display-2 {
	font-size: 3.125rem;
}
@media (max-width: 991px) {
	.display-2 {
		font-size: 2.5rem;
	}
}

.display-3 {
	font-size: 2.5rem;
}
@media (max-width: 991px) {
	.display-3 {
		font-size: 2rem;
	}
}

.display-4 {
	font-size: 1.875rem;
}
@media (max-width: 991px) {
	.display-4 {
		font-size: 1.5rem;
	}
}

.display-5 {
	font-size: 1.25rem;
}
@media (max-width: 991px) {
	.display-5 {
		font-size: 1rem;
	}
}

.blockquote {
	padding: 1.25rem;
	border: 1px solid #ebedf2;
}

address p {
	margin-bottom: 0;
}

.blockquote-primary {
	border-color: #b66dff;
}
.blockquote-primary .blockquote-footer {
	color: #b66dff;
}

.blockquote-secondary {
	border-color: #c3bdbd;
}
.blockquote-secondary .blockquote-footer {
	color: #c3bdbd;
}

.blockquote-success {
	border-color: #1bcfb4;
}
.blockquote-success .blockquote-footer {
	color: #1bcfb4;
}

.blockquote-info {
	border-color: #198ae3;
}
.blockquote-info .blockquote-footer {
	color: #198ae3;
}

.blockquote-warning {
	border-color: #fed713;
}
.blockquote-warning .blockquote-footer {
	color: #fed713;
}

.blockquote-danger {
	border-color: #fe7c96;
}
.blockquote-danger .blockquote-footer {
	color: #fe7c96;
}

.blockquote-light {
	border-color: #f8f9fa;
}
.blockquote-light .blockquote-footer {
	color: #f8f9fa;
}

.blockquote-dark {
	border-color: #3e4b5b;
}
.blockquote-dark .blockquote-footer {
	color: #3e4b5b;
}

.error-page h1,
.error-page .h1 {
	font-size: 12rem;
}
@media (max-width: 991px) {
	.error-page h1,
	.error-page .h1 {
		font-size: 8rem;
	}
}

.icon-lg {
	font-size: 2.5rem;
}

.icon-md {
	font-size: 1.875rem;
}

.icon-sm {
	font-size: 1rem;
}

/* Miscellanoeous */
body,
html {
	overflow-x: hidden;
	padding-right: 0 !important;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
	overflow: auto;
}

.container-scroller {
	overflow: hidden;
}

pre {
	background: color(gray-lighter);
	padding: 15px;
	font-size: 14px;
}

code {
	padding: 5px;
	color: #fe7c96;
	font-family: "ubuntu-light", sans-serif;
	font-size: 0.875rem;
	border-radius: 4px;
}

.page-header {
	margin: 0 0 1.5rem 0;
}
.page-header .breadcrumb {
	border: 0;
	margin-bottom: 0;
}

.page-title {
	color: #343a40;
	font-size: 1.125rem;
	margin-bottom: 0;
}
.page-title .page-title-icon {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 4px;
	text-align: center;
	-webkit-box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
	box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
}
.page-title .page-title-icon i {
	font-size: 0.9375rem;
	line-height: 36px;
}

/* Footer */
.footer {
	background: #f2edf3;
	color: color(dark);
	border-top: 1px solid #e7dee9;
	padding: 30px 1rem;
	transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	font-size: calc(0.875rem - 0.05rem);
	font-family: "ubuntu-regular", sans-serif;
}
.footer a {
	color: #1bcfb4;
	font-size: inherit;
}
@media (max-width: 991px) {
	.footer {
		margin-left: 0;
		width: 100%;
	}
}

/* Utilities */
.grid-margin,
.purchase-popup {
	margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
	.grid-margin-sm-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.grid-margin-md-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 992px) {
	.grid-margin-lg-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 1200px) {
	.grid-margin-xl-0 {
		margin-bottom: 0;
	}
}

.img-lg {
	width: 92px;
	height: 92px;
}

.img-sm {
	width: 43px;
	height: 43px;
}

.img-xs {
	width: 37px;
	height: 37px;
}

.img-ss,
.image-grouped .text-avatar,
.image-grouped img {
	width: 35px;
	height: 35px;
}

.stretch-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-pack: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
}
.stretch-card > .card {
	width: 100%;
	min-width: 100%;
}

@media (min-width: 576px) {
	.border-right-sm {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 768px) {
	.border-right-md {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 992px) {
	.border-right-lg {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 576px) {
	.border-left-sm {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 768px) {
	.border-left-md {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 992px) {
	.border-left-lg {
		border-left: 1px solid #ebedf2;
	}
}

.text-gray,
.card .card-subtitle {
	color: #8c8c8c;
}

.text-black {
	color: #000000;
}

.text-small {
	font-size: 12px;
}

.flex-grow {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.font-weight-light {
	font-family: "ubuntu-light", sans-serif;
}

.font-weight-bold {
	font-family: "ubuntu-bold", sans-serif;
}

.font-weight-normal {
	font-family: "ubuntu-regular", sans-serif;
}

.image-grouped {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.image-grouped .text-avatar,
.image-grouped img {
	border-radius: 100%;
	margin-left: -10px;
	z-index: 0;
	border: 4px solid #fff;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-property: "box-shadow", "z-index", "transform",
		"border-width", "box-shadow", "transform";
	transition-property: "box-shadow", "z-index", "transform", "border-width",
		"box-shadow", "transform";
	transition-property: "box-shadow", "z-index", "transform", "border-width";
}
.image-grouped .text-avatar:first-child,
.image-grouped img:first-child {
	margin-left: 0;
}
.image-grouped .text-avatar:hover,
.image-grouped img:hover {
	z-index: 1;
	-webkit-box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	border-width: 0;
}
.image-grouped .text-avatar {
	color: #b66dff;
	font-size: 11px;
	font-weight: 600;
}

.aligner-wrapper {
	position: relative;
}
.aligner-wrapper .absolute {
	position: absolute;
}
.aligner-wrapper .absolute.absolute-center {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
}
.aligner-wrapper .absolute.left {
	left: 0;
}
.aligner-wrapper .absolute.right {
	right: 0;
}
.aligner-wrapper .absolute.bottom {
	bottom: 0;
}
.aligner-wrapper .absolute.top {
	top: 0;
}

.v-strock-1 {
	width: 2px;
}

.v-strock-2 {
	width: 3px;
}

.v-strock-3 {
	width: 4px;
}

.v-strock-4 {
	width: 5px;
}

.dot-indicator {
	width: 10px;
	height: 10px;
	border-radius: 100%;
}
.dot-indicator.dot-indicator-sm {
	width: 6px;
	height: 6px;
}

.bg-gradient-primary {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#da8cff),
		to(#9a55ff)
	) !important;
	background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.bg-gradient-secondary {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#e7ebf0),
		to(#868e96)
	) !important;
	background: linear-gradient(to right, #e7ebf0, #868e96) !important;
}

.bg-gradient-success {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#84d9d2),
		to(#07cdae)
	) !important;
	background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}

.bg-gradient-info {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#90caf9),
		color-stop(99%, #047edf)
	) !important;
	background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}

.bg-gradient-warning {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#f6e384),
		to(#ffd500)
	) !important;
	background: linear-gradient(to right, #f6e384, #ffd500) !important;
}

.bg-gradient-danger {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ffbf96),
		to(#fe7096)
	) !important;
	background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

.bg-gradient-light {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#f4f4f4),
		to(#e4e4e9)
	) !important;
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9) !important;
}

.bg-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b) !important;
}

/* Demo Styles */
.template-demo .slider-wrap {
	height: 100px;
}

.template-demo .progress {
	margin-top: 1.5rem;
}

.template-demo > h2,
.template-demo > .h2,
.template-demo > h3,
.template-demo > .h3,
.template-demo > h4,
.template-demo > .h4,
.template-demo > h5,
.template-demo > .h5,
.template-demo > h6,
.template-demo > .h6,
.template-demo > h1,
.template-demo > .h1 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo .ul-slider.noUi-horizontal {
	margin-top: 2rem;
}

.template-demo .ul-slider.noUi-vertical {
	margin-right: 2rem;
}

.template-demo > .dropdown {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.template-demo nav .breadcrumb {
	margin-bottom: 1.375rem;
}

.template-demo nav:last-child .breadcrumb {
	margin-bottom: 0;
}

.template-demo .editable-form > .form-group {
	border-bottom: 1px solid #ebedf2;
	padding-bottom: 0.8rem;
	margin-bottom: 0.8rem;
}

.template-demo .circle-progress {
	padding: 15px;
}

.demo-modal {
	position: static;
	display: block;
}
.demo-modal .modal-dialog.modal-lg {
	max-width: 100%;
}

.loader-demo-box {
	width: 100%;
	height: 200px;
}

.dropdown-menu-static-demo {
	height: 250px;
	margin-bottom: 20px;
}

.rounded-legend ul li {
	list-style-type: none;
	color: #9c9fa6;
	font-size: 0.75rem;
}
.rounded-legend ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 0.5rem;
}
.rtl .rounded-legend ul li .legend-dots {
	margin-left: 0.5rem;
}

.rounded-legend.legend-horizontal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.rounded-legend.legend-horizontal ul li {
	display: inline-block;
	margin-right: 1.5rem;
}
.rtl .rounded-legend.legend-horizontal ul li {
	margin-right: auto;
	margin-left: 1.5rem;
}

.rounded-legend.legend-top-right ul {
	float: right;
}
.rtl .rounded-legend.legend-top-right ul {
	float: left;
}

.rounded-legend.legend-vertical ul li {
	margin-top: 1rem;
}

#proBanner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #000;
}
#proBanner .card-body-padding {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
}
@media (max-width: 766px) {
	#proBanner .card-body-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
#proBanner .buy-now-text {
	color: #ffffff;
}
@media (max-width: 766px) {
	#proBanner .buy-now-text {
		font-size: 10px;
		line-height: 1.5;
	}
}
#proBanner .btn.buy-now-btn {
	background: transparent;
	color: #88b6ff;
	border-radius: 10px;
	padding: 1rem 0;
	border-radius: 4px;
	font-weight: bold;
	font-size: 1rem;
}
@media (max-width: 766px) {
	#proBanner .btn.buy-now-btn {
		min-width: 80px;
		padding: 1rem 0.5rem;
		font-size: 12px;
	}
}
#proBanner .btn#bannerClose i {
	margin-right: 1rem;
	font-size: 1.25rem;
}
@media (max-width: 766px) {
	#proBanner .btn#bannerClose i {
		font-size: 1rem;
		margin-right: 0;
	}
}
#proBanner a {
	text-decoration: none;
}
#proBanner a i {
	font-size: 1.25rem;
}
@media (max-width: 766px) {
	#proBanner a i {
		font-size: 1rem;
	}
}

.proBanner-padding-top {
	padding-top: 80px !important;
}

/* Dashboard */
.card-statistics .highlight-icon {
	height: 53px;
	width: 53px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50px;
}
.card-statistics .highlight-icon i {
	font-size: 27px;
}

.card-statistics .card-col {
	border-right: 1px solid #ebedf2;
}
.card-statistics .card-col:last-child {
	border-right: none;
}
@media (max-width: 575.98px) {
	.card-statistics .card-col {
		padding-left: 0;
		padding-right: 0;
		position: relative;
		border: none;
	}
	.card-statistics .card-col:first-child:after,
	.card-statistics .card-col:first-child:before,
	.card-statistics .card-col:nth-child(2):after,
	.card-statistics .card-col:nth-child(2):before,
	.card-statistics .card-col:nth-child(3):after,
	.card-statistics .card-col:nth-child(3):before {
		content: "";
		position: absolute;
		background: #ebedf2;
	}
	.card-statistics .card-col:first-child:before {
		bottom: 0;
		width: 94.2%;
		height: 1px;
		right: 0;
	}
	.card-statistics .card-col:first-child:after {
		bottom: 0;
		width: 1px;
		height: 100%;
		right: 0;
	}
	.card-statistics .card-col:nth-child(2):before {
		bottom: 0;
		width: 94.2%;
		height: 1px;
		left: 0;
	}
	.card-statistics .card-col:nth-child(3):before {
		width: 1px;
		height: 100%;
		right: 0;
	}
}

.card-revenue-table .revenue-item {
	border-bottom: 1px solid #ebedf2;
}
.card-revenue-table .revenue-item:last-child {
	border-bottom: 0;
}
.card-revenue-table .revenue-item .revenue-desc {
	margin-right: auto;
	width: 80%;
}
.card-revenue-table .revenue-item .revenue-desc p {
	margin-bottom: 0;
}
.card-revenue-table .revenue-item .revenue-amount {
	margin-left: auto;
	width: 40%;
}
.card-revenue-table .revenue-item .revenue-amount p {
	font-size: 1.25rem;
	font-family: "roboto", sans-serif;
	font-weight: 600;
	text-align: right;
}
.rtl .card-revenue-table .revenue-item .revenue-amount p {
	text-align: left;
}

.card-revenue {
	background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
	background-size: cover;
	color: color(white);
}
.card-revenue .highlight-text {
	font-size: 1.875rem;
	font-family: "roboto", sans-serif;
	font-weight: 500;
}
.card-revenue .badge {
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 1.125rem;
	padding: 0.5rem 1.25rem;
}

.product-chart-wrapper {
	height: 92%;
}

#dashboardTrendingProgress {
	width: 60px;
}

.dashboard-bar-chart-legend .col {
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.dashboard-bar-chart-legend .col .bg {
	margin-left: auto;
	margin-right: auto;
	height: 5px;
	width: 30px;
	display: block;
	margin-top: 5px;
}
.dashboard-bar-chart-legend .col:nth-child(1) .bg {
	background: #198ae3;
}
.dashboard-bar-chart-legend .col:nth-child(2) .bg {
	background: #b66dff;
}
.dashboard-bar-chart-legend .col:nth-child(3) .bg {
	background: #fe7c96;
}

.intro-banner {
	background: #dbe4ec;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 20px;
	border-radius: 2px;
}
.intro-banner .banner-image {
	width: 24%;
	max-width: 100%;
}
.intro-banner .banner-image img {
	display: block;
	margin: auto;
}
.intro-banner .content-area {
	width: 58%;
	color: #000000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: end;
}

.sales-mini-tabs {
	border-bottom: none;
}
.sales-mini-tabs .nav-item .nav-link {
	border: none;
	font-weight: 500;
	padding: 5px 15px;
	border-radius: 2px;
	-webkit-transition-duration: 0.7s;
	transition-duration: 0.7s;
	-webkit-transition-property: "color";
	transition-property: "color";
}
.sales-mini-tabs .nav-item .nav-link.active {
	font-weight: 600;
	background: white;
	color: #b66dff;
}

.purchase-popup {
	background: #fff;
	padding: 15px 20px;
	border-radius: 3px;
}
.purchase-popup .btn {
	margin-right: 20px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 5px;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
}
.purchase-popup .btn.download-button {
	background: rgba(249, 249, 249, 0.7);
	color: #969292;
	border: 1px solid #d7d7d7;
}
.purchase-popup .btn.purchase-button {
	background-color: rgba(172, 50, 228, 0.9);
	background-image: -owg-linear-gradient(to right, #da8cff, #9a55ff);
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#da8cff),
		to(#9a55ff)
	);
	background-image: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
	border: none;
	line-height: 1;
	vertical-align: middle;
}
.purchase-popup p {
	margin-bottom: auto;
	margin-top: auto;
	color: #7e7e7e;
	font-weight: 400;
	vertical-align: middle;
	line-height: 1;
}
.purchase-popup i {
	vertical-align: middle;
	line-height: 1;
	margin: auto 0;
	color: #b1b1b1;
}

/*-------------------------------------------------------------------*/
/* === Components === */
/* Badges */
.badge {
	border-radius: 0.125rem;
	font-size: 11px;
	font-weight: initial;
	line-height: 1;
	padding: 0.375rem 0.5625rem;
}
.badge.badge-pill {
	border-radius: 10rem;
}

/*Badge variations*/
.badge-primary {
	border: 1px solid #b66dff;
	background: #b66dff;
	color: #ffffff;
}

.badge-secondary {
	border: 1px solid #c3bdbd;
	background: #c3bdbd;
	color: #ffffff;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
	border: 1px solid #1bcfb4;
	background: #1bcfb4;
	color: #ffffff;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
	border: 1px solid #198ae3;
	background: #198ae3;
	color: #ffffff;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
	border: 1px solid #fed713;
	background: #fed713;
	color: #ffffff;
}

.badge-danger {
	border: 1px solid #fe7c96;
	background: #fe7c96;
	color: #ffffff;
}

.badge-light {
	border: 1px solid #f8f9fa;
	background: #f8f9fa;
	color: #ffffff;
}

.badge-dark {
	border: 1px solid #3e4b5b;
	background: #3e4b5b;
	color: #ffffff;
}

/*Badge gradient variations*/
.badge-gradient-primary {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#da8cff),
		to(#9a55ff)
	);
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
}

.badge-gradient-secondary {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#e7ebf0),
		to(#868e96)
	);
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #ffffff;
}

.badge-gradient-success {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#84d9d2),
		to(#07cdae)
	);
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #ffffff;
}

.badge-gradient-info {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#90caf9),
		color-stop(99%, #047edf)
	);
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #ffffff;
}

.badge-gradient-warning {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#f6e384),
		to(#ffd500)
	);
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #ffffff;
}

.badge-gradient-danger {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ffbf96),
		to(#fe7096)
	);
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #ffffff;
}

.badge-gradient-light {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#f4f4f4),
		to(#e4e4e9)
	);
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #ffffff;
}

.badge-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #ffffff;
}

/*Badge outlined variations*/
.badge-outline-primary {
	color: #b66dff;
	border: 1px solid #b66dff;
}

.badge-outline-secondary {
	color: #c3bdbd;
	border: 1px solid #c3bdbd;
}

.badge-outline-success {
	color: #1bcfb4;
	border: 1px solid #1bcfb4;
}

.badge-outline-info {
	color: #198ae3;
	border: 1px solid #198ae3;
}

.badge-outline-warning {
	color: #fed713;
	border: 1px solid #fed713;
}

.badge-outline-danger {
	color: #fe7c96;
	border: 1px solid #fe7c96;
}

.badge-outline-light {
	color: #f8f9fa;
	border: 1px solid #f8f9fa;
}

.badge-outline-dark {
	color: #3e4b5b;
	border: 1px solid #3e4b5b;
}

/* Bootstrap Progress */
.progress {
	border-radius: 3px;
	height: 8px;
}
.progress .progress-bar {
	border-radius: 3px;
}
.progress.progress-sm {
	height: 0.375rem;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 15px;
}
.progress.progress-xl {
	height: 18px;
}

/* Breadcrumbs */
.breadcrumb {
	border: 1px solid #ebedf2;
}
.breadcrumb .breadcrumb-item {
	font-size: 0.875rem;
}
.breadcrumb .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}
.breadcrumb.breadcrumb-custom {
	padding: 0 0;
	border-color: #dbe3e6;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item {
	font-size: 0.875rem;
	background: #dbe3e6;
	padding: 0.56rem 10px;
	color: #000000;
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:before {
	content: "";
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a {
	position: relative;
	color: inherit;
	border: 1px solid #dbe3e6;
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before,
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
	position: absolute;
	top: -9px;
	width: 0;
	height: 0;
	content: "";
	border-top: 21px solid transparent;
	border-bottom: 21px solid transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
	right: -22px;
	z-index: 3;
	border-left-color: #dbe3e6;
	border-left-style: solid;
	border-left-width: 12px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
	border-top: 21px solid transparent;
	border-bottom: 22px solid transparent;
	border-left: 12px solid #ffffff;
	top: -9px;
	right: -23px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item span {
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}
.breadcrumb.bg-success,
.breadcrumb.bg-dark,
.breadcrumb.bg-danger,
.breadcrumb.bg-warning,
.breadcrumb.bg-primary,
.breadcrumb.bg-info {
	border: none;
}
.breadcrumb.bg-success .breadcrumb-item,
.breadcrumb.bg-dark .breadcrumb-item,
.breadcrumb.bg-danger .breadcrumb-item,
.breadcrumb.bg-warning .breadcrumb-item,
.breadcrumb.bg-primary .breadcrumb-item,
.breadcrumb.bg-info .breadcrumb-item {
	color: #ffffff;
}
.breadcrumb.bg-success .breadcrumb-item:before,
.breadcrumb.bg-dark .breadcrumb-item:before,
.breadcrumb.bg-danger .breadcrumb-item:before,
.breadcrumb.bg-warning .breadcrumb-item:before,
.breadcrumb.bg-primary .breadcrumb-item:before,
.breadcrumb.bg-info .breadcrumb-item:before {
	color: inherit;
}
.breadcrumb.bg-success .breadcrumb-item a,
.breadcrumb.bg-success .breadcrumb-item span,
.breadcrumb.bg-dark .breadcrumb-item a,
.breadcrumb.bg-dark .breadcrumb-item span,
.breadcrumb.bg-danger .breadcrumb-item a,
.breadcrumb.bg-danger .breadcrumb-item span,
.breadcrumb.bg-warning .breadcrumb-item a,
.breadcrumb.bg-warning .breadcrumb-item span,
.breadcrumb.bg-primary .breadcrumb-item a,
.breadcrumb.bg-primary .breadcrumb-item span,
.breadcrumb.bg-info .breadcrumb-item a,
.breadcrumb.bg-info .breadcrumb-item span {
	color: inherit;
}

/* inverse breadcrumb */
.bg-inverse-primary,
.image-grouped .text-avatar {
	background: #f6edff;
	border-color: #b66dff;
}
.bg-inverse-primary .breadcrumb-item,
.image-grouped .text-avatar .breadcrumb-item {
	color: #b66dff;
}
.bg-inverse-primary .breadcrumb-item:before,
.image-grouped .text-avatar .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-primary .breadcrumb-item a,
.image-grouped .text-avatar .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-primary.breadcrumb-custom,
.image-grouped .breadcrumb-custom.text-avatar {
	background: transparent;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
	background: #f6edff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
	color: #b66dff;
	border: none;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
	border-left-color: #f6edff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item span,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
	color: #b66dff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-secondary {
	background: white;
	border-color: #c3bdbd;
}
.bg-inverse-secondary .breadcrumb-item {
	color: #c3bdbd;
}
.bg-inverse-secondary .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-secondary .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-secondary.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
	background: white;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
	color: #c3bdbd;
	border: none;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: white;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
	color: #c3bdbd;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-success {
	background: #7ceedd;
	border-color: #1bcfb4;
}
.bg-inverse-success .breadcrumb-item {
	color: #1bcfb4;
}
.bg-inverse-success .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-success .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-success.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item {
	background: #7ceedd;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
	color: #1bcfb4;
	border: none;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #7ceedd;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
	color: #1bcfb4;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-info {
	background: #89c4f2;
	border-color: #198ae3;
}
.bg-inverse-info .breadcrumb-item {
	color: #198ae3;
}
.bg-inverse-info .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-info .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-info.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item {
	background: #89c4f2;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
	color: #198ae3;
	border: none;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #89c4f2;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
	color: #198ae3;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-warning {
	background: #ffed92;
	border-color: #fed713;
}
.bg-inverse-warning .breadcrumb-item {
	color: #fed713;
}
.bg-inverse-warning .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-warning .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-warning.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
	background: #ffed92;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
	color: #fed713;
	border: none;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #ffed92;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
	color: #fed713;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-danger {
	background: #fffbfb;
	border-color: #fe7c96;
}
.bg-inverse-danger .breadcrumb-item {
	color: #fe7c96;
}
.bg-inverse-danger .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-danger .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-danger.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
	background: #fffbfb;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
	color: #fe7c96;
	border: none;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #fffbfb;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
	color: #fe7c96;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-light {
	background: white;
	border-color: #f8f9fa;
}
.bg-inverse-light .breadcrumb-item {
	color: #f8f9fa;
}
.bg-inverse-light .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-light .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-light.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item {
	background: white;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a {
	color: #f8f9fa;
	border: none;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: white;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item span {
	color: #f8f9fa;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-dark {
	background: #778aa2;
	border-color: #3e4b5b;
}
.bg-inverse-dark .breadcrumb-item {
	color: #3e4b5b;
}
.bg-inverse-dark .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-dark .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-dark.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
	background: #778aa2;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
	color: #3e4b5b;
	border: none;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #778aa2;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
	color: #3e4b5b;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

/* Checkboxes and Radios */
.form-check {
	position: relative;
	display: block;
	margin-top: 15px;
	margin-bottom: 10px;
	padding-left: 0;
}

/* Icons */
.icons-list {
	border-left: 1px solid #ebedf2;
	border-top: 1px solid #ebedf2;
}
.icons-list > div {
	border-bottom: 1px solid #ebedf2;
	border-right: 1px solid #ebedf2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px 15px;
	font-family: "ubuntu-regular", sans-serif;
	font-size: 0.875rem;
}
.icons-list > div i {
	display: inline-block;
	font-size: 20px;
	width: 40px;
	text-align: left;
	color: #b66dff;
}

/* Lists */

ol,
dl {
	padding-left: 1rem;
	font-size: 0.875rem;
}
ul li,
ol li,
dl li {
	line-height: 1.8;
}

.list-ticked,
.list-arrow,
.list-star {
	list-style: none;
	padding: 0;
}
.list-ticked li,
.list-arrow li,
.list-star li {
	padding-left: 1.5rem;
}
.list-ticked li:before,
.list-arrow li:before,
.list-star li:before {
	font-family: "Material Design Icons";
	margin-left: -1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
}

.list-ticked li:before {
	content: "\F12D";
	color: #fe7c96;
}

.list-arrow li:before {
	content: "\F142";
	color: #1bcfb4;
}

.list-star li:before {
	content: "\F4CE";
	color: #fed713;
}

.gradient-bullet-list {
	padding-left: 0;
}
.rtl .gradient-bullet-list {
	padding-right: 0;
}
.gradient-bullet-list li {
	position: relative;
	list-style-type: none;
	padding-left: 25px;
	line-height: 1;
	padding-bottom: 25px;
}
.gradient-bullet-list li:before,
.gradient-bullet-list li:after {
	content: "";
	position: absolute;
}
.gradient-bullet-list li:before {
	top: 0;
	left: 0;
	width: 15px;
	height: 15px;
	border-radius: 100%;
}
.gradient-bullet-list li:after {
	width: 11px;
	height: 11px;
	top: 2px;
	left: 2px;
	background: #fff;
	border-radius: 100%;
}
.gradient-bullet-list li:nth-child(1):before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ffbf96),
		to(#fe7096)
	);
	background: linear-gradient(to right, #ffbf96, #fe7096);
}
.gradient-bullet-list li:nth-child(2):before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#90caf9),
		color-stop(99%, #047edf)
	);
	background: linear-gradient(to right, #90caf9, #047edf 99%);
}
.gradient-bullet-list li:nth-child(3):before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#f6e384),
		to(#ffd500)
	);
	background: linear-gradient(to right, #f6e384, #ffd500);
}
.gradient-bullet-list li:nth-child(4):before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#84d9d2),
		to(#07cdae)
	);
	background: linear-gradient(to right, #84d9d2, #07cdae);
}
.gradient-bullet-list li:nth-child(5):before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#da8cff),
		to(#9a55ff)
	);
	background: linear-gradient(to right, #da8cff, #9a55ff);
}

.bullet-line-list {
	padding-left: 30px;
	margin-bottom: 0;
	position: relative;
	list-style-type: none;
}
.rtl .bullet-line-list {
	padding-right: 0px;
}
.bullet-line-list li {
	position: relative;
	line-height: 1;
	padding-bottom: 10px;
}
.bullet-line-list li:before {
	content: "";
	position: absolute;
	border-radius: 100%;
	width: 12px;
	height: 12px;
	left: -28px;
	top: 6px;
	border: 3px solid #b66dff;
	margin-right: 15px;
	z-index: 2;
	background: color(white);
}
.bullet-line-list li:after {
	content: "";
	border: 1px solid #ebedf2;
	position: absolute;
	bottom: 0;
	left: -23px;
	height: 100%;
}
.bullet-line-list li:first-child:after {
	content: "";
	height: 80%;
}
.bullet-line-list li:last-child {
	padding-bottom: 0;
}
.bullet-line-list li:last-child:after {
	content: "";
	top: 0;
	height: 30%;
}

/* Preview */
.preview-list .preview-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 0.75rem 1.5rem;
	font-size: 0.875rem;
}
.preview-list .preview-item:last-child {
	border-bottom: 0;
}
.preview-list .preview-item:hover {
	background: #e9ecef;
}
.preview-list .preview-item .form-check {
	margin-top: 8px;
	margin-right: 1rem;
}
.preview-list .preview-item .preview-thumbnail {
	color: color(white);
	position: relative;
}
.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.preview-list .preview-item .preview-thumbnail .preview-icon {
	padding: 6px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.preview-list .preview-item .preview-thumbnail .preview-icon i {
	font-size: 1.125rem;
	margin: 0;
}
.preview-list .preview-item .preview-thumbnail .badge {
	border: 2px solid color(white);
	border-radius: 100%;
	bottom: 5px;
	display: block;
	height: 14px;
	left: -5px;
	padding: 0;
	position: absolute;
	width: 14px;
}
.preview-list .preview-item .preview-item-content {
	line-height: 1;
	padding-left: 15px;
}
.preview-list .preview-item .preview-item-content:first-child {
	padding-left: 0;
}
.preview-list .preview-item .preview-item-content p {
	margin-bottom: 10px;
}
.preview-list .preview-item .preview-item-content p .content-category {
	font-family: "source-sans-pro-semibold", sans-serif;
	padding-right: 15px;
	border-right: 1px solid #ebedf2;
}
.rtl .preview-list .preview-item .preview-item-content {
	padding-left: 0;
	padding-right: 1rem;
	margin-right: 0;
	margin-left: auto;
}
.preview-list .preview-item .preview-actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.preview-list .preview-item .preview-actions i {
	width: 29px;
	color: color(gray-lightest);
	height: 29px;
	border: 2px solid color(gray-lightest);
	border-radius: 100%;
	padding: 3px 6px;
	display: inline-block;
}
.preview-list .preview-item .preview-actions i:first-child {
	margin-right: 10px;
}

.preview-list.comment-preview .preview-item {
	padding: 0.87rem 0;
}
.preview-list.comment-preview .preview-item:first-child {
	padding-top: 0;
}
.preview-list.comment-preview .preview-item p {
	line-height: 27px;
}

.preview-list.bordered .preview-item {
	border-bottom: 1px solid #ebedf2;
}
.preview-list.bordered .preview-item:last-child {
	border-bottom: 0;
}

/* Tabs */
.add-items {
	margin-bottom: 1.5rem;
	overflow: hidden;
}
.add-items input[type="text"] {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
	background: transparent;
}
.add-items .btn {
	margin-left: 0.5rem;
}
.rtl .add-items .btn {
	margin-left: auto;
	margin-right: 0.5rem;
}

.list-wrapper {
	height: 100%;
	max-height: 100%;
}
.list-wrapper ul {
	padding: 0;
	text-align: left;
	list-style: none;
	margin-bottom: 0;
}
.list-wrapper ul li {
	font-size: 0.9375rem;
	padding: 0.4rem 0;
	border-bottom: 1px solid #ebedf2;
}
.list-wrapper ul li:first-child {
	border-bottom: none;
}
.list-wrapper ul li .form-check {
	max-width: 90%;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.list-wrapper input[type="checkbox"] {
	margin-right: 15px;
}
.list-wrapper .remove {
	cursor: pointer;
	font-size: 1.438rem;
	font-weight: 600;
	width: 1.25rem;
	height: 1.25rem;
	line-height: 20px;
	text-align: center;
}
.list-wrapper .completed {
	text-decoration: line-through;
	-webkit-text-decoration-color: #198ae3;
	text-decoration-color: #198ae3;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .auth-form-light {
	background: #ffffff;
}
.auth .auth-form-light select {
	color: #c9c8c8;
}
.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .input-group .form-control:active {
	border-color: #ebedf2;
}

.auth .brand-logo {
	margin-bottom: 2rem;
}
.auth .brand-logo img {
	width: 150px;
}

.auth form .form-group {
	margin-bottom: 1.5rem;
}
.auth form .form-group label {
	font-size: 0.8125rem;
}
.auth form .form-group .form-control {
	background: transparent;
	border-radius: 0;
	font-size: 0.9375rem;
}

.auth form .auth-form-btn {
	height: 50px;
	line-height: 1.5;
}

.auth form .auth-link {
	font-size: 0.875rem;
}
.auth form .auth-link:hover {
	color: initial;
}
.css-1dimb5e-singleValue {
	grid-area: 1/1/2/3;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box;
	font-size: inherit;
	font-family: "Heebo";
	font-weight: normal;
}
.label-div {
	font-weight: 500 !important;
	font-size: 11px !important;
	line-height: 19px !important;
	color: #0b0b0b;
}

.form-control::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #bebebe;
	opacity: 1;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
.form-control::-ms-placeholder {
	/* Internet Explorer 10-11 */
	color: #bebebe;
	opacity: 1;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

/* Navbar */

/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
	padding-top: 70px;
}
.navbar-data-toggle--popover {
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	color: #0b0b0b;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.checkbox-elements--table {
	vertical-align: top !important;
	margin-left: 10px;
}
.checkbox-all-elements--table {
	display: block;
	height: 2vh;
}
.no-data--found {
	display: block;
	background: #f5f5f5;
	top: 50px;
	padding: 15px 15px;
	border-radius: 3px;
	width: auto;
	box-shadow: 0 0 4px -1px #bfbfbf;
	line-height: 19px;
	color: #0b0b0b;
	font-weight: 500;
	white-space: normal;
	vertical-align: middle;
	font-size: 13px;
}
.sidebar .nav.sub-menu .nav-item .nav-links {
	padding: 15px 20px !important;
	padding-right: 10px !important;
}
.sidebar .nav .nav-item .collapse {
	margin-left: -13%;
	margin-right: -13%;
	margin-top: 13px;
}
@media (min-width: 992px) {
	.sidebar-icon-only .navbar .navbar-brand-wrapper {
		width: 70px;
	}
	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
		display: none;
	}
	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
		display: inline-block;
	}
	.sidebar-icon-only .navbar .navbar-menu-wrapper {
		width: calc(100% - 70px);
	}
	.sidebar-icon-only .sidebar {
		width: 70px;
	}
	.sidebar-icon-only .sidebar .nav {
		overflow: visible;
	}
	.sidebar-icon-only .sidebar .nav .nav-item {
		position: relative;
		padding: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
		display: block;
		text-align: center;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		border-radius: 0 5px 5px 0px;
	}
	.rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		border-radius: 5px 0 0 5px;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
		margin-right: 0;
		margin-left: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
		display: none;
	}
	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 0 5px 0 0px;
	}
	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 5px 0 0 0;
	}
	/* .sidebar .nav .nav-item.active::hover {
    background: white;
  }
  .sidebar .nav .nav-item.active.nav.sub-menu {
    margin-bottom: 0;
    margin-top: 14px;
    list-style: none;
    background: aliceblue;
    margin-left: 0;
    display: block;
    margin-right: 0;
    width: 112%;
  } */
	.sidebar .nav.sub-menu .nav-item .nav-links.active {
		background: #e9e9e9 !important;
		width: 100%;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-category {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
		margin: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
		display: block;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .collapse {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: #fcfcfc;
		padding: 0.5rem 1.4rem;
		left: 70px;
		position: absolute;
		text-align: left;
		top: 0;
		bottom: 0;
		width: 190px;
		z-index: 1;
		line-height: 1.8;
	}
	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title {
		left: auto;
		right: 70px;
		text-align: left;
	}
	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title:after {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		display: block;
		padding: 0.5rem 0;
		background: #fcfcfc;
		border-radius: 0 0 5px 0;
		position: absolute;
		left: 70px;
		width: 190px;
	}
	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		left: auto;
		right: 70px;
		border-radius: 0 0 0 5px;
	}
	.sidebar-icon-only .sidebar .nav.sub-menu {
		padding: 0 0 0 1.5rem;
	}
	.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: left;
		padding-left: 20px;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
		margin-right: auto;
		margin-left: 0;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: right;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
		left: 0;
		right: unset;
	}
	.sidebar-icon-only .sidebar .sidebar-actions {
		display: none;
	}
	.sidebar-icon-only .main-panel {
		width: calc(100% - 70px);
	}
}

.rtl {
	direction: rtl;
	text-align: right;
}
.rtl .sidebar .nav {
	padding-right: 0;
}
.rtl .product-chart-wrapper::-webkit-scrollbar,
.rtl .sidebar-fixed .nav::-webkit-scrollbar,
.rtl .table-responsive::-webkit-scrollbar,
.rtl ul.chats::-webkit-scrollbar {
	width: 0.5em;
}
.rtl .product-chart-wrapper::-webkit-scrollbar-track,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
.rtl .table-responsive::-webkit-scrollbar-track,
.rtl ul.chats::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
.rtl .table-responsive::-webkit-scrollbar-thumb,
.rtl ul.chats::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.page-body-wrapper {
	/* padding-top: 60px; */
	/* min-height: calc(100vh - 70px); */
	height: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}
.page-body-wrapper.full-page-wrapper {
	width: 100%;
	min-height: 100vh;
}

.main-panel {
	transition: width 0.25s ease, margin 0.25s ease;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	flex-direction: column;
	padding-left: 203px;
}
@media (max-width: 991px) {
	.main-panel {
		margin-left: 0;
		width: 100%;
	}
}

.content-wrapper {
	background: #f2edf3;
	padding: 2.75rem 2.25rem;
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

/*# sourceMappingURL=style.css.map */
.btn {
	padding: 8px 10px;
	border-radius: 5px;
	font-size: 13px;
}
.btns {
	padding: 11px 34px;
	border-radius: 5px;
	font-size: 13px;
}
.btn-light-border {
	border: 1px solid #ecf1f7;
	background: transparent;
}
.col-gap12 {
	column-gap: 12px;
}
.puple {
	background: #ecf1f7;
}
.black {
	color: #0b0b0b;
}
.grey {
	color: #a1a1a1;
}
.red {
	color: #c71515;
}
.border-0 {
	border: 0;
}

.section-title h2 {
	font-weight: 500;
	font-size: 24px;
	line-height: 35px;
	color: #0b0b0b;
	margin-bottom: 20px;
}
.section-title {
	margin-bottom: 22px;
}
.section-title h4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}
.rightbar {
	padding: 26px 0px 0 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pr-11 {
	padding-right: 26px;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9;
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #fff;
	border-radius: 10px;
	border-right: 1px solid #e9e9e9;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d3dae4;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #4e4c4c;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
	--bs-table-accent-bg: #fff;
}
.headname {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #0b0b0b;
	margin-top: 19px;
}
.idname {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #5a616b;
}

ul.margin-list li {
	display: inline-block;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin-right: 7px;
	width: 40px;
	height: 41px;
	font-size: 13px;
	line-height: 40px;
	color: #a1a1a1;
	text-align: center;
	align-items: center;
}
.addtypegroup .modal-footer {
	justify-content: flex-end;
}
.btn-close {
	width: 20px;
	height: 20px;
	background-image: url(../assets/Images/cros.svg) !important;
	opacity: 1;
	background-size: auto;
	padding: 0;
}
.col-gap-20 {
	column-gap: 20px;
}
.padi-30 {
	padding-left: 30px;
}
.col-gap-14 {
	column-gap: 14px;
}
.w-282 {
	width: 282px;
}
@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		--bs-modal-width: 668px !important;
	}
}

.addtypegroup .form-group {
	margin-bottom: 10px;
}

.invoicecard .card {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin: 0px 14px 0 0;
}
ul.sociallink li {
	display: inline-block;
	text-align: center;
	margin: 8px;
	width: 40px;
	height: 40px;
	border: 1px solid #c0bcbc;
	border-radius: 50%;
	line-height: 40px;
}
ul.sociallink li img {
	width: 20px;
	height: 20px;
}
.rtf--ab__c {
	display: block;
	/* position: absolute; */
	top: 0;
	right: 20px !important;
	left: 5.5rem !important;
	padding: 10px 0;
	margin: 55px 0 !important;
}

.row-multiple-select--h3 {
	margin: 3px;
	color: #5a616b;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}
.row-multiple--select {
	display: flex;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	height: 35px;
	margin-bottom: 20px;
}
.row-multiple--select--customer {
	display: flex;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	height: 35px;
}
.checkbox_round {
	width: 18px;
	height: 18px;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	outline: none;
	cursor: pointer;
	-webkit-appearance: none;
}

.checkbox_round:hover {
	border-color: #000;
}

.checkbox_round {
	position: relative;
}

.checkbox_round:checked {
	border: none;
	outline: 2px solid deeppink;
}

.table-hover > tbody > tr {
	background: url;
	cursor: pointer;
	color: blue;
}
.colored-link {
	color: red; /* Set your desired color here */
}
.table-hover > tbody > tr {
	background: url;
	cursor: alias !important;
	color: blue;
}
.dropdown {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	margin: auto;
	z-index: 3;
}
.dropdown-list {
	position: absolute;

	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	list-style: none;
	border-radius: 5px;
	pointer-events: none;
	transition: all 0.5s ease;
	transform: translateY(-10px);
	top: 0;
	left: 0px;
	margin-top: 2rem;
}

.dropdown-list {
	clear: both;
	font-weight: 400;

	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	padding: 1rem;

	background: #ffffff;
	-webkit-box-shadow: 6px 8px 6px -6px #333;
	-moz-box-shadow: 6px 8px 6px -6px #333;
	box-shadow: 6px 8px 6px -6px #333;
	margin-right: 0;
	margin-left: 0;
}
.dropdown-content a::hover {
	background-color: #ddd;
}
.mt-5--table {
	margin-top: 22px;
}
ul li.menunews {
	/* border-bottom: 1px solid #ccc;   */
	list-style: none;
	height: 30px;
}
ul li.menunews a {
	display: block;
	color: #266cae;
	text-decoration: none;
}

ul li.menunews:hover {
	background-color: red;
	opacity: 1;
}
.img-fluid--icons {
	max-width: 100%;
	height: auto;
}
/* .img-fluid--icons:hover {
	width: 36px;
	justify-content: center;
	opacity: 1;

	background: #f5f5f5;

	height: 36px;
	z-index: 3;
	border-radius: 50%;
} */
.modal-content-edit--type {
	width: 100% !important;
}
.edit-type-modal--title {
	gap: 50px;
	display: flex;
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
}
.edit-type-modal-span--active {
	font-weight: 600;
	color: #0001c7;
	margin: 10px;
}
.edit-type-modal-span--edit_id {
	font-weight: 600;
	margin: 10px;
}
.edit-type-modal-standard-type {
	display: flex;
	width: 100%;
	gap: 20px;
}
.edit-type-modal-isMultpile--type {
	display: table;
	width: 40%;
}
.edit-type-modal-span--in-active {
	font-weight: 600;
	color: red;
	margin: 10px;
}
.mt-21px--table {
	margin-top: 21px;
}
.mt-2-branch {
	margin-bottom: 2rem !important;
}
.set_left {
	margin-left: 25% !important;
}

.set_left {
	margin-left: 25% !important;
}

.sp_form .alert-danger {
	background-color: #fff !important;
	border: none !important;
	padding: 0px !important;
	margin: 0px !important;
	top: 5px !important;
	padding-bottom: 8px !important;
}

.sp_form .alert-danger p {
	color: red;
}
.sp_form .alert-danger button {
	display: none;
}
/* .sp_form .error {
  margin-left: 32% !important;
} */

.sp_form .margin-error {
	margin-left: 26% !important;
}
.sp_input:focus::placeholder {
	/* WebKit, Blink, Edge */
	color: #d3d3d3 !important;
}

input.skip_placeholder::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #fff !important;
}
input.skip_placeholder:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff !important;
	opacity: 1;
}
input.skip_placeholder::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff !important;
	opacity: 1;
}
input.skip_placeholder:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff !important;
}
input.skip_placeholder::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff !important;
}

input.skip_placeholder::placeholder {
	/* Most modern browsers support this now. */
	color: #fff !important;
}

input.placeholder-light::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #ccc !important;
}
input.placeholder-light:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #ccc !important;
	opacity: 1;
}
input.placeholder-light::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #ccc !important;
	opacity: 1;
}
input.placeholder-light:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ccc !important;
}
input.placeholder-light::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #ccc !important;
}

input.placeholder-light::placeholder {
	/* Most modern browsers support this now. */
	color: #ccc !important;
}

input.placeholder-dark::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #7c7f85 !important;
	letter-spacing: 2px;
	margin: 0 5px;
}
input.placeholder-dark:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #7c7f85 !important;
	opacity: 1;
}
input.placeholder-dark::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #7c7f85 !important;
	opacity: 1;
}
input.placeholder-dark:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #7c7f85 !important;
}
input.placeholder-dark::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #7c7f85 !important;
}

input.placeholder-dark::placeholder {
	/* Most modern browsers support this now. */
	color: #7c7f85 !important;
}

.selectable .row {
	padding: 10px;
}
/* .selectable .row:hover {
  cursor: all-scroll !important;
} */

.react-beautiful-dnd-draggable {
	transition: transform 0.2s;
}
.unauthorised--page {
	margin-left: 34%;
	margin-top: 12%;
	width: 23rem;
	display: block;
}
.unauthorised--page .number {
	margin-left: 56px;
}
.unauthorised--page .icon {
	margin-left: 30px;
}
.unauthorised--page .access {
	font-size: 20px;
	margin-left: 8px;
	font-weight: 400;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
	line-height: 0px !important;
	height: 0px !important;
	/* padding: 50px !important; */
	/* margin: 2px !important; */
}

/* .daterangepicker.ltr {
  margin: 0px !important;
} */

.editor_special {
	padding-left: 0px !important;
}
.image-rendering {
	min-height: 100%;
	object-fit: cover;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	aspect-ratio: auto 200 / 100;
	height: 8em;
	@media (min-width: 1100px) {
		height: 16em;
	}
}
.custom-row-workspace {
	width: max-content;
	display: flex;
}

.editor_ws .loader {
	margin-left: -8%;
}
.editor_ws_image .loader {
	top: 24% !important;
	left: 44% !important;
}

.detailslist .card img {
	color: transparent !important;
}
.autocomplete {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 49px;
	font-size: 13px;
	border-top-width: 0;
	list-style: none;
	max-height: 80px;
	overflow-y: auto;
	padding-left: 7px;
	width: calc(300px + 4.5rem);
	position: absolute;
	z-index: 9999;
	background: #ffffff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	top: "50px";
	left: "10px";
}
.editor.autocomplete {
	width: calc(124px + 4.5rem) !important;
}
.search--box {
	position: inherit;
	display: flex;
	width: 23.5rem;
}
.editor.search--box {
	position: inherit;
	display: flex;
	width: 14.4rem;
}
.clear-icons {
	margin-top: 9px;
	display: flex;
	text-align: end;
	margin-left: -30px;
}

.date-picker--container {
	margin-bottom: 8px;
	width: 15rem;
	flex-direction: column;

	background: white;
	height: 83px;
	overflow: scroll;
}
.negative-margin--container {
	margin-top: -90px;
}
.mt--10px {
	margin-top: 10px;
}
.border-image--card {
	border: 3px solid #2c6bcf !important;
	border-radius: 2% !important;
}
.checkbox-order--sub {
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	left: 13px;
	top: 13px;
}
.layer-image--selector {
	background: #e9edf5 !important;
}
.card-button--selected {
	background: #f6f6f6 !important;
}
.detailslist {
	margin: 6px 0px 0px !important;
	/* margin: 0px 7px 0px; */
}
.row-multiple-select--coordinator {
	display: flex;
	grid-row-gap: 57px;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	gap: 30px;
}
/* .image-settings-folder--select {
	color: #a1a1a1;
	margin: 6px;
	display: flex;
	margin-left: 39%;
} */
.order-folder--count {
	color: #a1a1a1;
	margin: 6px;
}
.co-ordinator-super-admin--selectAll {
	font-weight: 500 !important;
	font-size: 13px !important;
	line-height: 19px !important;
	margin-left: 14px;
	color: #5a616b;
}
.order-folder--hover {
	content: "";
	display: flex;
	background: #ecf1f7;
	width: auto;
	min-height: 47px;
	height: auto;
	border-radius: 4px;
	top: 3px;
	margin-bottom: auto;
	margin-top: auto;
	display: flex;
	align-items: center; /* Vertically center align text */
}

/* Add a fixed width to the text container */
.order-folder--hover .catgory-name {
	width: 150px; /* Adjust the width as needed */
	white-space: nowrap; /* Prevent text from wrapping */
	overflow: hidden;
	text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
}

.bothbtn-order {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	align-items: center;
	color: #0b0b0b;
	text-align: center;
	margin-bottom: 6px;
	padding: 11px 6px !important;
	width: 100%;
	column-gap: 4px;
	display: flex;
	justify-content: center;
	@media (min-width: 1100px) {
		column-gap: 10px;
		width: max-content;
		padding: 11px 10px !important;
	}
}
.modal-container--image {
	top: 5.5em;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: max-content;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1119;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.modal--image {
	height: calc(100vh - 88px);
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
}
.modal-image-icons {
	max-width: fit-content;
	display: flex;
	justify-content: center;
	height: auto;
	border-radius: 8px;
	margin-bottom: auto;
	justify-content: start;
	width: 100%;
}
.modal-image--order {
	background-color: white;
	border: 1px solid #888;
	width: 30%;
	margin-right: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	height: auto;
	border-radius: 8px;
	max-height: -moz-fit-content;
	max-height: fit-content;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 130px);
	overflow-y: auto;
	padding: 10px;
}

@media (min-width: 1280px) and (max-width: 1400px) {
	.modal-settings--container {
		height: 43.8em;
		overflow: auto; /* Add scrollbars if content exceeds container height */
		width: 100%; /* Take up full width */
		margin-bottom: auto; /* Adjust margin as needed */
	}
}

@media (min-height: 1590px) and (max-height: 2080px) {
	.modal-image--order {
		height: 100%;
	}
}
.modal-settings--container {
	height: 31.6em;
	overflow: auto; /* Add scrollbars if content exceeds container height */
	width: 100%; /* Take up full width */
	margin-bottom: auto; /* Adjust margin as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.modal-settings--container {
		min-height: 30rem; /* Adjust minimum height for smaller screens */
	}
}

@media (max-width: 576px) {
	.modal-settings--container {
		min-height: 20rem; /* Adjust minimum height for even smaller screens */
	}
}

.modal-overlay--order {
	position: fixed;
	top: 30px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	z-index: 1036;
	flex-direction: column;
}
.close-icons-order--image {
	margin-top: 2em;
	display: flex;
	z-index: 99999;
	width: 100%;
	position: fixed;
	justify-items: flex-end;
}

.order-text--outlined {
	font-size: 13px;
	font-weight: 500;
	/* margin-top: 3px; */
	width: 9em;
	height: 18px;
	/* overflow-y: scroll; */
}

.btn-image-order-save--outlined {
	background: black;
	color: white;
	border-radius: 12px !important;
	width: 7rem;
	height: 43px;
	display: flex;
}
.image--comment {
	display: flex;
	margin: 0px 23px 56px;
	width: 31px;
}
.image--comment-qc-workspace {
	display: flex;
	width: 31px;
	margin-left: 12px;
	margin-right: 13px;
	margin-top: 0;
	margin-bottom: 0;
	/* justify-content: center; */
}
.comments-time-image--details {
	display: flex;
	width: 13rem;
}
.scroll-image-details--comment {
	height: 428px;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 20rem !important;
}
.img-fluid-image-details--view {
	height: fit-content;
}
.edit-profile-setting-container--single {
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
	height: 57px;
}
/* .next--icons {
	margin-top: 28.8%;
	margin-left: 12px;
} */
.next--icons {
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	right: auto;
	flex-direction: row-reverse;
	margin-right: 15px;
}
/* .prev--icons {
	margin-top: 27.75%;
	margin-right: 12px;
} */
.prev--icons {
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	margin-left: 15px;
}
.btn-image-order--outlined {
	width: 23rem;
	background: black !important;
	border-radius: 12px !important;
	color: white !important;
	height: 43px;
	display: flex !important;
}
.modal-image-icons {
	background-color: white;
	border: 1px solid #888;
	max-width: -moz-fit-content;
	max-width: fit-content;
	/* margin: 4.5% 2% 40% 1%; */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	min-height: 22.4vh;
	height: auto;
	border-radius: 8px;
	/* margin-top: auto; */
	margin-bottom: auto;
	justify-content: start;
	margin-left: 8px;
}

.order-text-outlined--name {
	margin-left: 83px;
	font-size: 13px;
	font-weight: 500;
	width: auto;
	display: inherit;
	margin-top: 1.7px;
}
.reassigned--text {
	font-size: 15px;
	font-weight: 500;
	width: max-content;
	margin-bottom: 20px;
	color: #0b0b0b;
}
.flex-grow-1 .search--box {
	width: auto !important;
}
.flex-grow-1 .autocomplete {
	width: 54.2%;
}
.image-message--overlay {
	/* position: absolute; */
	left: 419px;
	top: 378px;
	/* background-color: pink; */
	border: 1px solid black;
	padding: 4px;
	font-size: 12px;
	cursor: pointer;
	margin-left: 20px;
	margin-bottom: 10px;
	padding: 10px;
	background-color: #fff;
	width: auto;
	text-align: left;
	border: 2px solid #125ecb;
	border-radius: 20px;
	width: 200px;
	padding: 10px;
	border-radius: 20px;
	color: black;
	margin: 20px;
}
.send-icon {
	/* position: absolute; */
	top: 26.3rem;
	right: 178px;
	transform: translateY(-50%);
	width: 24px;
	height: 27px;
	background-image: url(../assets/Images/icon-search.svg);
	background-size: cover; /* Scale the background image to cover the element */
	cursor: pointer; /* Change the cursor to a pointer on hover (for clickability) */
}
.send-icon-container {
	display: flex;
	position: absolute;
	margin-top: 8.8%;
	margin-left: 79%;
}
.image-message-container---overlay {
	left: 419px;
	top: 378px;

	border: 1px solid black;
	padding: 4px;
	font-size: 12px;
	cursor: pointer;
	margin-left: 5px;
	margin-bottom: 10px;
	padding: 10px;
	background-color: pink;
	width: auto;
	text-align: left;
	border: 2px solid #125ecb;
	border-radius: 20px;
	width: max-content;
	padding: 10px;
	border-radius: 20px;
	color: black;
}
.send-icon-container:hover {
	border: 2px solid #125ecb;
	border-radius: 50%;
	background: aliceblue;
	margin-top: 24px;
	margin-right: auto;
	margin-left: 14.5rem;
}
.avatar-bg {
	background-image: url(../assets/Images/coordinator_actions_icon/messageMarkerIcon.svg);
	background-size: cover; /* Scale the background image to cover the element */
	cursor: pointer; /* Change the cursor to a pointer on hover (for clickability) */
	background-size: cover;
	cursor: pointer;
	transform: translateY(-17%);
	width: 49px;
	margin-top: 23px;
}
.avatar-container {
	margin-left: 5px;
	margin-top: 4px;
}
.avatar {
	border-radius: 50%;
	border: 2px solid white;
	width: 32px;
	height: 29px;
	margin-top: 9px;
	margin-left: 9px;
	justify-content: center;
	display: flex;
	color: white;
}
.avatar--h3 {
	font-size: 12px;
	font-weight: 400;
}
.send-icon-container:hover {
	border: 2px solid #125ecb;
	border-radius: 50%;
	background: aliceblue;
}
.settings-profile--text {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px !important;
	font-weight: 500 !important;
}
.image-details-margin--type {
	margin-top: 0.6rem;
	font-size: 18px;
	font-weight: 800;
}
.css-1nmdiq5-menu {
	z-index: 99999999 !important;
}

.select-custom-width {
	width: 60%;
}

div.callout {
	height: 35px;
	width: 35px;
	margin: -21px 17px;
	/*float: left;*/
	/* z-index: 1; */
}
div.callout {
	background-color: #fff;
	background-image: -moz-linear-gradient(top, #fff, #fff);
	position: relative;
	color: #ccc;
	border-radius: 6px;
	/* box-shadow: 0px 0px 20px #999; */
	/* margin: 25px; */
	/* line-height: 40px; */
	padding: 5px;
	/* margin: 10px; */
	/* border: 1px solid #333; */
	border: 1px solid #b7b7b7;

	text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
	box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
	/* margin-top: -3px; */
	/*box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset;*/
	/* margin-left: 50%;
  margin-top: 20%; */
}

/* .callout::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}
.callout.left::before {
  left: 0%;
  right: -20px;
  top: 40%;
  border-left: 10px solid #fff;
}
.callout.top::before {
  border-right: 11px solid #fff;
  bottom: 22px;
  left: -20px;
}
.callout.bottom::before {
  left: 45%;
  top: -20px;
  border-bottom: 10px solid #fff;
}
.callout.right::before {
  top: 40%;
  border-right: 10px solid #fff;
}
.callout.top-left::before {
  bottom: -20px;
  border-top: 10px solid #fff;
}
.callout.top-right::before {
  bottom: -20px;
  border-top: 10px solid #fff;
} */

div.firstletter {
	background-color: #d5f4b9;
	color: #639c2b;
	border-radius: 7px;
	padding: 2.5px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
}

div.inner_first_letter {
	background-color: #d5f4b9;
	color: #639c2b;
	border-radius: 7px;
	padding: 5px;
	display: inline-block;
	margin-left: 20px;
	width: 28px;
	margin-top: 12px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
}

.showcomments {
	display: none;
	background-color: #fff;
	width: 249px;
	/* height: 210px; */
	/* min-height: 210px;
  overflow: hidden; */
	height: fit-content;
	border-radius: 7px;
	margin-left: 4.2rem;
	margin-top: -14.5%;
	overflow-y: auto;
	/* filter: drop-shadow(rgba(0, 0, 0, 0.1) 5px 5px 3px); */
	box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
	overflow-y: scroll;
}

.callout.active {
	box-shadow: 0px 0px 0px 1.5px #6082b6;
}

.callout.active.other_comment {
	box-shadow: 0px 0px 0px 1.5px #639c2b;
}

.firstletter.mycomment,
.inner_first_letter.mycomment {
	color: #fff !important;
	background-color: #6082b6 !important;
}

.addcomment {
	/* display: none; */
	background-color: #fff;
	width: 275px;
	height: 160px;
	/* z-index: 99999999999; */
	border-radius: 7px;
	/* margin-left: 10%;
  margin-top: -22%; */

	position: absolute;
	filter: drop-shadow(rgba(0, 0, 0, 0.1) 5px 5px 3px);
}

/* .pan-container > div:first-child {
  -webkit-transform: unset !important;
  transform: unset !important;
  height: 100% !important;
}

.pan-container {
  height: 100% !important;
} */

.callout.active {
	box-shadow: 0px 0px 0px 1.5px #6082b6;
}

.callout.active.other_comment {
	box-shadow: 0px 0px 0px 1.5px #639c2b;
}

.firstletter.mycomment,
.inner_first_letter.mycomment {
	color: #fff !important;
	background-color: #6082b6 !important;
}

/* .editor.image {
	width: 75em !important;
} */

.editor.upload_image,
.editor.download_image {
	background: #f6f3f4;
	color: #045bb4 !important;
	font-weight: 600;
	border: 1px solid #3c79d3;
}

.editor.disabled {
	border: 1px solid #dbd9da;
	background: #f6f3f4 !important;
	color: #d2d2d2 !important;
	font-weight: bold;
}

.addcomment a {
	color: inherit;
	text-decoration: inherit;
}

.add_comment_parent {
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}
/* .add_comment_parent {
   margin-left: 3%;
  margin-top: -6.5%;
} */

.add_comment_parent {
	margin-left: 30px;
	margin-top: -60px;
}
/* .add_comment_parent.dragged {
  margin-left: -10% !important;
  margin-top: 22% !important;
} */

.addcomment-triangle-down-right {
	width: 0;
	height: 0;
	border-top: 31px solid transparent;
	border-bottom: 0px solid transparent;
	border-left: 50px solid #fff;
	margin-left: 94%;
	margin-top: -38%;
}

.addcomment-triangle-down-left {
	width: 0;
	height: 0;
	border-bottom: 35px solid #fff;
	border-left: 53px solid transparent;
	margin-left: -28px;
	margin-top: -41%;
}

.move_right {
	margin: 0px -335px;
}

.hover-show-comments {
	display: none;
	background-color: #fff;
	width: 180px;
	height: fit-content;
	border-radius: 7px;
	margin-top: -25%;
	cursor: move;
	margin-left: 10%;
}

.modal-image-icons--qc-workspace .hover-show-comments {
	width: 182px !important;
}

.hover-show-comments .comment_details {
	margin: 0em 3em 0em !important;
	word-wrap: break-word;
}

.comment_d_parent {
	width: 95%;
	overflow: hidden;
	margin: 10px;
}

.hide {
	/* transition: 0.01s; */
	transform: translateX(-9999px);
}

/* .callout:hover .showcomments {
  display: block;
} */

.triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 14px solid #fff;
	border-left: 20px solid transparent;
	margin-left: -25px;
	margin-top: -19px;
}

.comment-triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 30px solid #fff;
	border-left: 50px solid transparent;
	margin-left: -30px;
	margin-top: -6%;
	position: fixed;
}

.hover-comment-triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 40px solid #fff;
	border-left: 50px solid transparent;
	margin-left: -40px;
	margin-top: -25%;
}
.commented_by {
	margin: 17px 10px 20px 10px;
	font-weight: 400;
	font-size: 14px;
}
.more_options {
	margin-left: 4rem;
	width: 16px;
}

.close_comment {
	width: 25px;
	margin-left: 5px;
	margin-bottom: -1px;
}

.add_close_comment {
	width: 22px;
	margin-left: 38px;
	margin-bottom: -1px;
	height: 20px;
}

.edit_close_comment {
	position: absolute;
	right: 2px;
	top: 8px;
	width: 21px;
	cursor: pointer;
}

.add_more_options {
	width: 14px;
}
.comment_date {
	margin-left: 3.6rem;
	margin-top: 1px;
	color: #acacac;
	font-size: 12px;
}

.comm_date {
	font-size: 12px !important;
	color: #acacac;
	margin: 2px 0px 0px 50px;
}

.comment_details {
	color: #0b0b0b;
	margin: 12px;
	margin-left: 3.6rem;
	font-size: 13px;
	padding-bottom: 5px;
}
.comment_add {
	margin-left: 1.3rem;
	height: 55px;
	position: relative;
}

.comment_add .trigger {
	position: absolute;
	right: 29px;
	top: 1px;
	width: 36px;
}

.comment_edit {
	margin-left: 1.4rem;
	height: 55px;
	position: relative;
}

.comment_edit .trigger {
	position: absolute;
	right: 29px;
	top: 1px;
	width: 36px;
}

.comment_edit input {
	width: 90.5%;
	margin-left: -2.1px;
}

.comment_add input,
.comment_edit input {
	/* background: #f5f5f5 url("../assets/Images/message.svg") no-repeat;
  background-position: right center; */
	background: #f5f5f5;
	height: 38px;
}

.co-ordinating--assigned-to-editor {
	display: flex;
	margin-left: 67%;
	margin-top: -4.8rem !important;
	flex-direction: column;
}
.total-images--count {
	font-size: 16px;
	font-weight: 600;
	color: #606772;
	margin-top: 18px;
}
.start-time--images {
	font-size: 16px;
	font-weight: 600;
	color: #606772;
}
/********Merged IMages Modal*************/
.sidebar-image--duplicate {
	justify-content: end;
	grid-column-gap: 113px;
	column-gap: 113px;
	margin-right: 10px;
	height: 700px;
	overflow: scroll;
	width: 22rem;
	margin-left: 18px;
}

.add-merged-image--text {
	font-size: 13px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #125ecb;
	margin-top: 16px;
	margin-bottom: 20px;
}
.merged-image--text {
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}
.merged-image-keep-original--text {
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}
.merged-image-text--container {
	display: flex;
	justify-content: space-between;
}

.dd #dropdownMenuButton {
	background: none !important;
	width: auto !important;
}

.dd .dropdown-menu {
	margin-left: -77px !important;
}

.dropdown.dd {
	display: inline-block !important;
}
.spinner-border.trigger {
	margin-top: 2px;
	right: 30px;
}
.grab {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	.thumbnails-list {
		cursor: pointer;
	}
}

.grab:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.side-bar-single-select--h2 {
	font-size: 16px;
	font-weight: 500;
	line-height: 13px;
}
.sidebar-image--name {
	width: 100%;
	/* justify-content: end;
	grid-column-gap: 113px;
	column-gap: 113px;
	margin-right: 4px; */
	/* width: fit-content; */
}
.sidebar--select {
	/* width: max-content; */
	height: 90vh;
	padding: 0px;
}
.sidebar-image-name--image-modal {
	justify-content: end;
	grid-column-gap: 113px;
	column-gap: 113px;
	/* margin-right: 15px; */
	width: auto;
	/* margin-left: 5px; */
}
.select-all--image-settings {
	border-radius: 2px;
	width: 16px;
	height: 15px;
}
.me-12 {
	margin-right: 12.5rem !important;
}
.global-gap--btn {
	column-count: 2;
	column-gap: 15px;
	display: flex;
}
.modal-image-icons--qc-workspace {
	background-color: white;
	border: 1px solid #888;
	width: auto;
	margin: 114px 10px 32.8rem 42px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-evenly;
	height: 14rem;
	border-radius: 8px;
	flex-direction: column;
}
.btn-image-order-outlined--qc {
	width: 8rem;
	background: black !important;
	border-radius: 18px !important;
	color: white !important;
	height: 39px;
}
.dot {
	height: 18px;
	width: 18px;
	background-color: white;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 6px;
	border: 1px solid #125ecb;
}
.dot_1 {
	height: 18px;
	width: 18px;
	background-color: black;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot_2 {
	height: 18px;
	width: 18px;
	background-color: #7cfc00;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot_3 {
	height: 18px;
	width: 18px;
	background: linear-gradient(to top, #daecb8 0%, #e33127 100%);
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot--gap {
	display: flex;
	margin-left: 7rem;
	justify-content: space-evenly;
}
.reject-cross--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 4px 38px;
}
.accept-tick--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 0px;
}
.image-modal--details-text {
	background: #f5f5f5;
	height: 50px;
}
.edit-type--text {
	font-size: 15px;
	margin-left: 20px;
	font-weight: 400;
}
.color-picker {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* margin-top: 20px; */
	z-index: 97;
	position: absolute;
}
.mr-l-r--duplicate {
	margin-right: 12px;
	margin-left: 7px;
	margin-top: 0px;
}
.dropdown-duplicate--image {
	display: flex;
	justify-content: space-between;
}
.dropdown-duplicate-image--text {
	font-size: 14px;
	font-weight: 400;
	margin-top: 6px;
}
.my-modal-rename--duplicate {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	z-index: 12000;
	flex-direction: column;
	max-height: 8em;
	min-height: 14em;
}
.btn-image-order-duplicate--outlined {
	width: 23rem;
	border-radius: 8px !important;
	color: black;
	display: flex !important;
	background: #fbfbfb;
}
.editororderdetails--sidebar {
	height: 90vh;
	overflow-y: auto;
	padding-bottom: 57px;
	overflow-x: clip;
	max-width: 22em;
	width: 21em;
	display: flex;
}

.delete-icon {
	background: url(../assets/Images/coordinator_actions_icon/white_delete.svg)
		no-repeat;
	display: block;

	/* background: white;
	mask-size: contain; */
}

.drag-select {
	/* background-color: rgba(20, 137, 189, 0.5); */
	background: rgba(0, 175, 255, 0.1);
	/* border: 0.5px solid rgba(0, 175, 255, 0.9); */
	z-index: 11;
	opacity: 0.1;
	/* */
}
.treeviewlist.custom-viewlist li {
	padding-left: 0;
}

@media (min-width: 800px) and (max-width: 1280px) {
	.custom-row-workspace .customcol-3 {
		width: auto !important;
	}
	.treeviewlist.custom-viewlist li {
		width: auto !important;
		height: max-content;
		padding: 14px 6px 20px;
	}
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 32em !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 42em !important; */
	}
	.custom-row-workspace {
		/* width: 72em; */
		display: flex;
	}
}
@media (min-width: 1280px) and (max-width: 1400px) {
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 30em !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 40em !important; */
	}
}
@media only screen and (min-width: 1600px) and (max-width: 2800px) {
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* Skip this line for code review */
		/* width: 29em !important; */
		/* width: 100% !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
	}
	.close-icons-order--image {
		margin-top: 1.125% !important;
		margin-bottom: 0;
	}
}
.editororderdetails--qc {
	height: 90vh;
	overflow-y: auto;
	padding-bottom: 57px;
	overflow-x: clip;
	width: auto;
}
.editororderdetails--qc .card-text {
	font-weight: 500;
	font-size: 13px !important;
	line-height: 19px !important;
	text-align: center;
	padding: 2px 0 10px;
	color: #0b0b0b;
}
/* .editor.image {
	width: 55em !important;
} */
.dot-gap--qc {
	display: flex;
	margin-left: 6rem;
	justify-content: space-evenly;
}
.original-image--qc {
	margin-left: 6px;
	margin-top: 4px;
	margin-right: 2px;
}
.scroll--edit-group {
	height: 23em;
	overflow: scroll;
	width: 100%;
}
.form-check {
	width: max-content;
}
.ml-9 {
	margin-left: 7.5rem !important;
}
.btn-image-order--qc {
	width: 8rem;
	background: #eae7e8 !important;
	color: #0058ab !important;
	height: 39px;
}
.margin--warning {
	margin-left: 15px;
	margin-top: 6px;
}
.reject-cross-qc--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 4px 28px;
}
.text-qc--btn {
	margin-top: 0;
	font-size: 13px;
	font-weight: 700;
}
.date-picker-qc--container {
	margin-bottom: 8px;
	width: 16rem;
	flex-direction: column;
	background: #f7f7f7;
	height: 83px;
	overflow: scroll;
	position: absolute;
	z-index: 999;
	border: 2px solid #f7f7f7;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	/* color: var(--bs-btn-disabled-color); */
	pointer-events: none !important;
	/* background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color); */
	opacity: 0.45 !important;
}
.margin--warning--disabled {
	margin-left: 15px;
	margin-top: 6px;
	opacity: 0.2;
}
.bulk_upload_error p {
	padding: 10px;
}

.workspace--switch {
	display: flex;
	justify-content: space-between;
}
.workspace-form--switch {
	margin: 0px 0px 12px 38px;
	font-size: 26px;
	margin-right: auto;
}

.modal-body p.err {
	white-space: pre-wrap;
	margin: -15px 0px 0px 0px;
}

.modal--image .color-picker {
	z-index: 999;
}
.order-status {
	color: #f1a100;
}
.order--success {
	color: #008e1a;
}
.modal-tab {
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	margin-right: 23px;
	gap: 25px;
	align-items: center;
}
/* .modal-container {
	width: 90%;
	margin-bottom: 0px;
	min-height: 77vh;
	height: auto;
	overflow: auto;
	max-height: 80vh;
} */
.togler-menu--close {
	position: fixed;
	bottom: 0;
	left: 0px;
	z-index: 999;
}
.mr-25px {
	margin-right: 23px;
}
.set-assgin-editor {
	margin-top: -5% !important;
	margin-left: 43% !important;
}
.settings_selected {
	color: #205aa7;
	width: max-content;
	font-size: 11px;
	@media (min-width: 1100px) {
		font-size: 12px;
	}
}
.bothbtn-order.pending {
	background: #ecf1f7;
}
.mr-auto {
	margin-right: auto;
}
.date_filter {
	border-radius: 4px;
}

.coordinator .autocomplete {
	width: 91.1% !important;
}

.coordinator_hr {
	width: 105% !important;
	margin-left: -2%;
	/* margin-bottom: 3%; */
	margin-bottom: 40.359px;
	border: none !important;
	height: 1px !important;
	/* Set the hr color */
	color: #e9e9e9 !important; /* old IE */
	background-color: #e9e9e9 !important; /* Modern Browsers */
	opacity: 1;
}
.drp-calendar.left .prev.available {
	padding: 0px;
	margin: -75px 39px;
}

.drp-calendar.left .prev.available:hover {
	background-color: unset !important;
	color: unset !important;
}

.drp-calendar.right .next.available {
	margin: -84px 230px;
	pointer-events: none;
}

.drp-calendar.right .next.available:hover {
	background-color: unset !important;
	color: unset !important;
}

.custom-coordinator-search-mt {
	margin: 2px 0px 4px -68px;
}

/* .coordinator-search-mt {
	margin: 7px 0px 9px -68px;
} */

.filter .css-1dimb5e-singleValue {
	font-weight: bold;
	font-size: 14px;
}
.order--success {
	color: #008e1a;
}
.duplicate-setting--Modal {
	max-width: 40rem;
	width: 100%;
	margin-bottom: 0 !important;
	max-height: 43rem;
}
.image-modal--setting {
	max-height: 86vh;
	height: auto;
}
.width-35rem {
	max-width: 35rem;
	min-width: 35rem;
	marign-left: 10px;
	margin-right: 10px;
	/* height: 25.5rem; */
}
.width-auto {
	max-width: 35.38rem;
	width: max-content !important;
}
.error {
	font-size: 12px;
	font-weight: 400;
	color: red;
}
.error-duplicate--settings {
	display: flex;
	justify-content: start;
	margin-left: 12px;
	margin-bottom: 12px;
	color: #f1a100;
	font-size: 14px;
	font-weight: 700;
}
.duplicate-setting-image--content {
	justify-content: center;
	grid-column-gap: 113px;
	grid-column-gap: 113px;
	grid-column-gap: 0;
	column-gap: normal;
	margin-right: 0px;
}
.duplicate-settings-profile--select {
	position: absolute;
	width: -webkit-fill-available;
	justify-content: space-between;
	margin-right: auto;
	height: auto;
}
.p-0 {
	padding: 0;
}
.duplicate-settings-modal--count {
	/* height: 23em; */
	overflow-y: auto;
	overflow-x: hidden;
}
.edit-setting-select--duplicate {
	position: fixed;
	max-width: 22em;
}
.scroll-y-axis--duplicate {
	height: 20.85rem;
	scroll: auto;
	overflow-y: hidden;
	width: 100%;
	overflow-x: hidden;
	margin-left: 6px;
}
.side-bar-duplicate-settings--view {
	height: 29.5rem;
	overflow: scroll;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
}
.folder__marker_blue {
	background: #ecf1f7;
	width: auto;
	min-height: 47px;
	height: auto;
	border-radius: 0px;
	top: 3px;
	margin-bottom: auto;
	margin-top: auto;
	display: flex;
	align-items: center;
}

.modalHeight {
	/* New css */
	max-height: 18em !important;
	min-height: 18em !important;
}
.coordinator_card {
	height: auto;
	background: rgb(255, 255, 255);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 167px;
	margin-left: auto;
	margin-right: auto;
}
.canvas {
	display: block;
	width: 100%;
}
.canvas .img {
	width: 100%;
	height: 100%;
}
.pan_container {
	height: -webkit-fill-available;
	/* width: calc(85vw - 450px) !important; */
	position: relative;
	background: #474747;
	overflow: hidden;
	/* overflow: hidden; */
	scroll-behavior: smooth;
}
#image_holder {
	background: #474747;
}
.modal-open {
	overflow: auto !important;
	z-index: 10;
}
.color_blue {
	color: var(--blue);
}
.color_blue:hover {
	color: var(--primary-black);
}
.color_black {
	color: var(--primary-black);
}
#zoom_canvas {
	background: url(../assets/Images/orders/transparent.jpg);
}
.canvas {
	height: auto;
	background: rgb(255, 255, 255);
	position: -webkit-sticky;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	transform: unset !important;
	margin-bottom: auto;
}
.magnifier-holder--img {
	justify-content: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	display: flex;
	height: 2.7rem !important;
	width: 3em;
}
modal {
	will-change: transform;
}
.disabled_icons {
	background-color: #e4e4e4;
	border-right: 3px solid #a9a9a9;
	border-left: 3px solid #6b6b6b;
	opacity: 0.39;
}
.disabled_icons:hover {
	background-color: #e4e4e4;
	opacity: 0.39;
	border-right: 3px solid #a9a9a9;
	border-left: 3px solid #6b6b6b;
	cursor: not-allowed;
}
.content {
	display: contents;
}
.overlayLayer {
	position: absolute;
	/* display: none; */
	background-color: rgba(0, 0, 0, 0.2);
	width: 234vw;
	height: 200vh;
}
.error_order-create {
	border: 1px solid red !important;
}
.position--relative {
	position: relative;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
	pointer-events: none;
	opacity: 0.25;
}
.dropzone {
	min-height: calc(100vh - 308px);
	position: absolute;
	width: -webkit-fill-available;
}
.card.is-loading {
	min-height: 317px;
}

.card.is-loading {
	.image,
	/* h2, */
	p {
		background: #eee;
		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		border-radius: 5px;
		background-size: 200% 100%;
		animation: 1.5s shine linear infinite;
		width: 100%;
		margin-top: 0;
	}

	.image {
		height: 200px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	h2 {
		height: 30px;
	}

	p {
		height: 70px;
	}
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
