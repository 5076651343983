.Toastify__toast--error {
	border: 1px solid #eb5757;
	border-radius: 50px !important;
	background: #fae1e2 !important;
}

.Toastify__toast--success {
	border: 3px solid #687a96 !important;
	border-radius: 10px !important;
	background: #0a3e7e !important;
	width: 240px;
	color: #fff;
	margin-top: 42px;
}
.Toastify__toast--success::before {
	background-image: url("../../../assets/Images/green-check-mark-tick-40.svg");
	background-repeat: no-repeat;
	/* content: url("../../../assets/Images/green-check-mark-tick-40.svg"); */
	content: "";
	background-size: 20px 20px;
	width: 40px;
	position: relative;
	z-index: 9999;
	left: 12px;
	top: 10px;
}
.Toastify__toast--success::after {
	/* content: url("../../../assets/Images/cross-white-png.png"); */
	background-image: url("../../../assets/Images/cross-white-png.png");
	background-repeat: no-repeat;
	/* content: url("../../../assets/Images/green-check-mark-tick-40.svg"); */
	content: "";
	background-size: 18px 18px;
	width: 20px;
	position: relative;
	z-index: 9999;
	left: -2px;
	top: 11px;
}

/* Ignore this code we need this in the future */
/* .Toastify__toast--error::after {
  content: url("../../../assets/Images/cros.svg");
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 250px;
  padding-top: 26px !important;
  top: 4px;
  width: 5px;
  height: 5px;
} 
 .Toastify__toast--error::before {
  content: url("../../../assets/Images/cross-red.svg");
  position: relative;
  z-index: 9999;
  left: 12px;
  top: 2px;
} */

.Toastify__toast-body {
	color: #fff;
	font-size: 14px;
	padding-left: 10px;
	width: 100%;
	font-weight: 400;
	margin-right: 20px !important;
}

.Toastify__toast {
	min-height: 58px !important;
}
