.image-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80%;
	max-width: 150px; /* Adjust the max width as needed */
	margin: 0 0 auto;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 10em; */
}

.slide-container {
	display: flex;
	justify-content: space-between;
	margin-left: 4em;
	width: auto;
}

.slide {
	max-width: 108%;
	height: auto;
	margin-right: 15px;
	height: 9rem;
}
.slide:hover {
	border: 1px solid #125ecb;
}
.prev,
.next {
	position: absolute;
	top: 30%;
	/* background-color: #333; */
	color: #fff;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.prev {
	left: -26px;
}

.next {
	right: -226px;
}
