.coordinator .date-range {
  color: #a1a1a1;
  /* padding-left: 15px; */
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

/* span.disabled, */
ul li.disabled {
  background: #dddddd !important;
  color: #ccc !important;
}

ul.output_specicfication li {
  padding-right: 15px;
}

span.span_14 {
  font-size: 14px !important;
}

.coordinator p.p_14 {
  font-size: 14px !important;
}
