.width-94 {
	/* width: 91%; */
}
.width-auto {
	width: auto;
}
.position--absolute {
	position: absolute;
}
/* Define a CSS class for the hover effect */
.hovered-edit {
	fill: blue; /* Initial color */
	transition: fill 0.3s; /* Add a smooth transition effect */
}

/* Apply the hover effect when hovering over the SVG */
svg:hover .hovered-edit {
	fill: red; /* Color to change to on hover */
}
.css-1jqq78o-placeholder {
	grid-area: 1/1/2/3;
	color: hsl(0, 0%, 50%);
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box;
	width: max-content;
}
.css-3iigni-container {
	width: max-content;
	pointer-events: none;
	position: relative;
	box-sizing: border-box;
	visibility: visible;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: contents;
	grid-area: 1/1/2/3;
	grid-template-columns: 0 min-content;
	margin: 2px;
	padding-bottom: 2px;
	padding-top: 2px;
	color: hsl(0, 0%, 20%);
	box-sizing: border-box;
}
.checkbox-order--duplicate {
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	right: 12px;
	top: 9px;
}
/* SimpleDropdown.css */
/* Style for the dropdown container */
.dropdown--duplicate {
	position: relative;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	height: 3rem;
}
.dropdown-button--duplicate {
	/* max-width: 23rem; */
	width: 100%;
	display: flex;
	/* margin-top: 9px; */
	height: 2rem;
	align-items: center;
}

/* Style for the dropdown content */
/* SimpleDropdown.css */
/* Style for the dropdown container */
.dropdown--duplicate {
	position: relative;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	height: 3rem;
}
.dropdown-button--duplicate {
	/* max-width: 23rem; */
	width: 100%;
	display: flex;
	/* margin-top: 9px; */
	height: 2rem;
}

/* Style for the dropdown content */
.dropdown-content {
	position: absolute;
	background-color: #f9f9f9;
	/* min-width: 160px; */
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-top: 15px;
	/* max-width: 22.2rem; */
	width: 100%;
	margin-left: -9px;
	overflow: scroll;
	height: 10rem;
}

/* Style for dropdown items */
.dropdown-content span {
	padding: 12px 16px;
	text-decoration: none;
	display: flex;
	color: #333;
	justify-content: space-between;
}

/* Style for dropdown items on hover */
.dropdown-content span:hover {
	background-color: #ddd;
}

/* Show the dropdown content when the button is clicked */
.dropdown.open .dropdown-content {
	display: block;
}
.dropdown-duplicate-image--modal {
	position: relative;
	background-clip: border-box;
	height: 3rem;
	display: flex;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	margin-left: 12px;
	color: #5a5a5a;
}
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.dropdown-content-duplicate-image--modal {
	position: absolute;
	background-color: black;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-top: 7px;
	max-width: 49rem;
	width: -webkit-fill-available;
	overflow-y: scroll;
	height: 10rem;
	scrollbar-width: thin;
}
/* Style for dropdown items */
.dropdown-content-duplicate-image--modal span {
	padding: 13px 16px;
	text-decoration: none;
	display: flex;
	color: white;
	justify-content: space-between;
}

/* Style for dropdown items on hover */
.dropdown-content-duplicate-image--modal span:hover {
	background-color: #ddd;
	color: black;
}

/* Show the dropdown content when the button is clicked */
.dropdown.open .dropdown-content-duplicate-image--modal {
	display: block;
}
.custom-column {
	width: 50%;
}
.sidebar-specifications {
	/* max-width: 22.5em; */
	width: auto;
}
.coordinator .sidebar-specifications {
	/* width: 22em; */
	/* border-top: 1px solid rgba(0, 0, 0, 0.2) !important; */
}
.coordinator .border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 23px;
	width: 100%;
}
.edit-setting--select {
	position: absolute;
	max-width: 22.3em;
	width: auto;
}
.sidebar--select {
	/* width: fit-content; */
	height: 90vh;
	padding: 0px;
}
.sidebar-select--editType {
	/* height: 67vh; */
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
	padding-top: 18px;
	padding-left: 10px;
	/* padding-right: 10px; */
	height: calc(100vh - 330px);
	scrollbar-width:thin;
}
.sidebar-select--editType .form-group{
	margin-bottom: 10px;
}
.sidebar-select--editType.cs {
	height: 75vh !important;
}
.sidebar-select--editType.editSettings {
	padding-top: 6px !important;
}

.edit-setting-sidebar {
	margin-left: auto;
	margin-right: auto;
	width: auto;
}
.custom-row-workspace {
	width: 100%;
	display: grid !important;
	grid-template-columns: 3fr 9fr;
	gap: 15px;
}
.ml-auto {
	margin-left: auto;
}
.tabs-wrap--no-wrap {
	overflow-x: scroll;
	overflow-y: hidden;
	position: relative;
	width: 35em;
	flex-wrap: nowrap !important;
}
.btn-secondary--bg {
	background-color: #ecf1f7 !important;
	border: 0 !important;
	transition: 0.6s all;
	color: #0b0b0b;
}
.sidebar-select--header {
	width: fit-content;
	justify-content: end;
	display: flex;
	padding-bottom: 0;
	margin-bottom: -10px;
	margin-left: auto;
	margin-top: 11px;
}
@media (min-width: 1980px) {
	.col-sm-7 {
		flex: 0 0 auto;
		width: 67.333333%;
	}
}
@media (max-width: 1479px) {
	/* Apply styles for screen widths below 1480 pixels */

	.sidebar-image--name {
		/* max-width: fit-content;
		width: fit-content; */
		/* margin-left: 5px; */
	}
	.sidebar-specifications {
		position: absolute;
		/* width: 20.5em; */
	}
	.edit-setting--select {
		position: absolute;
		max-width: 21.5em;
		width: auto;
	}

	.col-gap-201 {
		flex-direction: column;
	}
}
.mr-auto {
	margin-right: auto;
}
.btn-pending {
	margin: 3px;
	color: #f7f7f7;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}

.switch > .row {
	padding-bottom: 2rem;
	vertical-align: middle;
	text-align: center;
	border-bottom: 1px solid rgba(189, 193, 200, 0.5);
}

.switch h3 {
	font-weight: 400;
}
.switch h3 > small {
	font-weight: 200;
	font-size: 0.75em;
	color: #939aa5;
}
.switch h6 {
	font-weight: 700;
	font-size: 0.65rem;
	letter-spacing: 3.32px;
	text-transform: uppercase;
	color: #bdc1c8;
	margin: 0;
	line-height: 5rem;
}
.switch .btn-toggle {
	top: 50%;
	transform: translateY(-50%);
}
.btn-toggle {
	margin: 3px;
	color: #f7f7f7;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
	outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
	line-height: 1.5rem;
	width: 4rem;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle:before {
	content: "Off";
	left: -4rem;
}
.btn-toggle:after {
	content: "On";
	right: -4rem;
	opacity: 0.5;
}
.btn-toggle > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.active {
	transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
	left: 1.6875rem;
	transition: left 0.25s;
}
.btn-toggle.active:before {
	opacity: 0.5;
}
.btn-toggle.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
	line-height: -0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.4125rem;
	width: 2.325rem;
}
.btn-toggle.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
	display: none;
}
.btn-toggle:before,
.btn-toggle:after {
	color: #6b7381;
}
.btn-toggle.active {
	background-color: hsl(132.94deg 100% 50%);
}
.btn-toggle.btn-lg {
	margin: 0 5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 2.5rem;
	width: 5rem;
	border-radius: 2.5rem;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
	outline: none;
}
.btn-toggle.btn-lg:before,
.btn-toggle.btn-lg:after {
	line-height: 2.5rem;
	width: 5rem;
	text-align: center;
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-lg:before {
	content: "Off";
	left: -5rem;
}
.btn-toggle.btn-lg:after {
	content: "On";
	right: -5rem;
	opacity: 0.5;
}
.btn-toggle.btn-lg > .handle {
	position: absolute;
	top: 0.3125rem;
	left: 0.3125rem;
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 1.875rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
	left: 2.8125rem;
	transition: left 0.25s;
}
.btn-toggle.btn-lg.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-lg.active:after {
	opacity: 1;
}
.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
	line-height: 0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.6875rem;
	width: 3.875rem;
}
.btn-toggle.btn-lg.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-lg.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-lg.btn-xs:before,
.btn-toggle.btn-lg.btn-xs:after {
	display: none;
}
.btn-toggle.btn-sm {
	margin: 0 0.5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 2em;
	width: 8em;
	border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
	outline: none;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
	line-height: 1.5rem;
	width: 0.5rem;
	text-align: center;
	font-weight: 800;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-sm:before {
	content: "Off";
	left: -1.5rem;
}
/* .btn-toggle.btn-sm:after {
	content: "Pending";
	left: 1.88rem;
	opacity: 0.5;
} */
.btn-toggle.btn-sm > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-sm.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-sm.active > .handle {
	left: 4.9875rem;
	transition: left 0.25s;
}
.btn-toggle.btn-sm.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
	line-height: -0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.4125rem;
	width: 2.325rem;
}
.btn-toggle.btn-sm.btn-sm:before {
	text-align: -webkit-right;
	left: 4em;
	margin: 0 0px 1px 2px;
}
.btn-toggle.btn-sm.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
	display: none;
}
.btn-toggle.btn-xs {
	margin: 0 0;
	padding: 0;
	position: relative;
	border: none;
	height: 1rem;
	width: 2rem;
	border-radius: 1rem;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
	outline: none;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
	line-height: 1rem;
	width: 0;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-xs:before {
	content: "Off";
	left: 2.87em;
}
.btn-toggle.btn-xs:after {
	content: "On";
	right: 0;
	opacity: 0.5;
}
.btn-toggle.btn-xs > .handle {
	position: absolute;
	top: 0.125rem;
	left: 0.125rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.75rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-xs.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-xs.active > .handle {
	left: 1.125rem;
	transition: left 0.25s;
}
.btn-toggle.btn-xs.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-xs.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
	line-height: -1rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.275rem;
	width: 1.55rem;
}
.btn-toggle.btn-xs.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-xs.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
	display: none;
}
.btn-toggle.btn-secondary {
	color: #6b7381;
	background: #bdc1c8;
}
.btn-toggle.btn-secondary:before,
.btn-toggle.btn-secondary:after {
	color: #6b7381;
}
.btn-toggle.btn-secondary.active {
	background-color: #ff8300;
}
.margin-18 {
	margin: 18px;
}
.padding-left-3 {
	padding-left: 3px;
}
.padding-left-4 {
	padding-left: 4px;
}
