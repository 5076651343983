:root {
  --black: #0b0b0b;
}

.fonts {
	font-family: "Heebo", sans-serif;
	line-height: 19px;
	color: var(--black);
	font-weight: 500;
	white-space: normal;
	vertical-align: middle;
}
body.modal-open {
	position: fixed;
	width: -webkit-fill-available;
}
body {
	-webkit-user-drag: none;
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.6;
}

/* Sidebar */
.sidebar {
	position: fixed;
	/* top: 0; */
	right: 0;
	left: 0;
	z-index: 1030;
	min-height: 100vh;
	background: #ffffff;
	border-right: 1px solid #e9e9e9;
	padding: 0;
	width: 203px;
	z-index: 11;
	transition: width 0.25s ease, background 0.25s ease;
	-webkit-transition: width 0.25s ease, background 0.25s ease;
	-moz-transition: width 0.25s ease, background 0.25s ease;
	-ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar .nav {
	overflow: hidden;
	flex-wrap: nowrap;
	flex-direction: column;
	margin-bottom: 60px;
	margin-top: 28px;
}
.sidebar .nav .nav-item {
	padding: 14px 20px;
	transition-duration: 0.25s;
	transition-property: background;
	-webkit-transition-property: background;
}
.sidebar .nav .nav-item .collapse {
	z-index: 999;
}
.sidebar .nav .nav-item .nav-links {
	display: flex;
	align-items: center;
	white-space: nowrap;
	transition-duration: 0.45s;
	transition-property: color;
	-webkit-transition-property: color;
}
.sidebar .nav .nav-item .nav-links i {
	color: inherit;
}
.menu-icon {
	font-size: 1.125rem;
	line-height: 1;
	margin-left: auto;
	color: #bba8bff5;
}
.menu-icon {
	margin-left: 0;
	margin-right: 12px;
}
:before {
	vertical-align: middle;
}
.menu-arrow {
	line-height: 1;
	font-size: 1.125rem;
	margin-left: auto;
	color: #9e9da0;
	position: absolute;
	right: 15px;
}
.menu-arrow {
	margin-left: 65px;
}
.sidebar .nav .nav-item .nav-links i.menu-arrow:before {
	/* content: "\f107";
  font-size: inherit;
  color: #5a616b; */
}
.sidebar .nav .nav-item .nav-links i.menu-arrow + .menu-icon {
	margin-left: 0.25rem;
}
.menu-arrow + .menu-icon {
	margin-left: 0;
	margin-right: 0.25rem;
}
.sidebar .nav .nav-item .nav-links .menu-title {
	color: inherit;
	width: 36%;
	display: inline-block;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	line-height: 1;
	vertical-align: middle;
}
.sidebar .nav .nav-item .nav-links .badge {
	margin-right: auto;
	margin-left: 1rem;
}
.sidebar .nav .nav-item .nav-links[aria-expanded="true"] .menu-arrow:before {
	/* content: "\f106"; */
}
.sidebar .nav .nav-item.active {
	background: #0b0b0b;
}
.sidebar .nav .nav-item.active:hover {
	pointer-events: none;
	background: #0b0b0b !important;
}
.bg--black {
	background: #0b0b0b;
	color: white;
}
.sidebar .nav .nav-item.active > .nav-links .menu-title {
	color: #fff;
}
.sidebar .nav .nav-item.active > .nav-links i {
	color: #b66dff;
}
/* .sidebar .nav .nav-item:hover {
  background: #efefef;
} */
.sidebar .nav .nav-item.nav-profile .nav-links {
	height: auto;
	line-height: 1;
	border-top: 0;
	padding: 1.25rem 0;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image {
	width: 44px;
	height: 44px;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image img {
	width: 44px;
	height: 44px;
	border-radius: 100%;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: 1rem;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: auto;
	margin-right: 1rem;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	font-size: 1.125rem;
	margin-left: auto;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	margin-left: 0;
	margin-right: auto;
}
.sidebar .nav .nav-item.sidebar-actions {
	margin-top: 1rem;
}
.sidebar .nav .nav-item.sidebar-actions .nav-links {
	border-top: 0;
	display: block;
	height: auto;
}
.sidebar .nav .nav-item.sidebar-actions:hover {
	background: initial;
}
.sidebar .nav .nav-item.sidebar-actions:hover .nav-links {
	color: initial;
}
.sidebar
	.nav:not(.sub-menu)
	> .nav-item:hover:not(.nav-category):not(.nav-profile)
	> .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
}
.sidebar .nav.sub-menu {
	margin-bottom: 0px;
	margin-top: 0px;
	list-style: none;
}
.sidebar .nav.sub-menu .nav-item {
	padding: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	padding: 14px 20px;
	position: relative;
	height: auto;
	border-top: 0;
	white-space: normal;
}
/* .sidebar .nav.sub-menu .nav-item .nav-links:before {
              content: "\f0da";;
              font-family: "Fontawesome";
              display: block;
              position: absolute;
              left: 0px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              color: #a2a2a2;
              font-size: .75rem; } */
.rtl .sidebar .nav.sub-menu .nav-item .nav-links:before {
	left: auto;
	right: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	color: #b66dff;
	background: #0b0b0b !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links:hover {
	/* color: #555555; */
}
.sidebar .nav.sub-menu .nav-item:hover {
	background: transparent;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
	.sidebar-offcanvas {
		position: fixed;
		max-height: calc(100vh - 70px);
		top: 60px;
		bottom: 0;
		overflow: auto;
		right: -260px;
		transition: all 0.25s ease-out;
	}
	.sidebar-offcanvas.active {
		right: 0;
	}

	.sidebar {
		width: 54px;
	}
	.sidebar .nav .nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.sidebar .nav .nav-item .nav-links .menu-title {
		display: none;
	}
	.menu-icon {
		margin: 0 auto !important;
	}
	.menu-arrow {
		margin-left: 1px;
		font-size: 15px;
		position: absolute;
		right: 5px;
	}
	i.mdi.mdi-crosshairs-gps.menu-icon {
		display: none;
	}
	.main-panel {
		padding-left: 54px !important;
	}
}
.sidefull {
	position: relative;
}
.bg-highlighter {
	background: #efefef !important;
	color: #0001c7 !important;
}
.p-b--0 {
	padding-bottom: 0 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links {
	padding: 15px 35px !important;
}
.sidebar .nav .nav-item .collapse {
	margin-left: -13%;
	margin-right: -13%;
	margin-bottom: -9%;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	background: #0b0b0b !important;
	width: 100%;
	color: white !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links:hover {
	background: #efefef !important;
}
.sub-menu--blue {
	color: #125ecb !important;
}
.menu-arrow--highlight {
	line-height: 1;
	font-size: 1.125rem;
	margin-left: auto;
	position: absolute;
	right: 15px;
	color: #125ecb !important;
}
.menu-arrow--highlight {
	margin-left: 65px;
}

#date_range {
  width: 0px;
  height: 0px;
  background: #fff;
  border-color: #fff;
  visibility: hidden;
  display: none;
  margin-top: 4px;
}


 .duplicate-compulsory-field span , .compulsory-field span {
  color: #3073f5 !important;
  font-weight: bold !important;
  font-size: 18px;
  padding-left: 5px;
  vertical-align : -webkit-baseline-middle;
} 

.duplicate-compulsory-field-error span , .compulsory-field-error span {
  color: #a92322 !important;
  font-weight: bold !important;
  font-size: 18px;
  padding-left: 5px;
  vertical-align : -webkit-baseline-middle;
}

ul.duplicate-compulsory-field-error li input[value=""] , div.duplicate-compulsory-field-error input[value=""] 
, .compulsory-field-error + div input.form-control[value=""] , .compulsory-field-error + div input.field_error {
  border:  1px solid #a92322 !important;
}


.phoneNumberParent .PhoneInputCountry {
    display: inline-block;
    margin-top: 6px;
}

.PhoneInputInput {
  position: absolute;
  border: none;
  margin-top: -3px;
  width: calc(90% - 15%);
  margin-top: 3px;
  padding-left: 5px;
  transform: translateZ(0);
}

.phoneNumberParent .PhoneInput:focus-within {
  border: 1px solid #3073f5;
}

.PhoneInputCountrySelect {
  display: none;
}

.dynamic-country-list {
    margin-top: -41px;
}

.duplicate-footer-custom-height {
  height: 4px;
}
.time .react-time-picker__wrapper {
  border : none !important;
}

.react-time-picker__inputGroup__input:focus {
  background: #fff !important;
}

.react-time-picker__inputGroup__input:invalid {
  background: #fff !important;
}

.modalHeight .success {
  white-space: pre;
  padding-top: 2.90rem;
}


.modalHeight .err {
  white-space: pre;
  padding-top: 2rem;
}


.coordinator .date-range {
  color: #a1a1a1;
  /* padding-left: 15px; */
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

/* span.disabled, */
ul li.disabled {
  background: #dddddd !important;
  color: #ccc !important;
}

ul.output_specicfication li {
  padding-right: 15px;
}

span.span_14 {
  font-size: 14px !important;
}

.coordinator p.p_14 {
  font-size: 14px !important;
}

.ml_4_percent {
  margin-left: 4.8%;
}

.ml_3_percent {
  margin-left: 3%;
}

.ml_2_percent {
  margin-left: 2%;
}

.ml_1_percent {
  margin-left: 1%;
}

.mbn_16 {
  margin-bottom: 16px;
}

.show_left_border {
  border-left: 2px solid #ececec !important;
  background: #fff;
}

.no-border {
  border-right: unset !important;
}

.mg-bottom15 {
  margin-bottom: -15px;
}

.mg-bottom16 {
  margin-bottom: -16px;
}

.pl-7{
  padding-left : 7px;
}

.ml-7{
  margin-left : 7px;
}
.ml-8{
  margin-left : 8px;
}

.ml-12{
  margin-left : 12px;
}

.ml-32{
  margin-left : 2rem;
}

.css-olqui2-singleValue {
 font-size: 13px !important;
}

.coordinator input:disabled { /* WebKit browsers */
  color: #A1A1A1;
  background-color: #F2F2F2 !important;
}
.coordinator input:disabled::-webkit-input-placeholder { /* WebKit browsers */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:#A1A1A1;
  opacity: 0.6;
}

div[class*="-control"] {
  font-size: 13px !important;
  height: 41px !important;
}

.right_img_corner {
  float: right;
  position: inherit;
  margin-left: 18px;
  margin-top: 3.9px;
  width: 14px;
}

.client-order-settings {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 35px 35px 0;
  border-color: transparent #746DAD transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}
.btns.sent {
  width: 83%;
}

.btns.sent .sent {
  font-weight: bold;
  color: #64b05e;
}

.duplicate-settings-error-box {
  border: 1px solid #D7C3C8;
  background-color: #FBF7F8;
  color: #97505E;
  border-radius: 5px;
  padding: 0px 21px 0px 16px;
  height: 3rem;
  line-height: 1.3rem;
  margin-top: -3px;
  font-size: 0.88em;
  font-weight: 400;
  /* width: auto; */
}



.Toastify__toast--error {
	border: 1px solid #eb5757;
	border-radius: 50px !important;
	background: #fae1e2 !important;
}

.Toastify__toast--success {
	border: 3px solid #687a96 !important;
	border-radius: 10px !important;
	background: #0a3e7e !important;
	width: 240px;
	color: #fff;
	margin-top: 42px;
}
.Toastify__toast--success::before {
	background-image: url(/static/media/green-check-mark-tick-40.eabecf3c.svg);
	background-repeat: no-repeat;
	/* content: url("../../../assets/Images/green-check-mark-tick-40.svg"); */
	content: "";
	background-size: 20px 20px;
	width: 40px;
	position: relative;
	z-index: 9999;
	left: 12px;
	top: 10px;
}
.Toastify__toast--success::after {
	/* content: url("../../../assets/Images/cross-white-png.png"); */
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAEGAAABBgHN0zO8AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAK5JREFUOI3llE0OwiAQRr9aPFhNvJfs9B66qHrH50Ka4ASGNiHdSDIJgY/H/MEAqOc4dKXtATxLukkKK84GSVHS9LMK5HblO55AMHu5haQBuOR7VjgCjyR8AccCzNWUbh+Be+VA88JaSBYaCrBiSmrAJU9zAsxmXs2vB7Seup4t1r0Pdwu5VACv+s22qVWzCd0CK0HfFmrFMQm3PL3oAU9J4LZGBo3AlK8P8G8f7AdcNQf8YnqHPwAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	/* content: url("../../../assets/Images/green-check-mark-tick-40.svg"); */
	content: "";
	background-size: 18px 18px;
	width: 20px;
	position: relative;
	z-index: 9999;
	left: -2px;
	top: 11px;
}

/* Ignore this code we need this in the future */
/* .Toastify__toast--error::after {
  content: url("../../../assets/Images/cros.svg");
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 250px;
  padding-top: 26px !important;
  top: 4px;
  width: 5px;
  height: 5px;
} 
 .Toastify__toast--error::before {
  content: url("../../../assets/Images/cross-red.svg");
  position: relative;
  z-index: 9999;
  left: 12px;
  top: 2px;
} */

.Toastify__toast-body {
	color: #fff;
	font-size: 14px;
	padding-left: 10px;
	width: 100%;
	font-weight: 400;
	margin-right: 20px !important;
}

.Toastify__toast {
	min-height: 58px !important;
}

@media (min-width: 200px) and (max-width: 900px) {
	h1 {
		font-size: 3em;
	}

	h2 {
		font-size: 2em;
	}
	.mobile-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 50%;
		margin-bottom: 50%;
	}

	.mobile--text {
		/* Your text styles */
		text-align: center;
		background: #fff;
		position: relative;
		padding-top: 12px;
		width: max-content;
	}
	.mobile--text h2 {
		font-size: 13px !important;
		font-weight: 600;
	}
	.detailslist {
		/* grid-template-columns: repeat(auto-fit, 14.8rem) !important; */
		overflow: scroll;
		overflow-x: hidden;
		/* width: 17em !important; */
	}
	/* .custom-row-workspace {
		width: 990px !important;
	} */
	.col-md-7 {
		width: 17em !important;
	}
	/* .editororderdetails {
		width: 34em !important;
	} */
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 16.8rem);
		overflow: scroll;
		overflow-x: hidden;
		justify-content: start;
		height: 71vh;
		grid-column-gap: 20px;
		grid-column-gap: 20px;
		grid-column-gap: 20px;
		column-gap: 20px;
		width: 50em;
	}
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 15.9rem);
		width: 53em;
	}
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 17.2em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 32em !important;
	}
	.editororderdetail {
		height: 90vh;
		/* width: 14em; */
	}
	.editororderdetails_editor {
		height: 90vh;
	}
	.edit-setting--select {
		position: absolute;
		max-width: 16.5em;
		width: auto;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 15em !important;

		height: 24rem !important; */
		cursor: pointer !important;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 20.5em !important;
		height: 24rem !important;
		cursor: pointer !important;
	}
	/* .detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 16.6rem) !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1025px) and (max-width: 1025px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}
}
@media (min-width: 1026px) and (max-width: 1362px) {
	.col-md-7 {
		flex: none !important;
		width: 28.5em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}

	.col-md-6 {
		flex: 0 0 auto;
		width: 30%;
	}
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 11.6rem);
		overflow: scroll;
		overflow-x: hidden;
		justify-content: start;
		height: 71vh;
		grid-column-gap: 20px;
		grid-column-gap: 20px;
		grid-column-gap: 47px;
		column-gap: 47px;
		width: 50em;
	}
	.detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 17.9rem) !important;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 13.2em !important;
	}
}
@media (min-width: 900px) and (max-width: 1199px) {
	.col-md-7 {
		flex: none !important;
		width: 16em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 16em !important;
	}
	/*  {
		width: 16em !important;
	} */
	.detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 12.6rem) !important;
		grid-column-gap: 50px !important;
		column-gap: 50px !important;
	}
	.col-sm-10 {
		flex: 0 0 auto;
		/* width: 68.333333% !important; */
	}
	.modal-image--order {
		min-height: 83vh;
		height: auto;
	}
	/* .detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 12.9rem) !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 13.8em !important;
	} */
}
@media (min-width: 1400px) and (max-width: 1500px) {
	.coordinator .sidebar-specifications {
		width: 19em !important;
	}
	.col-sm-9 {
		flex: 0 0 auto;
		width: 69%;
	}
	.col-md-7 {
		width: 39em !important;
	}
	.col-md-9 {
		width: 39em !important;
	}
	/* .detailslist {
		grid-template-columns: repeat(auto-fit, 11.2rem) !important;
		width: 41em !important;
		grid-column-gap: 20px !important;
		column-gap: 28px !important;
	} */
	.detailslist_editor {
		grid-template-columns: repeat(auto-fit, 18.2rem) !important;
		width: 60em !important;
		grid-column-gap: 20px !important;
		grid-column-gap: 28px !important;
		column-gap: 28px !important;
	}

	/* .editororderdetails-new .coordibox .card {
		 width: 12.5em !important; 
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 19.5em !important;
	}
}
@media (min-width: 1200px) and (max-width: 1279px) {
	.col-md-7 {
		width: 26em !important;
	}
	.col-md-9 {
		flex: none !important;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 12.666667% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 12em !important;
		height: 20rem !important; */
		cursor: pointer !important;
	}
	/* .detailslist {
		width: 32em !important;
		grid-template-columns: repeat(auto-fit, 11.8rem) !important;
	} */
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 14.8rem) !important;
		width: 50em;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1280px) and (max-width: 1400px) {
	.col-md-7 {
		width: 26em !important;
	}
	.col-md-9 {
		flex: none !important;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 12.666667% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 12em !important;
		height: 24rem !important; */
		cursor: pointer !important;
	}
	/* .detailslist {
		width: 26em !important;
		grid-template-columns: repeat(auto-fit, 11.5rem) !important;
	} */
	/* .detailslist_editor {
		grid-template-columns: repeat(auto-fit, 14.6em) !important;
		column-gap: 35px !important;
	} */
}
@media (min-width: 1280px) and (max-width: 1367px) {
	.col-sm-2 {
		flex: 0 0 auto;
	}
	.col-sm-6 {
		flex: 0 0 auto;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 16.2em !important;
	} */
}
@media (min-width: 1501px) and (max-width: 1600px) {
	.col-sm-9 {
		flex: 0 0 auto;
		width: 69%;
	}
	.col-md-7 {
		width: 41em !important;
	}
	.col-md-9 {
		width: 41em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: auto;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 213px !important;
		height: 20rem !important; */
		grid-column-gap: 20px !important;
		column-gap: 20px !important;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 240px !important;
		height: 20rem !important;
		column-gap: 20px !important;
	} */
	/* .detailslist {
		width: 46em !important;
		grid-template-columns: repeat(auto-fit, 12.6rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 18px !important;
	} */
	/* .detailslist_editor {
		width: 72em !important;
		grid-template-columns: repeat(auto-fit, 14.6rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 18px !important;
	} */
}
@media (min-width: 1601px) and (max-width: 1799px) {
	.col-md-7 {
		flex: none !important;
		width: 48em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 48em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	/* .editororderdetails-new .coordibox .card {
		width: 15.4em !important;
		height: 23rem !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18.4em !important;
		height: 23rem !important;
	} */
	/* .detailslist {
		width: 51em !important;
		grid-template-columns: repeat(auto-fit, 14.8rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 20px !important;
	} */
	/* .detailslist_editor {
		width: 77em !important;
		grid-template-columns: repeat(auto-fit, 17.9rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 20px !important;
	} */
}
@media (min-width: 1800px) and (max-width: 1898px) {
	.col-md-7 {
		width: 56em !important;
	}
	.col-md-9 {
		width: 56em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	/* .detailslist {
		width: 69em !important;
		grid-template-columns: repeat(auto-fit, 17.4rem) !important;
	} */
	/* .detailslist_editor {
		width: 85em !important;
		grid-template-columns: repeat(auto-fit, 19.8rem) !important;
	} */
	/* .editororderdetails-new .coordibox .card {
		width: 18em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 20em !important;
		height: 24rem !important;
	} */
}
@media (min-width: 1899px) and (max-width: 1980px) {
}
@media (min-width: 1981px) and (max-width: 2080px) {
}
@media (min-width: 1899px) and (max-width: 3000px) {
	.col-md-7 {
		flex: none !important;
		width: 67em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 67em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 18em !important;
		height: 26rem !important; */
		grid-column-gap: 20px;
		column-gap: 20px;

		cursor: pointer;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18em !important;
		height: 26rem !important;
		column-gap: 20px;

		cursor: pointer;
	} */

	/* .detailslist {
		width: 66em !important;
		grid-template-columns: repeat(auto-fit, 20.9rem) !important;
	} */

	/* .editororderdetails-new .coordibox .card {
		width: 21em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 21em !important;
	} */
	/* .detailslist_editor {
		width: 116em !important;
        grid-template-columns: repeat(auto-fit, 19.9rem) !important;
		grid-column-gap: 26px !important;
		column-gap: 26px !important;
	} */
}
@media (min-width: 3001px) and (max-width: 5000px) {
	.col-md-7 {
		width: 69% !important;
	}
	.col-md-9 {
		width: 67em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 18em !important;
		height: 26rem !important; */
		grid-column-gap: 20px;
		column-gap: 20px;

		cursor: pointer;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18em !important;
		height: 26rem !important;
		column-gap: 20px;

		cursor: pointer;
	} */
	/* .detailslist {
		width: 120em !important;
		grid-template-columns: repeat(auto-fit, 21rem) !important;
	} */
	/* .detailslist_editor {
		width: 158em !important;
		grid-template-columns: repeat(auto-fit, 20.98rem) !important;
	} */
	/* .editororderdetails-new .coordibox .card {
		width: 20em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 21.5em !important;
	} */
}
@media (min-width: 800px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 27.5em;
	}
	.col-md-9 {
		width: 27.5em;
	}
}
@media (min-width: 400px) {
	.col-md-2 {
		flex: 0 0 auto;
		width: 13.666667% !important;
	}
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1024px) and (max-width: 1100px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1101px) and (max-width: 1122px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 300px) and (max-width: 799px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 19em !important;
	}
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 17em !important;
	}
}
@media (max-width: 991px) {
	.main-panel {
		width: 1200px !important;
		overflow: scroll;
	}
}

* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}
.main-panel {
	background: #fafafa;
}
.form-area {
	height: auto;
	background: #fff;
	overflow-y: auto;
	overscroll-behavior-x: auto;
	padding: 0px !important;
	border-radius: 10px;
	border: 1px solid #e9eff7;
}
.form-area--height {
	height: calc(100vh - 90px) !important;
}
.form-area--height75 {
	height: calc(100vh - 175px) !important;
}
.editororderdetail--height {
	height: calc(100vh - 192px);
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}
.padding-top-2 {
	padding-top: 2px !important;
}
.new-wordspace-area {
	display: grid !important;
	width: -moz-fit-content;
	width: fit-content;
	/* grid-template-columns: 4fr 6fr 4fr; */
	grid-gap: 4px;
	gap: 4px;
	@media (min-width: 1100px) {
		grid-template-columns: 4fr 12fr 6fr;
	}
}

.new-wordspace-area .folderlist {
	position: relative;
	border-left: 1px solid #efefef;
}
.card .lazyload-wrapper {
	width: 100%;
}
.card > div > img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.dropdown-toggle,
.dropdown {
	padding-right: 0px !important;
}
.dropdown .dropdown-menu {
	min-width: 200px;
}
.nav-link {
	margin-right: 4px !important;
}
.dropdown .dropdown-menu {
	transform: translate(-55px, 60px) !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	padding-right: 0px !important;
}
.row-multiple-select--coordinator {
	padding: 11px 10px 11px;
}
.custom-row-workspace > div {
	padding-top: 15px;
	flex-grow: 1 !important;
}
.order-details {
	margin-bottom: 18px;
}

@media (max-width: 1024px) {
	.card-header p {
		font-size: 11px !important;
		line-height: 15px !important;
		margin-bottom: 5px;
	}
	.order-details p {
		margin-bottom: 5px !important;
	}
	.order-details {
		padding: 5px 0 !important;
	}
	.order-details {
		padding: 5px 0 !important;
	}
}

@media (max-width: 1300px) {
	.treeviewlist.custom-viewlist .first-treeview li {
		padding-left: 15px;
	}
	.treeviewlist.custom-viewlist li {
		padding: 15px 1px 10px 0;
		font-size: 12px;
	}
}
.ordercard {
	padding-right: 3px;
	margin-top: 0.3em;
}
.treeviewlist {
	overflow: auto;
	overflow-x: hidden;
	height: calc(100vh - 192px);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}
/* .card > div > img {
	width: 100px;
	height: auto;
	object-fit: contain;
} */

.image-settings-folder--select {
	text-align: center;
	display: block;
	border: 1px solid #e9e9e9;
	padding: 8px;
	border-radius: 5px;
	margin: 0px 10px 8px 10px;
	font-size: 12px;
	@media (min-width: 1100px) {
		font-size: 15px;
	}
}

.custom-row-workspace__batcharea {
	padding-right: 3px;
	width: 22vw;
	/* width: auto; */
	@media (min-width: 1100px) {
		padding-right: 12px;
	}
}
/* new changes */
.navbar-expand-lg .navbar-nav {
	display: flex;
	grid-gap: 0px;
	gap: 0px;
	justify-content: center;
	align-items: center;
}
.nav-profile-text {
	margin-left: 8px !important;
}
.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}
.sidebar .nav .nav-item.active {
	background: #222222 !important;
}
.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	grid-gap: 24px !important;
	gap: 24px !important;
	grid-gap: 30px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}
.sidebar--select-sm {
	height: calc(100vh - 260px) !important;
	padding-bottom: 35px !important;
}
/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 16px;
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	grid-gap: 10px;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
/* .modal-image-icons__area {
	padding: 18px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 25px;
} */
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}
.nav-tabs .nav-link:hover {
	/* border-color: #fff #fff #fff !important; */
	border-radius: 0px;
}
.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	padding: 0px 0px 0px 8px;
	font-size: 13px;
	color: #f00;
	margin-top: -42%;
	margin-left: -3%;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 10px 0px 6px;
}
.o-flex {
	display: grid;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 204px);
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 240px) !important;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	/* padding-top: 15px !important; */
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}
/* .dropdown-duplicate-image--modal {
	flex-direction: column;
	padding-top: 10px;
} */
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	grid-gap: 0px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}
.Toastify__toast--success::before {
	width: 31px;
}
.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	height: calc(100vh - 88px);
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
}

.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
.nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 10px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
}
.nim__main-area {
	grid-area: m;
	width: -webkit-fill-available;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	border-bottom: 8px solid #ffff;
	position: relative;
	overflow: auto;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
	margin: auto;
}
.zoom_container__input:focus {
	border: 1px solid;
}

.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}

.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	grid-gap: 24px !important;
	gap: 24px !important;
	grid-gap: 30px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}

/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 16px;
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	grid-gap: 10px;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
/* .modal-image-icons__area {
	padding: 18px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 25px;
} */
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}

.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	display: block;
	/* width: 100%; */
	padding: 15px 0px 10px;
	font-size: 13px;
	color: #f00;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 11px 0px 6px;
}
.o-flex {
	display: grid;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 204px);
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 240px) !important;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	/* padding-top: 15px !important; */
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}

.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	grid-gap: 0px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}
 {
	width: 8px;
}
.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	height: calc(100vh - 88px) !important;
}
.modal-image--order {
	height: calc(100vh - 88px) !important;
}
.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
/* .nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 10px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
} */
.nim__main-area {
	grid-area: m;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
}
.zoom_container__input:focus {
	border: 1px solid;
}
.overflow__none {
	overflow: hidden !important;
}
.co-section-title h2 {
	font-weight: 500;
	font-size: 20px;
	line-height: 35px;
	color: #0b0b0b;
}
.co-section-title--spacing {
	padding: 10px 10px 10px 15px;
}
.co-section-title__mb0 {
	margin-bottom: 0px;
}
.dropdown--duplicate--coo__align {
	display: flex;
	justify-content: center;
	padding: 0px 6px;
}
.sidebar__header--margin .form-group {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}
.folderlist {
	padding-top: 0px !important;
}
.modal__title {
	position: fixed;
	top: auto;
	background: #ffffff;
	padding: 15px 24px 13px;
	border-radius: 5px 5px 0px 0px;
	border-bottom: 1px solid #dddddd;
	top: 0;
	position: relative;
	width: 100%;
}
.modal__height {
	height: calc(100vh - 48px);
	overflow-x: hidden !important;
}
.duplicate-settings-modal--count {
	height: calc(100vh - 280px);
	overflow-y: auto;
	overflow-x: hidden;
}
.modal-footer {
	padding: 2px 5px 1px !important;
	background: rgb(249, 249, 249) !important;
	border-top: 1px solid rgb(249, 249, 249) !important;
}
.new-modal-footer {
	padding: 5px 24px !important;
}
.modal-body .form-group {
	margin-bottom: 10px !important;
}
/********************* remove this *******************/
/* .coordibox .card {
	width: 30%;
	display: none;
} */
/* ****************************** */

.modal-image-icons {
	max-width: -moz-fit-content;
	max-width: fit-content;
	display: flex;
	justify-content: center;
	height: auto;
	border-radius: 8px;
	margin-bottom: auto;
	justify-content: start;
	width: 100%;
}
.modal-image-icons__area {
	/* padding: 18px 0px; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}
.modal-image-icons__each {
	padding: 9px 0px;
	width: 60px;
	text-align: center;
	margin-bottom: 1px;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
.modal-image-icons__each:hover {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-image-icons__each--active {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.settingsProfile-dropdown > div {
	min-height: 45px !important;
	min-width: auto;
}
@media (min-width: 1300px) {
	.settingsProfile-dropdown > div {
		min-height: 45px !important;
		min-width: 180px;
	}
}
.box2 > button {
	flex-grow: 1;
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 190px) !important;
	padding: 10px 15px 0px;
	scrollbar-width: thin;
}
.form-group {
	margin-bottom: 8px !important;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}

.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal--image > div {
	flex-grow: 1;
	/* max-height: calc(159vh - 575px); */
	max-height: calc(100vh - 89px);
}
.align-center {
	cursor: crosshair;
	transform: none;
	scale: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	font-size: 14px;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.btn__save {
	border: 1px solid #125ecb;
	background-color: transparent !important;
	color: #125ecb;
	/* gap: 15px !important; */
	padding: 11px 34px;
	border-radius: 5px;
	font-size: 13px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.o-flex {
	display: grid;
	grid-gap: 10px;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.magnifier-holder--img {
	justify-content: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	display: flex;
	height: 2.7rem !important;
	width: 3em;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}
.image-zoom {
	display: flex;
	align-items: center;
	margin-left: 30px;
	border: 1px solid #efefef;
	border-radius: 4px;
	width: 82px;
	background-color: #f8f8f8;
	justify-content: center;
}
.first-treeview--span {
	margin-left: 5px;
}
@media (min-width: 1200px) {
	.first-treeview--span {
		margin-left: 15px;
	}
}
.editor_ws_image {
	flex-grow: 1 !important;
}
.workbody {
	background: #fff !important;
	padding: 0px !important;
	overflow: hidden;
}
.rightbar {
	/* padding: 0px !important; */
}
.editororderdetails {
	height: calc(100vh - 190px) !important;
	scrollbar-width: thin;
	padding-bottom: 15px;
}

.custom-row-workspace {
	padding: 0px 0px 0px 15px;
}
/* .new-wordspace-area>div{
	margin-top: 5px;
} */

.qc-form-area {
	display: flex;
	justify-content: space-between;
	grid-gap: 15px;
	gap: 15px;
}
.qc-form-area__select {
	min-width: auto;
}
@media (min-width: 1150px) {
	.qc-form-area__select {
		min-width: 200px;
	}
}
/* new changes */
.navbar-expand-lg .navbar-nav {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	justify-content: center;
	align-items: center;
}
.nav-profile-text {
	margin-left: 8px !important;
}
.user-name {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 16px !important;
}
.dropdown-menu.show {
	top: 50px;
	padding: 10px 0px !important;
	margin-left: auto !important;
	right: 0px;
	margin-top: 12%;
}
.sidebar .nav .nav-item.active {
	background: #222 !important;
}
.menu-arrow {
	right: 18px !important;
}
.table-b {
	border: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom: none !important;
}
.page-item.active .page-link {
	background: var(--darkblue) !important;
}
.css-t3ipsp-control {
	min-height: 45px !important;
	box-shadow: none !important;
}
.rightbar {
	padding: 22px 0 0 30px;
}
.proceed-order-card {
	display: grid !important;
	grid-template-columns: 8fr 4fr !important;
	margin-top: 6px;
}
.row-multiple--select {
	grid-gap: 24px !important;
	gap: 24px !important;
	grid-gap: 30px !important;
	gap: 30px !important;
	align-items: center !important;
	padding: 0px 22px !important;
	/* min-height: 40px !important; */
	/* @media (min-width: 1024px) {
		min-height: 50px !important;
	} */
}
.sidebar--select-sm {
	height: calc(100vh - 260px) !important;
	/* padding: 23px !important; */
	padding-bottom: 35px !important;
}
/* .sidebar-specifications{
	padding-bottom: 60px;
} */

.image-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 16px;
	gap: 16px;
	padding: 0px 10px;
	/* display: none; */
}
.image-card > div {
	flex-basis: 200px;
	flex-grow: 1;
	min-height: 200px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 10px;
}
.image-card__image-area img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.image-card__details {
	padding: 10px 5px 12px;
}
.image-card__button {
	display: flex;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 10px;
	max-width: 180px;
	margin: 0 auto;
	justify-content: center;
	margin-top: 8px;
}
.modal-content {
	max-height: calc(100vh - 0px) !important;
	min-height: auto !important;
}
.modal-dialog-centered {
	max-height: calc(100vh - 500px) !important;
	height: auto !important;
}
.formbold-file-input {
	padding: 43px 0px !important;
	position: relative;
}
.formbold-file-input {
	min-height: calc(100vh - 308px);
}
.formbold-file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.progress-bar {
	background-color: #45b24a !important;
	height: 10px !important;
}
.container-pannel--confirm {
	height: calc(100vh - 204px) !important;
}
.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.submit-success-h4 {
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	@media (min-width: 1025px) {
		font-size: 30px;
		font-weight: 400;
		margin-top: 20px;
	}
}
.thankyoubox-sub {
	color: #858585;
	font-size: 15px;
	@media (min-width: 1025px) {
		font-size: 18px;
	}
}

.thankyoubox-sub span {
	color: black;
}
.form-area__p {
	font-size: 13px !important;
	margin-top: 15px;
	@media (min-width: 1025px) {
		font-size: 16px !important;
	}
}
.form-area__divider {
	width: 60px;
	height: 3px;
	background-color: var(--blue);
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 15px;
}
.user-role__flex {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	margin-left: 3px;
}
.user-role__title {
	font-size: 20px;
	font-weight: 600;
}
.border-on-table {
	border: 1px solid #ebedf2 !important;
}
.form-title-sm {
	font-size: 16px !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	line-height: 20px !important;
}
.new-modal__title {
	font-size: 20px !important;
	font-weight: 600 !important;
	background-color: transparent !important;
}
.new-modal > .form-label {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.edit-group-modal {
	max-width: 400px;
}
.modal-content {
	max-width: 600px !important;
	margin: 0 auto !important;
	overflow: auto !important;
}
.modal-body {
	padding: 15px 20px;
}
.modal-content .form-label2 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.mb-08 {
	margin-bottom: 8px !important;
}
.side-form-subtitle {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-sub-head {
	font-size: 13px;
	background: #ddd;
	padding: 6px 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	border-radius: 2px;
}
.side-form-label {
	margin-bottom: 10px !important;
	margin-top: 5px !important;
	display: block;
}
.right-close-btn {
	position: relative;
	top: -5px;
}
ul.margin-list li {
	width: 45px !important;
}
ul.margin-list li input {
	padding: 0px !important;
	text-align: center;
}
.customizeSettings {
	/* justify-content: end; */
	display: flex;
	flex-direction: row;
	grid-gap: 10px;
	gap: 10px;
}
.img-fluid-image-details--view {
	height: calc(100vh - 200px) !important;
	width: 100% !important;
}
.modal-image-icons__area {
	/* padding: 18px 0px; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}
.modal-image-icons__each {
	padding: 9px 0px;
	width: 60px;
	text-align: center;
	margin-bottom: 1px;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
.modal-image-icons__each:hover {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-image-icons__each--active {
	background-color: #edf4ff;
	border-right: 3px solid #edf4ff;
	border-left: 3px solid #2c5ac7;
}
.modal-header .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.o-modal-content__title {
	font-size: 20px;
}
.o-modal-content__divider {
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
	margin-bottom: 15px;
}
.o-modal-content__form-lable {
	font-weight: 400 !important;
	font-size: 12px !important;
}
.o-modal-content__button-area {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}
.o-modal-content__button-area > div {
	flex-grow: 1;
}
.o-modal-content__button-area .btn {
	width: 100%;
	justify-content: center;
}
.duplicate-modal {
	height: calc(100vh - 100px) !important;
	overflow: auto;
	padding: 0px !important;
}
.duplicate-modal__title {
	position: fixed;
	background: #fff;
	width: 590px;
	padding: 15px 24px 13px;
	top: 51px;
	border-radius: 5px;
	margin-left: -1px;
	border-bottom: 1px solid #ddd;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	top: auto;
}
.duplicate-modal__content-area {
	padding: 0px 25px;
	position: relative;
	z-index: 10; /* Ensure the input is clickable */
}
.modal-footer {
	padding: 2px 5px 1px;
	background: #f9f9f9;
	border-top: 1px solid #f9f9f9;
}
/* .duplicate-modal__grid-area{
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 20px;
} */
.duplicate-modal__tab {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	display: block !important;
	overflow-y: auto;
	border: none !important;
	height: 29rem;
}
.nav-tabs .nav-link:hover {
	/* border-color: #fff #fff #fff !important; */
	border-radius: 0px;
}
.duplicate-modal__tab li .nav-link {
	text-align: left;
}
.modal-header {
	padding: 0px !important;
}
.duplicate-modal-size {
	min-width: 595px;
}
.duplicate-settings-error-box {
	display: block;
	/* width: 100%; */
	padding: 15px 0px 10px;
	font-size: 13px;
	color: #f00;
}
.newstrip {
	border-bottom: 1px solid #e9e9e9;
	min-height: 58px !important;
	width: 100% !important;
}
.duplicate-modal__grid-area ul li {
	width: auto !important;
}
.create-order-form-area {
	height: calc(100vh - 145px) !important;
}
.extra-name-hide {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 160px;
}
.triangle {
	width: 0;
	height: 0;
	border-right: 30px solid #edebeb;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}
.triangle svg {
	position: relative;
	left: 14px;
	top: -2px;
}
.order_images {
	position: relative;
}
.editororderdetails .card {
	border: 3px solid auto;
}
.link-between {
	position: absolute;
	right: -16px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.link-between--left {
	left: -16px;
	justify-content: start;
}
.link-between--right {
	right: -16px;
	justify-content: end;
	display: inline;
}
.detailslist .imageLink:last-child .link-between--right {
	display: none;
}

.link-between svg {
	transform: rotate(-44deg);
}
.sidebar-select--editType-sm {
	height: calc(100vh - 350px) !important;
}
.sidebar__header {
	background-color: #f8f8f8;
	padding: 11px 0px 6px;
}
.o-flex {
	display: grid;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: 3fr 1fr;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.badge {
	padding: 5px 6px !important;
	font-size: 10px !important;
}
.order-confirm-h--editing-description {
	height: calc(100vh - 220px) !important;
}
@media (min-width: 1200px) {
	.order-confirm-h--editing-description {
		height: calc(100vh - 204px) !important;
	}
}
.sidebar-settings-image--image-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 190px) !important;
}
.sidebar-settings-image--image-modal2 {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 165px) !important;
}
.sidebar-settings-image--comment-modal {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 140px) !important;
	background-color: #f8f8f8;
	padding: 16px;
}
.small-name {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 100px !important;
}
.small-name2 {
	text-overflow: ellipsis;
	overflow: hidden !important;
	white-space: nowrap;
	width: 180px !important;
}

.dropdown--duplicate {
	flex-direction: column !important;
	padding-top: 7px !important;
}
.dropdown-content {
	position: absolute;
	margin-top: 42px !important;
	margin-left: auto !important;
	height: auto !important;
	max-height: 200px !important;
	box-shadow: 0px 0px 8px 0px rgb(121 119 119 / 20%);
	border-radius: 8px;
}
.update-user-form-area {
	height: calc(100vh - 120px);
}
.form-area2 textarea.form-control {
	height: calc(100vh - 430px) !important;
}
.dropdown-content-duplicate-image--modal-sm {
	margin-top: 38px !important;
	border-radius: 5px !important;
	overflow-x: hidden !important;
	height: auto !important;
	max-height: 200px;
}
/* .dropdown-duplicate-image--modal {
	flex-direction: column;
	padding-top: 10px;
} */
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.go2072408551 {
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	box-shadow: none;
	padding-top: 16px !important;
	padding-bottom: 14px !important;
	grid-gap: 0px !important;
	gap: 0px !important;
	justify-content: center;
	width: auto !important;
	border-left: 2px solid #00cf3f !important;
}
.go3958317564 {
	flex: none !important;
	font-size: 13px;
}

.Toastify__toast--success::after {
	width: 0px;
}
.modal-overlay--order {
	top: 0px !important;
}
.modal-container--image {
	background-color: transparent !important;
	top: 4.8em !important;
}
.modal--image {
	/* height: calc(100vh - 88px) !important; */
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
}
.modal--image > div {
	flex-grow: 1;
	/* max-height: calc(159vh - 575px); */
	max-height: calc(100vh - 89px);
}
.modal-image--order {
	height: calc(100vh - 88px) !important;
}
.close-icons-order--image {
	margin-top: 1em !important;
}
.navbar {
	z-index: auto !important;
}

.modal-overlay--order {
	padding: 10px;
}
.nim {
	height: 100%;
	display: grid;
	grid-template-columns: 60px repeat(10, 1fr) 100px;
	grid-template-rows: 60px 1fr;
	border-radius: 5px;
	overflow: hidden;
	grid-template-areas:
		"i h h h h h h h h h h h"
		"i m m m m m m m m f f f"
		"i m m m m m m m m f f f";
}
.nim__icon-area {
	background-color: #fff;
	grid-area: i;
	border-right: 1px solid #efefef;
	padding-top: 8px;
	display: flex;
	justify-content: center;
}
.nim__header-area {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
	grid-area: h;
	display: flex;
	align-items: center;
}
.nim__main-area {
	grid-area: m;
	width: -webkit-fill-available;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	border-bottom: 8px solid #ffff;
	position: relative;
	overflow: auto;
}
.nim__form-area {
	background-color: #fff;
	border-left: 1px solid #efefef;
	grid-area: f;
}
.nim__content {
	padding: 15px;
}
.nim__content h3 {
	font-size: 20px;
	margin-bottom: 8px;
}
.select-bg-color li {
	width: 15px;
	height: 15px;
	border-radius: 50px;
}
.next-pre-btn {
	background-color: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.dropdown-menu2 {
	position: absolute;
	top: 64px;
	right: 10px;
	width: 236px;
	min-height: 120px;
	background: #fff;
	border-radius: 15px;
	border: 1px solid #ddd;
	z-index: 10;
	padding: 10px 0px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu2 ul {
	margin-bottom: 0px;
}
.dropdown-menu2 ul li {
	padding: 4px 20px;
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	font-size: 14px;
}
.dropdown-menu2 ul li:hover {
	background-color: #efefef;
	cursor: pointer;
}
.dropdown-menu {
	display: none !important;
}

.modal-canvas--image {
	/* top: -60px; */
}
.modal-container {
	transform: scale(1);
	margin: auto;
}
.zoom_container__input:focus {
	border: 1px solid;
}
.auth-form__titlearea {
	text-align: center;
}
.auth-form__title {
	font-size: 30px;
	font-weight: 600;
	margin-top: 40px;
	@media (min-width: 1100px) {
		font-size: 36px;
	}
}
.auth-form__subtitle {
	font-size: 16px !important;
	color: #3b3b3b !important;
}
.new-auth-form {
	margin-top: 35px !important;
	box-shadow: #79797929 0px 0px 35px !important;
	margin-bottom: 30px !important;
}
.new-auth-form label {
	font-weight: 400 !important;
	color: #0b0b0b !important;
	font-size: 15px !important;
}
.new-auth-form .form-control {
	height: 48px;
	font-size: 16px !important;
}
input::placeholder,
textarea::placeholder {
	font-size: 13px !important;
}
.new-auth-form input::placeholder,
.new-auth-form textarea::placeholder {
	font-size: 15px !important;
	font-weight: 400 !important;
	color: #838383 !important;
}
.new-auth-form input[type="text"] {
	font-size: 16px !important;
}
.new-auth-form .form-group {
	margin-bottom: 25px !important;
}
.btn__signup {
	padding: 14px 10px !important;
	font-size: 14px !important;
}
.auth-form__content {
	padding: 0px 45px !important;
}
.auto-form__flex {
	display: flex;
	justify-content: space-between;
}
.zoom_container__input {
	font-size: 16px !important;
	margin-left: 30px !important;
}
.magnifier-holder--img {
	cursor: pointer;
}
.magnifier-holder--img img {
	width: 20px;
}

.image-zoom {
	display: flex;
	align-items: center;
	margin-left: 30px;
	border: 1px solid #efefef;
	border-radius: 4px;
	width: 82px;
	background-color: #f8f8f8;
}
.image-zoom__input {
	width: 50px;
	height: 42px;
	padding: 13px 0px 11px 13px;
	background-color: transparent !important;
	border: none;
	font-size: 16px;
	color: #0b0b0b;
}
.sidebar__flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #dddddd87;
}

.commentcard {
	margin-bottom: 25px;
	padding: 16px;
	border: 1px solid #ddd;
	border-radius: 12px;
}
.commentcard .commentcard__mt {
	margin-top: 15px;
}
.commentcard .commentcard__mt:first-child {
	margin-top: 0px;
}
.commentcard--bg {
	background-color: #fff;
	padding: 16px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	border: none;
}
.commentcard__profile {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}
@media (min-width: 1100px) {
	.commentcard__profile {
		grid-gap: 12px;
		gap: 12px;
	}
	.commentcard {
		padding: 20px;
	}
}
.commentcard__input {
	width: 100%;
	border: 1px solid #8f8f8f;
	border-radius: 5px;
	height: 42px;
	padding: 0px 15px;
	margin-top: 10px;
	color: #353535 !important;
}
.commentcard__input:focus {
	border-color: rgba(0, 0, 0, 1) !important;
}
@media (min-width: 1100px) {
	.commentcard__input {
		height: 45px;
	}
}
.commentcard__input::placeholder {
	font-size: 14px !important;
	color: #797979 !important;
}
.commentcard__dp {
	width: 40px;
	height: 40px;
	background-color: #ddd;
	border-radius: 50px;
}
.commentcard__name {
	font-size: 12px;
}
.commentcard__time {
	font-size: 11px;
	color: #918d8d;
	line-height: 1.6;
}
@media (min-width: 1100px) {
	.commentcard__name {
		font-size: 14px;
	}
	.commentcard__time {
		font-size: 12px;
		line-height: 1.5;
	}
}
.commentcard__btn-area {
	margin-top: 12px;
	display: flex;
	grid-gap: 15px;
	gap: 15px;
	align-items: center;
	justify-content: end;
	font-size: 15px;
}
@media (min-width: 1100px) {
	.commentcard__btn-area {
		margin-top: 16px;
	}
}

.commentcard__btn-area .next-btn {
	font-size: 14px;
	font-weight: 500;
}
.simplecomment {
	margin-top: 28px;
}
.commentcard__dropdown {
	display: flex;
	justify-content: space-between;
	/* padding-right: 20px; */
}
.commentcard--bg .commentcard__dropdown {
	padding-right: 0px;
}

.commentcard__comment {
	font-size: 14px;
	margin-top: 10px;
}
.commentcard--bg .commentcard__comment {
	margin-bottom: 10px;
}
.commentcard__dropdown-area {
	display: none;
	min-width: 105px;
	min-height: 62px;
	background-color: #fff;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	position: absolute;
	right: 15px;
	top: 5px;
	padding: 5px 15px;
}
.commentcard__dropdownlist {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	font-size: 12px;
	align-items: center;
	margin-top: 6px;
	margin-bottom: 6px;
}
.commentcard__dropdownlist:hover {
	cursor: pointer;
}
.commentcard__dropdown-area--visible {
	display: block;
}
.commentcard__dropdownlist svg {
	margin-top: -2px;
}
.thumbnailimage {
	display: flex;
	justify-content: center;
	align-items: center;
}
.thumbnailimage > p {
	position: absolute;
	bottom: 36%;
	font-size: 16px !important;
}
.align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.first-treeview--inner {
	margin-left: 20px;
}
.conform-order-message {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 310px);
	text-align: center;
}
.conform-order-message__content {
	color: #4d4c4c;
	font-size: 20px;
	max-width: 650px;
	margin: 0 auto;
	padding: 0px 50px;
	line-height: 1.5;
}
.conform-order-message__h3 {
	color: #4d4c4c;
	font-size: 18px;
	line-height: 1.5;
	border-bottom: 1px solid #ddd;
	padding-bottom: 15px;
	margin-bottom: 10px;
}
@media (min-width: 1200px) {
	.conform-order-message__h3 {
		padding-bottom: 25px;
		margin-bottom: 20px;
	}
}
.conform-order-message__checkbox {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	justify-content: center;
}
.conform-order-message__checkbox-p {
	color: #4d4c4c;
}

.item-upload-popup {
	max-width: 318px;
	background: #f7f9fc;
	position: absolute;
	width: 320px;
	right: 15px;
	bottom: 0;
	max-height: calc(100vh - 350px);
	height: auto;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	font-size: 14px;
	padding: 15px 20px;
	padding-bottom: 0px;
	z-index: 1;
	box-shadow: 0 -1px 10px #4800c917;
}
.item-upload-popup__title-area {
	display: flex;
	justify-content: space-between;
	grid-gap: 15px;
	gap: 15px;
	padding-bottom: 10px;
	/* margin-bottom: 10px; */
}
.item-upload-popup__flex {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	align-items: center;
}
.item-upload-popup__item-list {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	padding-right: 5px;
}
.item-upload-popup__flex2 {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	/* align-items: center; */
}
.item-upload-popup__item-area {
	overflow-x: hidden;
	scrollbar-width: thin;
	max-height: calc(100vh - 410px);
	height: auto;
	padding-bottom: 10px;
	border-top: 1px solid #ddd;
}
.pan_container {
	height: -webkit-fill-available;
	position: relative;
	overflow: hidden;
	scroll-behavior: smooth;
}
.privious-btn {
	border: 1px solid #0001c7 !important;
	background-color: transparent !important;
	color: #0001c7 !important;
	grid-gap: 15px !important;
	gap: 15px !important;
}
.sidebar__header__save-btn {
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.editororderdetails_1 {
	width: 100%;
	/* position: fixed; */
	/* overflow: hidden; */
}
.sidebar--select {
	width: 100%;
	max-width: 100%;
	height: calc(100vh - 280px);
	position: relative;
	padding-top: 6px;
}

/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + Badge Mixins
    + Button Mixins
    + Miscellaneous Mixins
    + BlockQuote Mixins
    + Cards Mixins
    + Color Functions Mixins
    + Tooltips
    + popovers
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Backgrounds
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
  * Components
    + Badges
    + Boostrap Progress
    + Buttons
    + Breadcrumbs
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Preview
    + Tables
    + Todo List
  * Landing screens
    + Auth
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------*/ /* Import the Input Component CSS */ /* Import the Span Tag CSS */ /* Import the UL/LI CSS */ /* Import Button CSS */ /* Import Responsive/Coordinator CSS */ /* Import Toast Alert CSS */ /* Import Mobile  CSS */ /*Import Media Query for main layout CSS*/
hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
}
ul {
	padding-left: 0;
}
li {
	list-style-type: none;
}
h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}
hr:not([size]) {
	height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

h1,
.h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	h1,
	.h1 {
		font-size: 2.5rem;
	}
}

h2,
.h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	h2,
	.h2 {
		font-size: 2rem;
	}
}

h3,
.h3 {
	font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
	h3,
	.h3 {
		font-size: 1.75rem;
	}
}

@media (min-width: 1200px) {
}

h5,
.h5 {
	font-size: 1.25rem;
}

h6,
.h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol {
	padding-left: 2rem;
}

ol,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small,
.small {
	font-size: 0.875em;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #0d6efd;
	text-decoration: none;
}
a:hover {
	color: #0a58ca;
	text-decoration: none;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr /* rtl:ignore */;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
}
pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
}
a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}
kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #6c757d;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}
select:disabled {
	opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

textarea {
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}
@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem;
	}
}
legend + * {
	clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::file-selector-button {
	font: inherit;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}
.email-validated {
	display: contents;
}
.loader-login {
	margin-left: auto;
	margin-right: auto;
}
.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-1 {
		font-size: 5rem;
	}
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-2 {
		font-size: 4.5rem;
	}
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-3 {
		font-size: 4rem;
	}
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-4 {
		font-size: 3.5rem;
	}
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-5 {
		font-size: 3rem;
	}
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-6 {
		font-size: 2.5rem;
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}
.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}
.blockquote > :last-child {
	margin-bottom: 0;
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #6c757d;
}
.blockquote-footer::before {
	content: "\2014\A0";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 0.875em;
	color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto; */
}

@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container,
	.container-sm,
	.container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}

.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: #ecf1f7;
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #ebedf2;
}
.table > :not(caption) > * > * {
	padding: 0.5rem 0.5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
	vertical-align: inherit;
}
.table > thead {
	position: sticky;
	top: 0;
	vertical-align: bottom;
}
.table > :not(:first-child) {
	border-top: 2px solid currentColor;
}

.caption-top {
	caption-side: top;
}

.table-sm > :not(caption) > * > * {
	padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
	border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
	border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
	border-top-width: 0;
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
	--bs-table-accent-bg: #ecf1f7;
	color: var(--bs-table-hover-color);
}

.table-primary {
	--bs-table-bg: #cfe2ff;
	--bs-table-striped-bg: #c5d7f2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bacbe6;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bacbe6;
}

.table-secondary {
	--bs-table-bg: #e2e3e5;
	--bs-table-striped-bg: #d7d8da;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #cbccce;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #cbccce;
}

.table-success {
	--bs-table-bg: #d1e7dd;
	--bs-table-striped-bg: #c7dbd2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bcd0c7;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bcd0c7;
}

.table-info {
	--bs-table-bg: #cff4fc;
	--bs-table-striped-bg: #c5e8ef;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #badce3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #badce3;
}

.table-warning {
	--bs-table-bg: #fff3cd;
	--bs-table-striped-bg: #f2e7c3;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e6dbb9;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e6dbb9;
}

.table-danger {
	--bs-table-bg: #f8d7da;
	--bs-table-striped-bg: #eccccf;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfc2c4;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfc2c4;
}

.table-light {
	--bs-table-bg: #f8f9fa;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfe0e1;
}

.table-dark {
	--bs-table-bg: #212529;
	--bs-table-striped-bg: #2c3034;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #373b3e;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #ecf1f7;
	--bs-table-hover-color: #fff;
	color: #fff;
	border-color: #373b3e;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.my-modal {
	width: 90vw; /* Occupy the 90% of the screen width */
	max-width: 90vw;
}
.flex-row--reverse {
	flex-flow: row-reverse;
}
.datalist {
	max-height: 20em;
	border: 0 none;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	height: 135px;
	border-color: #0d6efd;
	border: 1px solid rgba(0, 0, 0, 0.25);
	-webkit-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
}
.search--icons {
	position: absolute;
	z-index: 1;
	margin: 6px;
}
@media (max-width: 575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1399.98px) {
	.table-responsive-xxl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.form-label {
	margin-bottom: 0.5rem;
}

.col-form-label {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1;
}

.col-form-label-lg {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	font-size: 1.25rem;
}

.col-form-label-sm {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 0.875rem;
}

.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #6c757d;
}

.form-control,
.form-select {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	font-size: 13px;
}

.table tr {
	border-bottom: 1px solid #ebedf2;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b !important;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.94rem 0;
	margin-bottom: 0;
	line-height: 1;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	min-height: 2.575rem;
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	margin-inline-end: 0.81rem;
}
.form-control-sm::-webkit-file-upload-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	margin-inline-end: 0.81rem;
}

.form-control-lg {
	min-height: 3.175rem;
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	margin-inline-end: 1.94rem;
}
.form-control-lg::-webkit-file-upload-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	margin-inline-end: 1.94rem;
}

textarea.form-control {
	min-height: 2.875rem;
}

textarea.form-control-sm {
	min-height: 2.575rem;
}

textarea.form-control-lg {
	min-height: 3.175rem;
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: 0.94rem;
}
.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer;
}
.form-control-color::-moz-color-swatch {
	height: 1em;
	border-radius: 2px;
}
.form-control-color::-webkit-color-swatch {
	height: 1em;
	border-radius: 2px;
}

.form-select {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	-webkit-appearance: none;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-select {
		transition: none;
	}
}
.form-select:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
	padding-right: 0.75rem;
	background-image: none;
}
.form-select:disabled {
	background-color: #e9ecef;
}
.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #212529;
}

.form-select-sm {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.form-select-lg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}
.form-check .form-check-input {
	float: left;
	margin-left: -1.5em;
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: 0.25em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, 0.25);
	-webkit-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}
.form-check-input[type="checkbox"] {
	border-radius: 0.25em;
}
.form-check-input[type="radio"] {
	border-radius: 50%;
}
.form-check-input:active {
	filter: brightness(90%);
}
.form-check-input:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
	background-color: #0d6efd;
	border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
	background-color: #0d6efd;
	border-color: #0d6efd;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
	opacity: 0.5;
}

.form-switch {
	padding-left: 2.5em;
}
.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-switch .form-check-input {
		transition: none;
	}
}
.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
	pointer-events: none;
	filter: none;
	opacity: 0.65;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	appearance: none;
}
.form-range:focus {
	outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
	border: 0;
}
.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-webkit-slider-thumb {
		-webkit-transition: none;
		transition: none;
	}
}
.form-range::-webkit-slider-thumb:active {
	background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-moz-range-thumb {
		-moz-transition: none;
		transition: none;
	}
}
.form-range::-moz-range-thumb:active {
	background-color: #b6d4fe;
}
.form-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range:disabled {
	pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.form-floating {
	position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
}
.form-floating > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 0.75rem;
	pointer-events: none;
	border: 1px solid transparent;
	transform-origin: 0 0;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-floating > label {
		transition: none;
	}
}
.form-floating > .form-control {
	padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
	color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-select {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
	z-index: 3;
}
.input-group .btn {
	position: relative;
	z-index: 2;
}
.input-group .btn:focus {
	z-index: 3;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 2px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
	padding-right: 3rem;
}

.input-group:not(.has-validation)
	> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group.has-validation
	> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
		.valid-feedback
	):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #198754;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(25, 135, 84, 0.9);
	border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #198754;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
	border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
	border-color: #198754;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
	background-color: #198754;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
	z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
	z-index: 3;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #dc3545;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.9);
	border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
	border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
	background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
	color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
	z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
	z-index: 3;
}

.fade {
	transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}
.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}
.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing.collapse-horizontal {
		transition: none;
	}
}

.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}
.dropdown-toggle::after {
	/* display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; */
	display: flex;
	margin-top: 9px;
	/* margin-left: 23px !important;
	margin-right: 23px !important; */

	vertical-align: text-top;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid rgba(0, 0, 0, 0);
	border-bottom: 0;
	border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.125rem;
}

.dropdown-menu-start {
	--bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0;
}

.dropdown-menu-end {
	--bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto;
}

@media (min-width: 576px) {
	.dropdown-menu-sm-start {
		--bs-position: start;
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-sm-end {
		--bs-position: end;
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 768px) {
	.dropdown-menu-md-start {
		--bs-position: start;
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-end {
		--bs-position: end;
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 992px) {
	.dropdown-menu-lg-start {
		--bs-position: start;
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-end {
		--bs-position: end;
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1200px) {
	.dropdown-menu-xl-start {
		--bs-position: start;
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-end {
		--bs-position: end;
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1400px) {
	.dropdown-menu-xxl-start {
		--bs-position: start;
	}
	.dropdown-menu-xxl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xxl-end {
		--bs-position: end;
	}
	.dropdown-menu-xxl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-toggle::after {
	vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropstart .dropdown-toggle::after {
	display: none;
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropstart .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #ebedf2;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #343a40;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #0d6efd;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #343a40;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #343a40;
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
	color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.btn-toolbar .input-group {
	width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
	margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 1.875rem;
	padding-left: 1.875rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.6075rem;
	padding-left: 0.6075rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 2.25rem;
	padding-left: 2.25rem;
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
	width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
	margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #0d6efd;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.nav-link {
		transition: none;
	}
}
.nav-link:hover,
.nav-link:focus {
	color: #0a58ca;
}
.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #ebedf2;
}
.nav-tabs .nav-link {
	margin-bottom: -1px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	background: none;
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #ebedf2 #ebedf2 #ebedf2;
	isolation: isolate;
}
.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #343a40;
	background-color: #ffffff;
	border-color: #ebedf2 #ebedf2 #ffffff;
}
.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	background: none;
	border: 0;
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-nav .dropdown-menu {
	position: static;
}

.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.navbar-toggler {
		transition: none;
	}
}
.navbar-toggler:hover {
	text-decoration: none;
}
.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto;
}
.active-staff {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;

	display: flex;
	align-items: center;

	color: #125ecb;
}
table,
th,
td {
	width: "145px";
	textalign: "left";
}
@media (min-width: 576px) {
	.navbar-expand-sm {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
	.navbar-expand-sm .offcanvas-header {
		display: none;
	}
	.navbar-expand-sm .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-sm .offcanvas-top,
	.navbar-expand-sm .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-sm .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
	.navbar-expand-md .offcanvas-header {
		display: none;
	}
	.navbar-expand-md .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-md .offcanvas-top,
	.navbar-expand-md .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-md .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
	.navbar-expand-lg .offcanvas-header {
		display: none;
	}
	.navbar-expand-lg .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-lg .offcanvas-top,
	.navbar-expand-lg .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-lg .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 1200px) {
	.navbar-expand-xl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-xl .offcanvas-top,
	.navbar-expand-xl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

@media (min-width: 1400px) {
	.navbar-expand-xxl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xxl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xxl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xxl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-xxl .offcanvas-top,
	.navbar-expand-xxl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xxl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}

.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start;
}
.navbar-expand .navbar-nav {
	flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
	overflow: visible;
}
.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
}
.navbar-expand .navbar-toggler {
	display: none;
}
.navbar-expand .offcanvas-header {
	display: none;
}
.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	flex-grow: 1;
	visibility: visible !important;
	background-color: transparent;
	border-right: 0;
	border-left: 0;
	transition: none;
	transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
	height: auto;
	border-top: 0;
	border-bottom: 0;
}
.navbar-expand .offcanvas-body {
	display: flex;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
	width: auto;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff;
}

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button {
		transition: none;
	}
}
.accordion-button:not(.collapsed) {
	color: #0c63e4;
	background-color: #e7f1ff;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}
.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button::after {
		transition: none;
	}
}
.accordion-button:hover {
	z-index: 2;
}
.accordion-button:focus {
	z-index: 3;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
	margin-bottom: 0;
}

.accordion-item {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
	border-top: 0;
}
.accordion-item:last-of-type {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-body {
	padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
	border-width: 0;
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
	border-top: 0;
}
.accordion-flush .accordion-item:last-child {
	border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
	border-radius: 0;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.56rem 1.13rem;
	margin-bottom: 1rem;
	font-size: 0.875rem;
	list-style: none;
	background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/")
		/* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
	color: #495057;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	justify-content: end;
}

.page-link {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 19px;
	color: #5a616b;
	font-weight: 500;
	text-decoration: none;
	background-color: #fff;
	border: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.page-link {
		font-size: 13px;
		line-height: 19px;
		color: #5a616b;
		transition: none;
	}
}
.page-link:hover {
	z-index: 2;
	color: #0a58ca;
	background-color: #e9ecef;
	border-color: #dee2e6;
}
.page-link:focus {
	z-index: 3;
	color: #0a58ca;
	background-color: #e9ecef;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}

.page-item.active .page-link {
	/* z-index: 3; */
	color: #fff;
	background: #0b0b0b;
	border-radius: 6px;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
}

.page-link {
	padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: 0.75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
}
.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3rem;
}
.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

.alert-primary {
	color: #6d4199;
	background-color: #f0e2ff;
	border-color: #e9d3ff;
}
.alert-primary .alert-link {
	color: #57347a;
}

.alert-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
	border-color: #edebeb;
}
.alert-secondary .alert-link {
	color: #3e3d3d;
}

.alert-success {
	color: #0b5348;
	background-color: #d1f5f0;
	border-color: #bbf1e9;
}
.alert-success .alert-link {
	color: #09423a;
}

.alert-info {
	color: #0f5388;
	background-color: #d1e8f9;
	border-color: #badcf7;
}
.alert-info .alert-link {
	color: #0c426d;
}

.alert-warning {
	color: #665608;
	background-color: #fff7d0;
	border-color: #fff3b8;
}
.alert-warning .alert-link {
	color: #524506;
}

.alert-danger {
	color: #984a5a;
	background-color: #ffe5ea;
	border-color: #ffd8e0;
}
.alert-danger .alert-link {
	color: #7a3b48;
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe;
}
.alert-light .alert-link {
	color: #4f5050;
}

.alert-dark {
	color: #252d37;
	background-color: #d8dbde;
	border-color: #c5c9ce;
}
.alert-dark .alert-link {
	color: #1e242c;
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}
/* 
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } } */

.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		animation: none;
	}
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section;
}
.list-group-numbered > li::before {
	content: counters(section, ".") ". ";
	counter-increment: section;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}
.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}
.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd;
}
.list-group-item + .list-group-item {
	border-top-width: 0;
}
.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-horizontal {
	flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

@media (min-width: 576px) {
	.list-group-horizontal-sm {
		flex-direction: row;
	}
	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 768px) {
	.list-group-horizontal-md {
		flex-direction: row;
	}
	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 992px) {
	.list-group-horizontal-lg {
		flex-direction: row;
	}
	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1200px) {
	.list-group-horizontal-xl {
		flex-direction: row;
	}
	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1400px) {
	.list-group-horizontal-xxl {
		flex-direction: row;
	}
	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

.list-group-flush {
	border-radius: 0;
}
.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-primary {
	color: #6d4199;
	background-color: #f0e2ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #6d4199;
	background-color: #d8cbe6;
}
.list-group-item-primary.list-group-item-action.active {
	color: #ffffff;
	background-color: #6d4199;
	border-color: #6d4199;
}

.list-group-item-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #4e4c4c;
	background-color: #dbdada;
}
.list-group-item-secondary.list-group-item-action.active {
	color: #ffffff;
	background-color: #4e4c4c;
	border-color: #4e4c4c;
}

.list-group-item-success {
	color: #0b5348;
	background-color: #d1f5f0;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #0b5348;
	background-color: #bcddd8;
}
.list-group-item-success.list-group-item-action.active {
	color: #ffffff;
	background-color: #0b5348;
	border-color: #0b5348;
}

.list-group-item-info {
	color: #0f5388;
	background-color: #d1e8f9;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #0f5388;
	background-color: #bcd1e0;
}
.list-group-item-info.list-group-item-action.active {
	color: #ffffff;
	background-color: #0f5388;
	border-color: #0f5388;
}

.list-group-item-warning {
	color: #665608;
	background-color: #fff7d0;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #665608;
	background-color: #e6debb;
}
.list-group-item-warning.list-group-item-action.active {
	color: #ffffff;
	background-color: #665608;
	border-color: #665608;
}

.list-group-item-danger {
	color: #984a5a;
	background-color: #ffe5ea;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #984a5a;
	background-color: #e6ced3;
}
.list-group-item-danger.list-group-item-action.active {
	color: #ffffff;
	background-color: #984a5a;
	border-color: #984a5a;
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
	color: #ffffff;
	background-color: #636464;
	border-color: #636464;
}

.list-group-item-dark {
	color: #252d37;
	background-color: #d8dbde;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #252d37;
	background-color: #c2c5c8;
}
.list-group-item-dark.list-group-item-action.active {
	color: #ffffff;
	background-color: #252d37;
	border-color: #252d37;
}

.btn-close-white {
	filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.toast.showing {
	opacity: 0;
}
.toast:not(.show) {
	display: none;
}

.toast-container {
	width: max-content;
	max-width: 100%;
	pointer-events: none;
}
.toast-container > :not(:last-child) {
	margin-bottom: 0.75rem;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
	margin-right: -0.375rem;
	margin-left: 0.75rem;
}

.toast-body {
	padding: 0.75rem;
	word-wrap: break-word;
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.75rem;
	word-wrap: break-word;
	opacity: 0;
}
.tooltip.show {
	opacity: 0.9;
}
.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
	bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
	top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.4rem 0.75rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.375rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0 /* rtl:ignore */;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}
.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
	top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 1rem 1rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel.pointer-event {
	touch-action: pan-y;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}
.carousel-inner::after {
	display: block;
	clear: both;
	content: "";
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-item {
		transition: none;
	}
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-fade .active.carousel-item-start,
	.carousel-fade .active.carousel-item-end {
		transition: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-control-prev,
	.carousel-control-next {
		transition: none;
	}
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}
.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-indicators [data-bs-target] {
		transition: none;
	}
}
.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
	filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000;
}

.carousel-dark .carousel-caption {
	color: #000;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg) /* rtl:ignore */;
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
	.spinner-border,
	.spinner-grow {
		animation-duration: 1.5s;
	}
}

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1045;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.offcanvas {
		transition: none;
	}
}

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.offcanvas-backdrop.fade {
	opacity: 0;
}
.offcanvas-backdrop.show {
	opacity: 0.5;
}

.offcanvas-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin-top: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.offcanvas-body {
	flex-grow: 1;
	padding: 1rem 1rem;
	overflow-y: auto;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(100%);
}

.offcanvas.show {
	transform: none;
}

.placeholder {
	display: inline-block;
	min-height: 1em;
	vertical-align: middle;
	cursor: wait;
	background-color: currentColor;
	opacity: 0.5;
}
.placeholder.btn::before {
	display: inline-block;
	content: "";
}

.placeholder-xs {
	min-height: 0.6em;
}

.placeholder-sm {
	min-height: 0.8em;
}

.placeholder-lg {
	min-height: 1.2em;
}

.placeholder-glow .placeholder {
	animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}

.placeholder-wave {
	-webkit-mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	-webkit-mask-size: 200% 100%;
	mask-size: 200% 100%;
	animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0%;
		mask-position: -200% 0%;
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.link-primary {
	color: #b66dff;
}
.link-primary:hover,
.link-primary:focus {
	color: #c58aff;
}

.link-secondary {
	color: #c3bdbd;
}
.link-secondary:hover,
.link-secondary:focus {
	color: #cfcaca;
}

.link-success {
	color: #1bcfb4;
}
.link-success:hover,
.link-success:focus {
	color: #49d9c3;
}

.link-info {
	color: #198ae3;
}
.link-info:hover,
.link-info:focus {
	color: #47a1e9;
}

.link-warning {
	color: #fed713;
}
.link-warning:hover,
.link-warning:focus {
	color: #fedf42;
}

.link-danger {
	color: #fe7c96;
}
.link-danger:hover,
.link-danger:focus {
	color: #fe96ab;
}

.link-light {
	color: #f8f9fa;
}
.link-light:hover,
.link-light:focus {
	color: #f9fafb;
}

.link-dark {
	color: #3e4b5b;
}
.link-dark:hover,
.link-dark:focus {
	color: #323c49;
}

.ratio {
	position: relative;
	width: 100%;
}
.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: "";
}
.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%;
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020;
}

@media (min-width: 576px) {
	.sticky-sm-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 768px) {
	.sticky-md-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 992px) {
	.sticky-lg-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1200px) {
	.sticky-xl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1400px) {
	.sticky-xxl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.hstack {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
}

.vstack {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.vr {
	display: inline-block;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: 0.25;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.float-start {
	float: left !important;
}

.float-end {
	float: right !important;
}

.float-none {
	float: none !important;
}

.opacity-0 {
	opacity: 0 !important;
}

.opacity-25 {
	opacity: 0.25 !important;
}

.opacity-50 {
	opacity: 0.5 !important;
}

.opacity-75 {
	opacity: 0.75 !important;
}

.opacity-100 {
	opacity: 1 !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex,
.page-header,
.loader-demo-box,
.list-wrapper ul li,
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}

.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}

.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}

.translate-middle {
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}

.border,
.loader-demo-box {
	border: 1px solid #ebedf2 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top {
	border-top: 1px solid #ebedf2 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end {
	border-right: 1px solid #ebedf2 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #ebedf2 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start {
	border-left: 1px solid #ebedf2 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #b66dff !important;
}

.border-secondary,
.loader-demo-box {
	border-color: #c3bdbd !important;
}

.border-success {
	border-color: #1bcfb4 !important;
}

.border-info {
	border-color: #198ae3 !important;
}

.border-warning {
	border-color: #fed713 !important;
}

.border-danger {
	border-color: #fe7c96 !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #3e4b5b !important;
}

.border-white {
	border-color: #ffffff !important;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.vw-100 {
	width: 100vw !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mh-100 {
	max-height: 100% !important;
}

/* .vh-100 {
	height: 100vh !important;
} */

.min-vh-100 {
	min-height: 100vh !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.gap-0 {
	grid-gap: 0 !important;
	gap: 0 !important;
}

.gap-1 {
	grid-gap: 0.25rem !important;
	gap: 0.25rem !important;
}

.gap-2 {
	grid-gap: 0.5rem !important;
	gap: 0.5rem !important;
}

.gap-3 {
	grid-gap: 1rem !important;
	gap: 1rem !important;
}

.gap-4 {
	grid-gap: 1.5rem !important;
	gap: 1.5rem !important;
}

.gap-5 {
	grid-gap: 3rem !important;
	gap: 3rem !important;
}

.justify-content-start,
.list-wrapper ul li {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between,
.page-header {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center,
.page-header,
.loader-demo-box,
.list-wrapper ul li,
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0,
.demo-modal .modal-dialog {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo .circle-progress {
	margin-top: 0.5rem !important;
}

.mt-3,
.template-demo > .btn,
.template-demo > .btn-toolbar {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0,
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo > .dropdown {
	margin-right: 0.5rem !important;
}

.me-3,
.template-demo > .btn,
.template-demo > .btn-toolbar {
	margin-right: 1rem !important;
}

.me-4,
.rtl .navbar .navbar-menu-wrapper .search-field {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto,
.rtl .list-wrapper .remove {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3,
.template-demo .circle-progress-block {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0,
.rtl .preview-list .preview-item .preview-thumbnail .preview-icon i,
.rtl .list-wrapper .remove,
.rtl .navbar .navbar-menu-wrapper .search-field {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2,
.btn-toolbar .btn-group + .btn-group {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto,
.list-wrapper .remove {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2,
.template-demo .circle-progress-block {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3,
.card-revenue-table .revenue-item {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0,
.card-revenue-table .revenue-item:first-child {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0,
.card-revenue-table .revenue-item:last-child {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
	font-size: 1.25rem !important;
}

.fs-6 {
	font-size: 1rem !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}

.image_loader {
	top: 50% !important;
}

.loader_download {
	position: relative;
	top: 17.5%;
	left: 46%;
}

.assgin_qc {
	position: relative;
	top: 17.5%;
	left: 130%;
}

.upload_loader {
	margin-left: 18px;
}

/* rtl:begin:remove */
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary,
.list-wrapper .completed .remove {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary,
.list-wrapper .remove {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white,
.navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-secondary .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
	--bs-text-opacity: 1;
	color: #9c9fa6 !important;
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important;
}

.text-opacity-25 {
	--bs-text-opacity: 0.25;
}

.text-opacity-50 {
	--bs-text-opacity: 0.5;
}

.text-opacity-75 {
	--bs-text-opacity: 0.75;
}

.text-opacity-100 {
	--bs-text-opacity: 1;
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-primary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-secondary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-success-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-warning-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-body-bg-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent !important;
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
	--bs-bg-opacity: 1;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.user-select-all {
	-webkit-user-select: all !important;
	user-select: all !important;
}

.user-select-auto {
	-webkit-user-select: auto !important;
	user-select: auto !important;
}

.user-select-none {
	-webkit-user-select: none !important;
	user-select: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.rounded,
.loader-demo-box {
	border-radius: 0.25rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.rounded-1 {
	border-radius: 0.2rem !important;
}

.rounded-2 {
	border-radius: 0.25rem !important;
}

.rounded-3 {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}

.rounded-end {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
	border-bottom-left-radius: 0.25rem !important;
	border-top-left-radius: 0.25rem !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}
.modal-body-body---delete {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	margin: 0px;
	width: max-content;
}
.add-work--title {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
}
.add-work--content {
	font-family: "Heebo";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
}
.modal-content {
	width: max-content !important;
	max-height: 44em;
	min-height: 44em;
}
@media (min-width: 576px) {
	.float-sm-start {
		float: left !important;
	}
	.float-sm-end {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-grid {
		display: grid !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	.d-sm-none {
		display: none !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-sm-wrap-branch {
		flex-wrap: wrap !important;
		flex-direction: row-reverse;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
		flex-shrink: 1 !important;
		/* justify-content: ; */
		/* width: 90%; */
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-sm-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}
	.gap-sm-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}
	.gap-sm-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}
	.gap-sm-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.gap-sm-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}
	.gap-sm-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
	.order-sm-first {
		order: -1 !important;
	}
	.order-sm-0 {
		order: 0 !important;
	}
	.order-sm-1 {
		order: 1 !important;
	}
	.order-sm-2 {
		order: 2 !important;
	}
	.order-sm-3 {
		order: 3 !important;
	}
	.order-sm-4 {
		order: 4 !important;
	}
	.order-sm-5 {
		order: 5 !important;
	}
	.order-sm-last {
		order: 6 !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.me-sm-0 {
		margin-right: 0 !important;
	}
	.me-sm-1 {
		margin-right: 0.25rem !important;
	}
	.me-sm-2 {
		margin-right: 0.5rem !important;
	}
	.me-sm-3 {
		margin-right: 1rem !important;
	}
	.me-sm-4 {
		margin-right: 1.5rem !important;
	}
	.me-sm-5 {
		margin-right: 3rem !important;
	}
	.me-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ms-sm-0 {
		margin-left: 0 !important;
	}
	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}
	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}
	.ms-sm-3 {
		margin-left: 1rem !important;
	}
	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}
	.ms-sm-5 {
		margin-left: 3rem !important;
	}
	.ms-sm-auto {
		margin-left: auto !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pe-sm-0 {
		padding-right: 0 !important;
	}
	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pe-sm-3 {
		padding-right: 1rem !important;
	}
	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pe-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-0 {
		padding-left: 0 !important;
	}
	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}
	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}
	.ps-sm-3 {
		padding-left: 1rem !important;
	}
	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}
	.ps-sm-5 {
		padding-left: 3rem !important;
	}
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.float-md-start {
		float: left !important;
	}
	.float-md-end {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-grid {
		display: grid !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	.d-md-none {
		display: none !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-md-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}
	.gap-md-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}
	.gap-md-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}
	.gap-md-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.gap-md-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}
	.gap-md-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
	.order-md-first {
		order: -1 !important;
	}
	.order-md-0 {
		order: 0 !important;
	}
	.order-md-1 {
		order: 1 !important;
	}
	.order-md-2 {
		order: 2 !important;
	}
	.order-md-3 {
		order: 3 !important;
	}
	.order-md-4 {
		order: 4 !important;
	}
	.order-md-5 {
		order: 5 !important;
	}
	.order-md-last {
		order: 6 !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.me-md-0 {
		margin-right: 0 !important;
	}
	.me-md-1 {
		margin-right: 0.25rem !important;
	}
	.me-md-2 {
		margin-right: 0.5rem !important;
	}
	.me-md-3 {
		margin-right: 1rem !important;
	}
	.me-md-4 {
		margin-right: 1.5rem !important;
	}
	.me-md-5 {
		margin-right: 3rem !important;
	}
	.me-md-auto {
		margin-right: auto !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ms-md-0 {
		margin-left: 0 !important;
	}
	.ms-md-1 {
		margin-left: 0.25rem !important;
	}
	.ms-md-2 {
		margin-left: 0.5rem !important;
	}
	.ms-md-3 {
		margin-left: 1rem !important;
	}
	.ms-md-4 {
		margin-left: 1.5rem !important;
	}
	.ms-md-5 {
		margin-left: 3rem !important;
	}
	.ms-md-auto {
		margin-left: auto !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pe-md-0 {
		padding-right: 0 !important;
	}
	.pe-md-1 {
		padding-right: 0.25rem !important;
	}
	.pe-md-2 {
		padding-right: 0.5rem !important;
	}
	.pe-md-3 {
		padding-right: 1rem !important;
	}
	.pe-md-4 {
		padding-right: 1.5rem !important;
	}
	.pe-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.ps-md-0 {
		padding-left: 0 !important;
	}
	.ps-md-1 {
		padding-left: 0.25rem !important;
	}
	.ps-md-2 {
		padding-left: 0.5rem !important;
	}
	.ps-md-3 {
		padding-left: 1rem !important;
	}
	.ps-md-4 {
		padding-left: 1.5rem !important;
	}
	.ps-md-5 {
		padding-left: 3rem !important;
	}
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.float-lg-start {
		float: left !important;
	}
	.float-lg-end {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-grid {
		display: grid !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-lg-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}
	.gap-lg-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}
	.gap-lg-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}
	.gap-lg-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.gap-lg-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}
	.gap-lg-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
	.order-lg-first {
		order: -1 !important;
	}
	.order-lg-0 {
		order: 0 !important;
	}
	.order-lg-1 {
		order: 1 !important;
	}
	.order-lg-2 {
		order: 2 !important;
	}
	.order-lg-3 {
		order: 3 !important;
	}
	.order-lg-4 {
		order: 4 !important;
	}
	.order-lg-5 {
		order: 5 !important;
	}
	.order-lg-last {
		order: 6 !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.me-lg-0 {
		margin-right: 0 !important;
	}
	.me-lg-1 {
		margin-right: 0.25rem !important;
	}
	.me-lg-2 {
		margin-right: 0.5rem !important;
	}
	.me-lg-3 {
		margin-right: 1rem !important;
	}
	.me-lg-4 {
		margin-right: 1.5rem !important;
	}
	.me-lg-5 {
		margin-right: 3rem !important;
	}
	.me-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ms-lg-0 {
		margin-left: 0 !important;
	}
	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}
	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}
	.ms-lg-3 {
		margin-left: 1rem !important;
	}
	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}
	.ms-lg-5 {
		margin-left: 3rem !important;
	}
	.ms-lg-auto {
		margin-left: auto !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pe-lg-0 {
		padding-right: 0 !important;
	}
	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pe-lg-3 {
		padding-right: 1rem !important;
	}
	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pe-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-0 {
		padding-left: 0 !important;
	}
	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}
	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}
	.ps-lg-3 {
		padding-left: 1rem !important;
	}
	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}
	.ps-lg-5 {
		padding-left: 3rem !important;
	}
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-start {
		float: left !important;
	}
	.float-xl-end {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-grid {
		display: grid !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-xl-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}
	.gap-xl-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}
	.gap-xl-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}
	.gap-xl-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.gap-xl-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}
	.gap-xl-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.justify-content-xl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
	.order-xl-first {
		order: -1 !important;
	}
	.order-xl-0 {
		order: 0 !important;
	}
	.order-xl-1 {
		order: 1 !important;
	}
	.order-xl-2 {
		order: 2 !important;
	}
	.order-xl-3 {
		order: 3 !important;
	}
	.order-xl-4 {
		order: 4 !important;
	}
	.order-xl-5 {
		order: 5 !important;
	}
	.order-xl-last {
		order: 6 !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.me-xl-0 {
		margin-right: 0 !important;
	}
	.me-xl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xl-3 {
		margin-right: 1rem !important;
	}
	.me-xl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xl-5 {
		margin-right: 3rem !important;
	}
	.me-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ms-xl-0 {
		margin-left: 0 !important;
	}
	.ms-xl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xl-3 {
		margin-left: 1rem !important;
	}
	.ms-xl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xl-5 {
		margin-left: 3rem !important;
	}
	.ms-xl-auto {
		margin-left: auto !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pe-xl-0 {
		padding-right: 0 !important;
	}
	.pe-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xl-3 {
		padding-right: 1rem !important;
	}
	.pe-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-0 {
		padding-left: 0 !important;
	}
	.ps-xl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xl-3 {
		padding-left: 1rem !important;
	}
	.ps-xl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xl-5 {
		padding-left: 3rem !important;
	}
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

@media (min-width: 1400px) {
	.float-xxl-start {
		float: left !important;
	}
	.float-xxl-end {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-grid {
		display: grid !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
	.d-xxl-none {
		display: none !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-xxl-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}
	.gap-xxl-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}
	.gap-xxl-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}
	.gap-xxl-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.gap-xxl-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}
	.gap-xxl-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
	.order-xxl-first {
		order: -1 !important;
	}
	.order-xxl-0 {
		order: 0 !important;
	}
	.order-xxl-1 {
		order: 1 !important;
	}
	.order-xxl-2 {
		order: 2 !important;
	}
	.order-xxl-3 {
		order: 3 !important;
	}
	.order-xxl-4 {
		order: 4 !important;
	}
	.order-xxl-5 {
		order: 5 !important;
	}
	.order-xxl-last {
		order: 6 !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xxl-0 {
		margin-top: 0 !important;
	}
	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xxl-3 {
		margin-top: 1rem !important;
	}
	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xxl-5 {
		margin-top: 3rem !important;
	}
	.mt-xxl-auto {
		margin-top: auto !important;
	}
	.me-xxl-0 {
		margin-right: 0 !important;
	}
	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xxl-3 {
		margin-right: 1rem !important;
	}
	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xxl-5 {
		margin-right: 3rem !important;
	}
	.me-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xxl-auto {
		margin-bottom: auto !important;
	}
	.ms-xxl-0 {
		margin-left: 0 !important;
	}
	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xxl-3 {
		margin-left: 1rem !important;
	}
	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xxl-5 {
		margin-left: 3rem !important;
	}
	.ms-xxl-auto {
		margin-left: auto !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xxl-0 {
		padding-top: 0 !important;
	}
	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xxl-3 {
		padding-top: 1rem !important;
	}
	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xxl-5 {
		padding-top: 3rem !important;
	}
	.pe-xxl-0 {
		padding-right: 0 !important;
	}
	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xxl-3 {
		padding-right: 1rem !important;
	}
	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xxl-0 {
		padding-left: 0 !important;
	}
	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xxl-3 {
		padding-left: 1rem !important;
	}
	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xxl-5 {
		padding-left: 3rem !important;
	}
	.text-xxl-start {
		text-align: left !important;
	}
	.text-xxl-end {
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.fs-1 {
		font-size: 2.5rem !important;
	}
	.fs-2 {
		font-size: 2rem !important;
	}
	.fs-3 {
		font-size: 1.75rem !important;
	}
	.fs-4 {
		font-size: 1.5rem !important;
	}
}

@media print {
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-grid {
		display: grid !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	.d-print-none {
		display: none !important;
	}
}

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
.list-wrapper ul li .form-check,
.list-wrapper ul li .form-check .form-check-label {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
}

/* Animation Mixins */
@keyframes dropdownAnimation {
	from {
		opacity: 0;
		transform: translate3d(0, -30px, 0);
	}
	to {
		opacity: 1;
		transform: none;
		transform: translate3d(0, 0px, 0);
	}
}

.dropdownAnimation,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
	animation-name: dropdownAnimation;
	animation-duration: 0.25s;
	animation-fill-mode: both;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.fadeOut {
	animation-name: fadeOut;
}

.infinite-spin {
	animation-name: spin;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

.form-control,
.form-control:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.form-control {
	box-shadow: none;
}

.form-control:focus {
	outline: 0;
	box-shadow: none;
}

a,
div,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p,
span {
	text-shadow: none;
}

[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
select::-moz-focus-inner {
	outline: 0;
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
	outline-width: 0;
	outline-color: transparent;
	box-shadow: none;
	outline-style: none;
}

textarea {
	resize: none;
	overflow-x: hidden;
}

.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
	box-shadow: none;
	cursor: pointer;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
	outline: 0;
	outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
	outline: 0;
	outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
	outline: 0;
}

a:focus,
input:focus {
	border-color: transparent;
	outline: none;
}

/* Fonts */
/* @font-face {
  font-family: 'ubuntu-light';
  src: url("../fonts/Ubuntu/Ubuntu-Light.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Light.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Light.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-regular';
  src: url("../fonts/Ubuntu/Ubuntu-Regular.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Regular.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-medium';
  src: url("../fonts/Ubuntu/Ubuntu-Medium.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Medium.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'ubuntu-bold';
  src: url("../fonts/Ubuntu/Ubuntu-Bold.eot");
  
  src: url("../fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Bold.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype"); } */

.text-twitter {
	color: #2caae1;
}

.text-facebook {
	color: #3b579d;
}

.text-google {
	color: #dc4a38;
}

.text-linkedin {
	color: #0177b5;
}

.text-pinterest {
	color: #cc2127;
}

.text-youtube {
	color: #e52d27;
}

.text-github {
	color: #333333;
}

.text-behance {
	color: #1769ff;
}

.text-dribbble {
	color: #ea4c89;
}

.text-reddit {
	color: #ff4500;
}

.bg-twitter {
	background: #2caae1;
}

.bg-facebook {
	background: #3b579d;
}

.bg-google {
	background: #dc4a38;
}

.bg-linkedin {
	background: #0177b5;
}

.bg-pinterest {
	background: #cc2127;
}

.bg-youtube {
	background: #e52d27;
}

.bg-github {
	background: #333333;
}

.bg-behance {
	background: #1769ff;
}

.bg-dribbble {
	background: #ea4c89;
}

.bg-reddit {
	background: #ff4500;
}

/* Typography */
body {
	font-size: 14px;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

p {
	/* font-size: 0.875rem; */
	font-size: 14px;
	margin-bottom: 0;
}

h1,
.h1,
.h1 {
	font-size: 2.19rem;
}

h2,
.h2,
.h2 {
	font-size: 1.88rem;
}

h3,
.h3,
.h3 {
	font-size: 1.56rem;
}

h5,
.h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6,
.h6 {
	font-size: 0.9375rem;
}

p {
	font-size: 0.9375rem;
	line-height: 1.5;
}
a {
	text-decoration: none;
}
.display-1 {
	font-size: 3.75rem;
}
@media (max-width: 991px) {
	.display-1 {
		font-size: 3rem;
	}
}

.display-2 {
	font-size: 3.125rem;
}
@media (max-width: 991px) {
	.display-2 {
		font-size: 2.5rem;
	}
}

.display-3 {
	font-size: 2.5rem;
}
@media (max-width: 991px) {
	.display-3 {
		font-size: 2rem;
	}
}

.display-4 {
	font-size: 1.875rem;
}
@media (max-width: 991px) {
	.display-4 {
		font-size: 1.5rem;
	}
}

.display-5 {
	font-size: 1.25rem;
}
@media (max-width: 991px) {
	.display-5 {
		font-size: 1rem;
	}
}

.blockquote {
	padding: 1.25rem;
	border: 1px solid #ebedf2;
}

address p {
	margin-bottom: 0;
}

.blockquote-primary {
	border-color: #b66dff;
}
.blockquote-primary .blockquote-footer {
	color: #b66dff;
}

.blockquote-secondary {
	border-color: #c3bdbd;
}
.blockquote-secondary .blockquote-footer {
	color: #c3bdbd;
}

.blockquote-success {
	border-color: #1bcfb4;
}
.blockquote-success .blockquote-footer {
	color: #1bcfb4;
}

.blockquote-info {
	border-color: #198ae3;
}
.blockquote-info .blockquote-footer {
	color: #198ae3;
}

.blockquote-warning {
	border-color: #fed713;
}
.blockquote-warning .blockquote-footer {
	color: #fed713;
}

.blockquote-danger {
	border-color: #fe7c96;
}
.blockquote-danger .blockquote-footer {
	color: #fe7c96;
}

.blockquote-light {
	border-color: #f8f9fa;
}
.blockquote-light .blockquote-footer {
	color: #f8f9fa;
}

.blockquote-dark {
	border-color: #3e4b5b;
}
.blockquote-dark .blockquote-footer {
	color: #3e4b5b;
}

.error-page h1,
.error-page .h1 {
	font-size: 12rem;
}
@media (max-width: 991px) {
	.error-page h1,
	.error-page .h1 {
		font-size: 8rem;
	}
}

.icon-lg {
	font-size: 2.5rem;
}

.icon-md {
	font-size: 1.875rem;
}

.icon-sm {
	font-size: 1rem;
}

/* Miscellanoeous */
body,
html {
	overflow-x: hidden;
	padding-right: 0 !important;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
	overflow: auto;
}

.container-scroller {
	overflow: hidden;
}

pre {
	background: color(gray-lighter);
	padding: 15px;
	font-size: 14px;
}

code {
	padding: 5px;
	color: #fe7c96;
	font-family: "ubuntu-light", sans-serif;
	font-size: 0.875rem;
	border-radius: 4px;
}

.page-header {
	margin: 0 0 1.5rem 0;
}
.page-header .breadcrumb {
	border: 0;
	margin-bottom: 0;
}

.page-title {
	color: #343a40;
	font-size: 1.125rem;
	margin-bottom: 0;
}
.page-title .page-title-icon {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 4px;
	text-align: center;
	box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
}
.page-title .page-title-icon i {
	font-size: 0.9375rem;
	line-height: 36px;
}

/* Footer */
.footer {
	background: #f2edf3;
	color: color(dark);
	border-top: 1px solid #e7dee9;
	padding: 30px 1rem;
	transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	font-size: calc(0.875rem - 0.05rem);
	font-family: "ubuntu-regular", sans-serif;
}
.footer a {
	color: #1bcfb4;
	font-size: inherit;
}
@media (max-width: 991px) {
	.footer {
		margin-left: 0;
		width: 100%;
	}
}

/* Utilities */
.grid-margin,
.purchase-popup {
	margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
	.grid-margin-sm-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.grid-margin-md-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 992px) {
	.grid-margin-lg-0 {
		margin-bottom: 0;
	}
}

@media (min-width: 1200px) {
	.grid-margin-xl-0 {
		margin-bottom: 0;
	}
}

.img-lg {
	width: 92px;
	height: 92px;
}

.img-sm {
	width: 43px;
	height: 43px;
}

.img-xs {
	width: 37px;
	height: 37px;
}

.img-ss,
.image-grouped .text-avatar,
.image-grouped img {
	width: 35px;
	height: 35px;
}

.stretch-card {
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}
.stretch-card > .card {
	width: 100%;
	min-width: 100%;
}

@media (min-width: 576px) {
	.border-right-sm {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 768px) {
	.border-right-md {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 992px) {
	.border-right-lg {
		border-right: 1px solid #ebedf2;
	}
}

@media (min-width: 576px) {
	.border-left-sm {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 768px) {
	.border-left-md {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 992px) {
	.border-left-lg {
		border-left: 1px solid #ebedf2;
	}
}

.text-gray,
.card .card-subtitle {
	color: #8c8c8c;
}

.text-black {
	color: #000000;
}

.text-small {
	font-size: 12px;
}

.flex-grow {
	flex-grow: 1;
}

.font-weight-light {
	font-family: "ubuntu-light", sans-serif;
}

.font-weight-bold {
	font-family: "ubuntu-bold", sans-serif;
}

.font-weight-normal {
	font-family: "ubuntu-regular", sans-serif;
}

.image-grouped {
	display: flex;
}
.image-grouped .text-avatar,
.image-grouped img {
	border-radius: 100%;
	margin-left: -10px;
	z-index: 0;
	border: 4px solid #fff;
	transform: scale(1);
	transition-duration: 0.3s;
	transition-timing-function: ease;
	transition-property: "box-shadow", "z-index", "transform", "border-width",
		"box-shadow", "transform";
	transition-property: "box-shadow", "z-index", "transform", "border-width";
}
.image-grouped .text-avatar:first-child,
.image-grouped img:first-child {
	margin-left: 0;
}
.image-grouped .text-avatar:hover,
.image-grouped img:hover {
	z-index: 1;
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
	transform: scale(1.05);
	border-width: 0;
}
.image-grouped .text-avatar {
	color: #b66dff;
	font-size: 11px;
	font-weight: 600;
}

.aligner-wrapper {
	position: relative;
}
.aligner-wrapper .absolute {
	position: absolute;
}
.aligner-wrapper .absolute.absolute-center {
	top: 50%;
	transform: translateY(-50%);
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
}
.aligner-wrapper .absolute.left {
	left: 0;
}
.aligner-wrapper .absolute.right {
	right: 0;
}
.aligner-wrapper .absolute.bottom {
	bottom: 0;
}
.aligner-wrapper .absolute.top {
	top: 0;
}

.v-strock-1 {
	width: 2px;
}

.v-strock-2 {
	width: 3px;
}

.v-strock-3 {
	width: 4px;
}

.v-strock-4 {
	width: 5px;
}

.dot-indicator {
	width: 10px;
	height: 10px;
	border-radius: 100%;
}
.dot-indicator.dot-indicator-sm {
	width: 6px;
	height: 6px;
}

.bg-gradient-primary {
	background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.bg-gradient-secondary {
	background: linear-gradient(to right, #e7ebf0, #868e96) !important;
}

.bg-gradient-success {
	background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}

.bg-gradient-info {
	background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}

.bg-gradient-warning {
	background: linear-gradient(to right, #f6e384, #ffd500) !important;
}

.bg-gradient-danger {
	background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

.bg-gradient-light {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9) !important;
}

.bg-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b) !important;
}

/* Demo Styles */
.template-demo .slider-wrap {
	height: 100px;
}

.template-demo .progress {
	margin-top: 1.5rem;
}

.template-demo > h2,
.template-demo > .h2,
.template-demo > h3,
.template-demo > .h3,
.template-demo > h4,
.template-demo > .h4,
.template-demo > h5,
.template-demo > .h5,
.template-demo > h6,
.template-demo > .h6,
.template-demo > h1,
.template-demo > .h1 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo .ul-slider.noUi-horizontal {
	margin-top: 2rem;
}

.template-demo .ul-slider.noUi-vertical {
	margin-right: 2rem;
}

.template-demo > .dropdown {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.template-demo nav .breadcrumb {
	margin-bottom: 1.375rem;
}

.template-demo nav:last-child .breadcrumb {
	margin-bottom: 0;
}

.template-demo .editable-form > .form-group {
	border-bottom: 1px solid #ebedf2;
	padding-bottom: 0.8rem;
	margin-bottom: 0.8rem;
}

.template-demo .circle-progress {
	padding: 15px;
}

.demo-modal {
	position: static;
	display: block;
}
.demo-modal .modal-dialog.modal-lg {
	max-width: 100%;
}

.loader-demo-box {
	width: 100%;
	height: 200px;
}

.dropdown-menu-static-demo {
	height: 250px;
	margin-bottom: 20px;
}

.rounded-legend ul li {
	list-style-type: none;
	color: #9c9fa6;
	font-size: 0.75rem;
}
.rounded-legend ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 0.5rem;
}
.rtl .rounded-legend ul li .legend-dots {
	margin-left: 0.5rem;
}

.rounded-legend.legend-horizontal {
	display: flex;
}
.rounded-legend.legend-horizontal ul li {
	display: inline-block;
	margin-right: 1.5rem;
}
.rtl .rounded-legend.legend-horizontal ul li {
	margin-right: auto;
	margin-left: 1.5rem;
}

.rounded-legend.legend-top-right ul {
	float: right;
}
.rtl .rounded-legend.legend-top-right ul {
	float: left;
}

.rounded-legend.legend-vertical ul li {
	margin-top: 1rem;
}

#proBanner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #000;
}
#proBanner .card-body-padding {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
}
@media (max-width: 766px) {
	#proBanner .card-body-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
#proBanner .buy-now-text {
	color: #ffffff;
}
@media (max-width: 766px) {
	#proBanner .buy-now-text {
		font-size: 10px;
		line-height: 1.5;
	}
}
#proBanner .btn.buy-now-btn {
	background: transparent;
	color: #88b6ff;
	border-radius: 10px;
	padding: 1rem 0;
	border-radius: 4px;
	font-weight: bold;
	font-size: 1rem;
}
@media (max-width: 766px) {
	#proBanner .btn.buy-now-btn {
		min-width: 80px;
		padding: 1rem 0.5rem;
		font-size: 12px;
	}
}
#proBanner .btn#bannerClose i {
	margin-right: 1rem;
	font-size: 1.25rem;
}
@media (max-width: 766px) {
	#proBanner .btn#bannerClose i {
		font-size: 1rem;
		margin-right: 0;
	}
}
#proBanner a {
	text-decoration: none;
}
#proBanner a i {
	font-size: 1.25rem;
}
@media (max-width: 766px) {
	#proBanner a i {
		font-size: 1rem;
	}
}

.proBanner-padding-top {
	padding-top: 80px !important;
}

/* Dashboard */
.card-statistics .highlight-icon {
	height: 53px;
	width: 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
}
.card-statistics .highlight-icon i {
	font-size: 27px;
}

.card-statistics .card-col {
	border-right: 1px solid #ebedf2;
}
.card-statistics .card-col:last-child {
	border-right: none;
}
@media (max-width: 575.98px) {
	.card-statistics .card-col {
		padding-left: 0;
		padding-right: 0;
		position: relative;
		border: none;
	}
	.card-statistics .card-col:first-child:after,
	.card-statistics .card-col:first-child:before,
	.card-statistics .card-col:nth-child(2):after,
	.card-statistics .card-col:nth-child(2):before,
	.card-statistics .card-col:nth-child(3):after,
	.card-statistics .card-col:nth-child(3):before {
		content: "";
		position: absolute;
		background: #ebedf2;
	}
	.card-statistics .card-col:first-child:before {
		bottom: 0;
		width: 94.2%;
		height: 1px;
		right: 0;
	}
	.card-statistics .card-col:first-child:after {
		bottom: 0;
		width: 1px;
		height: 100%;
		right: 0;
	}
	.card-statistics .card-col:nth-child(2):before {
		bottom: 0;
		width: 94.2%;
		height: 1px;
		left: 0;
	}
	.card-statistics .card-col:nth-child(3):before {
		width: 1px;
		height: 100%;
		right: 0;
	}
}

.card-revenue-table .revenue-item {
	border-bottom: 1px solid #ebedf2;
}
.card-revenue-table .revenue-item:last-child {
	border-bottom: 0;
}
.card-revenue-table .revenue-item .revenue-desc {
	margin-right: auto;
	width: 80%;
}
.card-revenue-table .revenue-item .revenue-desc p {
	margin-bottom: 0;
}
.card-revenue-table .revenue-item .revenue-amount {
	margin-left: auto;
	width: 40%;
}
.card-revenue-table .revenue-item .revenue-amount p {
	font-size: 1.25rem;
	font-family: "roboto", sans-serif;
	font-weight: 600;
	text-align: right;
}
.rtl .card-revenue-table .revenue-item .revenue-amount p {
	text-align: left;
}

.card-revenue {
	background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
	background-size: cover;
	color: color(white);
}
.card-revenue .highlight-text {
	font-size: 1.875rem;
	font-family: "roboto", sans-serif;
	font-weight: 500;
}
.card-revenue .badge {
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 1.125rem;
	padding: 0.5rem 1.25rem;
}

.product-chart-wrapper {
	height: 92%;
}

#dashboardTrendingProgress {
	width: 60px;
}

.dashboard-bar-chart-legend .col {
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.dashboard-bar-chart-legend .col .bg {
	margin-left: auto;
	margin-right: auto;
	height: 5px;
	width: 30px;
	display: block;
	margin-top: 5px;
}
.dashboard-bar-chart-legend .col:nth-child(1) .bg {
	background: #198ae3;
}
.dashboard-bar-chart-legend .col:nth-child(2) .bg {
	background: #b66dff;
}
.dashboard-bar-chart-legend .col:nth-child(3) .bg {
	background: #fe7c96;
}

.intro-banner {
	background: #dbe4ec;
	display: flex;
	align-items: center;
	padding: 20px;
	border-radius: 2px;
}
.intro-banner .banner-image {
	width: 24%;
	max-width: 100%;
}
.intro-banner .banner-image img {
	display: block;
	margin: auto;
}
.intro-banner .content-area {
	width: 58%;
	color: #000000;
	display: flex;
	flex-direction: column;
	align-items: end;
}

.sales-mini-tabs {
	border-bottom: none;
}
.sales-mini-tabs .nav-item .nav-link {
	border: none;
	font-weight: 500;
	padding: 5px 15px;
	border-radius: 2px;
	transition-duration: 0.7s;
	transition-property: "color";
}
.sales-mini-tabs .nav-item .nav-link.active {
	font-weight: 600;
	background: white;
	color: #b66dff;
}

.purchase-popup {
	background: #fff;
	padding: 15px 20px;
	border-radius: 3px;
}
.purchase-popup .btn {
	margin-right: 20px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 5px;
	transition-duration: 0.2s;
}
.purchase-popup .btn.download-button {
	background: rgba(249, 249, 249, 0.7);
	color: #969292;
	border: 1px solid #d7d7d7;
}
.purchase-popup .btn.purchase-button {
	background-color: rgba(172, 50, 228, 0.9);
	background-image: -owg-linear-gradient(to right, #da8cff, #9a55ff);
	background-image: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
	border: none;
	line-height: 1;
	vertical-align: middle;
}
.purchase-popup p {
	margin-bottom: auto;
	margin-top: auto;
	color: #7e7e7e;
	font-weight: 400;
	vertical-align: middle;
	line-height: 1;
}
.purchase-popup i {
	vertical-align: middle;
	line-height: 1;
	margin: auto 0;
	color: #b1b1b1;
}

/*-------------------------------------------------------------------*/
/* === Components === */
/* Badges */
.badge {
	border-radius: 0.125rem;
	font-size: 11px;
	font-weight: initial;
	line-height: 1;
	padding: 0.375rem 0.5625rem;
}
.badge.badge-pill {
	border-radius: 10rem;
}

/*Badge variations*/
.badge-primary {
	border: 1px solid #b66dff;
	background: #b66dff;
	color: #ffffff;
}

.badge-secondary {
	border: 1px solid #c3bdbd;
	background: #c3bdbd;
	color: #ffffff;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
	border: 1px solid #1bcfb4;
	background: #1bcfb4;
	color: #ffffff;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
	border: 1px solid #198ae3;
	background: #198ae3;
	color: #ffffff;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
	border: 1px solid #fed713;
	background: #fed713;
	color: #ffffff;
}

.badge-danger {
	border: 1px solid #fe7c96;
	background: #fe7c96;
	color: #ffffff;
}

.badge-light {
	border: 1px solid #f8f9fa;
	background: #f8f9fa;
	color: #ffffff;
}

.badge-dark {
	border: 1px solid #3e4b5b;
	background: #3e4b5b;
	color: #ffffff;
}

/*Badge gradient variations*/
.badge-gradient-primary {
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
}

.badge-gradient-secondary {
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #ffffff;
}

.badge-gradient-success {
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #ffffff;
}

.badge-gradient-info {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #ffffff;
}

.badge-gradient-warning {
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #ffffff;
}

.badge-gradient-danger {
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #ffffff;
}

.badge-gradient-light {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #ffffff;
}

.badge-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #ffffff;
}

/*Badge outlined variations*/
.badge-outline-primary {
	color: #b66dff;
	border: 1px solid #b66dff;
}

.badge-outline-secondary {
	color: #c3bdbd;
	border: 1px solid #c3bdbd;
}

.badge-outline-success {
	color: #1bcfb4;
	border: 1px solid #1bcfb4;
}

.badge-outline-info {
	color: #198ae3;
	border: 1px solid #198ae3;
}

.badge-outline-warning {
	color: #fed713;
	border: 1px solid #fed713;
}

.badge-outline-danger {
	color: #fe7c96;
	border: 1px solid #fe7c96;
}

.badge-outline-light {
	color: #f8f9fa;
	border: 1px solid #f8f9fa;
}

.badge-outline-dark {
	color: #3e4b5b;
	border: 1px solid #3e4b5b;
}

/* Bootstrap Progress */
.progress {
	border-radius: 3px;
	height: 8px;
}
.progress .progress-bar {
	border-radius: 3px;
}
.progress.progress-sm {
	height: 0.375rem;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 15px;
}
.progress.progress-xl {
	height: 18px;
}

/* Breadcrumbs */
.breadcrumb {
	border: 1px solid #ebedf2;
}
.breadcrumb .breadcrumb-item {
	font-size: 0.875rem;
}
.breadcrumb .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}
.breadcrumb.breadcrumb-custom {
	padding: 0 0;
	border-color: #dbe3e6;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item {
	font-size: 0.875rem;
	background: #dbe3e6;
	padding: 0.56rem 10px;
	color: #000000;
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:before {
	content: "";
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a {
	position: relative;
	color: inherit;
	border: 1px solid #dbe3e6;
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before,
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
	position: absolute;
	top: -9px;
	width: 0;
	height: 0;
	content: "";
	border-top: 21px solid transparent;
	border-bottom: 21px solid transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
	right: -22px;
	z-index: 3;
	border-left-color: #dbe3e6;
	border-left-style: solid;
	border-left-width: 12px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
	border-top: 21px solid transparent;
	border-bottom: 22px solid transparent;
	border-left: 12px solid #ffffff;
	top: -9px;
	right: -23px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item span {
	display: inline-block;
	vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}
.breadcrumb.bg-success,
.breadcrumb.bg-dark,
.breadcrumb.bg-danger,
.breadcrumb.bg-warning,
.breadcrumb.bg-primary,
.breadcrumb.bg-info {
	border: none;
}
.breadcrumb.bg-success .breadcrumb-item,
.breadcrumb.bg-dark .breadcrumb-item,
.breadcrumb.bg-danger .breadcrumb-item,
.breadcrumb.bg-warning .breadcrumb-item,
.breadcrumb.bg-primary .breadcrumb-item,
.breadcrumb.bg-info .breadcrumb-item {
	color: #ffffff;
}
.breadcrumb.bg-success .breadcrumb-item:before,
.breadcrumb.bg-dark .breadcrumb-item:before,
.breadcrumb.bg-danger .breadcrumb-item:before,
.breadcrumb.bg-warning .breadcrumb-item:before,
.breadcrumb.bg-primary .breadcrumb-item:before,
.breadcrumb.bg-info .breadcrumb-item:before {
	color: inherit;
}
.breadcrumb.bg-success .breadcrumb-item a,
.breadcrumb.bg-success .breadcrumb-item span,
.breadcrumb.bg-dark .breadcrumb-item a,
.breadcrumb.bg-dark .breadcrumb-item span,
.breadcrumb.bg-danger .breadcrumb-item a,
.breadcrumb.bg-danger .breadcrumb-item span,
.breadcrumb.bg-warning .breadcrumb-item a,
.breadcrumb.bg-warning .breadcrumb-item span,
.breadcrumb.bg-primary .breadcrumb-item a,
.breadcrumb.bg-primary .breadcrumb-item span,
.breadcrumb.bg-info .breadcrumb-item a,
.breadcrumb.bg-info .breadcrumb-item span {
	color: inherit;
}

/* inverse breadcrumb */
.bg-inverse-primary,
.image-grouped .text-avatar {
	background: #f6edff;
	border-color: #b66dff;
}
.bg-inverse-primary .breadcrumb-item,
.image-grouped .text-avatar .breadcrumb-item {
	color: #b66dff;
}
.bg-inverse-primary .breadcrumb-item:before,
.image-grouped .text-avatar .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-primary .breadcrumb-item a,
.image-grouped .text-avatar .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-primary.breadcrumb-custom,
.image-grouped .breadcrumb-custom.text-avatar {
	background: transparent;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
	background: #f6edff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
	color: #b66dff;
	border: none;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
	border-left-color: #f6edff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item span,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
	color: #b66dff;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-secondary {
	background: white;
	border-color: #c3bdbd;
}
.bg-inverse-secondary .breadcrumb-item {
	color: #c3bdbd;
}
.bg-inverse-secondary .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-secondary .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-secondary.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
	background: white;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
	color: #c3bdbd;
	border: none;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: white;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
	color: #c3bdbd;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-success {
	background: #7ceedd;
	border-color: #1bcfb4;
}
.bg-inverse-success .breadcrumb-item {
	color: #1bcfb4;
}
.bg-inverse-success .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-success .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-success.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item {
	background: #7ceedd;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
	color: #1bcfb4;
	border: none;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #7ceedd;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
	color: #1bcfb4;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-info {
	background: #89c4f2;
	border-color: #198ae3;
}
.bg-inverse-info .breadcrumb-item {
	color: #198ae3;
}
.bg-inverse-info .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-info .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-info.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item {
	background: #89c4f2;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
	color: #198ae3;
	border: none;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #89c4f2;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
	color: #198ae3;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-warning {
	background: #ffed92;
	border-color: #fed713;
}
.bg-inverse-warning .breadcrumb-item {
	color: #fed713;
}
.bg-inverse-warning .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-warning .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-warning.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
	background: #ffed92;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
	color: #fed713;
	border: none;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #ffed92;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
	color: #fed713;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-danger {
	background: #fffbfb;
	border-color: #fe7c96;
}
.bg-inverse-danger .breadcrumb-item {
	color: #fe7c96;
}
.bg-inverse-danger .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-danger .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-danger.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
	background: #fffbfb;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
	color: #fe7c96;
	border: none;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #fffbfb;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
	color: #fe7c96;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-light {
	background: white;
	border-color: #f8f9fa;
}
.bg-inverse-light .breadcrumb-item {
	color: #f8f9fa;
}
.bg-inverse-light .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-light .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-light.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item {
	background: white;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a {
	color: #f8f9fa;
	border: none;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: white;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item span {
	color: #f8f9fa;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

.bg-inverse-dark {
	background: #778aa2;
	border-color: #3e4b5b;
}
.bg-inverse-dark .breadcrumb-item {
	color: #3e4b5b;
}
.bg-inverse-dark .breadcrumb-item:before {
	color: inherit;
}
.bg-inverse-dark .breadcrumb-item a {
	color: inherit;
}
.bg-inverse-dark.breadcrumb-custom {
	background: transparent;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
	background: #778aa2;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
	color: #3e4b5b;
	border: none;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #778aa2;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
	color: #3e4b5b;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
	background: transparent;
}

/* Checkboxes and Radios */
.form-check {
	position: relative;
	display: block;
	margin-top: 15px;
	margin-bottom: 10px;
	padding-left: 0;
}

/* Icons */
.icons-list {
	border-left: 1px solid #ebedf2;
	border-top: 1px solid #ebedf2;
}
.icons-list > div {
	border-bottom: 1px solid #ebedf2;
	border-right: 1px solid #ebedf2;
	display: flex;
	align-items: center;
	padding: 15px 15px;
	font-family: "ubuntu-regular", sans-serif;
	font-size: 0.875rem;
}
.icons-list > div i {
	display: inline-block;
	font-size: 20px;
	width: 40px;
	text-align: left;
	color: #b66dff;
}

/* Lists */

ol,
dl {
	padding-left: 1rem;
	font-size: 0.875rem;
}
ul li,
ol li,
dl li {
	line-height: 1.8;
}

.list-ticked,
.list-arrow,
.list-star {
	list-style: none;
	padding: 0;
}
.list-ticked li,
.list-arrow li,
.list-star li {
	padding-left: 1.5rem;
}
.list-ticked li:before,
.list-arrow li:before,
.list-star li:before {
	font-family: "Material Design Icons";
	margin-left: -1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
}

.list-ticked li:before {
	content: "\F12D";
	color: #fe7c96;
}

.list-arrow li:before {
	content: "\F142";
	color: #1bcfb4;
}

.list-star li:before {
	content: "\F4CE";
	color: #fed713;
}

.gradient-bullet-list {
	padding-left: 0;
}
.rtl .gradient-bullet-list {
	padding-right: 0;
}
.gradient-bullet-list li {
	position: relative;
	list-style-type: none;
	padding-left: 25px;
	line-height: 1;
	padding-bottom: 25px;
}
.gradient-bullet-list li:before,
.gradient-bullet-list li:after {
	content: "";
	position: absolute;
}
.gradient-bullet-list li:before {
	top: 0;
	left: 0;
	width: 15px;
	height: 15px;
	border-radius: 100%;
}
.gradient-bullet-list li:after {
	width: 11px;
	height: 11px;
	top: 2px;
	left: 2px;
	background: #fff;
	border-radius: 100%;
}
.gradient-bullet-list li:nth-child(1):before {
	background: linear-gradient(to right, #ffbf96, #fe7096);
}
.gradient-bullet-list li:nth-child(2):before {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
}
.gradient-bullet-list li:nth-child(3):before {
	background: linear-gradient(to right, #f6e384, #ffd500);
}
.gradient-bullet-list li:nth-child(4):before {
	background: linear-gradient(to right, #84d9d2, #07cdae);
}
.gradient-bullet-list li:nth-child(5):before {
	background: linear-gradient(to right, #da8cff, #9a55ff);
}

.bullet-line-list {
	padding-left: 30px;
	margin-bottom: 0;
	position: relative;
	list-style-type: none;
}
.rtl .bullet-line-list {
	padding-right: 0px;
}
.bullet-line-list li {
	position: relative;
	line-height: 1;
	padding-bottom: 10px;
}
.bullet-line-list li:before {
	content: "";
	position: absolute;
	border-radius: 100%;
	width: 12px;
	height: 12px;
	left: -28px;
	top: 6px;
	border: 3px solid #b66dff;
	margin-right: 15px;
	z-index: 2;
	background: color(white);
}
.bullet-line-list li:after {
	content: "";
	border: 1px solid #ebedf2;
	position: absolute;
	bottom: 0;
	left: -23px;
	height: 100%;
}
.bullet-line-list li:first-child:after {
	content: "";
	height: 80%;
}
.bullet-line-list li:last-child {
	padding-bottom: 0;
}
.bullet-line-list li:last-child:after {
	content: "";
	top: 0;
	height: 30%;
}

/* Preview */
.preview-list .preview-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0.75rem 1.5rem;
	font-size: 0.875rem;
}
.preview-list .preview-item:last-child {
	border-bottom: 0;
}
.preview-list .preview-item:hover {
	background: #e9ecef;
}
.preview-list .preview-item .form-check {
	margin-top: 8px;
	margin-right: 1rem;
}
.preview-list .preview-item .preview-thumbnail {
	color: color(white);
	position: relative;
}
.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.preview-list .preview-item .preview-thumbnail .preview-icon {
	padding: 6px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.preview-list .preview-item .preview-thumbnail .preview-icon i {
	font-size: 1.125rem;
	margin: 0;
}
.preview-list .preview-item .preview-thumbnail .badge {
	border: 2px solid color(white);
	border-radius: 100%;
	bottom: 5px;
	display: block;
	height: 14px;
	left: -5px;
	padding: 0;
	position: absolute;
	width: 14px;
}
.preview-list .preview-item .preview-item-content {
	line-height: 1;
	padding-left: 15px;
}
.preview-list .preview-item .preview-item-content:first-child {
	padding-left: 0;
}
.preview-list .preview-item .preview-item-content p {
	margin-bottom: 10px;
}
.preview-list .preview-item .preview-item-content p .content-category {
	font-family: "source-sans-pro-semibold", sans-serif;
	padding-right: 15px;
	border-right: 1px solid #ebedf2;
}
.rtl .preview-list .preview-item .preview-item-content {
	padding-left: 0;
	padding-right: 1rem;
	margin-right: 0;
	margin-left: auto;
}
.preview-list .preview-item .preview-actions {
	display: flex;
	flex-direction: row;
}
.preview-list .preview-item .preview-actions i {
	width: 29px;
	color: color(gray-lightest);
	height: 29px;
	border: 2px solid color(gray-lightest);
	border-radius: 100%;
	padding: 3px 6px;
	display: inline-block;
}
.preview-list .preview-item .preview-actions i:first-child {
	margin-right: 10px;
}

.preview-list.comment-preview .preview-item {
	padding: 0.87rem 0;
}
.preview-list.comment-preview .preview-item:first-child {
	padding-top: 0;
}
.preview-list.comment-preview .preview-item p {
	line-height: 27px;
}

.preview-list.bordered .preview-item {
	border-bottom: 1px solid #ebedf2;
}
.preview-list.bordered .preview-item:last-child {
	border-bottom: 0;
}

/* Tabs */
.add-items {
	margin-bottom: 1.5rem;
	overflow: hidden;
}
.add-items input[type="text"] {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
	background: transparent;
}
.add-items .btn {
	margin-left: 0.5rem;
}
.rtl .add-items .btn {
	margin-left: auto;
	margin-right: 0.5rem;
}

.list-wrapper {
	height: 100%;
	max-height: 100%;
}
.list-wrapper ul {
	padding: 0;
	text-align: left;
	list-style: none;
	margin-bottom: 0;
}
.list-wrapper ul li {
	font-size: 0.9375rem;
	padding: 0.4rem 0;
	border-bottom: 1px solid #ebedf2;
}
.list-wrapper ul li:first-child {
	border-bottom: none;
}
.list-wrapper ul li .form-check {
	max-width: 90%;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.list-wrapper input[type="checkbox"] {
	margin-right: 15px;
}
.list-wrapper .remove {
	cursor: pointer;
	font-size: 1.438rem;
	font-weight: 600;
	width: 1.25rem;
	height: 1.25rem;
	line-height: 20px;
	text-align: center;
}
.list-wrapper .completed {
	text-decoration: line-through;
	-webkit-text-decoration-color: #198ae3;
	text-decoration-color: #198ae3;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .auth-form-light {
	background: #ffffff;
}
.auth .auth-form-light select {
	color: #c9c8c8;
}
.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .input-group .form-control:active {
	border-color: #ebedf2;
}

.auth .brand-logo {
	margin-bottom: 2rem;
}
.auth .brand-logo img {
	width: 150px;
}

.auth form .form-group {
	margin-bottom: 1.5rem;
}
.auth form .form-group label {
	font-size: 0.8125rem;
}
.auth form .form-group .form-control {
	background: transparent;
	border-radius: 0;
	font-size: 0.9375rem;
}

.auth form .auth-form-btn {
	height: 50px;
	line-height: 1.5;
}

.auth form .auth-link {
	font-size: 0.875rem;
}
.auth form .auth-link:hover {
	color: initial;
}
.css-1dimb5e-singleValue {
	grid-area: 1/1/2/3;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box;
	font-size: inherit;
	font-family: "Heebo";
	font-weight: normal;
}
.label-div {
	font-weight: 500 !important;
	font-size: 11px !important;
	line-height: 19px !important;
	color: #0b0b0b;
}

.form-control::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #bebebe;
	opacity: 1;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
.form-control::-ms-placeholder {
	/* Internet Explorer 10-11 */
	color: #bebebe;
	opacity: 1;
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

/* Navbar */

/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
	padding-top: 70px;
}
.navbar-data-toggle--popover {
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	color: #0b0b0b;
	display: flex;
	flex-direction: row;
	grid-gap: 10px;
	gap: 10px;
}
.checkbox-elements--table {
	vertical-align: top !important;
	margin-left: 10px;
}
.checkbox-all-elements--table {
	display: block;
	height: 2vh;
}
.no-data--found {
	display: block;
	background: #f5f5f5;
	top: 50px;
	padding: 15px 15px;
	border-radius: 3px;
	width: auto;
	box-shadow: 0 0 4px -1px #bfbfbf;
	line-height: 19px;
	color: #0b0b0b;
	font-weight: 500;
	white-space: normal;
	vertical-align: middle;
	font-size: 13px;
}
.sidebar .nav.sub-menu .nav-item .nav-links {
	padding: 15px 20px !important;
	padding-right: 10px !important;
}
.sidebar .nav .nav-item .collapse {
	margin-left: -13%;
	margin-right: -13%;
	margin-top: 13px;
}
@media (min-width: 992px) {
	.sidebar-icon-only .navbar .navbar-brand-wrapper {
		width: 70px;
	}
	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
		display: none;
	}
	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
		display: inline-block;
	}
	.sidebar-icon-only .navbar .navbar-menu-wrapper {
		width: calc(100% - 70px);
	}
	.sidebar-icon-only .sidebar {
		width: 70px;
	}
	.sidebar-icon-only .sidebar .nav {
		overflow: visible;
	}
	.sidebar-icon-only .sidebar .nav .nav-item {
		position: relative;
		padding: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
		display: block;
		text-align: center;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		border-radius: 0 5px 5px 0px;
	}
	.rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		border-radius: 5px 0 0 5px;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
		margin-right: 0;
		margin-left: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
		display: none;
	}
	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 0 5px 0 0px;
	}
	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 5px 0 0 0;
	}
	/* .sidebar .nav .nav-item.active::hover {
    background: white;
  }
  .sidebar .nav .nav-item.active.nav.sub-menu {
    margin-bottom: 0;
    margin-top: 14px;
    list-style: none;
    background: aliceblue;
    margin-left: 0;
    display: block;
    margin-right: 0;
    width: 112%;
  } */
	.sidebar .nav.sub-menu .nav-item .nav-links.active {
		background: #e9e9e9 !important;
		width: 100%;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-category {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
		margin: 0;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
		display: block;
	}
	.sidebar-icon-only .sidebar .nav .nav-item .collapse {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
		display: flex;
		align-items: center;
		background: #fcfcfc;
		padding: 0.5rem 1.4rem;
		left: 70px;
		position: absolute;
		text-align: left;
		top: 0;
		bottom: 0;
		width: 190px;
		z-index: 1;
		line-height: 1.8;
	}
	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title {
		left: auto;
		right: 70px;
		text-align: left;
	}
	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title:after {
		display: none;
	}
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		display: block;
		padding: 0.5rem 0;
		background: #fcfcfc;
		border-radius: 0 0 5px 0;
		position: absolute;
		left: 70px;
		width: 190px;
	}
	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		left: auto;
		right: 70px;
		border-radius: 0 0 0 5px;
	}
	.sidebar-icon-only .sidebar .nav.sub-menu {
		padding: 0 0 0 1.5rem;
	}
	.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: left;
		padding-left: 20px;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
		margin-right: auto;
		margin-left: 0;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: right;
	}
	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
		left: 0;
		right: unset;
	}
	.sidebar-icon-only .sidebar .sidebar-actions {
		display: none;
	}
	.sidebar-icon-only .main-panel {
		width: calc(100% - 70px);
	}
}

.rtl {
	direction: rtl;
	text-align: right;
}
.rtl .sidebar .nav {
	padding-right: 0;
}
.rtl .product-chart-wrapper::-webkit-scrollbar,
.rtl .sidebar-fixed .nav::-webkit-scrollbar,
.rtl .table-responsive::-webkit-scrollbar,
.rtl ul.chats::-webkit-scrollbar {
	width: 0.5em;
}
.rtl .product-chart-wrapper::-webkit-scrollbar-track,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
.rtl .table-responsive::-webkit-scrollbar-track,
.rtl ul.chats::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
.rtl .table-responsive::-webkit-scrollbar-thumb,
.rtl ul.chats::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.page-body-wrapper {
	/* padding-top: 60px; */
	/* min-height: calc(100vh - 70px); */
	height: auto;
	display: flex;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}
.page-body-wrapper.full-page-wrapper {
	width: 100%;
	min-height: 100vh;
}

.main-panel {
	transition: width 0.25s ease, margin 0.25s ease;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	flex-direction: column;
	padding-left: 203px;
}
@media (max-width: 991px) {
	.main-panel {
		margin-left: 0;
		width: 100%;
	}
}

.content-wrapper {
	background: #f2edf3;
	padding: 2.75rem 2.25rem;
	width: 100%;
	flex-grow: 1;
}

/*# sourceMappingURL=style.css.map */
.btn {
	padding: 8px 10px;
	border-radius: 5px;
	font-size: 13px;
}
.btns {
	padding: 11px 34px;
	border-radius: 5px;
	font-size: 13px;
}
.btn-light-border {
	border: 1px solid #ecf1f7;
	background: transparent;
}
.col-gap12 {
	grid-column-gap: 12px;
	column-gap: 12px;
}
.puple {
	background: #ecf1f7;
}
.black {
	color: #0b0b0b;
}
.grey {
	color: #a1a1a1;
}
.red {
	color: #c71515;
}
.border-0 {
	border: 0;
}

.section-title h2 {
	font-weight: 500;
	font-size: 24px;
	line-height: 35px;
	color: #0b0b0b;
	margin-bottom: 20px;
}
.section-title {
	margin-bottom: 22px;
}
.section-title h4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}
.rightbar {
	padding: 26px 0px 0 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pr-11 {
	padding-right: 26px;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9;
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #fff;
	border-radius: 10px;
	border-right: 1px solid #e9e9e9;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d3dae4;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #4e4c4c;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
	--bs-table-accent-bg: #fff;
}
.headname {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #0b0b0b;
	margin-top: 19px;
}
.idname {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #5a616b;
}

ul.margin-list li {
	display: inline-block;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin-right: 7px;
	width: 40px;
	height: 41px;
	font-size: 13px;
	line-height: 40px;
	color: #a1a1a1;
	text-align: center;
	align-items: center;
}
.addtypegroup .modal-footer {
	justify-content: flex-end;
}
.btn-close {
	width: 20px;
	height: 20px;
	background-image: url(/static/media/cros.250666a3.svg) !important;
	opacity: 1;
	background-size: auto;
	padding: 0;
}
.col-gap-20 {
	grid-column-gap: 20px;
	column-gap: 20px;
}
.padi-30 {
	padding-left: 30px;
}
.col-gap-14 {
	grid-column-gap: 14px;
	column-gap: 14px;
}
.w-282 {
	width: 282px;
}
@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		--bs-modal-width: 668px !important;
	}
}

.addtypegroup .form-group {
	margin-bottom: 10px;
}

.invoicecard .card {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin: 0px 14px 0 0;
}
ul.sociallink li {
	display: inline-block;
	text-align: center;
	margin: 8px;
	width: 40px;
	height: 40px;
	border: 1px solid #c0bcbc;
	border-radius: 50%;
	line-height: 40px;
}
ul.sociallink li img {
	width: 20px;
	height: 20px;
}
.rtf--ab__c {
	display: block;
	/* position: absolute; */
	top: 0;
	right: 20px !important;
	left: 5.5rem !important;
	padding: 10px 0;
	margin: 55px 0 !important;
}

.row-multiple-select--h3 {
	margin: 3px;
	color: #5a616b;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}
.row-multiple--select {
	display: flex;
	grid-row-gap: 57px;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	height: 35px;
	margin-bottom: 20px;
}
.row-multiple--select--customer {
	display: flex;
	grid-row-gap: 57px;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	height: 35px;
}
.checkbox_round {
	width: 18px;
	height: 18px;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	outline: none;
	cursor: pointer;
	-webkit-appearance: none;
}

.checkbox_round:hover {
	border-color: #000;
}

.checkbox_round {
	position: relative;
}

.checkbox_round:checked {
	border: none;
	outline: 2px solid deeppink;
}

.table-hover > tbody > tr {
	background: url;
	cursor: pointer;
	color: blue;
}
.colored-link {
	color: red; /* Set your desired color here */
}
.table-hover > tbody > tr {
	background: url;
	cursor: alias !important;
	color: blue;
}
.dropdown {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	margin: auto;
	z-index: 3;
}
.dropdown-list {
	position: absolute;

	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	list-style: none;
	border-radius: 5px;
	pointer-events: none;
	transition: all 0.5s ease;
	transform: translateY(-10px);
	top: 0;
	left: 0px;
	margin-top: 2rem;
}

.dropdown-list {
	clear: both;
	font-weight: 400;

	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	padding: 1rem;

	background: #ffffff;
	box-shadow: 6px 8px 6px -6px #333;
	margin-right: 0;
	margin-left: 0;
}
.dropdown-content a::hover {
	background-color: #ddd;
}
.mt-5--table {
	margin-top: 22px;
}
ul li.menunews {
	/* border-bottom: 1px solid #ccc;   */
	list-style: none;
	height: 30px;
}
ul li.menunews a {
	display: block;
	color: #266cae;
	text-decoration: none;
}

ul li.menunews:hover {
	background-color: red;
	opacity: 1;
}
.img-fluid--icons {
	max-width: 100%;
	height: auto;
}
/* .img-fluid--icons:hover {
	width: 36px;
	justify-content: center;
	opacity: 1;

	background: #f5f5f5;

	height: 36px;
	z-index: 3;
	border-radius: 50%;
} */
.modal-content-edit--type {
	width: 100% !important;
}
.edit-type-modal--title {
	grid-gap: 50px;
	gap: 50px;
	display: flex;
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
}
.edit-type-modal-span--active {
	font-weight: 600;
	color: #0001c7;
	margin: 10px;
}
.edit-type-modal-span--edit_id {
	font-weight: 600;
	margin: 10px;
}
.edit-type-modal-standard-type {
	display: flex;
	width: 100%;
	grid-gap: 20px;
	gap: 20px;
}
.edit-type-modal-isMultpile--type {
	display: table;
	width: 40%;
}
.edit-type-modal-span--in-active {
	font-weight: 600;
	color: red;
	margin: 10px;
}
.mt-21px--table {
	margin-top: 21px;
}
.mt-2-branch {
	margin-bottom: 2rem !important;
}
.set_left {
	margin-left: 25% !important;
}

.set_left {
	margin-left: 25% !important;
}

.sp_form .alert-danger {
	background-color: #fff !important;
	border: none !important;
	padding: 0px !important;
	margin: 0px !important;
	top: 5px !important;
	padding-bottom: 8px !important;
}

.sp_form .alert-danger p {
	color: red;
}
.sp_form .alert-danger button {
	display: none;
}
/* .sp_form .error {
  margin-left: 32% !important;
} */

.sp_form .margin-error {
	margin-left: 26% !important;
}
.sp_input:focus::placeholder {
	/* WebKit, Blink, Edge */
	color: #d3d3d3 !important;
}

input.skip_placeholder::placeholder {
	/* Most modern browsers support this now. */
	color: #fff !important;
}

input.placeholder-light::placeholder {
	/* Most modern browsers support this now. */
	color: #ccc !important;
}

input.placeholder-dark::placeholder {
	/* Most modern browsers support this now. */
	color: #7c7f85 !important;
}

.selectable .row {
	padding: 10px;
}
/* .selectable .row:hover {
  cursor: all-scroll !important;
} */

.react-beautiful-dnd-draggable {
	transition: transform 0.2s;
}
.unauthorised--page {
	margin-left: 34%;
	margin-top: 12%;
	width: 23rem;
	display: block;
}
.unauthorised--page .number {
	margin-left: 56px;
}
.unauthorised--page .icon {
	margin-left: 30px;
}
.unauthorised--page .access {
	font-size: 20px;
	margin-left: 8px;
	font-weight: 400;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
	line-height: 0px !important;
	height: 0px !important;
	/* padding: 50px !important; */
	/* margin: 2px !important; */
}

/* .daterangepicker.ltr {
  margin: 0px !important;
} */

.editor_special {
	padding-left: 0px !important;
}
.image-rendering {
	min-height: 100%;
	object-fit: cover;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	aspect-ratio: auto 200 / 100;
	height: 8em;
	@media (min-width: 1100px) {
		height: 16em;
	}
}
.custom-row-workspace {
	width: max-content;
	display: flex;
}

.editor_ws .loader {
	margin-left: -8%;
}
.editor_ws_image .loader {
	top: 24% !important;
	left: 44% !important;
}

.detailslist .card img {
	color: transparent !important;
}
.autocomplete {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 49px;
	font-size: 13px;
	border-top-width: 0;
	list-style: none;
	max-height: 80px;
	overflow-y: auto;
	padding-left: 7px;
	width: calc(300px + 4.5rem);
	position: absolute;
	z-index: 9999;
	background: #ffffff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	top: "50px";
	left: "10px";
}
.editor.autocomplete {
	width: calc(124px + 4.5rem) !important;
}
.search--box {
	position: inherit;
	display: flex;
	width: 23.5rem;
}
.editor.search--box {
	position: inherit;
	display: flex;
	width: 14.4rem;
}
.clear-icons {
	margin-top: 9px;
	display: flex;
	text-align: end;
	margin-left: -30px;
}

.date-picker--container {
	margin-bottom: 8px;
	width: 15rem;
	flex-direction: column;

	background: white;
	height: 83px;
	overflow: scroll;
}
.negative-margin--container {
	margin-top: -90px;
}
.mt--10px {
	margin-top: 10px;
}
.border-image--card {
	border: 3px solid #2c6bcf !important;
	border-radius: 2% !important;
}
.checkbox-order--sub {
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	left: 13px;
	top: 13px;
}
.layer-image--selector {
	background: #e9edf5 !important;
}
.card-button--selected {
	background: #f6f6f6 !important;
}
.detailslist {
	margin: 6px 0px 0px !important;
	/* margin: 0px 7px 0px; */
}
.row-multiple-select--coordinator {
	display: flex;
	grid-row-gap: 57px;
	grid-row-gap: 57px;
	row-gap: 57px;
	position: initial;
	z-index: 2;
	width: max-content;
	grid-gap: 30px;
	gap: 30px;
}
/* .image-settings-folder--select {
	color: #a1a1a1;
	margin: 6px;
	display: flex;
	margin-left: 39%;
} */
.order-folder--count {
	color: #a1a1a1;
	margin: 6px;
}
.co-ordinator-super-admin--selectAll {
	font-weight: 500 !important;
	font-size: 13px !important;
	line-height: 19px !important;
	margin-left: 14px;
	color: #5a616b;
}
.order-folder--hover {
	content: "";
	display: flex;
	background: #ecf1f7;
	width: auto;
	min-height: 47px;
	height: auto;
	border-radius: 4px;
	top: 3px;
	margin-bottom: auto;
	margin-top: auto;
	display: flex;
	align-items: center; /* Vertically center align text */
}

/* Add a fixed width to the text container */
.order-folder--hover .catgory-name {
	width: 150px; /* Adjust the width as needed */
	white-space: nowrap; /* Prevent text from wrapping */
	overflow: hidden;
	text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
}

.bothbtn-order {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	align-items: center;
	color: #0b0b0b;
	text-align: center;
	margin-bottom: 6px;
	padding: 11px 6px !important;
	width: 100%;
	grid-column-gap: 4px;
	column-gap: 4px;
	display: flex;
	justify-content: center;
	@media (min-width: 1100px) {
		grid-column-gap: 10px;
		column-gap: 10px;
		width: max-content;
		padding: 11px 10px !important;
	}
}
.modal-container--image {
	top: 5.5em;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: max-content;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1119;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.modal--image {
	height: calc(100vh - 88px);
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #474747;
	margin-left: auto;
	margin-right: auto;
}
.modal-image-icons {
	max-width: -moz-fit-content;
	max-width: fit-content;
	display: flex;
	justify-content: center;
	height: auto;
	border-radius: 8px;
	margin-bottom: auto;
	justify-content: start;
	width: 100%;
}
.modal-image--order {
	background-color: white;
	border: 1px solid #888;
	width: 30%;
	margin-right: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	height: auto;
	border-radius: 8px;
	max-height: -moz-fit-content;
	max-height: fit-content;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 130px);
	overflow-y: auto;
	padding: 10px;
}

@media (min-width: 1280px) and (max-width: 1400px) {
	.modal-settings--container {
		height: 43.8em;
		overflow: auto; /* Add scrollbars if content exceeds container height */
		width: 100%; /* Take up full width */
		margin-bottom: auto; /* Adjust margin as needed */
	}
}

@media (min-height: 1590px) and (max-height: 2080px) {
	.modal-image--order {
		height: 100%;
	}
}
.modal-settings--container {
	height: 31.6em;
	overflow: auto; /* Add scrollbars if content exceeds container height */
	width: 100%; /* Take up full width */
	margin-bottom: auto; /* Adjust margin as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.modal-settings--container {
		min-height: 30rem; /* Adjust minimum height for smaller screens */
	}
}

@media (max-width: 576px) {
	.modal-settings--container {
		min-height: 20rem; /* Adjust minimum height for even smaller screens */
	}
}

.modal-overlay--order {
	position: fixed;
	top: 30px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	z-index: 1036;
	flex-direction: column;
}
.close-icons-order--image {
	margin-top: 2em;
	display: flex;
	z-index: 99999;
	width: 100%;
	position: fixed;
	justify-items: flex-end;
}

.order-text--outlined {
	font-size: 13px;
	font-weight: 500;
	/* margin-top: 3px; */
	width: 9em;
	height: 18px;
	/* overflow-y: scroll; */
}

.btn-image-order-save--outlined {
	background: black;
	color: white;
	border-radius: 12px !important;
	width: 7rem;
	height: 43px;
	display: flex;
}
.image--comment {
	display: flex;
	margin: 0px 23px 56px;
	width: 31px;
}
.image--comment-qc-workspace {
	display: flex;
	width: 31px;
	margin-left: 12px;
	margin-right: 13px;
	margin-top: 0;
	margin-bottom: 0;
	/* justify-content: center; */
}
.comments-time-image--details {
	display: flex;
	width: 13rem;
}
.scroll-image-details--comment {
	height: 428px;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 20rem !important;
}
.img-fluid-image-details--view {
	height: -moz-fit-content;
	height: fit-content;
}
.edit-profile-setting-container--single {
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
	height: 57px;
}
/* .next--icons {
	margin-top: 28.8%;
	margin-left: 12px;
} */
.next--icons {
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	right: auto;
	flex-direction: row-reverse;
	margin-right: 15px;
}
/* .prev--icons {
	margin-top: 27.75%;
	margin-right: 12px;
} */
.prev--icons {
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	margin-left: 15px;
}
.btn-image-order--outlined {
	width: 23rem;
	background: black !important;
	border-radius: 12px !important;
	color: white !important;
	height: 43px;
	display: flex !important;
}
.modal-image-icons {
	background-color: white;
	border: 1px solid #888;
	max-width: -moz-fit-content;
	max-width: fit-content;
	/* margin: 4.5% 2% 40% 1%; */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	min-height: 22.4vh;
	height: auto;
	border-radius: 8px;
	/* margin-top: auto; */
	margin-bottom: auto;
	justify-content: start;
	margin-left: 8px;
}

.order-text-outlined--name {
	margin-left: 83px;
	font-size: 13px;
	font-weight: 500;
	width: auto;
	display: inherit;
	margin-top: 1.7px;
}
.reassigned--text {
	font-size: 15px;
	font-weight: 500;
	width: max-content;
	margin-bottom: 20px;
	color: #0b0b0b;
}
.flex-grow-1 .search--box {
	width: auto !important;
}
.flex-grow-1 .autocomplete {
	width: 54.2%;
}
.image-message--overlay {
	/* position: absolute; */
	left: 419px;
	top: 378px;
	/* background-color: pink; */
	border: 1px solid black;
	padding: 4px;
	font-size: 12px;
	cursor: pointer;
	margin-left: 20px;
	margin-bottom: 10px;
	padding: 10px;
	background-color: #fff;
	width: auto;
	text-align: left;
	border: 2px solid #125ecb;
	border-radius: 20px;
	width: 200px;
	padding: 10px;
	border-radius: 20px;
	color: black;
	margin: 20px;
}
.send-icon {
	/* position: absolute; */
	top: 26.3rem;
	right: 178px;
	transform: translateY(-50%);
	width: 24px;
	height: 27px;
	background-image: url(/static/media/icon-search.b43b804c.svg);
	background-size: cover; /* Scale the background image to cover the element */
	cursor: pointer; /* Change the cursor to a pointer on hover (for clickability) */
}
.send-icon-container {
	display: flex;
	position: absolute;
	margin-top: 8.8%;
	margin-left: 79%;
}
.image-message-container---overlay {
	left: 419px;
	top: 378px;

	border: 1px solid black;
	padding: 4px;
	font-size: 12px;
	cursor: pointer;
	margin-left: 5px;
	margin-bottom: 10px;
	padding: 10px;
	background-color: pink;
	width: auto;
	text-align: left;
	border: 2px solid #125ecb;
	border-radius: 20px;
	width: max-content;
	padding: 10px;
	border-radius: 20px;
	color: black;
}
.send-icon-container:hover {
	border: 2px solid #125ecb;
	border-radius: 50%;
	background: aliceblue;
	margin-top: 24px;
	margin-right: auto;
	margin-left: 14.5rem;
}
.avatar-bg {
	background-image: url(/static/media/messageMarkerIcon.d9f7f317.svg);
	background-size: cover; /* Scale the background image to cover the element */
	cursor: pointer; /* Change the cursor to a pointer on hover (for clickability) */
	background-size: cover;
	cursor: pointer;
	transform: translateY(-17%);
	width: 49px;
	margin-top: 23px;
}
.avatar-container {
	margin-left: 5px;
	margin-top: 4px;
}
.avatar {
	border-radius: 50%;
	border: 2px solid white;
	width: 32px;
	height: 29px;
	margin-top: 9px;
	margin-left: 9px;
	justify-content: center;
	display: flex;
	color: white;
}
.avatar--h3 {
	font-size: 12px;
	font-weight: 400;
}
.send-icon-container:hover {
	border: 2px solid #125ecb;
	border-radius: 50%;
	background: aliceblue;
}
.settings-profile--text {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px !important;
	font-weight: 500 !important;
}
.image-details-margin--type {
	margin-top: 0.6rem;
	font-size: 18px;
	font-weight: 800;
}
.css-1nmdiq5-menu {
	z-index: 99999999 !important;
}

.select-custom-width {
	width: 60%;
}

div.callout {
	height: 35px;
	width: 35px;
	margin: -21px 17px;
	/*float: left;*/
	/* z-index: 1; */
}
div.callout {
	background-color: #fff;
	background-image: -moz-linear-gradient(top, #fff, #fff);
	position: relative;
	color: #ccc;
	border-radius: 6px;
	/* box-shadow: 0px 0px 20px #999; */
	/* margin: 25px; */
	/* line-height: 40px; */
	padding: 5px;
	/* margin: 10px; */
	/* border: 1px solid #333; */
	border: 1px solid #b7b7b7;

	text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
	box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
	/* margin-top: -3px; */
	/*box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset;*/
	/* margin-left: 50%;
  margin-top: 20%; */
}

/* .callout::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}
.callout.left::before {
  left: 0%;
  right: -20px;
  top: 40%;
  border-left: 10px solid #fff;
}
.callout.top::before {
  border-right: 11px solid #fff;
  bottom: 22px;
  left: -20px;
}
.callout.bottom::before {
  left: 45%;
  top: -20px;
  border-bottom: 10px solid #fff;
}
.callout.right::before {
  top: 40%;
  border-right: 10px solid #fff;
}
.callout.top-left::before {
  bottom: -20px;
  border-top: 10px solid #fff;
}
.callout.top-right::before {
  bottom: -20px;
  border-top: 10px solid #fff;
} */

div.firstletter {
	background-color: #d5f4b9;
	color: #639c2b;
	border-radius: 7px;
	padding: 2.5px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
}

div.inner_first_letter {
	background-color: #d5f4b9;
	color: #639c2b;
	border-radius: 7px;
	padding: 5px;
	display: inline-block;
	margin-left: 20px;
	width: 28px;
	margin-top: 12px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
}

.showcomments {
	display: none;
	background-color: #fff;
	width: 249px;
	/* height: 210px; */
	/* min-height: 210px;
  overflow: hidden; */
	height: -moz-fit-content;
	height: fit-content;
	border-radius: 7px;
	margin-left: 4.2rem;
	margin-top: -14.5%;
	overflow-y: auto;
	/* filter: drop-shadow(rgba(0, 0, 0, 0.1) 5px 5px 3px); */
	box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
	overflow-y: scroll;
}

.callout.active {
	box-shadow: 0px 0px 0px 1.5px #6082b6;
}

.callout.active.other_comment {
	box-shadow: 0px 0px 0px 1.5px #639c2b;
}

.firstletter.mycomment,
.inner_first_letter.mycomment {
	color: #fff !important;
	background-color: #6082b6 !important;
}

.addcomment {
	/* display: none; */
	background-color: #fff;
	width: 275px;
	height: 160px;
	/* z-index: 99999999999; */
	border-radius: 7px;
	/* margin-left: 10%;
  margin-top: -22%; */

	position: absolute;
	filter: drop-shadow(rgba(0, 0, 0, 0.1) 5px 5px 3px);
}

/* .pan-container > div:first-child {
  -webkit-transform: unset !important;
  transform: unset !important;
  height: 100% !important;
}

.pan-container {
  height: 100% !important;
} */

.callout.active {
	box-shadow: 0px 0px 0px 1.5px #6082b6;
}

.callout.active.other_comment {
	box-shadow: 0px 0px 0px 1.5px #639c2b;
}

.firstletter.mycomment,
.inner_first_letter.mycomment {
	color: #fff !important;
	background-color: #6082b6 !important;
}

/* .editor.image {
	width: 75em !important;
} */

.editor.upload_image,
.editor.download_image {
	background: #f6f3f4;
	color: #045bb4 !important;
	font-weight: 600;
	border: 1px solid #3c79d3;
}

.editor.disabled {
	border: 1px solid #dbd9da;
	background: #f6f3f4 !important;
	color: #d2d2d2 !important;
	font-weight: bold;
}

.addcomment a {
	color: inherit;
	text-decoration: inherit;
}

.add_comment_parent {
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}
/* .add_comment_parent {
   margin-left: 3%;
  margin-top: -6.5%;
} */

.add_comment_parent {
	margin-left: 30px;
	margin-top: -60px;
}
/* .add_comment_parent.dragged {
  margin-left: -10% !important;
  margin-top: 22% !important;
} */

.addcomment-triangle-down-right {
	width: 0;
	height: 0;
	border-top: 31px solid transparent;
	border-bottom: 0px solid transparent;
	border-left: 50px solid #fff;
	margin-left: 94%;
	margin-top: -38%;
}

.addcomment-triangle-down-left {
	width: 0;
	height: 0;
	border-bottom: 35px solid #fff;
	border-left: 53px solid transparent;
	margin-left: -28px;
	margin-top: -41%;
}

.move_right {
	margin: 0px -335px;
}

.hover-show-comments {
	display: none;
	background-color: #fff;
	width: 180px;
	height: -moz-fit-content;
	height: fit-content;
	border-radius: 7px;
	margin-top: -25%;
	cursor: move;
	margin-left: 10%;
}

.modal-image-icons--qc-workspace .hover-show-comments {
	width: 182px !important;
}

.hover-show-comments .comment_details {
	margin: 0em 3em 0em !important;
	word-wrap: break-word;
}

.comment_d_parent {
	width: 95%;
	overflow: hidden;
	margin: 10px;
}

.hide {
	/* transition: 0.01s; */
	transform: translateX(-9999px);
}

/* .callout:hover .showcomments {
  display: block;
} */

.triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 14px solid #fff;
	border-left: 20px solid transparent;
	margin-left: -25px;
	margin-top: -19px;
}

.comment-triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 30px solid #fff;
	border-left: 50px solid transparent;
	margin-left: -30px;
	margin-top: -6%;
	position: fixed;
}

.hover-comment-triangle-down-right {
	width: 0;
	height: 0;
	border-bottom: 40px solid #fff;
	border-left: 50px solid transparent;
	margin-left: -40px;
	margin-top: -25%;
}
.commented_by {
	margin: 17px 10px 20px 10px;
	font-weight: 400;
	font-size: 14px;
}
.more_options {
	margin-left: 4rem;
	width: 16px;
}

.close_comment {
	width: 25px;
	margin-left: 5px;
	margin-bottom: -1px;
}

.add_close_comment {
	width: 22px;
	margin-left: 38px;
	margin-bottom: -1px;
	height: 20px;
}

.edit_close_comment {
	position: absolute;
	right: 2px;
	top: 8px;
	width: 21px;
	cursor: pointer;
}

.add_more_options {
	width: 14px;
}
.comment_date {
	margin-left: 3.6rem;
	margin-top: 1px;
	color: #acacac;
	font-size: 12px;
}

.comm_date {
	font-size: 12px !important;
	color: #acacac;
	margin: 2px 0px 0px 50px;
}

.comment_details {
	color: #0b0b0b;
	margin: 12px;
	margin-left: 3.6rem;
	font-size: 13px;
	padding-bottom: 5px;
}
.comment_add {
	margin-left: 1.3rem;
	height: 55px;
	position: relative;
}

.comment_add .trigger {
	position: absolute;
	right: 29px;
	top: 1px;
	width: 36px;
}

.comment_edit {
	margin-left: 1.4rem;
	height: 55px;
	position: relative;
}

.comment_edit .trigger {
	position: absolute;
	right: 29px;
	top: 1px;
	width: 36px;
}

.comment_edit input {
	width: 90.5%;
	margin-left: -2.1px;
}

.comment_add input,
.comment_edit input {
	/* background: #f5f5f5 url("../assets/Images/message.svg") no-repeat;
  background-position: right center; */
	background: #f5f5f5;
	height: 38px;
}

.co-ordinating--assigned-to-editor {
	display: flex;
	margin-left: 67%;
	margin-top: -4.8rem !important;
	flex-direction: column;
}
.total-images--count {
	font-size: 16px;
	font-weight: 600;
	color: #606772;
	margin-top: 18px;
}
.start-time--images {
	font-size: 16px;
	font-weight: 600;
	color: #606772;
}
/********Merged IMages Modal*************/
.sidebar-image--duplicate {
	justify-content: end;
	grid-column-gap: 113px;
	grid-column-gap: 113px;
	column-gap: 113px;
	margin-right: 10px;
	height: 700px;
	overflow: scroll;
	width: 22rem;
	margin-left: 18px;
}

.add-merged-image--text {
	font-size: 13px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #125ecb;
	margin-top: 16px;
	margin-bottom: 20px;
}
.merged-image--text {
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}
.merged-image-keep-original--text {
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}
.merged-image-text--container {
	display: flex;
	justify-content: space-between;
}

.dd #dropdownMenuButton {
	background: none !important;
	width: auto !important;
}

.dd .dropdown-menu {
	margin-left: -77px !important;
}

.dropdown.dd {
	display: inline-block !important;
}
.spinner-border.trigger {
	margin-top: 2px;
	right: 30px;
}
.grab {
	cursor: move;
	cursor: grab;
	cursor: -webkit-grab;
	.thumbnails-list {
		cursor: pointer;
	}
}

.grab:active {
	cursor: grabbing;
	cursor: -webkit-grabbing;
}

.side-bar-single-select--h2 {
	font-size: 16px;
	font-weight: 500;
	line-height: 13px;
}
.sidebar-image--name {
	width: 100%;
	/* justify-content: end;
	grid-column-gap: 113px;
	column-gap: 113px;
	margin-right: 4px; */
	/* width: fit-content; */
}
.sidebar--select {
	/* width: max-content; */
	height: 90vh;
	padding: 0px;
}
.sidebar-image-name--image-modal {
	justify-content: end;
	grid-column-gap: 113px;
	grid-column-gap: 113px;
	column-gap: 113px;
	/* margin-right: 15px; */
	width: auto;
	/* margin-left: 5px; */
}
.select-all--image-settings {
	border-radius: 2px;
	width: 16px;
	height: 15px;
}
.me-12 {
	margin-right: 12.5rem !important;
}
.global-gap--btn {
	column-count: 2;
	grid-column-gap: 15px;
	column-gap: 15px;
	display: flex;
}
.modal-image-icons--qc-workspace {
	background-color: white;
	border: 1px solid #888;
	width: auto;
	margin: 114px 10px 32.8rem 42px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-evenly;
	height: 14rem;
	border-radius: 8px;
	flex-direction: column;
}
.btn-image-order-outlined--qc {
	width: 8rem;
	background: black !important;
	border-radius: 18px !important;
	color: white !important;
	height: 39px;
}
.dot {
	height: 18px;
	width: 18px;
	background-color: white;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 6px;
	border: 1px solid #125ecb;
}
.dot_1 {
	height: 18px;
	width: 18px;
	background-color: black;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot_2 {
	height: 18px;
	width: 18px;
	background-color: #7cfc00;
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot_3 {
	height: 18px;
	width: 18px;
	background: linear-gradient(to top, #daecb8 0%, #e33127 100%);
	border-radius: 50%;
	display: inline-block;
	margin-top: 14px;
	margin-left: 8px;
	border: 1px solid #125ecb;
}
.dot--gap {
	display: flex;
	margin-left: 7rem;
	justify-content: space-evenly;
}
.reject-cross--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 4px 38px;
}
.accept-tick--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 0px;
}
.image-modal--details-text {
	background: #f5f5f5;
	height: 50px;
}
.edit-type--text {
	font-size: 15px;
	margin-left: 20px;
	font-weight: 400;
}
.color-picker {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* margin-top: 20px; */
	z-index: 97;
	position: absolute;
}
.mr-l-r--duplicate {
	margin-right: 12px;
	margin-left: 7px;
	margin-top: 0px;
}
.dropdown-duplicate--image {
	display: flex;
	justify-content: space-between;
}
.dropdown-duplicate-image--text {
	font-size: 14px;
	font-weight: 400;
	margin-top: 6px;
}
.my-modal-rename--duplicate {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	z-index: 12000;
	flex-direction: column;
	max-height: 8em;
	min-height: 14em;
}
.btn-image-order-duplicate--outlined {
	width: 23rem;
	border-radius: 8px !important;
	color: black;
	display: flex !important;
	background: #fbfbfb;
}
.editororderdetails--sidebar {
	height: 90vh;
	overflow-y: auto;
	padding-bottom: 57px;
	overflow-x: clip;
	max-width: 22em;
	width: 21em;
	display: flex;
}

.delete-icon {
	background: url(/static/media/white_delete.10428bcb.svg)
		no-repeat;
	display: block;

	/* background: white;
	mask-size: contain; */
}

.drag-select {
	/* background-color: rgba(20, 137, 189, 0.5); */
	background: rgba(0, 175, 255, 0.1);
	/* border: 0.5px solid rgba(0, 175, 255, 0.9); */
	z-index: 11;
	opacity: 0.1;
	/* */
}
.treeviewlist.custom-viewlist li {
	padding-left: 0;
}

@media (min-width: 800px) and (max-width: 1280px) {
	.custom-row-workspace .customcol-3 {
		width: auto !important;
	}
	.treeviewlist.custom-viewlist li {
		width: auto !important;
		height: max-content;
		padding: 14px 6px 20px;
	}
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 32em !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 42em !important; */
	}
	.custom-row-workspace {
		/* width: 72em; */
		display: flex;
	}
}
@media (min-width: 1280px) and (max-width: 1400px) {
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 30em !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* width: 40em !important; */
	}
}
@media only screen and (min-width: 1600px) and (max-width: 2800px) {
	.editororderdetails {
		/* height: 90vh; */
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
		/* Skip this line for code review */
		/* width: 29em !important; */
		/* width: 100% !important; */
	}
	.editororderdetails_editor {
		overflow-y: auto;
		/* padding-bottom: 57px; */
		overflow-x: clip;
	}
	.close-icons-order--image {
		margin-top: 1.125% !important;
		margin-bottom: 0;
	}
}
.editororderdetails--qc {
	height: 90vh;
	overflow-y: auto;
	padding-bottom: 57px;
	overflow-x: clip;
	width: auto;
}
.editororderdetails--qc .card-text {
	font-weight: 500;
	font-size: 13px !important;
	line-height: 19px !important;
	text-align: center;
	padding: 2px 0 10px;
	color: #0b0b0b;
}
/* .editor.image {
	width: 55em !important;
} */
.dot-gap--qc {
	display: flex;
	margin-left: 6rem;
	justify-content: space-evenly;
}
.original-image--qc {
	margin-left: 6px;
	margin-top: 4px;
	margin-right: 2px;
}
.scroll--edit-group {
	height: 23em;
	overflow: scroll;
	width: 100%;
}
.form-check {
	width: max-content;
}
.ml-9 {
	margin-left: 7.5rem !important;
}
.btn-image-order--qc {
	width: 8rem;
	background: #eae7e8 !important;
	color: #0058ab !important;
	height: 39px;
}
.margin--warning {
	margin-left: 15px;
	margin-top: 6px;
}
.reject-cross-qc--btn {
	justify-content: space-evenly;
	display: flex;
	margin: 4px 28px;
}
.text-qc--btn {
	margin-top: 0;
	font-size: 13px;
	font-weight: 700;
}
.date-picker-qc--container {
	margin-bottom: 8px;
	width: 16rem;
	flex-direction: column;
	background: #f7f7f7;
	height: 83px;
	overflow: scroll;
	position: absolute;
	z-index: 999;
	border: 2px solid #f7f7f7;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	/* color: var(--bs-btn-disabled-color); */
	pointer-events: none !important;
	/* background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color); */
	opacity: 0.45 !important;
}
.margin--warning--disabled {
	margin-left: 15px;
	margin-top: 6px;
	opacity: 0.2;
}
.bulk_upload_error p {
	padding: 10px;
}

.workspace--switch {
	display: flex;
	justify-content: space-between;
}
.workspace-form--switch {
	margin: 0px 0px 12px 38px;
	font-size: 26px;
	margin-right: auto;
}

.modal-body p.err {
	white-space: pre-wrap;
	margin: -15px 0px 0px 0px;
}

.modal--image .color-picker {
	z-index: 999;
}
.order-status {
	color: #f1a100;
}
.order--success {
	color: #008e1a;
}
.modal-tab {
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	margin-right: 23px;
	grid-gap: 25px;
	gap: 25px;
	align-items: center;
}
/* .modal-container {
	width: 90%;
	margin-bottom: 0px;
	min-height: 77vh;
	height: auto;
	overflow: auto;
	max-height: 80vh;
} */
.togler-menu--close {
	position: fixed;
	bottom: 0;
	left: 0px;
	z-index: 999;
}
.mr-25px {
	margin-right: 23px;
}
.set-assgin-editor {
	margin-top: -5% !important;
	margin-left: 43% !important;
}
.settings_selected {
	color: #205aa7;
	width: max-content;
	font-size: 11px;
	@media (min-width: 1100px) {
		font-size: 12px;
	}
}
.bothbtn-order.pending {
	background: #ecf1f7;
}
.mr-auto {
	margin-right: auto;
}
.date_filter {
	border-radius: 4px;
}

.coordinator .autocomplete {
	width: 91.1% !important;
}

.coordinator_hr {
	width: 105% !important;
	margin-left: -2%;
	/* margin-bottom: 3%; */
	margin-bottom: 40.359px;
	border: none !important;
	height: 1px !important;
	/* Set the hr color */
	color: #e9e9e9 !important; /* old IE */
	background-color: #e9e9e9 !important; /* Modern Browsers */
	opacity: 1;
}
.drp-calendar.left .prev.available {
	padding: 0px;
	margin: -75px 39px;
}

.drp-calendar.left .prev.available:hover {
	background-color: unset !important;
	color: unset !important;
}

.drp-calendar.right .next.available {
	margin: -84px 230px;
	pointer-events: none;
}

.drp-calendar.right .next.available:hover {
	background-color: unset !important;
	color: unset !important;
}

.custom-coordinator-search-mt {
	margin: 2px 0px 4px -68px;
}

/* .coordinator-search-mt {
	margin: 7px 0px 9px -68px;
} */

.filter .css-1dimb5e-singleValue {
	font-weight: bold;
	font-size: 14px;
}
.order--success {
	color: #008e1a;
}
.duplicate-setting--Modal {
	max-width: 40rem;
	width: 100%;
	margin-bottom: 0 !important;
	max-height: 43rem;
}
.image-modal--setting {
	max-height: 86vh;
	height: auto;
}
.width-35rem {
	max-width: 35rem;
	min-width: 35rem;
	marign-left: 10px;
	margin-right: 10px;
	/* height: 25.5rem; */
}
.width-auto {
	max-width: 35.38rem;
	width: max-content !important;
}
.error {
	font-size: 12px;
	font-weight: 400;
	color: red;
}
.error-duplicate--settings {
	display: flex;
	justify-content: start;
	margin-left: 12px;
	margin-bottom: 12px;
	color: #f1a100;
	font-size: 14px;
	font-weight: 700;
}
.duplicate-setting-image--content {
	justify-content: center;
	grid-column-gap: 113px;
	grid-column-gap: 113px;
	grid-column-gap: 0;
	grid-column-gap: normal;
	column-gap: normal;
	margin-right: 0px;
}
.duplicate-settings-profile--select {
	position: absolute;
	width: -webkit-fill-available;
	justify-content: space-between;
	margin-right: auto;
	height: auto;
}
.p-0 {
	padding: 0;
}
.duplicate-settings-modal--count {
	/* height: 23em; */
	overflow-y: auto;
	overflow-x: hidden;
}
.edit-setting-select--duplicate {
	position: fixed;
	max-width: 22em;
}
.scroll-y-axis--duplicate {
	height: 20.85rem;
	scroll: auto;
	overflow-y: hidden;
	width: 100%;
	overflow-x: hidden;
	margin-left: 6px;
}
.side-bar-duplicate-settings--view {
	height: 29.5rem;
	overflow: scroll;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
}
.folder__marker_blue {
	background: #ecf1f7;
	width: auto;
	min-height: 47px;
	height: auto;
	border-radius: 0px;
	top: 3px;
	margin-bottom: auto;
	margin-top: auto;
	display: flex;
	align-items: center;
}

.modalHeight {
	/* New css */
	max-height: 18em !important;
	min-height: 18em !important;
}
.coordinator_card {
	height: auto;
	background: rgb(255, 255, 255);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 167px;
	margin-left: auto;
	margin-right: auto;
}
.canvas {
	display: block;
	width: 100%;
}
.canvas .img {
	width: 100%;
	height: 100%;
}
.pan_container {
	height: -webkit-fill-available;
	/* width: calc(85vw - 450px) !important; */
	position: relative;
	background: #474747;
	overflow: hidden;
	/* overflow: hidden; */
	scroll-behavior: smooth;
}
#image_holder {
	background: #474747;
}
.modal-open {
	overflow: auto !important;
	z-index: 10;
}
.color_blue {
	color: var(--blue);
}
.color_blue:hover {
	color: var(--primary-black);
}
.color_black {
	color: var(--primary-black);
}
#zoom_canvas {
	background: url(/static/media/transparent.42e9edc7.jpg);
}
.canvas {
	height: auto;
	background: rgb(255, 255, 255);
	position: -webkit-sticky;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	transform: unset !important;
	margin-bottom: auto;
}
.magnifier-holder--img {
	justify-content: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	display: flex;
	height: 2.7rem !important;
	width: 3em;
}
modal {
	will-change: transform;
}
.disabled_icons {
	background-color: #e4e4e4;
	border-right: 3px solid #a9a9a9;
	border-left: 3px solid #6b6b6b;
	opacity: 0.39;
}
.disabled_icons:hover {
	background-color: #e4e4e4;
	opacity: 0.39;
	border-right: 3px solid #a9a9a9;
	border-left: 3px solid #6b6b6b;
	cursor: not-allowed;
}
.content {
	display: contents;
}
.overlayLayer {
	position: absolute;
	/* display: none; */
	background-color: rgba(0, 0, 0, 0.2);
	width: 234vw;
	height: 200vh;
}
.error_order-create {
	border: 1px solid red !important;
}
.position--relative {
	position: relative;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
	pointer-events: none;
	opacity: 0.25;
}
.dropzone {
	min-height: calc(100vh - 308px);
	position: absolute;
	width: -webkit-fill-available;
}
.card.is-loading {
	min-height: 317px;
}

.card.is-loading {
	.image,
	/* h2, */
	p {
		background: #eee;
		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		border-radius: 5px;
		background-size: 200% 100%;
		animation: 1.5s shine linear infinite;
		width: 100%;
		margin-top: 0;
	}

	.image {
		height: 200px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	h2 {
		height: 30px;
	}

	p {
		height: 70px;
	}
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

@media (max-width: 767px) {
  .card,
  .pipeline {
    width: 100%;
  }
  .section-title {
    text-align: center;
  }
  .invoicecard .card {
    margin-bottom: 14px;
  }
}

.navbar {
	/* padding: 5px; */
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 60px;
	z-index: 1030;
	background: #0b0b0b;
	transition: background 0.25s ease;
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-ms-transition: background 0.25s ease;
}
.navbar-brand {
	padding: 0 15px;
}
#profileDropdown:hover {
	background: #0b0b0b;
	transition: background 0.25s ease;
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-ms-transition: background 0.25s ease;
}
@media (max-width: 991px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}
}
.navbar .navbar-brand-wrapper .navbar-brand {
	color: #27367f;
	font-size: 1.5rem;
	line-height: 48px;
	margin-right: 0;
	padding: 0.25rem 0;
	width: 100%;
}
.nav-profile-img {
	grid-column-gap: 14px;
	column-gap: 14px;
}
.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
	color: #1b2658;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
	width: calc(260px - 120px);
	max-width: 100%;
	height: 28px;
	margin: auto;
	vertical-align: middle;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
	display: none;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
	width: calc(70px - 50px);
	max-width: 100%;
	height: 28px;
	margin: auto;
}
.navbar .navbar-menu-wrapper {
	transition: width 0.25s ease;
	-webkit-transition: width 0.25s ease;
	-moz-transition: width 0.25s ease;
	-ms-transition: width 0.25s ease;
	color: #9c9fa6;
	padding-left: 24px;
	padding-right: 24px;
	width: calc(100% - 260px);
	height: 70px;
}
.user-name {
	font-weight: 700;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
	max-width: 10rem;
	overflow: auto;
	white-space: nowrap;
}
.user-logout {
	font-weight: 700;
	font-size: 26px;
	line-height: 19px;
	color: #ffffff;
}
.user-designation {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
}
/* Dropdowns */
.dropdown-toggle:after {
	margin-left: 46px;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-bottom: 0;
	font: normal normal normal 14px/1 FontAwesome;
	content: "\F107";
	width: auto;
	height: auto;
	vertical-align: baseline;
	font-size: 0.75rem;
}

.dropdown .dropdown-menu {
	margin-top: 0.75rem;
	font-size: 0.875rem;
	box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
	margin-left: -6.8rem;
    /* position: absolute; */
    inset: -5px 46px auto 0 !important;
    transform: translate(70px, 45px);
    /* background: black !important; */
    border-radius: 12px !important;
    color: #c0bcbc;
}
.dropdown .dropdown-menu .dropdown-item {
	font-size: 1rem;
	padding: 0.25rem 1.5rem;
	/* color:white!important; */
}
.dropdown .dropdown-menu .dropdown-item:active {
	background: initial;
}
.dropdown .dropdown-menu .dropdown-item:hover {
	background-color: #f8f9fa;
	color: black!important;
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.navbar .navbar-menu-wrapper .navbar-toggler {
	border: 0;
	color: inherit;
	height: 70px;
	border-radius: 0px;
	padding-left: 5px;
	padding-right: 20px;
}
.navbar .navbar-menu-wrapper .navbar-toggler:focus {
	box-shadow: none;
}
.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
	font-size: 1.5rem;
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
		display: none;
	}
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
		padding-left: 15px;
		padding-right: 11px;
		border-right: none;
	}
}
.navbar .navbar-menu-wrapper .search-field .input-group input {
	font-size: 0.875rem;
	padding: 0.5rem;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group i {
	font-size: 17px;
	margin-right: 0;
	color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
	background: transparent;
}
.notify {
	position: relative;
}
.count-symbol,
.count-number {
	position: absolute;
	border-radius: 100%;
}
.count-symbol {
	top: 15px;
	right: 8px;
	width: 10px;
	height: 10px;
	background: #ff0000;
}
.count-number {
	min-width: 14px;
	height: 14px;
	font-size: 0.5rem;
	color: #ffffff;
	bottom: 16px;
	right: -5px;
	line-height: 1;
	text-align: center;
}
.navbar .navbar-menu-wrapper .count-indicator:after {
	display: none;
}
.navbar .navbar-menu-wrapper .navbar-nav {
	flex-direction: row;
	align-items: center;
}
.nav-link {
	color: inherit;
	font-size: 0.875rem;
	margin-right: 1.5rem;
	margin-right: 10px;
	display: flex;
	align-items: center;
}
@media (max-width: 767px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}
}
.notify i {
	font-size: 24px;
	color: #fff;
}
.dropdown-toggle:after {
	color: #fff;
	font-size: 18px;
}
.dropdown-menu {
	border: none;
	border-radius: 5px;
	box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	position: absolute;
	font-size: 0.9rem;
	margin-top: 0;
	padding: 0;
}
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	right: auto;
	left: 0;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	margin-bottom: 0;
	padding: 11px 13px;
	cursor: pointer;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	font-size: 17px;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	.ellipsis {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-left: 10px;
}
.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-divider {
	margin: 0;
}
@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
		position: static;
	}
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
		left: 20px;
		right: 20px;
		top: 70px;
		width: calc(100% - 40px);
	}
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
	padding-left: 20px;
	padding-right: 5px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
	margin-right: 0;
	margin-left: 0;
}
.nav-profile-img {
	position: relative;
	width: 40px;
	height: 40px;
}
.nav-profile-img img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}
.nav-profile-img .availability-status {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	border: 2px solid #ffffff;
	bottom: 5px;
	right: -5px;
}
.nav-profile-img .availability-status.online {
	background: #1bcfb4;
}
.nav-profile-img .availability-status.offline {
	background: #fe7c96;
}
.nav-profile-img .availability-status.busy {
	background: #fed713;
}
.nav-profile-text {
	margin-left: 1.25rem;
	width: auto !important;
}
.rtl.nav-profile-text {
	margin-left: 0;
	margin-right: 1.25rem;
}

@media (max-width: 767px) {
	.nav-profile-text {
		display: none;
	}
}
.dropdown-toggle:after {
	line-height: 2;
}
@media (min-width: 992px) {
	.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: auto;
	}
	.rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: 0;
		margin-right: auto;
	}
}

@media (max-width: 991px) {
	.navbar {
		flex-direction: row;
	}
	.navbar .navbar-brand-wrapper {
		width: 75px;
	}
	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
		display: none;
	}
	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
		display: inline-block;
	}
	.navbar-collapse {
		display: flex;
		margin-top: 0.5rem;
	}
}

@media (max-width: 480px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}
	.navbar .navbar-brand-wrapper .brand-logo-mini {
		padding-top: 0px;
	}
}

/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper {
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper {
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper {
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
	color: #ffffff;
}

.image-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80%;
	max-width: 150px; /* Adjust the max width as needed */
	margin: 0 0 auto;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 10em; */
}

.slide-container {
	display: flex;
	justify-content: space-between;
	margin-left: 4em;
	width: auto;
}

.slide {
	max-width: 108%;
	height: auto;
	margin-right: 15px;
	height: 9rem;
}
.slide:hover {
	border: 1px solid #125ecb;
}
.prev,
.next {
	position: absolute;
	top: 30%;
	/* background-color: #333; */
	color: #fff;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.prev {
	left: -26px;
}

.next {
	right: -226px;
}

.image-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80%;
	max-width: 150px; /* Adjust the max width as needed */
	margin: 0 0 auto;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 10em; */
}

.slide-container {
	display: flex;
	justify-content: space-between;
	margin-left: 4em;
	width: auto;
}

.slide {
	max-width: 108%;
	height: auto;
	margin-right: 15px;
	height: 9rem;
}
.slide:hover {
	border: 1px solid #125ecb;
}
.prev,
.next {
	position: absolute;
	top: 30%;
	/* background-color: #333; */
	color: #fff;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.prev {
	left: -26px;
}

.next {
	right: -226px;
}

.image-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80%;
	max-width: 150px; /* Adjust the max width as needed */
	margin: 0 0 auto;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 10em; */
}

.slide-container {
	display: flex;
	justify-content: space-between;
	margin-left: 4em;
	width: auto;
}

.slide {
	max-width: 108%;
	height: auto;
	margin-right: 15px;
	height: 9rem;
}
.slide:hover {
	border: 1px solid #125ecb;
}
.prev,
.next {
	position: absolute;
	top: 30%;
	/* background-color: #333; */
	color: #fff;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.prev {
	left: -26px;
}

.next {
	right: -226px;
}

.image-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80%;
	max-width: 150px; /* Adjust the max width as needed */
	margin: 0 0 auto;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 10em; */
}

.slide-container {
	display: flex;
	justify-content: space-between;
	margin-left: 4em;
	width: auto;
}

.slide {
	max-width: 108%;
	height: auto;
	margin-right: 15px;
	height: 9rem;
}
.slide:hover {
	border: 1px solid #125ecb;
}
.prev,
.next {
	position: absolute;
	top: 30%;
	/* background-color: #333; */
	color: #fff;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.prev {
	left: -26px;
}

.next {
	right: -226px;
}

/* Target the horizontal scrollbar */
.page-body-wrapper {
	scrollbar-width: thick;
	scrollbar-color: #c7c7c7 #ffffff;
}
.editororderdetail {
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}

.editororderdetail html ::-webkit-scrollbar-thumb {
	scrollbar-width: 1px;
	scrollbar-color: #c7c7c7 #ffffff;
}
.editororderdetails_editor html ::-webkit-scrollbar-thumb {
	scrollbar-width: 1px;
	scrollbar-color: #c7c7c7 #ffffff;
}
.customer-count {
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: #0b0b0b;
}
/* .col-md-3 {
	position: relative;
	width: 100%;
	min-height: auto;
	padding-left: 0 !important;
} */
/* .col-lg-6 {
	padding-left: 0 !important;
} */
.customer-count span {
	margin-right: 6px;
}

.customer-search {
	flex: 1 1;
}

.chatdetails {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
}

.media-body h5 {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.time {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	color: #a1a1a1 !important;
}

.customertab {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	padding: 4px;
	padding-left: 4px !important;
	width: 23%;
	margin-bottom: 21px !important;
}

.customertab .nav-item .nav-link {
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
	font-weight: 500;
	margin-right: 0;
	display: block;
}

.customertab .nav-link.active {
	background: #ecf1f7 !important;
	border-radius: 4px;
	border-bottom: 0 !important;
}

.chatlist {
	width: 97%;
}

.chatlist li {
	margin-bottom: 22px;
}

.chatlist li .media img {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
}

.seacrhbox {
	position: absolute;
	top: 0;
	width: 33%;
	left: 26%;
}

.tab-content {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	width: auto;
}

.chatmessage {
	position: fixed !important;
	bottom: 0;
	width: 100% !important;
	background: #fff;
	padding-bottom: 30px;
	padding-top: 18px;
}

.chatmessage .input-group-text {
	background: #ecf1f7;
	border-radius: 4px;
	border: 0;
	margin-left: 10px !important;
	height: 41px;
}

.chatmessage > .form-control:not(:last-child) {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

ul.invoicedetails li {
	display: inline-block;
	margin-right: 40px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	margin-right: 40px;
}

ul.invoicedetails li span {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.subbox {
	background: #ecf1f7;
	border-radius: 4px;
	padding: 12px 19px 14px;
	margin-bottom: 47px;
}

.subbox ul li {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
}

.headli {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
}

.balncedue {
	background: #fff;
	padding: 9px 12px;
	border-radius: 4px;
}

.medialist {
	height: 58vh;
	overflow-y: auto;
	padding-bottom: 40px;
}

.online i {
	font-size: 9px;
	color: #008e1a;
}

.online p {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	display: flex;
	align-items: center;
}

.online p span {
	margin-left: 6px;
}

.namelist {
	height: 220px;
	overflow-y: scroll;
}

.namelist li {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	color: #0b0b0b;
}

.namelist li span {
	margin-left: 15px;
}

.customernamelist li {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	color: #0b0b0b;
}

.customernamelist li span {
	margin-left: 15px;
}

.profilepic {
	position: relative;
	width: 30px;
	height: 30px;
	border-radius: 3px;
	overflow: hidden;
}

.profilepic img {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
}

.profilepic i {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 8px;
	color: #008e1a;
	border: 1px solid #fff;
	border-radius: 50%;
}

.online-icon {
	position: relative;
}

.online-icon i {
	position: absolute;
	bottom: 22%;
	right: -18%;
	font-size: 8px;
	color: #008e1a;
	border: 1px solid #fff;
	border-radius: 50%;
}

.usersearch {
	border-bottom: 1px solid #e9e9e9;
}

.chatsidebar {
	padding-right: 20px;
}

.pad0 {
	padding: 0;
}

.chattabcontent .tab-content {
	position: relative;
}

.chattabcontent .tab-content:before {
	content: "";
	position: absolute;
	top: 0;
	width: -webkit-fill-available;
	left: -8%;
	z-index: 9;
	border-top: 1px solid #e9e9e9;
}

.chattabcontent h4 {
	margin-bottom: 0;
}

.innercustomertab .customertab {
	width: 33%;
}

.innercustomertab .tab-content {
	border-top: 1px solid #e9e9e9;
}

.innercustomertab .tab-content:before {
	display: none;
}

.overviewstab .stafftab {
	margin-bottom: 0;
}

.customerdetailtable {
	position: relative;
}

.border-left {
	border-right: 1px solid #dedede;
}

.profile-details-list {
	background: #fff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	position: relative;
}

.profile-details-list li {
	display: flex;
	justify-content: space-between;
	padding: 17px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	border-bottom: 1px solid #e9e9e9;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

.titlecontent {
	width: 57%;
	color: #0b0b0b;
}

.titletext {
	width: 34%;
}

.react-bootstrap-table th .order > .dropup > .caret {
	margin: 10px 0;
	color: #a1a1a1;
}

.detailstab {
	position: relative;
}

.doticon {
	position: absolute;
	top: 0;
	right: 0;
}

.chat-head {
	position: relative;
	border-bottom: 1px solid #dedede;
}

.chatmsg {
	width: 60%;
}

.view-invoicetable .table td {
	padding: 12px 12px;
}

.table {
	margin-bottom: 0;
	border: 1px solid rgba(0, 0, 0, 0);
}

.table thead th {
	border-top: 0;
	border-bottom-width: 1px;
	background: #f5f5f5;
	font-size: 13px;
	font-size: 13px;
	font-size: 13px;
	line-height: 19px;
	line-height: 19px;
	line-height: 19px;
	color: #0b0b0b;
	font-weight: 500;
	white-space: normal;
	vertical-align: middle;
}

.table thead th i {
	margin-left: 0.325rem;
}

.table th {
	vertical-align: middle;
	font-size: 0.875rem;
	border: 0;
	line-height: 1;
	white-space: nowrap;
	padding: 20px 12px !important;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
}

.table th img {
	width: 36px;
	height: 36px;
	border-radius: 100%;
}

.table th .badge {
	margin-bottom: 0;
}

.table td {
	vertical-align: middle;
	font-size: 0.875rem;
	border: 0;
	line-height: 1;
	white-space: nowrap;
	padding: 20px 12px !important;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
}

.table td img {
	width: 36px;
	height: 36px;
	border-radius: 100%;
}

.table td .badge {
	margin-bottom: 0;
}

.react-bootstrap-table table {
	table-layout: auto !important;
}

.react-bootstrap-table table tr:hover {
	background: #ebedf2;
}

.table-b {
	border-radius: 7px;
	border: 1px solid #ebedf2;
}

.comon-table {
	height: 320px;
	overflow: auto;
}

th {
	padding: 20px 8px;
}

td {
	padding: 20px 8px;
}

.radius {
	border-radius: 6px;
	overflow: hidden;
}

.table.table-borderless {
	border: none;
}

.table.table-borderless tr {
	border: none;
}

.table.table-borderless td {
	border: none;
}

.table.table-borderless th {
	border: none;
}

.font-text-13 {
	font-size: 13px;
}

.navbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 60px;
	z-index: 1030;
	background: #0b0b0b;
	transition: background 0.25s ease;
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-ms-transition: background 0.25s ease;
}

.navbar-brand {
	padding: 0 15px;
}

@media (max-width: 991px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}
}

.navbar .navbar-brand-wrapper .navbar-brand {
	color: #27367f;
	font-size: 1.5rem;
	line-height: 48px;
	margin-right: 0;
	padding: 0.25rem 0;
	width: 100%;
}

.nav-profile-img {
	grid-column-gap: 14px;
	column-gap: 14px;
}

.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
	color: #1b2658;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
	width: 140px;
	max-width: 100%;
	height: 28px;
	margin: auto;
	vertical-align: middle;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
	display: none;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
	width: 20px;
	max-width: 100%;
	height: 28px;
	margin: auto;
}

.navbar .navbar-menu-wrapper {
	transition: width 0.25s ease;
	-webkit-transition: width 0.25s ease;
	-moz-transition: width 0.25s ease;
	-ms-transition: width 0.25s ease;
	color: #9c9fa6;
	padding-left: 24px;
	padding-right: 24px;
	width: calc(100% - 260px);
	height: 70px;
}

.user-name {
	font-weight: 700;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
}

.user-designation {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #fff;
}

.dropdown-toggle:after {
	margin-left: 46px;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-bottom: 0;
	font: normal normal normal 14px/1 FontAwesome;
	content: "\F107";
	width: auto;
	height: auto;
	vertical-align: baseline;
	font-size: 0.75rem;
}

.dropdown .dropdown-menu {
	margin-top: 0.75rem;
	font-size: 0.875rem;
	box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.dropdown .dropdown-menu .dropdown-item {
	font-size: 1rem;
	padding: 0.25rem 1.5rem;
}

.dropdown .dropdown-menu .dropdown-item:active {
	background: initial;
}

.dropdown .dropdown-menu .dropdown-item:hover {
	background-color: #f8f9fa;
	color: #fff;
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.navbar .navbar-menu-wrapper .navbar-toggler {
	border: 0;
	color: inherit;
	height: 70px;
	border-radius: 0px;
	padding-left: 5px;
	padding-right: 20px;
}

.navbar .navbar-menu-wrapper .navbar-toggler:focus {
	box-shadow: none;
}

.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
	font-size: 1.5rem;
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
		display: none;
	}
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
		padding-left: 15px;
		padding-right: 11px;
		border-right: none;
	}
}

.navbar .navbar-menu-wrapper .search-field .input-group input {
	font-size: 0.875rem;
	padding: 0.5rem;
}

.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}

.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}

.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}

.navbar
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	font-size: 0.875rem;
	color: #9c9fa6;
}

.navbar .navbar-menu-wrapper .search-field .input-group i {
	font-size: 17px;
	margin-right: 0;
	color: #9c9fa6;
}

.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
	background: rgba(0, 0, 0, 0);
}

.notify {
	position: relative;
	margin-right: 0px !important;
}

.count-symbol,
.count-number {
	position: absolute;
	border-radius: 100%;
}

.count-symbol {
	top: 15px;
	right: 8px;
	width: 10px;
	height: 10px;
	background: red;
}

.count-number {
	min-width: 14px;
	height: 14px;
	font-size: 0.5rem;
	color: #fff;
	bottom: 16px;
	right: -5px;
	line-height: 1;
	text-align: center;
}

.navbar .navbar-menu-wrapper .count-indicator:after {
	display: none;
}

.navbar .navbar-menu-wrapper .navbar-nav {
	flex-direction: row;
	align-items: center;
}

.nav-link {
	color: inherit;
	font-size: 0.875rem;
	margin-right: 1.5rem;
	margin-right: 10px;
	display: flex;
	align-items: center;
}

@media (max-width: 767px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
		margin-left: 0.8rem;
		margin-right: 0.8rem;
	}
}

.notify i {
	font-size: 24px;
	color: #fff;
}

.dropdown-toggle:after {
	color: #fff;
	font-size: 18px;
}

.dropdown-menu {
	border: none;
	border-radius: 5px;
	box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	position: absolute;
	font-size: 0.9rem;
	margin-top: 0;
	padding: 0;
}

.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown {
	right: auto;
	left: 0;
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	margin-bottom: 0;
	padding: 11px 13px;
	cursor: pointer;
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	font-size: 17px;
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	.ellipsis {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-left: 10px;
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-divider {
	margin: 0;
}

@media (max-width: 991px) {
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
		position: static;
	}

	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
		left: 20px;
		right: 20px;
		top: 70px;
		width: calc(100% - 40px);
	}
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
	padding-left: 20px;
	padding-right: 5px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
	margin-right: 0;
	margin-left: 0;
}

.nav-profile-img {
	position: relative;
	width: 40px;
	height: 40px;
}

.nav-profile-img img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}

.nav-profile-img .availability-status {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	border: 2px solid #fff;
	bottom: 5px;
	right: -5px;
}

.nav-profile-img .availability-status.online {
	background: #1bcfb4;
}

.nav-profile-img .availability-status.offline {
	background: #fe7c96;
}

.nav-profile-img .availability-status.busy {
	background: #fed713;
}

.nav-profile-text {
	margin-left: 1.25rem;
}

.rtl.nav-profile-text {
	margin-left: 0;
	margin-right: 1.25rem;
}

@media (max-width: 767px) {
	.nav-profile-text {
		display: none;
	}
}

.dropdown-toggle:after {
	line-height: 2;
}

@media (min-width: 992px) {
	.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: auto;
	}

	.rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
		margin-left: 0;
		margin-right: auto;
	}
}

@media (max-width: 991px) {
	.navbar {
		flex-direction: row;
	}

	.navbar .navbar-brand-wrapper {
		width: 75px;
	}

	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
		display: none;
	}

	.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
		display: inline-block;
	}

	.navbar-collapse {
		display: flex;
		margin-top: 0.5rem;
	}
}

@media (max-width: 480px) {
	.navbar .navbar-brand-wrapper {
		width: 55px;
	}

	.navbar .navbar-brand-wrapper .brand-logo-mini {
		padding-top: 0px;
	}
}

.navbar.navbar-primary .navbar-menu-wrapper {
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #fff;
}

.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-primary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #fff;
}

.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-secondary
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-success .navbar-menu-wrapper {
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #fff;
}

.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-success
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-info .navbar-menu-wrapper {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #fff;
}

.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-info
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-warning .navbar-menu-wrapper {
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #fff;
}

.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-warning
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-danger .navbar-menu-wrapper {
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #fff;
}

.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-danger
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-light .navbar-menu-wrapper {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #fff;
}

.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-light
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.navbar.navbar-dark .navbar-menu-wrapper {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #fff;
}

.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-ms-input-placeholder {
	color: #fff;
}

.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input:-moz-placeholder {
	color: #fff;
}

.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-moz-placeholder {
	color: #fff;
}

.navbar.navbar-dark
	.navbar-menu-wrapper
	.search-field
	.input-group
	input::-webkit-input-placeholder {
	color: #fff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
	color: #fff;
}

.card-text {
	font-weight: 700;
	font-size: 19px !important;
	line-height: 29px !important;
	color: #000;
}

.pipeline {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
}

.pipeline .card {
	border: 0 !important;
}

.pipeline .card .card-img {
	height: 120px;
}

.pipeline .card:last-child:after {
	display: none;
}

.pipeline .card:after {
	content: "";
	position: absolute;
	top: 50%;
	width: 5px;
	height: 22px;
	left: 101%;
	transform: translate(-50%, -50%);
	z-index: 9;
	border-right: 1px solid #dedede;
	height: 59px;
}

.pipeline .bg-card {
	background: #f8feff;
	padding: 0;
	position: relative;
	border-right: 1px solid #e9e9e9;
	border-radius: 0;
}

.pipeline .bg-card::after {
	display: none;
}

.pipeline .bg-card .card-title {
	color: #0b0b0b;
	font-weight: 800;
}

.pipeline .card-img-overlay {
	padding: 12px 8px;
}

.pipeline .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.card {
	text-align: center;
	background: #fff;
	position: relative;
	padding-top: 12px;
	border-radius: 6px !important;
	/* margin: 0px 14px 0 0; */
	/* width: 180px; */
	overflow: hidden;
}

.card .card-body + .card-body {
	padding-top: 1rem;
}

.card .card-title {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	margin-bottom: 8px;
}

.card .card-subtitle {
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
}

.card .card-description {
	color: #76838f;
	margin-bottom: 1.5rem;
}

.overviewtab .bg-card {
	border: 1 !important;
	background: #f8feff;
	position: relative;
}

.overviewtab .bg-card:after {
	content: "";
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAWCAYAAAAILVbQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABcSURBVHgB5ZCxEYAgFEMTPlg7giM4giNQ2jqZbuAKdLYMBH7Rk8IFbHhN7nJ5TdjPx0BnVxKTAlGTeMvO7gRGFJ6UvJlaVG7DUBHwJZosaSlefFfhdOLRMPzr5Au4NCLPBv4ZOwAAAABJRU5ErkJggg==)
		no-repeat center center;
	position: absolute;
	top: 50%;
	width: 5px;
	height: 22px;
	right: -9%;
	transform: translate(-50%, -50%);
	z-index: 9;
	height: 59px;
}

.overviewtab .card-img-overlay {
	padding: 12px 8px;
}

.overviewtab .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.ordertab .card-img-overlay {
	padding: 12px 8px;
}

.ordertab .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.card-body {
	padding: 0px 4px !important;
	@media (min-width: 1100px) {
		padding: 7px 8px !important;
	}
}

.card-text {
	font-weight: 700;
	font-size: 19px;
	line-height: 29px;
}

.invoicecard .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.card.card-outline-success {
	border: 1px solid theme-color("success");
}

.card.card-outline-primary {
	border: 1px solid theme-color("primary");
}

.card.card-outline-warning {
	border: 1px solid theme-color("warning");
}

.card.card-outline-danger {
	border: 1px solid theme-color("danger");
}

.card.card-rounded {
	border-radius: 5px;
}

.card.card-faded {
	background: #b5b0b2;
	border-color: #b5b0b2;
}

.card.card-circle-progress {
	color: #fff;
	text-align: center;
}

.card.card-img-holder {
	position: relative;
}

.card.card-img-holder .card-img-absolute {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}

.card.bg-blue-gradient {
	background: linear-gradient(to right, #065efd, #3169fd, #6f79fc);
	color: #fff;
}

.card.bg-orange-gradient {
	background: linear-gradient(to right, #ff7f2e, #fe7452);
	color: #fff;
}

.card.bg-green-gradient {
	background: linear-gradient(to right, #24e8a6, #09cdd1);
	color: #fff;
}

.card.card-no-shadow {
	box-shadow: none;
}

.card-inverse-primary {
	background: rgba(182, 109, 255, 0.2);
	border: 1px solid theme-color-level(#b66dff, 1);
	color: theme-color-level(#b66dff, 3);
}

.card-inverse-secondary {
	background: rgba(195, 189, 189, 0.2);
	border: 1px solid theme-color-level(#c3bdbd, 1);
	color: theme-color-level(#c3bdbd, 3);
}

.card-inverse-success {
	background: rgba(27, 207, 180, 0.2);
	border: 1px solid theme-color-level(#1bcfb4, 1);
	color: theme-color-level(#1bcfb4, 3);
}

.card-inverse-info {
	background: rgba(25, 138, 227, 0.2);
	border: 1px solid theme-color-level(#198ae3, 1);
	color: theme-color-level(#198ae3, 3);
}

.card-inverse-warning {
	background: rgba(254, 215, 19, 0.2);
	border: 1px solid theme-color-level(#fed713, 1);
	color: theme-color-level(#fed713, 3);
}

.card-inverse-danger {
	background: rgba(254, 124, 150, 0.2);
	border: 1px solid theme-color-level(#fe7c96, 1);
	color: theme-color-level(#fe7c96, 3);
}

.card-inverse-light {
	background: rgba(248, 249, 250, 0.2);
	border: 1px solid theme-color-level(#f8f9fa, 1);
	color: theme-color-level(#f8f9fa, 3);
}

.card-inverse-dark {
	background: rgba(62, 75, 91, 0.2);
	border: 1px solid theme-color-level(#3e4b5b, 1);
	color: theme-color-level(#3e4b5b, 3);
}

@media (min-width: 576px) {
	.card-columns {
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		grid-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}

	.card-columns .card {
		display: inline-block;
		width: 100%;
		margin-bottom: 0.75rem;
	}
}

@media (min-width: 575px) and (max-width: 767px) {
	.pipeline .card-img-overlay {
		top: -8%;
		transform: translate(-50%, 0%);
		left: 50%;
		right: 0;
		position: absolute;
		width: 100%;
	}
}

@media (max-width: 1025px) {
	.customertab {
		width: 50%;
	}

	.innercustomertab .customertab {
		width: 63%;
	}

	.seacrhbox {
		right: 0;
		left: auto;
	}

	.col-gap-30-sm {
		grid-column-gap: 30px;
		column-gap: 30px;
	}

	.overviewtab .card {
		width: 130px;
		margin-bottom: 14px;
	}

	.overviewtab .bg-card:after {
		right: -5%;
	}

	.card-text {
		font-size: 16px;
		line-height: 29px;
	}
}

@media (min-width: 769px) and (max-width: 1094px) {
	.card .card-title {
		font-size: 11px;
		line-height: 19px;
		margin-bottom: 2px;
	}

	.card-body {
		padding: 7px;
	}

	.card-text {
		font-size: 12px;
		line-height: 24px;
	}
}

@media (min-width: 1026px) and (max-width: 1201px) {
	.customertab {
		width: 50%;
	}

	.seacrhbox {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1026px) {
	.overviewtab .card {
		width: 137px;
		margin-bottom: 14px;
		margin-right: 0.91em;
	}
}

@media (max-width: 768px) {
	.chatdetails {
		font-size: 10px;
		line-height: 16px;
	}

	.overviewtab .bg-card:after {
		display: none;
	}

	.overviewtab .card-img-overlay {
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		right: 0;
		position: absolute;
	}

	.profile-details-list li {
		display: block;
		text-align: center;
	}

	.titletext {
		width: 100%;
	}

	.titlecontent {
		width: 100%;
	}

	.card {
		width: 100%;
	}

	.card .card-title {
		font-size: 10px;
		line-height: 14px;
	}

	.pipeline {
		width: 100%;
	}

	.pipeline .card:after {
		display: none;
	}

	.pipeline .card-img-overlay {
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		right: 0;
		position: absolute;
	}

	.card-text {
		font-size: 14px;
		line-height: 21px;
	}

	.text-right {
		text-align: center !important;
	}
}

@media (max-width: 767px) {
	.overviewtab .card {
		width: 100%;
		margin-bottom: 14px;
	}

	.ordertab .card {
		width: 100%;
		margin-bottom: 14px;
	}

	.staffoverview .card {
		width: 100%;
		margin-bottom: 14px;
	}
}

@media (max-width: 575px) {
	.customertab {
		width: 60%;
	}

	.customertab .nav-item .nav-link {
		font-size: 8px;
		line-height: 13px;
	}

	.seacrhbox {
		right: 0;
		left: auto;
	}
}

.qc-top-space {
	padding-top: 0;
}

.qc-main-pad {
	padding-left: 30px;
	padding-right: 30px;
}

.mobile-edit-canvas.col-sm-9 {
	border-right: 1px solid #c8c8c8;
}

.canvas-tool {
	display: flex;
	align-items: center;
	background-color: #f5f5f5;
	height: 40px;
	border-radius: 4px;
	padding: 0 7px;
	margin-right: 3px;
	box-sizing: border-box;
}

.canvas-tool-list {
	display: flex;
}

.canvas-tool-list li {
	margin: 0 9px;
}

.canvas-tool-list li svg {
	width: 18px;
	height: 18px;
}

.canvas-tool-list li svg:hover rect,
.canvas-tool-list li svg:hover path {
	stroke: #125ecb;
	transition: all 0.3s ease;
}

.canvas-approve ul {
	display: flex;
	align-items: center;
}

.canvas-approve li {
	display: flex;
	align-items: center;
	padding: 0 5px;
}

.canvas-approve li a {
	width: 108px;
	height: 38px;
	font-size: 13px;
	line-height: 38px;
	color: #0b0b0b;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	display: block;
	text-align: center;
	transition: all 0.3s ease;
}

.canvas-approve li a span {
	position: relative;
	padding-left: 23px;
}

.canvas-approve li a span:before {
	content: "";
	position: absolute;
	top: 47%;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	background: no-repeat 0 0;
	transform: translateY(-47%);
}

.canvas-approve li a.qc-reject span:before {
	background-image: url(/static/media/cross-icon1.b7fc60ba.svg);
}

.canvas-approve li a.qc-approve span:before {
	background-image: url(/static/media/tick-icon.ffa62cea.svg);
}

.canvas-approve li a:hover {
	border-color: #125ecb;
	color: #125ecb;
}

.qc-canvas-main {
	height: 100vh;
}

.qc-canvas-main-block .qc-canvas-thumb {
	padding-bottom: 20px;
}

.qc-canvas-main-block .qc-canvas-thumb ul {
	display: flex;
	align-items: center;
}

.qc-canvas-main-block .qc-canvas-thumb ul li {
	width: 92px;
	margin-right: 10px;
	font-size: 13px;
}

.qc-canvas-main-block .qc-canvas-thumb ul li span {
	padding-bottom: 2px;
	display: block;
	text-align: center;
}

.qc-canvas-main-block .qc-canvas-thumb ul li img {
	width: 92px;
	max-width: 92px;
	height: 92px;
	max-height: 92px;
	object-fit: cover;
	object-position: top;
	border: 1px solid rgba(0, 0, 0, 0);
}

.qc-canvas-main-block .qc-canvas-thumb ul li img:hover,
.qc-canvas-main-block .qc-canvas-thumb ul li img.active {
	border-color: #125ecb;
	transition: all 0.3s ease;
}

.qc-edit-canvas {
	padding: 20px 0 0;
}

.qc-canvas-big {
	height: 90vh;
	overflow-y: auto;
	overflow-x: clip;
	text-align: center;
	padding: 20px 20px 350px 25px;
	background-color: #e9e9e9;
	border-top: 1px solid #c8c8c8;
}

.qc-canvas-big img {
	max-width: 100%;
}

.qc-pageitem {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	margin-bottom: 20px;
	padding: 0 20px 0 3px;
}

.qc-pageitem .qc-prev-next {
	width: 8px;
	height: 10px;
	display: block;
	background: no-repeat 0 0;
	transition: all 0.3s ease;
}

.qc-pageitem .qc-prev {
	background-image: url(/static/media/prev-icon.28c91bbb.svg);
}

.qc-pageitem .qc-prev:hover {
	background-image: url(/static/media/prev-icon-h.c9884692.svg);
}

.qc-pageitem .qc-next {
	background-image: url(/static/media/next-icon.15ebdd15.svg);
}

.qc-pageitem .qc-next:hover {
	background-image: url(/static/media/next-icon-h.9539727e.svg);
}

.qc-pageitem p {
	font-size: 13px;
	padding: 0 15px 0 13px;
}

.qc-edit-canvas-right-sidebar {
	padding-top: 30px;
}

.qc-edit-canvas-right {
	padding: 20px 20px 100px 3px;
	height: 90vh;
	overflow-y: auto;
}

.qc-edit-canvas-img {
	margin-bottom: 20px;
}

.qc-edit-canvas-img img {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin: 0 auto 20px;
	display: block;
}

.qc-edit-canvas-img p {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}

.qc-edit-canvas-img a {
	color: #125ecb;
	font-size: 13px;
	position: relative;
}

.qc-edit-canvas-img a:after {
	content: "";
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #125ecb;
}

.qc-edit-canvas-img a:hover,
.qc-edit-canvas-img a:hover:after {
	transition: all 0.3s ease;
}

.qc-edit-canvas-img a:hover {
	color: rgba(18, 94, 203, 0.6);
}

.qc-edit-canvas-img a:hover:after {
	background-color: rgba(18, 94, 203, 0.4);
}

.qc-edit-details {
	border-radius: 4px;
	background-color: #f5f5f5;
	padding: 15px 20px 10px;
	margin-bottom: 10px;
}

.qc-edit-canvas-right h4,
.qc-edit-details p {
	font-size: 13px;
}

.qc-edit-details h4 {
	font-weight: 500;
	color: #0b0b0b;
	margin-bottom: 7px;
}

.qc-edit-details p {
	color: #5a616b;
	margin-bottom: 5px;
	position: relative;
	padding-left: 80px;
}

.qc-edit-details p span {
	width: 80px;
	margin-right: 20px;
	position: absolute;
	top: 0;
	left: 0;
}

.qc-comment h4 {
	position: relative;
}

.qc-comment h4 span {
	font-size: 10px;
	font-weight: 400;
	color: #5a616b;
}

.qc-comment p {
	font-size: 13px;
	color: #5a616b;
}

.qc-canvas-top {
	display: flex;
	align-items: center;
	padding-left: 25px;
}

.canvas-tool-main {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-right: 15px;
}

@media only screen and (min-width: 1400px) {
	.qc-canvas-main-block .qc-canvas-thumb ul li {
		margin-right: 15px;
	}

	.canvas-tool {
		margin-left: 3px;
		margin-right: 8px;
	}

	.canvas-approve li {
		padding: 0 8px;
	}
}

@media only screen and (max-width: 1239px) {
	.qc-canvas-top {
		display: block;
		text-align: center;
		padding-bottom: 20px;
	}

	.qc-canvas-main-block .qc-canvas-thumb ul {
		justify-content: center;
	}

	.canvas-tool-main {
		padding-bottom: 5px;
	}

	.canvas-tool {
		margin: 0 5px;
		display: inline-block;
	}

	.canvas-approve {
		margin: 0 5px;
	}

	.canvas-tool-list {
		height: 40px;
		align-items: center;
	}

	.canvas-approve ul,
	.qc-canvas-main-block .qc-canvas-thumb ul {
		justify-content: center;
	}

	.qc-canvas-main-block .qc-canvas-thumb ul li {
		margin: 0 5px;
	}
}

@media only screen and (max-width: 991px) {
	.mobile-edit-canvas,
	.mobile-canvas-info {
		width: 100%;
	}

	.qc-canvas-top {
		padding-left: 20px;
		padding-right: 20px;
	}

	.qc-edit-details p {
		padding-left: 95px;
	}

	.qc-canvas-main {
		height: inherit;
	}

	.qc-canvas-big {
		height: inherit;
		overflow-y: inherit;
		overflow-x: inherit;
		padding-bottom: 20px;
	}

	.qc-edit-canvas-right {
		padding-left: 20px;
		padding-bottom: 20px;
		height: inherit;
		overflow-y: inherit;
	}

	.mobile-edit-canvas,
	.mobile-canvas-info {
		padding-right: 15px !important;
	}

	.qc-canvas-main-block .qc-canvas-thumb {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.qc-main-pad {
		padding-left: 20px;
		padding-right: 20px;
	}

	.canvas-approve {
		display: flex;
		justify-content: center;
	}

	.qc-pageitem {
		position: inherit;
		justify-content: center;
		right: 0;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 639px) {
	.canvas-tool-main {
		display: block;
	}

	.canvas-tool {
		margin: 0 0 15px;
	}

	.canvas-approve {
		margin: 0;
	}
}

@media only screen and (max-width: 479px) {
	.qc-canvas-top {
		padding-left: 15px;
		padding-right: 15px;
	}

	.qc-canvas-big {
		padding-top: 15px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.qc-canvas-main-block .qc-canvas-thumb ul li {
		width: 70px;
	}

	.qc-canvas-main-block .qc-canvas-thumb ul li img {
		width: 70px;
		max-width: 70px;
		height: 70px;
		max-height: 70px;
	}
}

.customer-chart {
	border: 1px solid #e9e9e9;
	margin-bottom: 20px;
	border-radius: 4px;
	padding: 20px 30px 25px;
}

.chart-filter {
	display: flex;
	justify-content: end;
}

.chart-filter label {
	display: flex;
	justify-content: end;
	line-height: 41px;
}

.chart-graph img {
	max-width: 100%;
}

.chart-graph-row {
	display: flex;
	align-items: center;
}

.chart-graph-row .chart-btn a {
	color: #5a616b;
	font-size: 20px;
	padding: 5px;
}

.chart-graph-row .chart-btn a:hover {
	color: #000;
	transition: all 0.3s;
}

.report-edit .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.pr-30 {
	padding-right: 30px;
}

.chart-btn {
	display: flex;
}

@media only screen and (max-width: 991px) {
	.card-text {
		font-size: 16px;
		line-height: 24px;
	}

	.report-edit {
		flex-wrap: wrap;
	}

	.report-edit .card {
		width: 31%;
		margin-bottom: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.pr-30 {
		padding-right: 20px;
	}

	.customer-search .col-2,
	.customer-search .col-4 {
		width: 100%;
	}

	.customer-search .col-2 {
		margin-bottom: 10px;
	}

	.main-panel {
		padding-left: 53px;
	}

	.rightbar {
		padding-left: 11px;
	}

	.section-title h2 {
		font-size: 20px;
		line-height: 28px;
	}

	.customer-chart {
		padding: 10px 15px 15px;
	}

	.chart-filter .col-2 {
		width: 50%;
	}

	.chart-btn {
		display: flex;
	}

	.report-edit .card {
		width: 100%;
	}

	.card-img-overlay {
		position: relative;
		padding: 0;
	}
}

@media only screen and (max-width: 639px) {
	.chart-graph .col-11 {
		width: 80%;
	}

	.chart-graph .col-1.chart-btn {
		width: 20%;
	}
}

@media (max-width: 767px) {
	.card,
	.pipeline {
		width: 100%;
	}

	.section-title {
		text-align: center;
	}

	.invoicecard .card {
		margin-bottom: 14px;
	}
}

.sidebar {
	position: fixed;
	top: 60px;
	right: 0;
	left: 0;
	z-index: 1030;
	min-height: 100vh;
	background: #fff;
	border-right: 1px solid #e9e9e9;
	padding: 0;
	/* width: 15.6%; */
	z-index: 11;
	transition: width 0.25s ease, background 0.25s ease;
	-webkit-transition: width 0.25s ease, background 0.25s ease;
	-moz-transition: width 0.25s ease, background 0.25s ease;
	-ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar--close {
	width: 56px !important;
	position: fixed;
	max-height: calc(100vh - 70px);
	top: 60px;
	bottom: 0;
	overflow: auto;
	right: -260px;
	transition: all 0.25s ease-out;
	min-height: 100vh;
	background: #fff;
	border-right: 1px solid #e9e9e9;
	padding: 0;
	left: 0;
}
.display--none {
	display: none;
}
.sidebar--close.nav .nav-item {
	padding-left: 0;
	padding-right: 0;
}

.sidebar .nav {
	overflow: hidden;
	flex-wrap: nowrap;
	flex-direction: column;
	margin-bottom: 60px;
	/* margin-top: 3.8em; */
	margin-top: 0px !important;
}

.sidebar .nav .nav-item {
	padding: 14px 18px;
	transition-duration: 0.25s;
	transition-property: background;
	-webkit-transition-property: background;
}

.sidebar .nav .nav-item .collapse {
	z-index: 999;
}

.sidebar .nav .nav-item .nav-links {
	display: flex;
	align-items: center;
	white-space: nowrap;
	transition-duration: 0.45s;
	transition-property: color;
	-webkit-transition-property: color;
}

.sidebar .nav .nav-item .nav-links i {
	color: inherit;
}

.menu-icon {
	font-size: 1.125rem;
	line-height: 1;
	margin-left: auto;
	color: rgba(187, 168, 191, 0.9607843137);
}

.menu-icon {
	margin-left: 0;
	margin-right: 12px;
}

:before {
	vertical-align: middle;
}

.menu-arrow {
	line-height: 1;
	font-size: 1.125rem;
	margin-left: auto;
}

.menu-arrow {
	margin-left: 65px;
}

.sidebar .nav .nav-item .nav-links i.menu-arrow:before {
	/* content: "";
	font-size: inherit; */
	color: #5a616b;
}

.sidebar .nav .nav-item .nav-links i.menu-arrow + .menu-icon {
	margin-left: 0.25rem;
}

.menu-arrow + .menu-icon {
	margin-left: 0;
	margin-right: 0.25rem;
}

.sidebar .nav .nav-item .nav-links .menu-title {
	color: inherit;
	width: 36%;
	display: inline-block;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	line-height: 1;
	vertical-align: middle;
}

.sidebar .nav .nav-item .nav-links .badge {
	margin-right: auto;
	margin-left: 1rem;
}

.sidebar .nav .nav-item .nav-links[aria-expanded="true"] .menu-arrow:before {
	content: "\F106";
}

.sidebar .nav .nav-item.active {
	background: #0b0b0b;
}

.sidebar .nav .nav-item.active > .nav-links .menu-title {
	color: #fff;
}

.sidebar .nav .nav-item.active > .nav-links i {
	color: #b66dff;
}

.sidebar .nav .nav-item:hover {
	background: #efefef;
}

.sidebar .nav .nav-item.nav-profile .nav-links {
	height: auto;
	line-height: 1;
	border-top: 0;
	padding: 1.25rem 0;
}

.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image {
	width: 44px;
	height: 44px;
}

.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image img {
	width: 44px;
	height: 44px;
	border-radius: 100%;
}

.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: 1rem;
}

.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: auto;
	margin-right: 1rem;
}

.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	font-size: 1.125rem;
	margin-left: auto;
}

.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	margin-left: 0;
	margin-right: auto;
}

.sidebar .nav .nav-item.sidebar-actions {
	margin-top: 1rem;
}

.sidebar .nav .nav-item.sidebar-actions .nav-links {
	border-top: 0;
	display: block;
	height: auto;
}

.sidebar .nav .nav-item.sidebar-actions:hover {
	background: initial;
}

.sidebar .nav .nav-item.sidebar-actions:hover .nav-links {
	color: initial;
}

.sidebar
	.nav:not(.sub-menu)
	> .nav-item:hover:not(.nav-category):not(.nav-profile)
	> .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
}

.sidebar .nav.sub-menu {
	margin-bottom: 0px;
	/* margin-top: 14px; */
	list-style: none;
}

.sidebar .nav.sub-menu .nav-item {
	padding: 0;
	/* margin-top: 14px; */
}

.sidebar .nav.sub-menu .nav-item .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	padding: 14px 20px;
	position: relative;
	height: auto;
	border-top: 0;
	white-space: normal;
}

.rtl .sidebar .nav.sub-menu .nav-item .nav-links:before {
	left: auto;
	right: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-links.active {
	color: white;
	background: #222 !important;
	background: #222 !important;
	background: #222 !important;
	/* background: rgba(0, 0, 0, 0); */
}

.sidebar .nav.sub-menu .nav-item .nav-links:hover {
	color: #555;
	background: rgba(0, 0, 0, 0);
}

.sidebar .nav.sub-menu .nav-item:hover {
	background: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 991px) {
	.sidebar-offcanvas {
		position: fixed;
		max-height: calc(100vh - 70px);
		top: 60px;
		bottom: 0;
		overflow: auto;
		right: -260px;
		transition: all 0.25s ease-out;
	}

	.sidebar-offcanvas.active {
		right: 0;
	}

	.sidebar {
		width: 54px;
	}

	.sidebar .nav .nav-item {
		padding-left: 0;
		padding-right: 0;
	}

	.sidebar .nav .nav-item .nav-links .menu-title {
		display: none;
	}

	.menu-icon {
		margin: 0 auto !important;
	}

	.menu-arrow {
		margin-left: 1px;
	}

	.main-panel {
		padding-left: 54px !important;
	}
}

.sidefull {
	position: relative;
}

.togler-menu {
	position: fixed;
	bottom: 0;
	left: 148px;
	z-index: 999;
}

@media screen and (max-width: 991px) {
	.togler-menu {
		left: 0;
	}
}

.stafftab {
	margin-bottom: 20px !important;
}

.stafftab .nav-link.active {
	border-bottom: 2px solid #125ecb !important;
	border: 0;
	color: #125ecb !important;
	font-weight: 600;
}

.stafftab .nav-item {
	flex-grow: inherit !important;
	flex-basis: auto !important;
}

.nav-tabs .nav-item.show .nav-link {
	border-bottom: 2px solid #125ecb !important;
	border: 0;
	color: #125ecb;
	font-weight: 600;
}

.new-user-flex i {
	color: #5a616b;
}

.uploadimg {
	background: #fff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	padding: 20px;
	position: relative;
}

.edit {
	top: 6%;
	right: 6%;
	font-size: 21px;
	color: #125ecb;
	display: flex;
	flex-direction: row-reverse;
}

.form-label {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.form-group {
	margin-bottom: 20px;
}

.modal-header {
	background: #f5f5f5;
	border: 0;
	height: 7vh;
}

.modal-title.h4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
	margin-bottom: 0;
}
.modal.show .modal-dialog {
	margin-top: 2.9em;
}

.modal-footer {
	justify-content: space-between;
}

.addfield {
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	color: #125ecb;
	text-align: right;
}

.profile-table tr {
	border-bottom: 1px solid #e9e9e9;
}

.profile-table tr th {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b !important;
}

.profile-table tr td {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.add-team-list {
	margin-bottom: 0;
}

.add-team-list li {
	display: inline-block;
	width: 16%;
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.add-team-list li:nth-child(even) {
	font-weight: 600;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.staffoverview .card {
	width: 130px;
}

@media (max-width: 1025px) {
	.staffoverview .card {
		width: 130px;
		margin-bottom: 14px;
	}

	.staffoverview .bg-card:after {
		right: -9%;
	}
}

@media (max-width: 768px) {
	.tab-content h4 {
		text-align: center;
		font-size: 13px;
	}

	.profilepic {
		width: 24px;
		height: 24px;
	}

	.namelist li span {
		margin-left: 10px;
		font-size: 12px;
	}

	.customernamelist li span {
		margin-left: 10px;
		font-size: 12px;
	}

	.staffoverview .card {
		width: 100%;
	}

	.uploadimg img {
		width: 60%;
	}

	.edit {
		font-size: 13px;
	}

	.headname {
		font-size: 10px;
		line-height: 15px;
	}

	.idname {
		font-size: 10px;
		line-height: 15px;
	}

	.chatmessage {
		position: inherit;
		display: block;
		width: auto;
	}

	.border-left {
		border-right: 1px solid rgba(0, 0, 0, 0);
	}

	.chatmsg {
		width: 100%;
	}

	.medialist {
		margin-top: 20px;
		padding-bottom: 0px;
	}
}

@media (min-width: 769px) and (max-width: 1201px) {
	.chatmsg {
		width: 47%;
	}
}

@media (max-width: 767px) {
	.staffoverview .card {
		width: 100%;
		margin-bottom: 14px;
	}

	.staffoverview .card-img-overlay {
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		right: 0;
		position: absolute;
		width: 100%;
		padding: 12px 8px;
	}
}

@media (max-width: 576px) {
	.online {
		margin: 0 auto !important;
		margin-bottom: 19px !important;
	}

	.online p {
		display: block;
		text-align: center;
	}
}

p {
	color: #5a616b;
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}

	50% {
		opacity: 1;
		transform: none;
	}
}

@keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}

@keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0%;
		mask-position: -200% 0%;
	}
}

@keyframes dropdownAnimation {
	from {
		opacity: 0;
		transform: translate3d(0, -30px, 0);
	}

	to {
		opacity: 1;
		transform: none;
		transform: translate3d(0, 0px, 0);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
}

hr:not([size]) {
	height: 1px;
}

ul {
	padding-left: 0;
}

ul ul {
	margin-bottom: 0;
}

ul ol {
	margin-bottom: 0;
}

ul li {
	line-height: 1.8;
}

li {
	list-style-type: none;
}

h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	text-shadow: none;
}

h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.375rem + 1.5vw);
	text-shadow: none;
	font-size: 2.19rem;
}

.h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.375rem + 1.5vw);
	text-shadow: none;
	font-size: 2.19rem;
	font-size: 2.19rem;
}

h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.325rem + 0.9vw);
	text-shadow: none;
	font-size: 1.88rem;
}

.h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.325rem + 0.9vw);
	text-shadow: none;
	font-size: 1.88rem;
	font-size: 1.88rem;
}

h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.3rem + 0.6vw);
	text-shadow: none;
	font-size: 1.56rem;
}

.h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: calc(1.3rem + 0.6vw);
	text-shadow: none;
	font-size: 1.56rem;
	font-size: 1.56rem;
}

.h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	text-shadow: none;
}

h5 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.25rem;
	text-shadow: none;
	font-size: 1rem;
}

.h5 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.25rem;
	text-shadow: none;
	font-size: 1rem;
	font-size: 1rem;
}

h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1rem;
	font-size: 0.9375rem;
}

.h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1rem;
	font-size: 0.9375rem;
	font-size: 0.9375rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
	text-shadow: none;
	font-size: 0.875rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	line-height: 1.5;
}

abbr[title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

abbr[data-bs-original-title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

address p {
	margin-bottom: 0;
}

ol {
	padding-left: 2rem;
	margin-top: 0;
	margin-bottom: 1rem;
	padding-left: 1rem;
	font-size: 0.875rem;
}

ol ol {
	margin-bottom: 0;
}

ol ul {
	margin-bottom: 0;
}

ol li {
	line-height: 1.8;
}

dl {
	margin-top: 0;
	margin-bottom: 1rem;
	padding-left: 1rem;
	font-size: 0.875rem;
}

dl li {
	line-height: 1.8;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b {
	font-weight: bolder;
}

strong {
	font-weight: bolder;
}

small {
	font-size: 0.875em;
}

.small {
	font-size: 0.875em;
}

mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

sub {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
	bottom: -0.25em;
}

sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
	top: -0.5em;
}

a {
	color: #0d6efd;
	text-decoration: none;
	text-shadow: none;
	box-shadow: none;
	text-decoration: none;
}

a:hover {
	color: #0a58ca;
	text-decoration: none;
	box-shadow: none;
}

a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

a > code {
	color: inherit;
}

a:active {
	outline: 0;
	box-shadow: none;
}

a:focus {
	outline: 0;
	box-shadow: none;
	border-color: rgba(0, 0, 0, 0);
	outline: none;
}

a:visited {
	outline: 0;
	box-shadow: none;
}

a:checked {
	box-shadow: none;
}

pre {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
	background: color(gray-lighter);
	padding: 15px;
	font-size: 14px;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
	padding: 5px;
	color: #fe7c96;
	font-family: "ubuntu-light", sans-serif;
	font-size: 0.875rem;
	border-radius: 4px;
}

kbd {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}

kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
}

svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #6c757d;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

thead {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

tbody {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

tfoot {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

td {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

button {
	border-radius: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	-webkit-appearance: button;
	box-shadow: none;
	outline: 0;
	outline-offset: 0;
}

button:focus {
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
	outline: 0;
	outline-offset: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

button:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner {
	outline: 0;
}

button:active {
	box-shadow: none;
	outline: 0;
	outline-offset: 0;
}

button:hover {
	box-shadow: none;
	outline: 0;
	outline-offset: 0;
}

button:visited {
	box-shadow: none;
	outline: 0;
	outline-offset: 0;
}

button:checked {
	outline: 0;
	outline-offset: 0;
}

input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
	box-shadow: none;
}

input:focus {
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
	box-shadow: none;
	border-color: rgba(0, 0, 0, 0);
	outline: none;
}

input:active {
	box-shadow: none;
}

input:hover {
	box-shadow: none;
}

input:visited {
	box-shadow: none;
}

select {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	word-wrap: normal;
	box-shadow: none;
}

select:disabled {
	opacity: 1;
}

select::-moz-focus-inner {
	outline: 0;
}

select:focus {
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
	box-shadow: none;
}

select:active {
	box-shadow: none;
}

select:visited {
	box-shadow: none;
}

optgroup {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	resize: vertical;
	resize: none;
	overflow-x: hidden;
	box-shadow: none;
}

textarea:focus {
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
	box-shadow: none;
}

textarea:active {
	box-shadow: none;
}

textarea:hover {
	box-shadow: none;
}

textarea:visited {
	box-shadow: none;
}

[role="button"] {
	cursor: pointer;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

[type="button"] {
	-webkit-appearance: button;
}

[type="button"]:not(:disabled) {
	cursor: pointer;
}

[type="reset"] {
	-webkit-appearance: button;
}

[type="reset"]:not(:disabled) {
	cursor: pointer;
}

[type="submit"] {
	-webkit-appearance: button;
}

[type="submit"]:not(:disabled) {
	cursor: pointer;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}

legend + * {
	clear: left;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

output {
	display: inline-block;
}

p {
	font-weight: 400;
	font-size: 13px !important;
	line-height: 19px !important;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}

.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2;
	font-size: 3.75rem;
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2;
	font-size: 3.125rem;
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2;
	font-size: 2.5rem;
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2;
	font-size: 1.875rem;
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2;
	font-size: 1.25rem;
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
	padding: 1.25rem;
	border: 1px solid #ebedf2;
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #6c757d;
}

.blockquote-footer::before {
	content: "\2014\A0";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 0.875em;
	color: #6c757d;
}

.container {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem); */
	/* margin-right: auto;
	margin-left: auto; */
}

.container-fluid {
	width: 100%;
	/* overflow: overlay; */
	/* padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem); */
	/* margin-right: auto;
	margin-left: auto; */
}

.container-sm {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem); */
	margin-right: auto;
	margin-left: auto;
}

.container-md {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.container-lg {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.container-xl {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto; */
}

.container-xxl {
	width: 100%;
	/* padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto; */
}

.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: #ecf1f7;
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #ebedf2;
	margin-bottom: 0 !important;
}

.table > :not(:first-child) {
	border-top: none !important;
}

.table > tbody {
	vertical-align: inherit;
}

.table > thead {
	position: sticky;
	top: 0;
	vertical-align: bottom;
}

.table tr {
	border-bottom: 1px solid #ebedf2;
}

.table tbody :not(:first-child) {
	border-top: none;
}

.caption-top {
	caption-side: top;
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
	color: var(--bs-table-hover-color);
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: thin;
}

/* .form-label {
	margin-bottom: 0.5rem;
} */

.col-form-label {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1;
}

.col-form-label-lg {
	padding-top: calc(0.94rem + 1px);
	padding-bottom: calc(0.94rem + 1px);
	font-size: 1.25rem;
}

.col-form-label-sm {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 0.875rem;
}

.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #6c757d;
}

.form-control {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	font-size: 13px;
	box-shadow: none;
}

.form-control:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	outline: 0;
	box-shadow: none;
	outline: none;
	outline-width: 0;
	outline-color: rgba(0, 0, 0, 0);
	box-shadow: none;
	outline-style: none;
}

.form-select {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	font-size: 13px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	-webkit-appearance: none;
	appearance: none;
}

.form-select:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select:disabled {
	background-color: #e9ecef;
}

.form-select:-moz-focusring {
	color: rgba(0, 0, 0, 0);
	text-shadow: 0 0 0 #212529;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.94rem 0;
	margin-bottom: 0;
	line-height: 1;
	color: #212529;
	background-color: rgba(0, 0, 0, 0);
	border: solid rgba(0, 0, 0, 0);
	border-width: 1px 0;
}

.form-control-plaintext.form-control-sm {
	padding-right: 0;
	padding-left: 0;
}

.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	min-height: 2.575rem;
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	margin-inline-end: 0.81rem;
}

.form-control-sm::-webkit-file-upload-button {
	padding: 0.5rem 0.81rem;
	margin: -0.5rem -0.81rem;
	margin-inline-end: 0.81rem;
}

.form-control-lg {
	min-height: 3.175rem;
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	margin-inline-end: 1.94rem;
}

.form-control-lg::-webkit-file-upload-button {
	padding: 0.94rem 1.94rem;
	margin: -0.94rem -1.94rem;
	margin-inline-end: 1.94rem;
}

textarea.form-control {
	min-height: 2.875rem;
}

textarea.form-control-sm {
	min-height: 2.575rem;
}

textarea.form-control-lg {
	min-height: 3.175rem;
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: 0.94rem;
}

.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer;
}

.form-control-color::-moz-color-swatch {
	height: 1em;
	border-radius: 2px;
}

.form-control-color::-webkit-color-swatch {
	height: 1em;
	border-radius: 2px;
}

.form-select[multiple] {
	padding-right: 0.75rem;
	background-image: none;
}

.form-select[size]:not([size="1"]) {
	padding-right: 0.75rem;
	background-image: none;
}

.form-select-sm {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.form-select-lg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
	position: relative;
	display: block;
	margin-top: 15px;
	margin-bottom: 10px;
	padding-left: 0;
}

.form-check .form-check-input {
	float: left;
	margin-left: -1.5em;
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: 0.25em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, 0.25);
	-webkit-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}

.form-check-input:active {
	filter: brightness(90%);
}

.form-check-input:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
	background-color: #008e1a;
	border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label {
	opacity: 0.5;
}

.form-check-input[type="checkbox"] {
	border-radius: 0.25em;
}

.form-check-input[type="checkbox"]:indeterminate {
	background-color: #0d6efd;
	border-color: #0d6efd;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input[type="radio"] {
	border-radius: 50%;
}

.form-check-input[disabled] ~ .form-check-label {
	opacity: 0.5;
}

.form-switch {
	padding-left: 2.5em;
}

.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	transition: background-position 0.15s ease-in-out;
}

.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
}

.form-check-inline .form-check-input ~ .valid-feedback {
	margin-left: 0.5em;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
	margin-left: 0.5em;
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.btn-check:disabled + .btn {
	pointer-events: none;
	filter: none;
	opacity: 0.65;
}

.btn-check[disabled] + .btn {
	pointer-events: none;
	filter: none;
	opacity: 0.65;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: rgba(0, 0, 0, 0);
	-webkit-appearance: none;
	appearance: none;
}

.form-range:focus {
	outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
	border: 0;
}

.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	appearance: none;
}

.form-range::-webkit-slider-thumb:active {
	background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: rgba(0, 0, 0, 0);
	cursor: pointer;
	background-color: #dee2e6;
	border-color: rgba(0, 0, 0, 0);
	border-radius: 1rem;
}

.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	-moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	appearance: none;
}

.form-range::-moz-range-thumb:active {
	background-color: #b6d4fe;
}

.form-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: rgba(0, 0, 0, 0);
	cursor: pointer;
	background-color: #dee2e6;
	border-color: rgba(0, 0, 0, 0);
	border-radius: 1rem;
}

.form-range:disabled {
	pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.form-floating {
	position: relative;
}

.form-floating > .form-control {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
	padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder {
	color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-select {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-select ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 0.75rem;
	pointer-events: none;
	border: 1px solid rgba(0, 0, 0, 0);
	transform-origin: 0 0;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group > .form-control {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}

.input-group > .form-control:focus {
	z-index: 3;
}

.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}

.input-group > .form-select:focus {
	z-index: 3;
}

.input-group .btn {
	position: relative;
	z-index: 2;
}

.input-group .btn:focus {
	z-index: 3;
}

.input-group .form-control.is-valid {
	z-index: 1;
}

.input-group .form-control.is-valid:focus {
	z-index: 3;
}

.input-group .form-select.is-valid {
	z-index: 1;
}

.input-group .form-select.is-valid:focus {
	z-index: 3;
}

.input-group .form-control.is-invalid {
	z-index: 2;
}

.input-group .form-control.is-invalid:focus {
	z-index: 3;
}

.input-group .form-select.is-invalid {
	z-index: 2;
}

.input-group .form-select.is-invalid:focus {
	z-index: 3;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 2px;
}

.input-group-lg > .form-control {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-lg > .form-select {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
	padding-right: 3rem;
}

.input-group-lg > .input-group-text {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-lg > .btn {
	padding: 0.94rem 1.94rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-sm > .form-control {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.input-group-sm > .form-select {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
	padding-right: 3rem;
}

.input-group-sm > .input-group-text {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.input-group-sm > .btn {
	padding: 0.5rem 0.81rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #198754;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(25, 135, 84, 0.9);
	border-radius: 0.25rem;
}

.was-validated:valid ~ .valid-feedback {
	display: block;
}

.was-validated:valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid {
	border-color: #198754;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control:invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated textarea.form-control:invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid {
	border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]) {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-select:invalid {
	border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]) {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:valid {
	border-color: #198754;
}

.was-validated .form-check-input:valid:checked {
	background-color: #198754;
}

.was-validated .form-check-input:valid:focus {
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label {
	color: #198754;
}

.was-validated .form-check-input:invalid {
	border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked {
	background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label {
	color: #dc3545;
}

.was-validated .input-group .form-control:valid {
	z-index: 1;
}

.was-validated .input-group .form-control:valid:focus {
	z-index: 3;
}

.was-validated .input-group .form-control:invalid {
	z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus {
	z-index: 3;
}

.was-validated .input-group .form-select:valid {
	z-index: 1;
}

.was-validated .input-group .form-select:valid:focus {
	z-index: 3;
}

.was-validated .input-group .form-select:invalid {
	z-index: 2;
}

.was-validated .input-group .form-select:invalid:focus {
	z-index: 3;
}

.was-validated:invalid ~ .invalid-feedback {
	display: block;
}

.was-validated:invalid ~ .invalid-tooltip {
	display: block;
}

.is-valid ~ .valid-feedback {
	display: block;
}

.is-valid ~ .valid-tooltip {
	display: block;
}

.form-control.is-valid {
	border-color: #198754;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.form-select.is-valid {
	border-color: #198754;
}

.form-select.is-valid:not([multiple]):not([size]) {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid {
	border-color: #198754;
}

.form-check-input.is-valid:checked {
	background-color: #198754;
}

.form-check-input.is-valid:focus {
	box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid ~ .form-check-label {
	color: #198754;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #dc3545;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.9);
	border-radius: 0.25rem;
}

.is-invalid ~ .invalid-feedback {
	display: block;
}

.is-invalid ~ .invalid-tooltip {
	display: block;
}

.form-control.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.form-select.is-invalid {
	border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]) {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid {
	border-color: #dc3545;
}

.form-check-input.is-invalid:checked {
	background-color: #dc3545;
}

.form-check-input.is-invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label {
	color: #dc3545;
}

.fade {
	transition: opacity 0.15s linear;
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	transition: width 0.35s ease;
}

.dropup {
	position: relative;
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid rgba(0, 0, 0, 0);
	border-bottom: 0.3em solid;
	border-left: 0.3em solid rgba(0, 0, 0, 0);
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropup .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropup .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9;
}

.dropend {
	position: relative;
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid rgba(0, 0, 0, 0);
	border-right: 0;
	border-bottom: 0.3em solid rgba(0, 0, 0, 0);
	border-left: 0.3em solid;
	vertical-align: 0;
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropdown {
	position: relative;
}

.dropstart {
	position: relative;
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	display: none;
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid rgba(0, 0, 0, 0);
	border-right: 0.3em solid;
	border-bottom: 0.3em solid rgba(0, 0, 0, 0);
	vertical-align: 0;
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
	margin-right: 0;
}

.dropdown-toggle {
	white-space: nowrap;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid rgba(0, 0, 0, 0);
	border-bottom: 0;
	border-left: 0.3em solid rgba(0, 0, 0, 0);
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	border-radius: 0.25rem;
}

.dropdown-menu > li > a:active {
	outline: 0;
}

.dropdown-menu > li > a:focus {
	outline: 0;
}

.dropdown-menu > li > a:hover {
	outline: 0;
}

.dropdown-menu > li > a:visited {
	outline: 0;
}

.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.125rem;
}

.dropdown-menu-start {
	--bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0;
}

.dropdown-menu-end {
	--bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #ebedf2;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #343a40;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	border: 0;
}

.dropdown-item:hover {
	color: #1e2125;
	background-color: #e9ecef;
}

.dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef;
}

.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #0d6efd;
}

.dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
}

.dropdown-item.active {
	color: #fff;
	text-decoration: none;
	background-color: #0d6efd;
}

.dropdown-item.disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #343a40;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #343a40;
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
	color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd;
}

.dropdown-menu-dark .dropdown-item.active {
	color: #fff;
	background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled {
	color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
	color: #adb5bd;
}

.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group > .btn {
	position: relative;
	flex: 1 1 auto;
}

.btn-group > .btn:hover {
	z-index: 1;
}

.btn-group > .btn:focus {
	z-index: 1;
}

.btn-group > .btn:active {
	z-index: 1;
}

.btn-group > .btn:not(:first-child) {
	margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn-check:checked + .btn {
	z-index: 1;
}

.btn-group > .btn-check:focus + .btn {
	z-index: 1;
}

.btn-group > .btn.active {
	z-index: 1;
}

.btn-group > .btn-group:not(:first-child) {
	margin-left: -1px;
}

.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.btn-group-vertical > .btn {
	position: relative;
	flex: 1 1 auto;
	width: 100%;
}

.btn-group-vertical > .btn:hover {
	z-index: 1;
}

.btn-group-vertical > .btn:focus {
	z-index: 1;
}

.btn-group-vertical > .btn:active {
	z-index: 1;
}

.btn-group-vertical > .btn:not(:first-child) {
	margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.btn-group-vertical > .btn-check:checked + .btn {
	z-index: 1;
}

.btn-group-vertical > .btn-check:focus + .btn {
	z-index: 1;
}

.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-group-vertical > .btn-group {
	width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child) {
	margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.btn-toolbar .input-group {
	width: auto;
}

.btn-toolbar .btn-group + .btn-group {
	margin-left: 0.5rem !important;
}

.dropdown-toggle-split {
	padding-right: 1.875rem;
	padding-left: 1.875rem;
}

.dropdown-toggle-split::after {
	margin-left: 0;
}

.btn-sm + .dropdown-toggle-split {
	padding-right: 0.6075rem;
	padding-left: 0.6075rem;
}

.btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.6075rem;
	padding-left: 0.6075rem;
}

.btn-lg + .dropdown-toggle-split {
	padding-right: 2.25rem;
	padding-left: 2.25rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 2.25rem;
	padding-left: 2.25rem;
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #0d6efd;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}

.nav-link:hover {
	color: #0a58ca;
}

.nav-link:focus {
	color: #0a58ca;
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #ebedf2;
}

.nav-tabs .nav-link {
	margin-bottom: -1px;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	background: none;
	border: 1px solid rgba(0, 0, 0, 0);
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover {
	border-color: #ebedf2 #ebedf2 #ebedf2;
	isolation: isolate;
}

.nav-tabs .nav-link:focus {
	border-color: #ebedf2 #ebedf2 #ebedf2;
	isolation: isolate;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link.active {
	color: #343a40;
	background-color: #fff;
	border-color: #ebedf2 #ebedf2 #fff;
}

.nav-tabs .nav-item.show .nav-link {
	color: #343a40;
	background-color: #fff;
	border-color: #ebedf2 #ebedf2 #fff;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	background: none;
	border: 0;
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active {
	color: #fff;
	background-color: #0d6efd;
}

.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #0d6efd;
}

.nav-fill > .nav-link {
	flex: 1 1 auto;
	text-align: center;
}

.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-fill .nav-item .nav-link {
	width: 100%;
}

.nav-justified > .nav-link {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.nav-justified .nav-item .nav-link {
	width: 100%;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar > .container {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-fluid {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-sm {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-md {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-lg {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-xl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar > .container-xxl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item {
	display: flex !important;
	align-items: center !important;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
	animation-name: dropdownAnimation;
	animation-duration: 0.25s;
	animation-fill-mode: both;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
	display: flex !important;
}

.navbar-brand {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav .dropdown-menu {
	position: static;
}

.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 0.25rem;
	transition: box-shadow 0.15s ease-in-out;
}

.navbar-toggler:hover {
	text-decoration: none;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto;
}

.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.navbar-expand .navbar-nav {
	flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
	overflow: visible;
}

.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
}

.navbar-expand .navbar-toggler {
	display: none;
}

.navbar-expand .offcanvas-header {
	display: none;
}

.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	flex-grow: 1;
	visibility: visible !important;
	background-color: rgba(0, 0, 0, 0);
	border-right: 0;
	border-left: 0;
	transition: none;
	transform: none;
}

.navbar-expand .offcanvas-top {
	height: auto;
	border-top: 0;
	border-bottom: 0;
}

.navbar-expand .offcanvas-bottom {
	height: auto;
	border-top: 0;
	border-bottom: 0;
}

.navbar-expand .offcanvas-body {
	display: flex;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}

.navbar-dark .navbar-brand:hover {
	color: #fff;
}

.navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a {
	color: #fff;
}

.navbar-dark .navbar-text a:hover {
	color: #fff;
}

.navbar-dark .navbar-text a:focus {
	color: #fff;
}

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}

.accordion-button:not(.collapsed) {
	color: #0c63e4;
	background-color: #e7f1ff;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}

.accordion-button:hover {
	z-index: 2;
}

.accordion-button:focus {
	z-index: 3;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
	margin-bottom: 0;
}

.accordion-item {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
	border-top: 0;
}

.accordion-item:last-of-type {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-body {
	padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
	border-width: 0;
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
	border-top: 0;
}

.accordion-flush .accordion-item:last-child {
	border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
	border-radius: 0;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.56rem 1.13rem;
	margin-bottom: 1rem;
	font-size: 0.875rem;
	list-style: none;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #ebedf2;
}

.breadcrumb .breadcrumb-item {
	font-size: 0.875rem;
}

.breadcrumb .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
	color: #495057;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	justify-content: end;
}

.page-link {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 19px;
	color: #5a616b;
	font-weight: 500;
	text-decoration: none;
	background-color: #fff;
	border: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 0.375rem 0.75rem;
}

.page-link:hover {
	z-index: 2;
	color: #0a58ca;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 3;
	color: #0a58ca;
	background-color: #e9ecef;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}

.page-item:first-child .page-link {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
	border-radius: 6px;
	/* z-index: 3; */
	color: #fff;
	background: #0b0b0b !important;
	border-radius: 6px;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: 0.75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	border-radius: 0.125rem;
	font-size: 11px;
	font-weight: initial;
	line-height: 1;
	padding: 0.375rem 0.5625rem;
}

.badge:empty {
	display: none;
}

.btn {
	padding: 8px 10px;
	border-radius: 5px;
	font-size: 13px;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.btn:active {
	box-shadow: none;
}

.btn:active:focus {
	outline: 0;
	outline-offset: 0;
}

.btn:focus {
	box-shadow: none;
	outline: 0;
	outline-offset: 0;
}

.btn:hover {
	box-shadow: none;
}

.btn:visited {
	box-shadow: none;
}

.btn:active.focus {
	outline: 0;
	outline-offset: 0;
}

.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 0.25rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3rem;
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

.alert-primary {
	color: #6d4199;
	background-color: #f0e2ff;
	border-color: #e9d3ff;
}

.alert-primary .alert-link {
	color: #57347a;
}

.alert-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
	border-color: #edebeb;
}

.alert-secondary .alert-link {
	color: #3e3d3d;
}

.alert-success {
	color: #0b5348;
	background-color: #d1f5f0;
	border-color: #bbf1e9;
}

.alert-success .alert-link {
	color: #09423a;
}

.alert-info {
	color: #0f5388;
	background-color: #d1e8f9;
	border-color: #badcf7;
}

.alert-info .alert-link {
	color: #0c426d;
}

.alert-warning {
	color: #665608;
	background-color: #fff7d0;
	border-color: #fff3b8;
}

.alert-warning .alert-link {
	color: #524506;
}

.alert-danger {
	color: #984a5a;
	background-color: #ffe5ea;
	border-color: #ffd8e0;
}

.alert-danger .alert-link {
	color: #7a3b48;
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe;
}

.alert-light .alert-link {
	color: #4f5050;
}

.alert-dark {
	color: #252d37;
	background-color: #d8dbde;
	border-color: #c5c9ce;
}

.alert-dark .alert-link {
	color: #1e242c;
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
	border-radius: 3px;
	height: 8px;
}

.progress .progress-bar {
	border-radius: 3px;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #0d6efd;
	transition: width 0.6s ease;
}

.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: 1s linear infinite progress-bar-stripes;
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section;
}

.list-group-numbered > li::before {
	content: counters(section, ".") ". ";
	counter-increment: section;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}

.list-group-item-action:hover {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}

.list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}

.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}

.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}

.list-group-item + .list-group-item {
	border-top-width: 0;
}

.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-item.disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd;
}

.list-group-horizontal {
	flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}

.list-group-flush {
	border-radius: 0;
}

.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-primary {
	color: #6d4199;
	background-color: #f0e2ff;
}

.list-group-item-primary.list-group-item-action:hover {
	color: #6d4199;
	background-color: #d8cbe6;
}

.list-group-item-primary.list-group-item-action:focus {
	color: #6d4199;
	background-color: #d8cbe6;
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #6d4199;
	border-color: #6d4199;
}

.list-group-item-secondary {
	color: #4e4c4c;
	background-color: #f3f2f2;
}

.list-group-item-secondary.list-group-item-action:hover {
	color: #4e4c4c;
	background-color: #dbdada;
}

.list-group-item-secondary.list-group-item-action:focus {
	color: #4e4c4c;
	background-color: #dbdada;
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #4e4c4c;
	border-color: #4e4c4c;
}

.list-group-item-success {
	color: #0b5348;
	background-color: #d1f5f0;
}

.list-group-item-success.list-group-item-action:hover {
	color: #0b5348;
	background-color: #bcddd8;
}

.list-group-item-success.list-group-item-action:focus {
	color: #0b5348;
	background-color: #bcddd8;
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #0b5348;
	border-color: #0b5348;
}

.list-group-item-info {
	color: #0f5388;
	background-color: #d1e8f9;
}

.list-group-item-info.list-group-item-action:hover {
	color: #0f5388;
	background-color: #bcd1e0;
}

.list-group-item-info.list-group-item-action:focus {
	color: #0f5388;
	background-color: #bcd1e0;
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #0f5388;
	border-color: #0f5388;
}

.list-group-item-warning {
	color: #665608;
	background-color: #fff7d0;
}

.list-group-item-warning.list-group-item-action:hover {
	color: #665608;
	background-color: #e6debb;
}

.list-group-item-warning.list-group-item-action:focus {
	color: #665608;
	background-color: #e6debb;
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #665608;
	border-color: #665608;
}

.list-group-item-danger {
	color: #984a5a;
	background-color: #ffe5ea;
}

.list-group-item-danger.list-group-item-action:hover {
	color: #984a5a;
	background-color: #e6ced3;
}

.list-group-item-danger.list-group-item-action:focus {
	color: #984a5a;
	background-color: #e6ced3;
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #984a5a;
	border-color: #984a5a;
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover {
	color: #636464;
	background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #636464;
	border-color: #636464;
}

.list-group-item-dark {
	color: #252d37;
	background-color: #d8dbde;
}

.list-group-item-dark.list-group-item-action:hover {
	color: #252d37;
	background-color: #c2c5c8;
}

.list-group-item-dark.list-group-item-action:focus {
	color: #252d37;
	background-color: #c2c5c8;
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #252d37;
	border-color: #252d37;
}

.btn-close-white {
	filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.toast:not(.show) {
	display: none;
}

.toast.showing {
	opacity: 0;
}

.toast-container {
	width: max-content;
	max-width: 100%;
	pointer-events: none;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
	margin-right: -0.375rem;
	margin-left: 0.75rem;
}

.toast-body {
	padding: 0.75rem;
	word-wrap: break-word;
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.75rem;
	word-wrap: break-word;
	opacity: 0;
}

.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: rgba(0, 0, 0, 0);
	border-style: solid;
}

.tooltip.show {
	opacity: 0.9;
}

.bs-tooltip-top {
	padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow {
	bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
	bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-end {
	padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom {
	padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow {
	top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
	top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-start {
	padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.4rem 0.75rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.375rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}

.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
}

.popover .popover-arrow::before {
	position: absolute;
	display: block;
	content: "";
	border-color: rgba(0, 0, 0, 0);
	border-style: solid;
}

.popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: rgba(0, 0, 0, 0);
	border-style: solid;
}

.bs-popover-top > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-end > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-end > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow {
	top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
	top: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-start > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 1rem 1rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel.pointer-event {
	touch-action: pan-y;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-inner::after {
	display: block;
	clear: both;
	content: "";
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}

.carousel-item.active {
	display: block;
}

.carousel-item-next {
	display: block;
}

.carousel-item-next:not(.carousel-item-start) {
	transform: translateX(100%);
}

.carousel-item-prev {
	display: block;
}

.carousel-item-prev:not(.carousel-item-end) {
	transform: translateX(-100%);
}

.active.carousel-item-end {
	transform: translateX(100%);
}

.active.carousel-item-start {
	transform: translateX(-100%);
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none;
}

.carousel-fade .carousel-item.active {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-start {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .active.carousel-item-start {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s 0.6s;
}

.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s 0.6s;
}

.carousel-control-prev {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	left: 0;
}

.carousel-control-prev:hover {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	right: 0;
}

.carousel-control-next:hover {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid rgba(0, 0, 0, 0);
	border-bottom: 10px solid rgba(0, 0, 0, 0);
	opacity: 0.5;
	transition: opacity 0.6s ease;
}

.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center;
}

.carousel-dark .carousel-control-prev-icon {
	filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-control-next-icon {
	filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000;
}

.carousel-dark .carousel-caption {
	color: #000;
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: rgba(0, 0, 0, 0);
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1045;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	transition: transform 0.3s ease-in-out;
}

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}

.offcanvas-backdrop.fade {
	opacity: 0;
}

.offcanvas-backdrop.show {
	opacity: 0.5;
}

.offcanvas-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin-top: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.offcanvas-body {
	flex-grow: 1;
	padding: 1rem 1rem;
	overflow-y: auto;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(100%);
}

.offcanvas.show {
	transform: none;
}

.placeholder {
	display: inline-block;
	min-height: 1em;
	vertical-align: middle;
	cursor: wait;
	background-color: currentColor;
	opacity: 0.5;
}

.placeholder.btn::before {
	display: inline-block;
	content: "";
}

.placeholder-xs {
	min-height: 0.6em;
}

.placeholder-sm {
	min-height: 0.8em;
}

.placeholder-lg {
	min-height: 1.2em;
}

.placeholder-glow .placeholder {
	animation: placeholder-glow 2s ease-in-out infinite;
}

.placeholder-wave {
	-webkit-mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	mask-image: linear-gradient(
		130deg,
		#000000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000000 95%
	);
	-webkit-mask-size: 200% 100%;
	mask-size: 200% 100%;
	animation: placeholder-wave 2s linear infinite;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.link-primary {
	color: #b66dff;
}

.link-primary:hover {
	color: #c58aff;
}

.link-primary:focus {
	color: #c58aff;
}

.link-secondary {
	color: #c3bdbd;
}

.link-secondary:hover {
	color: #cfcaca;
}

.link-secondary:focus {
	color: #cfcaca;
}

.link-success {
	color: #1bcfb4;
}

.link-success:hover {
	color: #49d9c3;
}

.link-success:focus {
	color: #49d9c3;
}

.link-info {
	color: #198ae3;
}

.link-info:hover {
	color: #47a1e9;
}

.link-info:focus {
	color: #47a1e9;
}

.link-warning {
	color: #fed713;
}

.link-warning:hover {
	color: #fedf42;
}

.link-warning:focus {
	color: #fedf42;
}

.link-danger {
	color: #fe7c96;
}

.link-danger:hover {
	color: #fe96ab;
}

.link-danger:focus {
	color: #fe96ab;
}

.link-light {
	color: #f8f9fa;
}

.link-light:hover {
	color: #f9fafb;
}

.link-light:focus {
	color: #f9fafb;
}

.link-dark {
	color: #3e4b5b;
}

.link-dark:hover {
	color: #323c49;
}

.link-dark:focus {
	color: #323c49;
}

.ratio {
	position: relative;
	width: 100%;
}

.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: "";
}

.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%;
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020;
}

.hstack {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
}

.vstack {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch;
}

.visually-hidden {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.vr {
	display: inline-block;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: 0.25;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.float-start {
	float: left !important;
}

.float-end {
	float: right !important;
}

.float-none {
	float: none !important;
}

.opacity-0 {
	opacity: 0 !important;
}

.opacity-25 {
	opacity: 0.25 !important;
}

.opacity-50 {
	opacity: 0.5 !important;
}

.opacity-75 {
	opacity: 0.75 !important;
}

.opacity-100 {
	opacity: 1 !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.page-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	margin: 0 0 1.5rem 0;
}

.page-header .breadcrumb {
	border: 0;
	margin-bottom: 0;
}

.loader-demo-box {
	display: flex !important;
	border: 1px solid #ebedf2 !important;
	border-color: #c3bdbd !important;
	align-items: center !important;
	border-radius: 0.25rem !important;
	width: 100%;
	height: 200px;
}

.list-wrapper {
	height: 100%;
	max-height: 100%;
}

.list-wrapper ul {
	padding: 0;
	text-align: left;
	list-style: none;
	margin-bottom: 0;
}

.list-wrapper ul li {
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
	font-size: 0.9375rem;
	padding: 0.4rem 0;
	border-bottom: 1px solid #ebedf2;
}

.list-wrapper ul li .form-check {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
	max-width: 90%;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.list-wrapper ul li .form-check .form-check-label {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
}

.list-wrapper ul li:first-child {
	border-bottom: none;
}

.list-wrapper .remove {
	margin-left: auto !important;
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
	cursor: pointer;
	font-size: 1.438rem;
	font-weight: 600;
	width: 1.25rem;
	height: 1.25rem;
	line-height: 20px;
	text-align: center;
}

.list-wrapper .completed {
	text-decoration: line-through;
	-webkit-text-decoration-color: #198ae3;
	text-decoration-color: #198ae3;
}

.list-wrapper .completed .remove {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.list-wrapper input[type="checkbox"] {
	margin-right: 15px;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}

.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}

.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}

.translate-middle {
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}

.border {
	border: 1px solid #ebedf2 !important;
}

.border-0 {
	border: 0 !important;
	border: 0;
}

.border-top {
	border-top: 1px solid #ebedf2 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end {
	border-right: 1px solid #ebedf2 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #ebedf2 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start {
	border-left: 1px solid #ebedf2 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #b66dff !important;
}

.border-secondary {
	border-color: #c3bdbd !important;
}

.border-success {
	border-color: #1bcfb4 !important;
}

.border-info {
	border-color: #198ae3 !important;
}

.border-warning {
	border-color: #fed713 !important;
}

.border-danger {
	border-color: #fe7c96 !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #3e4b5b !important;
}

.border-white {
	border-color: #fff !important;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.vw-100 {
	width: 100vw !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mh-100 {
	height: 68vh !important;
}

/* .vh-100 {
	height: 100vh !important;
} */

.min-vh-100 {
	min-height: 100vh !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.gap-0 {
	grid-gap: 0 !important;
	gap: 0 !important;
}

.gap-1 {
	grid-gap: 0.25rem !important;
	gap: 0.25rem !important;
}

.gap-2 {
	grid-gap: 0.5rem !important;
	gap: 0.5rem !important;
}

.gap-3 {
	grid-gap: 1rem !important;
	gap: 1rem !important;
}

.gap-4 {
	grid-gap: 1.5rem !important;
	gap: 1.5rem !important;
}

.gap-5 {
	grid-gap: 3rem !important;
	gap: 3rem !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.demo-modal {
	position: static;
	display: block;
}

.demo-modal .modal-dialog {
	margin-top: 0 !important;
}

.demo-modal .modal-dialog.modal-lg {
	max-width: 100%;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.template-demo > .btn-group {
	margin-top: 0.5rem !important;
	margin-right: 0.5rem !important;
}

.template-demo > .btn-group-vertical {
	margin-top: 0.5rem !important;
	margin-right: 0.5rem !important;
}

.template-demo .circle-progress {
	margin-top: 0.5rem !important;
	padding: 15px;
}

.template-demo > .btn {
	margin-top: 1rem !important;
	margin-right: 1rem !important;
}

.template-demo > .btn-toolbar {
	margin-top: 1rem !important;
	margin-right: 1rem !important;
}

.template-demo > .dropdown {
	margin-right: 0.5rem !important;
	display: inline-block;
	margin-bottom: 0.5rem;
}

.template-demo .circle-progress-block {
	margin-bottom: 1rem !important;
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.template-demo .slider-wrap {
	height: 100px;
}

.template-demo .progress {
	margin-top: 1.5rem;
}

.template-demo > h2 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h2 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > h3 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h3 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > h4 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h4 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > h5 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h5 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > h6 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h6 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > h1 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo > .h1 {
	border-top: 1px solid #ebedf2;
	padding: 0.5rem 0 0;
}

.template-demo .ul-slider.noUi-horizontal {
	margin-top: 2rem;
}

.template-demo .ul-slider.noUi-vertical {
	margin-right: 2rem;
}

.template-demo nav .breadcrumb {
	margin-bottom: 1.375rem;
}

.template-demo nav:last-child .breadcrumb {
	margin-bottom: 0;
}

.template-demo .editable-form > .form-group {
	border-bottom: 1px solid #ebedf2;
	padding-bottom: 0.8rem;
	margin-bottom: 0.8rem;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0 {
	margin-right: 0 !important;
}

.rtl {
	direction: rtl;
	text-align: right;
}

.rtl
	.navbar
	.navbar-menu-wrapper
	.navbar-nav
	.nav-item.dropdown
	.dropdown-menu.navbar-dropdown
	.dropdown-item
	i {
	margin-right: 0 !important;
}

.rtl .navbar .navbar-menu-wrapper .search-field {
	margin-right: 1.5rem !important;
	margin-left: 0 !important;
}

.rtl .list-wrapper .remove {
	margin-right: auto !important;
	margin-left: 0 !important;
}

.rtl .preview-list .preview-item .preview-thumbnail .preview-icon i {
	margin-left: 0 !important;
}

.rtl .preview-list .preview-item .preview-item-content {
	padding-left: 0;
	padding-right: 1rem;
	margin-right: 0;
	margin-left: auto;
}

.rtl .rounded-legend ul li .legend-dots {
	margin-left: 0.5rem;
}

.rtl .rounded-legend.legend-horizontal ul li {
	margin-right: auto;
	margin-left: 1.5rem;
}

.rtl .rounded-legend.legend-top-right ul {
	float: left;
}

.rtl .card-revenue-table .revenue-item .revenue-amount p {
	text-align: left;
}

.rtl .gradient-bullet-list {
	padding-right: 0;
}

.rtl .bullet-line-list {
	padding-right: 0px;
}

.rtl .add-items .btn {
	margin-left: auto;
	margin-right: 0.5rem;
}

.rtl .sidebar .nav {
	padding-right: 0;
}

.rtl .product-chart-wrapper::-webkit-scrollbar {
	width: 0.5em;
}

.rtl .product-chart-wrapper::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rtl .product-chart-wrapper::-webkit-scrollbar-thumb {
	background-color: #a9a9a9;
	outline: 1px solid #708090;
}

.rtl .sidebar-fixed .nav::-webkit-scrollbar {
	width: 0.5em;
}

.rtl .sidebar-fixed .nav::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb {
	background-color: #a9a9a9;
	outline: 1px solid #708090;
}

.rtl .table-responsive::-webkit-scrollbar {
	width: 0.5em;
}

.rtl .table-responsive::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rtl .table-responsive::-webkit-scrollbar-thumb {
	background-color: #a9a9a9;
	outline: 1px solid #708090;
}

.rtl ul.chats::-webkit-scrollbar {
	width: 0.5em;
}

.rtl ul.chats::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rtl ul.chats::-webkit-scrollbar-thumb {
	background-color: #a9a9a9;
	outline: 1px solid #708090;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0 {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.card-revenue-table .revenue-item {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
	border-bottom: 1px solid #ebedf2;
}

.card-revenue-table .revenue-item:first-child {
	padding-top: 0 !important;
}

.card-revenue-table .revenue-item:last-child {
	padding-bottom: 0 !important;
	border-bottom: 0;
}

.card-revenue-table .revenue-item .revenue-desc {
	margin-right: auto;
	width: 80%;
}

.card-revenue-table .revenue-item .revenue-desc p {
	margin-bottom: 0;
}

.card-revenue-table .revenue-item .revenue-amount {
	margin-left: auto;
	width: 40%;
}

.card-revenue-table .revenue-item .revenue-amount p {
	font-size: 1.25rem;
	font-family: "roboto", sans-serif;
	font-weight: 600;
	text-align: right;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
	font-size: 1.25rem !important;
}

.fs-6 {
	font-size: 1rem !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

.text-primary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
	color: #000;
}

.text-white {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-secondary .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
	--bs-text-opacity: 1;
	color: #9c9fa6 !important;
}

.preview-list .preview-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0.75rem 1.5rem;
	font-size: 0.875rem;
}

.preview-list .preview-item .preview-item-content {
	line-height: 1;
	padding-left: 15px;
}

.preview-list .preview-item .preview-item-content p {
	margin-bottom: 10px;
}

.preview-list .preview-item .preview-item-content p .content-category {
	--bs-text-opacity: 1;
	color: #9c9fa6 !important;
	font-family: "source-sans-pro-semibold", sans-serif;
	padding-right: 15px;
	border-right: 1px solid #ebedf2;
}

.preview-list .preview-item .preview-item-content:first-child {
	padding-left: 0;
}

.preview-list .preview-item .preview-thumbnail {
	color: color(white);
	position: relative;
}

.preview-list .preview-item .preview-thumbnail .badge.badge-online {
	border: 1px solid #1bcfb4;
	background: #1bcfb4;
	color: #fff;
}

.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
	border: 1px solid #198ae3;
	background: #198ae3;
	color: #fff;
}

.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
	border: 1px solid #fed713;
	background: #fed713;
	color: #fff;
}

.preview-list .preview-item .preview-thumbnail img {
	width: 36px;
	height: 36px;
	border-radius: 100%;
}

.preview-list .preview-item .preview-thumbnail .preview-icon {
	width: 36px;
	height: 36px;
	border-radius: 100%;
	padding: 6px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.preview-list .preview-item .preview-thumbnail .preview-icon i {
	font-size: 1.125rem;
	margin: 0;
}

.preview-list .preview-item .preview-thumbnail .badge {
	border: 2px solid color(white);
	border-radius: 100%;
	bottom: 5px;
	display: block;
	height: 14px;
	left: -5px;
	padding: 0;
	position: absolute;
	width: 14px;
}

.preview-list .preview-item:last-child {
	border-bottom: 0;
}

.preview-list .preview-item:hover {
	background: #e9ecef;
}

.preview-list .preview-item .form-check {
	margin-top: 8px;
	margin-right: 1rem;
}

.preview-list .preview-item .preview-actions {
	display: flex;
	flex-direction: row;
}

.preview-list .preview-item .preview-actions i {
	width: 29px;
	color: color(gray-lightest);
	height: 29px;
	border: 2px solid color(gray-lightest);
	border-radius: 100%;
	padding: 3px 6px;
	display: inline-block;
}

.preview-list .preview-item .preview-actions i:first-child {
	margin-right: 10px;
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important;
}

.text-opacity-25 {
	--bs-text-opacity: 0.25;
}

.text-opacity-50 {
	--bs-text-opacity: 0.5;
}

.text-opacity-75 {
	--bs-text-opacity: 0.75;
}

.text-opacity-100 {
	--bs-text-opacity: 1;
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-primary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-secondary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-success-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-warning-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-body-bg-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: rgba(0, 0, 0, 0) !important;
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
	--bs-bg-opacity: 1;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.user-select-all {
	-webkit-user-select: all !important;
	user-select: all !important;
}

.user-select-auto {
	-webkit-user-select: auto !important;
	user-select: auto !important;
}

.user-select-none {
	-webkit-user-select: none !important;
	user-select: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.rounded {
	border-radius: 0.25rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.rounded-1 {
	border-radius: 0.2rem !important;
}

.rounded-2 {
	border-radius: 0.25rem !important;
}

.rounded-3 {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}

.rounded-end {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
	border-bottom-left-radius: 0.25rem !important;
	border-top-left-radius: 0.25rem !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

.dropdownAnimation {
	animation-name: dropdownAnimation;
	animation-duration: 0.25s;
	animation-fill-mode: both;
}

.fadeOut {
	animation-name: fadeOut;
}

.infinite-spin {
	animation-name: spin;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.fadeInUp {
	animation-name: fadeInUp;
}

body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	box-shadow: none;
	font-size: 16px; /* previously it was 1rem */
	font-family: "Heebo", sans-serif;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	padding-right: 0 !important;
}

div {
	text-shadow: none;
	box-shadow: none;
}

span {
	text-shadow: none;
}

[type="button"]:focus {
	outline: 0;
}

input[type="reset"]::-moz-focus-inner {
	outline: 0;
}

input[type="button"]::-moz-focus-inner {
	outline: 0;
}

input[type="submit"]::-moz-focus-inner {
	outline: 0;
}

input[type="file"] > input[type="button"]::-moz-focus-inner {
	outline: 0;
}

.btn-group.open .dropdown-toggle {
	box-shadow: none;
}

.btn.active.focus {
	outline: 0;
	outline-offset: 0;
}

.btn.active:focus {
	outline: 0;
	outline-offset: 0;
}

.btn.focus {
	outline: 0;
	outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
	outline: 0;
	outline-offset: 0;
}

.text-twitter {
	color: #2caae1;
}

.text-facebook {
	color: #3b579d;
}

.text-google {
	color: #dc4a38;
}

.text-linkedin {
	color: #0177b5;
}

.text-pinterest {
	color: #cc2127;
}

.text-youtube {
	color: #e52d27;
}

.text-github {
	color: #333;
}

.text-behance {
	color: #1769ff;
}

.text-dribbble {
	color: #ea4c89;
}

.text-reddit {
	color: #ff4500;
}

.bg-twitter {
	background: #2caae1;
}

.bg-facebook {
	background: #3b579d;
}

.bg-google {
	background: #dc4a38;
}

.bg-linkedin {
	background: #0177b5;
}

.bg-pinterest {
	background: #cc2127;
}

.bg-youtube {
	background: #e52d27;
}

.bg-github {
	background: #333;
}

.bg-behance {
	background: #1769ff;
}

.bg-dribbble {
	background: #ea4c89;
}

.bg-reddit {
	background: #ff4500;
}

.blockquote-primary {
	border-color: #b66dff;
}

.blockquote-primary .blockquote-footer {
	color: #b66dff;
}

.blockquote-secondary {
	border-color: #c3bdbd;
}

.blockquote-secondary .blockquote-footer {
	color: #c3bdbd;
}

.blockquote-success {
	border-color: #1bcfb4;
}

.blockquote-success .blockquote-footer {
	color: #1bcfb4;
}

.blockquote-info {
	border-color: #198ae3;
}

.blockquote-info .blockquote-footer {
	color: #198ae3;
}

.blockquote-warning {
	border-color: #fed713;
}

.blockquote-warning .blockquote-footer {
	color: #fed713;
}

.blockquote-danger {
	border-color: #fe7c96;
}

.blockquote-danger .blockquote-footer {
	color: #fe7c96;
}

.blockquote-light {
	border-color: #f8f9fa;
}

.blockquote-light .blockquote-footer {
	color: #f8f9fa;
}

.blockquote-dark {
	border-color: #3e4b5b;
}

.blockquote-dark .blockquote-footer {
	color: #3e4b5b;
}

.error-page h1 {
	font-size: 12rem;
}

.error-page .h1 {
	font-size: 12rem;
}

.icon-lg {
	font-size: 2.5rem;
}

.icon-md {
	font-size: 1.875rem;
}

.icon-sm {
	font-size: 1rem;
}

html {
	overflow-x: hidden;
	padding-right: 0 !important;
}

*:-webkit-full-screen {
	overflow: auto;
}

*:fullscreen *:-ms-fullscreen {
	overflow: auto;
}

.container-scroller {
	overflow: hidden;
}

.page-title {
	color: #343a40;
	font-size: 1.125rem;
	margin-bottom: 0;
}

.page-title .page-title-icon {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 4px;
	text-align: center;
	box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
}

.page-title .page-title-icon i {
	font-size: 0.9375rem;
	line-height: 36px;
}

.footer {
	background: #f2edf3;
	color: color(dark);
	border-top: 1px solid #e7dee9;
	padding: 30px 1rem;
	transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	font-size: 0.825rem;
	font-family: "ubuntu-regular", sans-serif;
}

.footer a {
	color: #1bcfb4;
	font-size: inherit;
}

.grid-margin {
	margin-bottom: 2.5rem;
}

/* .btn:hover {
	background-color: #d9d9d9 !important;
} */

.purchase-popup {
	margin-bottom: 2.5rem;
	background: #fff;
	padding: 15px 20px;
	border-radius: 3px;
}

.purchase-popup .btn {
	margin-right: 20px;
	font-weight: 500;
	color: #fff;
	border-radius: 5px;
	transition-duration: 0.2s;
}

.purchase-popup .btn.download-button {
	background: rgba(249, 249, 249, 0.7);
	color: #969292;
	border: 1px solid #d7d7d7;
}

.purchase-popup .btn.purchase-button {
	background-color: rgba(172, 50, 228, 0.9);
	background-image: -owg-linear-gradient(to right, #da8cff, #9a55ff);
	background-image: linear-gradient(to right, #da8cff, #9a55ff);
	color: #fff;
	border: none;
	line-height: 1;
	vertical-align: middle;
}

.purchase-popup p {
	margin-bottom: auto;
	margin-top: auto;
	color: #7e7e7e;
	font-weight: 400;
	vertical-align: middle;
	line-height: 1;
}

.purchase-popup i {
	vertical-align: middle;
	line-height: 1;
	margin: auto 0;
	color: #b1b1b1;
}

.img-lg {
	width: 92px;
	height: 92px;
}

.img-sm {
	width: 43px;
	height: 43px;
}

.img-xs {
	width: 37px;
	height: 37px;
}

.img-ss {
	width: 35px;
	height: 35px;
}

.image-grouped {
	display: flex;
}

.image-grouped .text-avatar {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	margin-left: -10px;
	z-index: 0;
	border: 4px solid #fff;
	transform: scale(1);
	transition-duration: 0.3s;
	transition-timing-function: ease;
	transition-property: "box-shadow", "z-index", "transform", "border-width",
		"box-shadow", "transform";
	transition-property: "box-shadow", "z-index", "transform", "border-width";
	color: #b66dff;
	font-size: 11px;
	font-weight: 600;
	background: #f6edff;
	border-color: #b66dff;
}

.image-grouped .text-avatar:first-child {
	margin-left: 0;
}

.image-grouped .text-avatar:hover {
	z-index: 1;
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
	transform: scale(1.05);
	border-width: 0;
}

.image-grouped .text-avatar .breadcrumb-item {
	color: #b66dff;
}

.image-grouped .text-avatar .breadcrumb-item:before {
	color: inherit;
}

.image-grouped .text-avatar .breadcrumb-item a {
	color: inherit;
}

.image-grouped img {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	margin-left: -10px;
	z-index: 0;
	border: 4px solid #fff;
	transform: scale(1);
	transition-duration: 0.3s;
	transition-timing-function: ease;
	transition-property: "box-shadow", "z-index", "transform", "border-width",
		"box-shadow", "transform";
	transition-property: "box-shadow", "z-index", "transform", "border-width";
}

.image-grouped img:first-child {
	margin-left: 0;
}

.image-grouped img:hover {
	z-index: 1;
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
	transform: scale(1.05);
	border-width: 0;
}

.image-grouped .breadcrumb-custom.text-avatar {
	background: rgba(0, 0, 0, 0);
}

.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
	background: #f6edff;
}

.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
	color: #b66dff;
	border: none;
}

.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
	border-left-color: #f6edff;
}

.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
	color: #b66dff;
}

.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.stretch-card {
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}

.stretch-card > .card {
	width: 100%;
	min-width: 100%;
}

.text-gray {
	color: #8c8c8c;
}

.card .card-subtitle {
	color: #8c8c8c;
}

.text-small {
	font-size: 12px;
}

.flex-grow {
	flex-grow: 1;
}

.font-weight-light {
	font-family: "ubuntu-light", sans-serif;
}

.font-weight-bold {
	font-family: "ubuntu-bold", sans-serif;
}

.font-weight-normal {
	font-family: "ubuntu-regular", sans-serif;
}

.aligner-wrapper {
	position: relative;
}

.aligner-wrapper .absolute {
	position: absolute;
}

.aligner-wrapper .absolute.absolute-center {
	top: 50%;
	transform: translateY(-50%);
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
}

.aligner-wrapper .absolute.left {
	left: 0;
}

.aligner-wrapper .absolute.right {
	right: 0;
}

.aligner-wrapper .absolute.bottom {
	bottom: 0;
}

.aligner-wrapper .absolute.top {
	top: 0;
}

.v-strock-1 {
	width: 2px;
}

.v-strock-2 {
	width: 3px;
}

.v-strock-3 {
	width: 4px;
}

.v-strock-4 {
	width: 5px;
}

.dot-indicator {
	width: 10px;
	height: 10px;
	border-radius: 100%;
}

.dot-indicator.dot-indicator-sm {
	width: 6px;
	height: 6px;
}

.bg-gradient-primary {
	background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.bg-gradient-secondary {
	background: linear-gradient(to right, #e7ebf0, #868e96) !important;
}

.bg-gradient-success {
	background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}

.bg-gradient-info {
	background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}

.bg-gradient-warning {
	background: linear-gradient(to right, #f6e384, #ffd500) !important;
}

.bg-gradient-danger {
	background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

.bg-gradient-light {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9) !important;
}

.bg-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b) !important;
}

.dropdown-menu-static-demo {
	height: 250px;
	margin-bottom: 20px;
}

.rounded-legend ul li {
	list-style-type: none;
	color: #9c9fa6;
	font-size: 0.75rem;
}

.rounded-legend ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 0.5rem;
}

.rounded-legend.legend-horizontal {
	display: flex;
}

.rounded-legend.legend-horizontal ul li {
	display: inline-block;
	margin-right: 1.5rem;
}

.rounded-legend.legend-top-right ul {
	float: right;
}

.rounded-legend.legend-vertical ul li {
	margin-top: 1rem;
}

#proBanner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #000;
}

#proBanner .card-body-padding {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
}

#proBanner .buy-now-text {
	color: #fff;
}

#proBanner .btn.buy-now-btn {
	background: rgba(0, 0, 0, 0);
	color: #88b6ff;
	border-radius: 10px;
	padding: 1rem 0;
	border-radius: 4px;
	font-weight: bold;
	font-size: 1rem;
}

#proBanner .btn#bannerClose i {
	margin-right: 1rem;
	font-size: 1.25rem;
}

#proBanner a {
	text-decoration: none;
}

#proBanner a i {
	font-size: 1.25rem;
}

.proBanner-padding-top {
	padding-top: 80px !important;
}

.card-statistics .highlight-icon {
	height: 53px;
	width: 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
}

.card-statistics .highlight-icon i {
	font-size: 27px;
}

.card-statistics .card-col {
	border-right: 1px solid #ebedf2;
}

.card-statistics .card-col:last-child {
	border-right: none;
}

.card-revenue {
	background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
	background-size: cover;
	color: color(white);
}

.card-revenue .highlight-text {
	font-size: 1.875rem;
	font-family: "roboto", sans-serif;
	font-weight: 500;
}

.card-revenue .badge {
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 1.125rem;
	padding: 0.5rem 1.25rem;
}

.product-chart-wrapper {
	height: 92%;
}

#dashboardTrendingProgress {
	width: 60px;
}

.dashboard-bar-chart-legend .col {
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.dashboard-bar-chart-legend .col .bg {
	margin-left: auto;
	margin-right: auto;
	height: 5px;
	width: 30px;
	display: block;
	margin-top: 5px;
}

.dashboard-bar-chart-legend .col:nth-child(1) .bg {
	background: #198ae3;
}

.dashboard-bar-chart-legend .col:nth-child(2) .bg {
	background: #b66dff;
}

.dashboard-bar-chart-legend .col:nth-child(3) .bg {
	background: #fe7c96;
}

.intro-banner {
	background: #dbe4ec;
	display: flex;
	align-items: center;
	padding: 20px;
	border-radius: 2px;
}

.intro-banner .banner-image {
	width: 24%;
	max-width: 100%;
}

.intro-banner .banner-image img {
	display: block;
	margin: auto;
}

.intro-banner .content-area {
	width: 58%;
	color: #000;
	display: flex;
	flex-direction: column;
	align-items: end;
}

.sales-mini-tabs {
	border-bottom: none;
}

.sales-mini-tabs .nav-item .nav-link {
	border: none;
	font-weight: 500;
	padding: 5px 15px;
	border-radius: 2px;
	transition-duration: 0.7s;
	transition-property: "color";
}

.sales-mini-tabs .nav-item .nav-link.active {
	font-weight: 600;
	background: #fff;
	color: #b66dff;
}

.badge.badge-pill {
	border-radius: 10rem;
}

.badge-primary {
	border: 1px solid #b66dff;
	background: #b66dff;
	color: #fff;
}

.badge-secondary {
	border: 1px solid #c3bdbd;
	background: #c3bdbd;
	color: #fff;
}

.badge-success {
	border: 1px solid #1bcfb4;
	background: #1bcfb4;
	color: #fff;
}

.badge-info {
	border: 1px solid #198ae3;
	background: #198ae3;
	color: #fff;
}

.badge-warning {
	border: 1px solid #fed713;
	background: #fed713;
	color: #fff;
}

.badge-danger {
	border: 1px solid #fe7c96;
	background: #fe7c96;
	color: #fff;
}

.badge-light {
	border: 1px solid #f8f9fa;
	background: #f8f9fa;
	color: #fff;
}

.badge-dark {
	border: 1px solid #3e4b5b;
	background: #3e4b5b;
	color: #fff;
}

.badge-gradient-primary {
	background: linear-gradient(to right, #da8cff, #9a55ff);
	color: #fff;
}

.badge-gradient-secondary {
	background: linear-gradient(to right, #e7ebf0, #868e96);
	color: #fff;
}

.badge-gradient-success {
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #fff;
}

.badge-gradient-info {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #fff;
}

.badge-gradient-warning {
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #fff;
}

.badge-gradient-danger {
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #fff;
}

.badge-gradient-light {
	background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
	color: #fff;
}

.badge-gradient-dark {
	background: linear-gradient(89deg, #5e7188, #3e4b5b);
	color: #fff;
}

.badge-outline-primary {
	color: #b66dff;
	border: 1px solid #b66dff;
}

.badge-outline-secondary {
	color: #c3bdbd;
	border: 1px solid #c3bdbd;
}

.badge-outline-success {
	color: #1bcfb4;
	border: 1px solid #1bcfb4;
}

.badge-outline-info {
	color: #198ae3;
	border: 1px solid #198ae3;
}

.badge-outline-warning {
	color: #fed713;
	border: 1px solid #fed713;
}

.badge-outline-danger {
	color: #fe7c96;
	border: 1px solid #fe7c96;
}

.badge-outline-light {
	color: #f8f9fa;
	border: 1px solid #f8f9fa;
}

.badge-outline-dark {
	color: #3e4b5b;
	border: 1px solid #3e4b5b;
}

.progress.progress-sm {
	height: 0.375rem;
}

.progress.progress-md {
	height: 8px;
}

.progress.progress-lg {
	height: 15px;
}

.progress.progress-xl {
	height: 18px;
}

.breadcrumb.breadcrumb-custom {
	padding: 0 0;
	border-color: #dbe3e6;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item {
	font-size: 0.875rem;
	background: #dbe3e6;
	padding: 0.56rem 10px;
	color: #000;
	display: inline-block;
	vertical-align: top;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.breadcrumb.breadcrumb-custom .breadcrumb-item:before {
	content: "";
}

.breadcrumb.breadcrumb-custom .breadcrumb-item a {
	position: relative;
	color: inherit;
	border: 1px solid #dbe3e6;
	display: inline-block;
	vertical-align: top;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
	position: absolute;
	top: -9px;
	width: 0;
	height: 0;
	content: "";
	border-top: 21px solid rgba(0, 0, 0, 0);
	border-bottom: 21px solid rgba(0, 0, 0, 0);
	right: -22px;
	z-index: 3;
	border-left-color: #dbe3e6;
	border-left-style: solid;
	border-left-width: 12px;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
	position: absolute;
	top: -9px;
	width: 0;
	height: 0;
	content: "";
	border-top: 21px solid rgba(0, 0, 0, 0);
	border-bottom: 21px solid rgba(0, 0, 0, 0);
	border-top: 21px solid rgba(0, 0, 0, 0);
	border-bottom: 22px solid rgba(0, 0, 0, 0);
	border-left: 12px solid #fff;
	top: -9px;
	right: -23px;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item span {
	display: inline-block;
	vertical-align: top;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item.active {
	color: rgba(52, 58, 64, 0.8);
}

.breadcrumb.bg-success {
	border: none;
}

.breadcrumb.bg-success .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-success .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-success .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-success .breadcrumb-item span {
	color: inherit;
}

.breadcrumb.bg-dark {
	border: none;
}

.breadcrumb.bg-dark .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-dark .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-dark .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-dark .breadcrumb-item span {
	color: inherit;
}

.breadcrumb.bg-danger {
	border: none;
}

.breadcrumb.bg-danger .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-danger .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-danger .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-danger .breadcrumb-item span {
	color: inherit;
}

.breadcrumb.bg-warning {
	border: none;
}

.breadcrumb.bg-warning .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-warning .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-warning .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-warning .breadcrumb-item span {
	color: inherit;
}

.breadcrumb.bg-primary {
	border: none;
}

.breadcrumb.bg-primary .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-primary .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-primary .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-primary .breadcrumb-item span {
	color: inherit;
}

.breadcrumb.bg-info {
	border: none;
}

.breadcrumb.bg-info .breadcrumb-item {
	color: #fff;
}

.breadcrumb.bg-info .breadcrumb-item:before {
	color: inherit;
}

.breadcrumb.bg-info .breadcrumb-item a {
	color: inherit;
}

.breadcrumb.bg-info .breadcrumb-item span {
	color: inherit;
}

.bg-inverse-primary {
	background: #f6edff;
	border-color: #b66dff;
}

.bg-inverse-primary .breadcrumb-item {
	color: #b66dff;
}

.bg-inverse-primary .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-primary .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-primary.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-primary.breadcrumb-custom .breadcrumb-item {
	background: #f6edff;
}

.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a {
	color: #b66dff;
	border: none;
}

.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #f6edff;
}

.bg-inverse-primary.breadcrumb-custom .breadcrumb-item span {
	color: #b66dff;
}

.bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-secondary {
	background: #fff;
	border-color: #c3bdbd;
}

.bg-inverse-secondary .breadcrumb-item {
	color: #c3bdbd;
}

.bg-inverse-secondary .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-secondary .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-secondary.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
	background: #fff;
}

.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
	color: #c3bdbd;
	border: none;
}

.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #fff;
}

.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
	color: #c3bdbd;
}

.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-success {
	background: #7ceedd;
	border-color: #1bcfb4;
}

.bg-inverse-success .breadcrumb-item {
	color: #1bcfb4;
}

.bg-inverse-success .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-success .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-success.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-success.breadcrumb-custom .breadcrumb-item {
	background: #7ceedd;
}

.bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
	color: #1bcfb4;
	border: none;
}

.bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #7ceedd;
}

.bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
	color: #1bcfb4;
}

.bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-info {
	background: #89c4f2;
	border-color: #198ae3;
}

.bg-inverse-info .breadcrumb-item {
	color: #198ae3;
}

.bg-inverse-info .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-info .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-info.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-info.breadcrumb-custom .breadcrumb-item {
	background: #89c4f2;
}

.bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
	color: #198ae3;
	border: none;
}

.bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #89c4f2;
}

.bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
	color: #198ae3;
}

.bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-warning {
	background: #ffed92;
	border-color: #fed713;
}

.bg-inverse-warning .breadcrumb-item {
	color: #fed713;
}

.bg-inverse-warning .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-warning .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-warning.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
	background: #ffed92;
}

.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
	color: #fed713;
	border: none;
}

.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #ffed92;
}

.bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
	color: #fed713;
}

.bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-danger {
	background: #fffbfb;
	border-color: #fe7c96;
}

.bg-inverse-danger .breadcrumb-item {
	color: #fe7c96;
}

.bg-inverse-danger .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-danger .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-danger.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
	background: #fffbfb;
}

.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
	color: #fe7c96;
	border: none;
}

.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #fffbfb;
}

.bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
	color: #fe7c96;
}

.bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-light {
	background: #fff;
	border-color: #f8f9fa;
}

.bg-inverse-light .breadcrumb-item {
	color: #f8f9fa;
}

.bg-inverse-light .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-light .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-light.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-light.breadcrumb-custom .breadcrumb-item {
	background: #fff;
}

.bg-inverse-light.breadcrumb-custom .breadcrumb-item a {
	color: #f8f9fa;
	border: none;
}

.bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #fff;
}

.bg-inverse-light.breadcrumb-custom .breadcrumb-item span {
	color: #f8f9fa;
}

.bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-dark {
	background: #778aa2;
	border-color: #3e4b5b;
}

.bg-inverse-dark .breadcrumb-item {
	color: #3e4b5b;
}

.bg-inverse-dark .breadcrumb-item:before {
	color: inherit;
}

.bg-inverse-dark .breadcrumb-item a {
	color: inherit;
}

.bg-inverse-dark.breadcrumb-custom {
	background: rgba(0, 0, 0, 0);
}

.bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
	background: #778aa2;
}

.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
	color: #3e4b5b;
	border: none;
}

.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
	border-left-color: #778aa2;
}

.bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
	color: #3e4b5b;
}

.bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
	background: rgba(0, 0, 0, 0);
}

.icons-list {
	border-left: 1px solid #ebedf2;
	border-top: 1px solid #ebedf2;
}

.icons-list > div {
	border-bottom: 1px solid #ebedf2;
	border-right: 1px solid #ebedf2;
	display: flex;
	align-items: center;
	padding: 15px 15px;
	font-family: "ubuntu-regular", sans-serif;
	font-size: 0.875rem;
}

.icons-list > div i {
	display: inline-block;
	font-size: 20px;
	width: 40px;
	text-align: left;
	color: #b66dff;
}

.list-ticked {
	list-style: none;
	padding: 0;
}

.list-ticked li {
	padding-left: 1.5rem;
}

.list-ticked li:before {
	font-family: "Material Design Icons";
	margin-left: -1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
	content: "\F12D";
	color: #fe7c96;
}

.list-arrow {
	list-style: none;
	padding: 0;
}

.list-arrow li {
	padding-left: 1.5rem;
}

.list-arrow li:before {
	font-family: "Material Design Icons";
	margin-left: -1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
	content: "\F142";
	color: #1bcfb4;
}

.list-star {
	list-style: none;
	padding: 0;
}

.list-star li {
	padding-left: 1.5rem;
}

.list-star li:before {
	font-family: "Material Design Icons";
	margin-left: -1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
	content: "\F4CE";
	color: #fed713;
}

.gradient-bullet-list {
	padding-left: 0;
}

.gradient-bullet-list li {
	position: relative;
	list-style-type: none;
	padding-left: 25px;
	line-height: 1;
	padding-bottom: 25px;
}

.gradient-bullet-list li:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 15px;
	height: 15px;
	border-radius: 100%;
}

.gradient-bullet-list li:after {
	content: "";
	position: absolute;
	width: 11px;
	height: 11px;
	top: 2px;
	left: 2px;
	background: #fff;
	border-radius: 100%;
}

.gradient-bullet-list li:nth-child(1):before {
	background: linear-gradient(to right, #ffbf96, #fe7096);
}

.gradient-bullet-list li:nth-child(2):before {
	background: linear-gradient(to right, #90caf9, #047edf 99%);
}

.gradient-bullet-list li:nth-child(3):before {
	background: linear-gradient(to right, #f6e384, #ffd500);
}

.gradient-bullet-list li:nth-child(4):before {
	background: linear-gradient(to right, #84d9d2, #07cdae);
}

.gradient-bullet-list li:nth-child(5):before {
	background: linear-gradient(to right, #da8cff, #9a55ff);
}

.bullet-line-list {
	padding-left: 30px;
	margin-bottom: 0;
	position: relative;
	list-style-type: none;
}

.bullet-line-list li {
	position: relative;
	line-height: 1;
	padding-bottom: 10px;
}

.bullet-line-list li:before {
	content: "";
	position: absolute;
	border-radius: 100%;
	width: 12px;
	height: 12px;
	left: -28px;
	top: 6px;
	border: 3px solid #b66dff;
	margin-right: 15px;
	z-index: 2;
	background: color(white);
}

.bullet-line-list li:after {
	content: "";
	border: 1px solid #ebedf2;
	position: absolute;
	bottom: 0;
	left: -23px;
	height: 100%;
}

.bullet-line-list li:first-child:after {
	content: "";
	height: 80%;
}

.bullet-line-list li:last-child {
	padding-bottom: 0;
}

.bullet-line-list li:last-child:after {
	content: "";
	top: 0;
	height: 30%;
}

.preview-list.comment-preview .preview-item {
	padding: 0.87rem 0;
}

.preview-list.comment-preview .preview-item:first-child {
	padding-top: 0;
}

.preview-list.comment-preview .preview-item p {
	line-height: 27px;
}

.preview-list.bordered .preview-item {
	border-bottom: 1px solid #ebedf2;
}

.preview-list.bordered .preview-item:last-child {
	border-bottom: 0;
}

.add-items {
	margin-bottom: 1.5rem;
	overflow: hidden;
}

.add-items input[type="text"] {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0);
}

.add-items .btn {
	margin-left: 0.5rem;
}

.auth .auth-form-light {
	background: #fff;
}

.auth .auth-form-light select {
	color: #c9c8c8;
}

.auth .auth-form-light .input-group .form-control:focus {
	border-color: #ebedf2;
}

.auth .auth-form-light .input-group .form-control:active {
	border-color: #ebedf2;
}

.auth .brand-logo {
	margin-bottom: 2rem;
}

.auth .brand-logo img {
	width: 150px;
}

.auth form .form-group {
	margin-bottom: 1.5rem;
}

.auth form .form-group label {
	font-size: 0.8125rem;
}

.auth form .form-group .form-control {
	background: rgba(0, 0, 0, 0);
	border-radius: 0;
	font-size: 0.9375rem;
}

.auth form .auth-form-btn {
	height: 50px;
	line-height: 1.5;
}

.auth form .auth-link {
	font-size: 0.875rem;
}

.auth form .auth-link:hover {
	color: initial;
}

.page-body-wrapper {
	padding-top: 60px;
	/* min-height: calc(100vh - 70px); */
	display: flex;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	margin-top: 4em;
	/* position: fixed; */
}

.page-body-wrapper.full-page-wrapper {
	width: 100%;
	min-height: 100vh;
}

.main-panel {
	transition: width 0.25s ease, margin 0.25s ease;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	flex-direction: column;
	/* padding-left: 17%; */
}
.main-panel--toggle {
	transition: width 0.25s ease, margin 0.25s ease;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	flex-direction: column;
	padding-left: 53px !important;
}

.content-wrapper {
	background: #f2edf3;
	padding: 2.75rem 2.25rem;
	width: 100%;
	flex-grow: 1;
}

.btns {
	/* padding: 11px 22px;
	border-radius: 5px;
	font-size: 13px; */
	padding: 11px 15px;
	border-radius: 5px;
	font-size: 13px;
	margin-left: auto;
	margin-right: auto;
}

.btn-light-border {
	border: 1px solid #ecf1f7;
	background: rgba(0, 0, 0, 0);
}

.col-gap12 {
	grid-column-gap: 12px;
	column-gap: 12px;
}

.puple {
	background: #ecf1f7;
	transition: 0.6s all;
}

.black {
	color: #0b0b0b;
}

.grey {
	color: #a1a1a1;
}

.red {
	color: #c71515;
}

.section-title {
	margin-bottom: 22px;
}

.section-title h2 {
	font-weight: 500;
	font-size: 24px;
	line-height: 35px;
	color: #0b0b0b;
	margin-bottom: 20px;
}

.section-title h4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}

.rightbar {
	padding: 12px 10px 10px 10px;
}

.rightbar.rightbar-marign-r {
	padding: 26px 30px 0 30px;
}

.btn-primary {
	background-color: #125ecb !important;
	border: 0 !important;
	transition: 0.6s all;
}

.btn-primary:hover {
	background: #0001c7 !important;
}

.pl-30 {
	padding-left: 30px;
}

.pr-11 {
	/* padding-right: 26px; */
	/* margin-top: 4em; */
}

.navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid rgba(0, 0, 0, 0);
	border-left: 4px solid rgba(0, 0, 0, 0);
}

.table-hover > tbody > tr:hover > * {
	--bs-table-accent-bg: #ecf1f7 !important;
	background-color: #ecf1f7 !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
	--bs-table-accent-bg: #fff;
}

.headname {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #0b0b0b;
	margin-top: 19px;
}

.idname {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #5a616b;
}

ul.margin-list li {
	display: inline-block;
	background: #fff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin-right: 7px;
	width: 40px;
	height: 41px;
	font-size: 13px;
	line-height: 40px;
	color: #a1a1a1;
	text-align: center;
	align-items: center;
}

.addtypegroup .modal-footer {
	justify-content: flex-end;
}

.addtypegroup .form-group {
	margin-bottom: 10px;
}

.btn-close {
	width: 20px;
	height: 20px;
	background-image: url(/static/media/cros.250666a3.svg) !important;
	opacity: 1;
	background-size: auto;
	padding: 0;
}

.col-gap-20 {
	grid-column-gap: 20px;
	column-gap: 20px;
}

.col-gap-10 {
	grid-column-gap: 10px;
	column-gap: 10px;
}

.padi-30 {
	padding-left: 30px;
}

.col-gap-14 {
	grid-column-gap: 14px;
	column-gap: 14px;
}

.w-282 {
	width: 282px;
}

.invoicecard .card {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin: 0px 14px 0 0;
}

ul.sociallink li {
	display: inline-block;
	text-align: center;
	margin: 8px;
	width: 40px;
	height: 40px;
	border: 1px solid #c0bcbc;
	border-radius: 50%;
	line-height: 40px;
}

ul.sociallink li img {
	width: 20px;
	height: 20px;
}

@media (min-width: 1200px) {
	h1 {
		font-size: 2.5rem;
	}

	.h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 2rem;
	}

	.h2 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1.75rem;
	}

	.h3 {
		font-size: 1.75rem;
	}

	legend {
		font-size: 1.5rem;
	}

	.display-1 {
		font-size: 5rem;
	}

	.display-2 {
		font-size: 4.5rem;
	}

	.display-3 {
		font-size: 4rem;
	}

	.display-4 {
		font-size: 3.5rem;
	}

	.display-5 {
		font-size: 3rem;
	}

	.display-6 {
		font-size: 2.5rem;
	}

	.container {
		/* max-width: 1140px; */
		max-width: -webkit-fill-available;
	}

	.container-sm {
		max-width: 1140px;
	}

	.container-md {
		max-width: 1140px;
	}

	.container-lg {
		max-width: 1140px;
	}

	.container-xl {
		max-width: 1140px;
	}

	.dropdown-menu-xl-start {
		--bs-position: start;
	}

	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-xl-end {
		--bs-position: end;
	}

	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}

	.navbar-expand-xl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible;
	}

	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-xl .navbar-toggler {
		display: none;
	}

	.navbar-expand-xl .offcanvas-header {
		display: none;
	}

	.navbar-expand-xl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: rgba(0, 0, 0, 0);
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}

	.navbar-expand-xl .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-xl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-xl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}

	.list-group-horizontal-xl {
		flex-direction: row;
	}

	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}

	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}

	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}

	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}

	.list-group-horizontal-xl > .list-group-item.active {
		margin-top: 0;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 100%;
	}

	.container-sm {
		max-width: 540px;
	}

	.dropdown-menu-sm-start {
		--bs-position: start;
	}

	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-sm-end {
		--bs-position: end;
	}

	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0;
		left: auto;
	}

	.navbar-expand-sm {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible;
	}

	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-sm .navbar-toggler {
		display: none;
	}

	.navbar-expand-sm .offcanvas-header {
		display: none;
	}

	.navbar-expand-sm .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: rgba(0, 0, 0, 0);
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}

	.navbar-expand-sm .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-sm .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-sm .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}

	.list-group-horizontal-sm {
		flex-direction: row;
	}

	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}

	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}

	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}

	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}

	.list-group-horizontal-sm > .list-group-item.active {
		margin-top: 0;
	}

	.sticky-sm-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}

	.float-sm-start {
		float: left !important;
	}

	.float-sm-end {
		float: right !important;
	}

	.float-sm-none {
		float: none !important;
	}

	.d-sm-inline {
		display: inline !important;
	}

	.d-sm-inline-block {
		display: inline-block !important;
	}

	.d-sm-block {
		display: block !important;
	}

	.d-sm-grid {
		display: grid !important;
	}

	.d-sm-table {
		display: table !important;
	}

	.d-sm-table-row {
		display: table-row !important;
	}

	.d-sm-table-cell {
		display: table-cell !important;
	}

	.d-sm-flex {
		display: flex !important;
	}

	.d-sm-inline-flex {
		display: inline-flex !important;
	}

	.d-sm-none {
		display: none !important;
	}

	.flex-sm-fill {
		flex: 1 1 auto !important;
	}

	.flex-sm-row {
		flex-direction: row !important;
	}

	.flex-sm-column {
		flex-direction: column !important;
	}

	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}

	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-sm-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}

	.gap-sm-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}

	.gap-sm-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}

	.gap-sm-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}

	.gap-sm-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}

	.gap-sm-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}

	.justify-content-sm-start {
		justify-content: flex-start !important;
	}

	.justify-content-sm-end {
		justify-content: flex-end !important;
	}

	.justify-content-sm-center {
		justify-content: center !important;
	}

	.justify-content-sm-between {
		justify-content: space-between !important;
	}

	.justify-content-sm-around {
		justify-content: space-around !important;
	}

	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-sm-start {
		align-items: flex-start !important;
	}

	.align-items-sm-end {
		align-items: flex-end !important;
	}

	.align-items-sm-center {
		align-items: center !important;
	}

	.align-items-sm-baseline {
		align-items: baseline !important;
	}

	.align-items-sm-stretch {
		align-items: stretch !important;
	}

	.align-content-sm-start {
		align-content: flex-start !important;
	}

	.align-content-sm-end {
		align-content: flex-end !important;
	}

	.align-content-sm-center {
		align-content: center !important;
	}

	.align-content-sm-between {
		align-content: space-between !important;
	}

	.align-content-sm-around {
		align-content: space-around !important;
	}

	.align-content-sm-stretch {
		align-content: stretch !important;
	}

	.align-self-sm-auto {
		align-self: auto !important;
	}

	.align-self-sm-start {
		align-self: flex-start !important;
	}

	.align-self-sm-end {
		align-self: flex-end !important;
	}

	.align-self-sm-center {
		align-self: center !important;
	}

	.align-self-sm-baseline {
		align-self: baseline !important;
	}

	.align-self-sm-stretch {
		align-self: stretch !important;
	}

	.order-sm-first {
		order: -1 !important;
	}

	.order-sm-0 {
		order: 0 !important;
	}

	.order-sm-1 {
		order: 1 !important;
	}

	.order-sm-2 {
		order: 2 !important;
	}

	.order-sm-3 {
		order: 3 !important;
	}

	.order-sm-4 {
		order: 4 !important;
	}

	.order-sm-5 {
		order: 5 !important;
	}

	.order-sm-last {
		order: 6 !important;
	}

	.m-sm-0 {
		margin: 0 !important;
	}

	.m-sm-1 {
		margin: 0.25rem !important;
	}

	.m-sm-2 {
		margin: 0.5rem !important;
	}

	.m-sm-3 {
		margin: 1rem !important;
	}

	.m-sm-4 {
		margin: 1.5rem !important;
	}

	.m-sm-5 {
		margin: 3rem !important;
	}

	.m-sm-auto {
		margin: auto !important;
	}

	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-sm-0 {
		margin-top: 0 !important;
	}

	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}

	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}

	.mt-sm-3 {
		margin-top: 1rem !important;
	}

	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}

	.mt-sm-5 {
		margin-top: 3rem !important;
	}

	.mt-sm-auto {
		margin-top: auto !important;
	}

	.me-sm-0 {
		margin-right: 0 !important;
	}

	.me-sm-1 {
		margin-right: 0.25rem !important;
	}

	.me-sm-2 {
		margin-right: 0.5rem !important;
	}

	.me-sm-3 {
		margin-right: 1rem !important;
	}

	.me-sm-4 {
		margin-right: 1.5rem !important;
	}

	.me-sm-5 {
		margin-right: 3rem !important;
	}

	.me-sm-auto {
		margin-right: auto !important;
	}

	.mb-sm-0 {
		margin-bottom: 0 !important;
	}

	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}

	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}

	.mb-sm-auto {
		margin-bottom: auto !important;
	}

	.ms-sm-0 {
		margin-left: 0 !important;
	}

	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}

	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}

	.ms-sm-3 {
		margin-left: 1rem !important;
	}

	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}

	.ms-sm-5 {
		margin-left: 3rem !important;
	}

	.ms-sm-auto {
		margin-left: auto !important;
	}

	.p-sm-0 {
		padding: 0 !important;
	}

	.p-sm-1 {
		padding: 0.25rem !important;
	}

	.p-sm-2 {
		padding: 0.5rem !important;
	}

	.p-sm-3 {
		padding: 1rem !important;
	}

	.p-sm-4 {
		padding: 1.5rem !important;
	}

	.p-sm-5 {
		padding: 3rem !important;
	}

	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-sm-0 {
		padding-top: 0 !important;
	}

	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}

	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}

	.pt-sm-3 {
		padding-top: 1rem !important;
	}

	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}

	.pt-sm-5 {
		padding-top: 3rem !important;
	}

	.pe-sm-0 {
		padding-right: 0 !important;
	}

	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}

	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}

	.pe-sm-3 {
		padding-right: 1rem !important;
	}

	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}

	.pe-sm-5 {
		padding-right: 3rem !important;
	}

	.pb-sm-0 {
		padding-bottom: 0 !important;
	}

	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}

	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}

	.ps-sm-0 {
		padding-left: 0 !important;
	}

	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}

	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}

	.ps-sm-3 {
		padding-left: 1rem !important;
	}

	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}

	.ps-sm-5 {
		padding-left: 3rem !important;
	}

	.text-sm-start {
		text-align: left !important;
	}

	.text-sm-end {
		text-align: right !important;
	}

	.text-sm-center {
		text-align: center !important;
	}

	.grid-margin-sm-0 {
		margin-bottom: 0;
	}

	.border-right-sm {
		border-right: 1px solid #ebedf2;
	}

	.border-left-sm {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 100%;
	}

	.container-sm {
		max-width: 720px;
	}

	.container-md {
		max-width: 720px;
	}

	.dropdown-menu-md-start {
		--bs-position: start;
	}

	.dropdown-menu-md-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-md-end {
		--bs-position: end;
	}

	.dropdown-menu-md-end[data-bs-popper] {
		right: 0;
		left: auto;
	}

	.navbar-expand-md {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible;
	}

	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-md .navbar-toggler {
		display: none;
	}

	.navbar-expand-md .offcanvas-header {
		display: none;
	}

	.navbar-expand-md .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: rgba(0, 0, 0, 0);
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}

	.navbar-expand-md .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-md .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-md .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}

	.list-group-horizontal-md {
		flex-direction: row;
	}

	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}

	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}

	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}

	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}

	.list-group-horizontal-md > .list-group-item.active {
		margin-top: 0;
	}

	.sticky-md-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}

	.float-md-start {
		float: left !important;
	}

	.float-md-end {
		float: right !important;
	}

	.float-md-none {
		float: none !important;
	}

	.d-md-inline {
		display: inline !important;
	}

	.d-md-inline-block {
		display: inline-block !important;
	}

	.d-md-block {
		display: block !important;
	}

	.d-md-grid {
		display: grid !important;
	}

	.d-md-table {
		display: table !important;
	}

	.d-md-table-row {
		display: table-row !important;
	}

	.d-md-table-cell {
		display: table-cell !important;
	}

	.d-md-flex {
		display: flex !important;
	}

	.d-md-inline-flex {
		display: inline-flex !important;
	}

	.d-md-none {
		display: none !important;
	}

	.flex-md-fill {
		flex: 1 1 auto !important;
	}

	.flex-md-row {
		flex-direction: row !important;
	}

	.flex-md-column {
		flex-direction: column !important;
	}

	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-md-wrap {
		flex-wrap: wrap !important;
	}

	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-md-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}

	.gap-md-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}

	.gap-md-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}

	.gap-md-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}

	.gap-md-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}

	.gap-md-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}

	.justify-content-md-start {
		justify-content: flex-start !important;
	}

	.justify-content-md-end {
		justify-content: flex-end !important;
	}

	.justify-content-md-center {
		justify-content: center !important;
	}

	.justify-content-md-between {
		justify-content: space-between !important;
	}

	.justify-content-md-around {
		justify-content: space-around !important;
	}

	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-md-start {
		align-items: flex-start !important;
	}

	.align-items-md-end {
		align-items: flex-end !important;
	}

	.align-items-md-center {
		align-items: center !important;
	}

	.align-items-md-baseline {
		align-items: baseline !important;
	}

	.align-items-md-stretch {
		align-items: stretch !important;
	}

	.align-content-md-start {
		align-content: flex-start !important;
	}

	.align-content-md-end {
		align-content: flex-end !important;
	}

	.align-content-md-center {
		align-content: center !important;
	}

	.align-content-md-between {
		align-content: space-between !important;
	}

	.align-content-md-around {
		align-content: space-around !important;
	}

	.align-content-md-stretch {
		align-content: stretch !important;
	}

	.align-self-md-auto {
		align-self: auto !important;
	}

	.align-self-md-start {
		align-self: flex-start !important;
	}

	.align-self-md-end {
		align-self: flex-end !important;
	}

	.align-self-md-center {
		align-self: center !important;
	}

	.align-self-md-baseline {
		align-self: baseline !important;
	}

	.align-self-md-stretch {
		align-self: stretch !important;
	}

	.order-md-first {
		order: -1 !important;
	}

	.order-md-0 {
		order: 0 !important;
	}

	.order-md-1 {
		order: 1 !important;
	}

	.order-md-2 {
		order: 2 !important;
	}

	.order-md-3 {
		order: 3 !important;
	}

	.order-md-4 {
		order: 4 !important;
	}

	.order-md-5 {
		order: 5 !important;
	}

	.order-md-last {
		order: 6 !important;
	}

	.m-md-0 {
		margin: 0 !important;
	}

	.m-md-1 {
		margin: 0.25rem !important;
	}

	.m-md-2 {
		margin: 0.5rem !important;
	}

	.m-md-3 {
		margin: 1rem !important;
	}

	.m-md-4 {
		margin: 1.5rem !important;
	}

	.m-md-5 {
		margin: 3rem !important;
	}

	.m-md-auto {
		margin: auto !important;
	}

	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-md-0 {
		margin-top: 0 !important;
	}

	.mt-md-1 {
		margin-top: 0.25rem !important;
	}

	.mt-md-2 {
		margin-top: 0.5rem !important;
	}

	.mt-md-3 {
		margin-top: 1rem !important;
	}

	.mt-md-4 {
		margin-top: 1.5rem !important;
	}

	.mt-md-5 {
		margin-top: 3rem !important;
	}

	.mt-md-auto {
		margin-top: auto !important;
	}

	.me-md-0 {
		margin-right: 0 !important;
	}

	.me-md-1 {
		margin-right: 0.25rem !important;
	}

	.me-md-2 {
		margin-right: 0.5rem !important;
	}

	.me-md-3 {
		margin-right: 1rem !important;
	}

	.me-md-4 {
		margin-right: 1.5rem !important;
	}

	.me-md-5 {
		margin-right: 3rem !important;
	}

	.me-md-auto {
		margin-right: auto !important;
	}

	.mb-md-0 {
		margin-bottom: 0 !important;
	}

	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-md-3 {
		margin-bottom: 1rem !important;
	}

	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-md-5 {
		margin-bottom: 3rem !important;
	}

	.mb-md-auto {
		margin-bottom: auto !important;
	}

	.ms-md-0 {
		margin-left: 0 !important;
	}

	.ms-md-1 {
		margin-left: 0.25rem !important;
	}

	.ms-md-2 {
		margin-left: 0.5rem !important;
	}

	.ms-md-3 {
		margin-left: 1rem !important;
	}

	.ms-md-4 {
		margin-left: 1.5rem !important;
	}

	.ms-md-5 {
		margin-left: 3rem !important;
	}

	.ms-md-auto {
		margin-left: auto !important;
	}

	.p-md-0 {
		padding: 0 !important;
	}

	.p-md-1 {
		padding: 0.25rem !important;
	}

	.p-md-2 {
		padding: 0.5rem !important;
	}

	.p-md-3 {
		padding: 1rem !important;
	}

	.p-md-4 {
		padding: 1.5rem !important;
	}

	.p-md-5 {
		padding: 3rem !important;
	}

	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-md-0 {
		padding-top: 0 !important;
	}

	.pt-md-1 {
		padding-top: 0.25rem !important;
	}

	.pt-md-2 {
		padding-top: 0.5rem !important;
	}

	.pt-md-3 {
		padding-top: 1rem !important;
	}

	.pt-md-4 {
		padding-top: 1.5rem !important;
	}

	.pt-md-5 {
		padding-top: 3rem !important;
	}

	.pe-md-0 {
		padding-right: 0 !important;
	}

	.pe-md-1 {
		padding-right: 0.25rem !important;
	}

	.pe-md-2 {
		padding-right: 0.5rem !important;
	}

	.pe-md-3 {
		padding-right: 1rem !important;
	}

	.pe-md-4 {
		padding-right: 1.5rem !important;
	}

	.pe-md-5 {
		padding-right: 3rem !important;
	}

	.pb-md-0 {
		padding-bottom: 0 !important;
	}

	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-md-3 {
		padding-bottom: 1rem !important;
	}

	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-md-5 {
		padding-bottom: 3rem !important;
	}

	.ps-md-0 {
		padding-left: 0 !important;
	}

	.ps-md-1 {
		padding-left: 0.25rem !important;
	}

	.ps-md-2 {
		padding-left: 0.5rem !important;
	}

	.ps-md-3 {
		padding-left: 1rem !important;
	}

	.ps-md-4 {
		padding-left: 1.5rem !important;
	}

	.ps-md-5 {
		padding-left: 3rem !important;
	}

	.text-md-start {
		text-align: left !important;
	}

	.text-md-end {
		text-align: right !important;
	}

	.text-md-center {
		text-align: center !important;
	}

	.grid-margin-md-0 {
		margin-bottom: 0;
	}

	.border-right-md {
		border-right: 1px solid #ebedf2;
	}

	.border-left-md {
		border-left: 1px solid #ebedf2;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 100%;
	}

	.container-sm {
		max-width: 960px;
	}

	.container-md {
		max-width: 960px;
	}

	.container-lg {
		max-width: 960px;
	}

	.dropdown-menu-lg-start {
		--bs-position: start;
	}

	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-lg-end {
		--bs-position: end;
	}

	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0;
		left: auto;
	}

	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
		position: fixed;
		z-index: 2 !important;
		display: flex;
	}

	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible;
	}

	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-lg .navbar-toggler {
		display: none;
	}

	.navbar-expand-lg .offcanvas-header {
		display: none;
	}

	.navbar-expand-lg .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: rgba(0, 0, 0, 0);
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}

	.navbar-expand-lg .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-lg .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-lg .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}

	.list-group-horizontal-lg {
		flex-direction: row;
	}

	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}

	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}

	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}

	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}

	.list-group-horizontal-lg > .list-group-item.active {
		margin-top: 0;
	}

	.sticky-lg-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}

	.float-lg-start {
		float: left !important;
	}

	.float-lg-end {
		float: right !important;
	}

	.float-lg-none {
		float: none !important;
	}

	.d-lg-inline {
		display: inline !important;
	}

	.d-lg-inline-block {
		display: inline-block !important;
	}

	.d-lg-block {
		display: block !important;
	}

	.d-lg-grid {
		display: grid !important;
	}

	.d-lg-table {
		display: table !important;
	}

	.d-lg-table-row {
		display: table-row !important;
	}

	.d-lg-table-cell {
		display: table-cell !important;
	}

	.d-lg-flex {
		display: flex !important;
	}

	.d-lg-inline-flex {
		display: inline-flex !important;
	}

	.d-lg-none {
		display: none !important;
	}

	.flex-lg-fill {
		flex: 1 1 auto !important;
	}

	.flex-lg-row {
		flex-direction: row !important;
	}

	.flex-lg-column {
		flex-direction: column !important;
	}

	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}

	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-lg-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}

	.gap-lg-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}

	.gap-lg-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}

	.gap-lg-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}

	.gap-lg-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}

	.gap-lg-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}

	.justify-content-lg-start {
		justify-content: flex-start !important;
	}

	.justify-content-lg-end {
		justify-content: flex-end !important;
	}

	.justify-content-lg-center {
		justify-content: center !important;
	}

	.justify-content-lg-between {
		justify-content: space-between !important;
	}

	.justify-content-lg-around {
		justify-content: space-around !important;
	}

	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-lg-start {
		align-items: flex-start !important;
	}

	.align-items-lg-end {
		align-items: flex-end !important;
	}

	.align-items-lg-center {
		align-items: center !important;
	}

	.align-items-lg-baseline {
		align-items: baseline !important;
	}

	.align-items-lg-stretch {
		align-items: stretch !important;
	}

	.align-content-lg-start {
		align-content: flex-start !important;
	}

	.align-content-lg-end {
		align-content: flex-end !important;
	}

	.align-content-lg-center {
		align-content: center !important;
	}

	.align-content-lg-between {
		align-content: space-between !important;
	}

	.align-content-lg-around {
		align-content: space-around !important;
	}

	.align-content-lg-stretch {
		align-content: stretch !important;
	}

	.align-self-lg-auto {
		align-self: auto !important;
	}

	.align-self-lg-start {
		align-self: flex-start !important;
	}

	.align-self-lg-end {
		align-self: flex-end !important;
	}

	.align-self-lg-center {
		align-self: center !important;
	}

	.align-self-lg-baseline {
		align-self: baseline !important;
	}

	.align-self-lg-stretch {
		align-self: stretch !important;
	}

	.order-lg-first {
		order: -1 !important;
	}

	.order-lg-0 {
		order: 0 !important;
	}

	.order-lg-1 {
		order: 1 !important;
	}

	.order-lg-2 {
		order: 2 !important;
	}

	.order-lg-3 {
		order: 3 !important;
	}

	.order-lg-4 {
		order: 4 !important;
	}

	.order-lg-5 {
		order: 5 !important;
	}

	.order-lg-last {
		order: 6 !important;
	}

	.m-lg-0 {
		margin: 0 !important;
	}

	.m-lg-1 {
		margin: 0.25rem !important;
	}

	.m-lg-2 {
		margin: 0.5rem !important;
	}

	.m-lg-3 {
		margin: 1rem !important;
	}

	.m-lg-4 {
		margin: 1.5rem !important;
	}

	.m-lg-5 {
		margin: 3rem !important;
	}

	.m-lg-auto {
		margin: auto !important;
	}

	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-lg-0 {
		margin-top: 0 !important;
	}

	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}

	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}

	.mt-lg-3 {
		margin-top: 1rem !important;
	}

	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}

	.mt-lg-5 {
		margin-top: 3rem !important;
	}

	.mt-lg-auto {
		margin-top: auto !important;
	}

	.me-lg-0 {
		margin-right: 0 !important;
	}

	.me-lg-1 {
		margin-right: 0.25rem !important;
	}

	.me-lg-2 {
		margin-right: 0.5rem !important;
	}

	.me-lg-3 {
		margin-right: 1rem !important;
	}

	.me-lg-4 {
		margin-right: 1.5rem !important;
	}

	.me-lg-5 {
		margin-right: 3rem !important;
	}

	.me-lg-auto {
		margin-right: auto !important;
	}

	.mb-lg-0 {
		margin-bottom: 0 !important;
	}

	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}

	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}

	.mb-lg-auto {
		margin-bottom: auto !important;
	}

	.ms-lg-0 {
		margin-left: 0 !important;
	}

	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}

	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}

	.ms-lg-3 {
		margin-left: 1rem !important;
	}

	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}

	.ms-lg-5 {
		margin-left: 3rem !important;
	}

	.ms-lg-auto {
		margin-left: auto !important;
	}

	.p-lg-0 {
		padding: 0 !important;
	}

	.p-lg-1 {
		padding: 0.25rem !important;
	}

	.p-lg-2 {
		padding: 0.5rem !important;
	}

	.p-lg-3 {
		padding: 1rem !important;
	}

	.p-lg-4 {
		padding: 1.5rem !important;
	}

	.p-lg-5 {
		padding: 3rem !important;
	}

	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-lg-0 {
		padding-top: 0 !important;
	}

	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}

	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}

	.pt-lg-3 {
		padding-top: 1rem !important;
	}

	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}

	.pt-lg-5 {
		padding-top: 3rem !important;
	}

	.pe-lg-0 {
		padding-right: 0 !important;
	}

	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}

	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}

	.pe-lg-3 {
		padding-right: 1rem !important;
	}

	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}

	.pe-lg-5 {
		padding-right: 3rem !important;
	}

	.pb-lg-0 {
		padding-bottom: 0 !important;
	}

	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}

	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}

	.ps-lg-0 {
		padding-left: 0 !important;
	}

	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}

	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}

	.ps-lg-3 {
		padding-left: 1rem !important;
	}

	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}

	.ps-lg-5 {
		padding-left: 3rem !important;
	}

	.text-lg-start {
		text-align: left !important;
	}

	.text-lg-end {
		text-align: right !important;
	}

	.text-lg-center {
		text-align: center !important;
	}

	.grid-margin-lg-0 {
		margin-bottom: 0;
	}

	.border-right-lg {
		border-right: 1px solid #ebedf2;
	}

	.border-left-lg {
		border-left: 1px solid #ebedf2;
	}

	.sidebar-icon-only .navbar .navbar-brand-wrapper {
		width: 70px;
	}

	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
		display: none;
	}

	.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
		display: inline-block;
	}

	.sidebar-icon-only .navbar .navbar-menu-wrapper {
		width: calc(100% - 70px);
	}

	.sidebar-icon-only .sidebar {
		width: 70px;
	}

	.sidebar-icon-only .sidebar .nav {
		overflow: visible;
	}

	.sidebar-icon-only .sidebar .nav .nav-item {
		position: relative;
		padding: 0;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
		display: block;
		text-align: center;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		display: none;
		border-radius: 0 5px 5px 0px;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
		margin-right: 0;
		margin-left: 0;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
		display: none;
	}

	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 0 5px 0 0px;
	}

	.sidebar-icon-only .sidebar .nav .nav-item .collapse {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.nav-category {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
		margin: 0;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
		display: block;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
		display: flex;
		align-items: center;
		background: #fcfcfc;
		padding: 0.5rem 1.4rem;
		left: 70px;
		position: absolute;
		text-align: left;
		top: 0;
		bottom: 0;
		width: 190px;
		z-index: 1;
		line-height: 1.8;
	}

	.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title:after {
		display: none;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse {
		display: block;
		padding: 0.5rem 0;
		background: #fcfcfc;
		border-radius: 0 0 5px 0;
		position: absolute;
		left: 70px;
		width: 190px;
	}

	.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		display: block;
		padding: 0.5rem 0;
		background: #fcfcfc;
		border-radius: 0 0 5px 0;
		position: absolute;
		left: 70px;
		width: 190px;
	}

	.sidebar-icon-only .sidebar .nav.sub-menu {
		padding: 0 0 0 1.5rem;
	}

	.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: left;
		padding-left: 20px;
	}

	.sidebar-icon-only .sidebar .sidebar-actions {
		display: none;
	}

	.sidebar-icon-only .main-panel {
		width: calc(100% - 70px);
	}

	.rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
		border-radius: 5px 0 0 5px;
	}

	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item
		.nav-link[aria-expanded]
		.menu-title {
		border-radius: 5px 0 0 0;
	}

	.rtl.sidebar-icon-only
		.sidebar
		.nav
		.nav-item.hover-open
		.nav-link
		.menu-title {
		left: auto;
		right: 70px;
		text-align: left;
	}

	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse {
		left: auto;
		right: 70px;
		border-radius: 0 0 0 5px;
	}

	.rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
		left: auto;
		right: 70px;
		border-radius: 0 0 0 5px;
	}

	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
		margin-right: auto;
		margin-left: 0;
	}

	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
		text-align: right;
	}

	.rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
		left: 0;
		right: unset;
	}

	.modal-lg {
		--bs-modal-width: 668px !important;
	}

	.modal-xl {
		--bs-modal-width: 668px !important;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: -webkit-fill-available;
	}

	.container-sm {
		max-width: 1320px;
	}

	.container-md {
		max-width: 1320px;
	}

	.container-lg {
		max-width: 1320px;
	}

	.container-xl {
		max-width: 1320px;
	}

	.container-xxl {
		max-width: 1320px;
	}

	.dropdown-menu-xxl-start {
		--bs-position: start;
	}

	.dropdown-menu-xxl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-xxl-end {
		--bs-position: end;
	}

	.dropdown-menu-xxl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}

	.navbar-expand-xxl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-xxl .navbar-nav-scroll {
		overflow: visible;
	}

	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}

	.navbar-expand-xxl .offcanvas-header {
		display: none;
	}

	.navbar-expand-xxl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: rgba(0, 0, 0, 0);
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}

	.navbar-expand-xxl .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-xxl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}

	.navbar-expand-xxl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}

	.list-group-horizontal-xxl {
		flex-direction: row;
	}

	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}

	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}

	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}

	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}

	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top: 0;
	}

	.sticky-xxl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}

	.float-xxl-start {
		float: left !important;
	}

	.float-xxl-end {
		float: right !important;
	}

	.float-xxl-none {
		float: none !important;
	}

	.d-xxl-inline {
		display: inline !important;
	}

	.d-xxl-inline-block {
		display: inline-block !important;
	}

	.d-xxl-block {
		display: block !important;
	}

	.d-xxl-grid {
		display: grid !important;
	}

	.d-xxl-table {
		display: table !important;
	}

	.d-xxl-table-row {
		display: table-row !important;
	}

	.d-xxl-table-cell {
		display: table-cell !important;
	}

	.d-xxl-flex {
		display: flex !important;
	}

	.d-xxl-inline-flex {
		display: inline-flex !important;
	}

	.d-xxl-none {
		display: none !important;
	}

	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}

	.flex-xxl-row {
		flex-direction: row !important;
	}

	.flex-xxl-column {
		flex-direction: column !important;
	}

	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}

	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-xxl-0 {
		grid-gap: 0 !important;
		gap: 0 !important;
	}

	.gap-xxl-1 {
		grid-gap: 0.25rem !important;
		gap: 0.25rem !important;
	}

	.gap-xxl-2 {
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
	}

	.gap-xxl-3 {
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}

	.gap-xxl-4 {
		grid-gap: 1.5rem !important;
		gap: 1.5rem !important;
	}

	.gap-xxl-5 {
		grid-gap: 3rem !important;
		gap: 3rem !important;
	}

	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}

	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}

	.justify-content-xxl-center {
		justify-content: center !important;
	}

	.justify-content-xxl-between {
		justify-content: space-between !important;
	}

	.justify-content-xxl-around {
		justify-content: space-around !important;
	}

	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-xxl-start {
		align-items: flex-start !important;
	}

	.align-items-xxl-end {
		align-items: flex-end !important;
	}

	.align-items-xxl-center {
		align-items: center !important;
	}

	.align-items-xxl-baseline {
		align-items: baseline !important;
	}

	.align-items-xxl-stretch {
		align-items: stretch !important;
	}

	.align-content-xxl-start {
		align-content: flex-start !important;
	}

	.align-content-xxl-end {
		align-content: flex-end !important;
	}

	.align-content-xxl-center {
		align-content: center !important;
	}

	.align-content-xxl-between {
		align-content: space-between !important;
	}

	.align-content-xxl-around {
		align-content: space-around !important;
	}

	.align-content-xxl-stretch {
		align-content: stretch !important;
	}

	.align-self-xxl-auto {
		align-self: auto !important;
	}

	.align-self-xxl-start {
		align-self: flex-start !important;
	}

	.align-self-xxl-end {
		align-self: flex-end !important;
	}

	.align-self-xxl-center {
		align-self: center !important;
	}

	.align-self-xxl-baseline {
		align-self: baseline !important;
	}

	.align-self-xxl-stretch {
		align-self: stretch !important;
	}

	.order-xxl-first {
		order: -1 !important;
	}

	.order-xxl-0 {
		order: 0 !important;
	}

	.order-xxl-1 {
		order: 1 !important;
	}

	.order-xxl-2 {
		order: 2 !important;
	}

	.order-xxl-3 {
		order: 3 !important;
	}

	.order-xxl-4 {
		order: 4 !important;
	}

	.order-xxl-5 {
		order: 5 !important;
	}

	.order-xxl-last {
		order: 6 !important;
	}

	.m-xxl-0 {
		margin: 0 !important;
	}

	.m-xxl-1 {
		margin: 0.25rem !important;
	}

	.m-xxl-2 {
		margin: 0.5rem !important;
	}

	.m-xxl-3 {
		margin: 1rem !important;
	}

	.m-xxl-4 {
		margin: 1.5rem !important;
	}

	.m-xxl-5 {
		margin: 3rem !important;
	}

	.m-xxl-auto {
		margin: auto !important;
	}

	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-xxl-0 {
		margin-top: 0 !important;
	}

	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}

	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}

	.mt-xxl-3 {
		margin-top: 1rem !important;
	}

	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}

	.mt-xxl-5 {
		margin-top: 3rem !important;
	}

	.mt-xxl-auto {
		margin-top: auto !important;
	}

	.me-xxl-0 {
		margin-right: 0 !important;
	}

	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}

	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}

	.me-xxl-3 {
		margin-right: 1rem !important;
	}

	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}

	.me-xxl-5 {
		margin-right: 3rem !important;
	}

	.me-xxl-auto {
		margin-right: auto !important;
	}

	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}

	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}

	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}

	.mb-xxl-auto {
		margin-bottom: auto !important;
	}

	.ms-xxl-0 {
		margin-left: 0 !important;
	}

	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}

	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}

	.ms-xxl-3 {
		margin-left: 1rem !important;
	}

	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}

	.ms-xxl-5 {
		margin-left: 3rem !important;
	}

	.ms-xxl-auto {
		margin-left: auto !important;
	}

	.p-xxl-0 {
		padding: 0 !important;
	}

	.p-xxl-1 {
		padding: 0.25rem !important;
	}

	.p-xxl-2 {
		padding: 0.5rem !important;
	}

	.p-xxl-3 {
		padding: 1rem !important;
	}

	.p-xxl-4 {
		padding: 1.5rem !important;
	}

	.p-xxl-5 {
		padding: 3rem !important;
	}

	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-xxl-0 {
		padding-top: 0 !important;
	}

	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}

	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}

	.pt-xxl-3 {
		padding-top: 1rem !important;
	}

	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}

	.pt-xxl-5 {
		padding-top: 3rem !important;
	}

	.pe-xxl-0 {
		padding-right: 0 !important;
	}

	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}

	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}

	.pe-xxl-3 {
		padding-right: 1rem !important;
	}

	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}

	.pe-xxl-5 {
		padding-right: 3rem !important;
	}

	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}

	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}

	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}

	.ps-xxl-0 {
		padding-left: 0 !important;
	}

	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}

	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}

	.ps-xxl-3 {
		padding-left: 1rem !important;
	}

	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}

	.ps-xxl-5 {
		padding-left: 3rem !important;
	}

	.text-xxl-start {
		text-align: left !important;
	}

	.text-xxl-end {
		text-align: right !important;
	}

	.text-xxl-center {
		text-align: center !important;
	}
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.card-statistics .card-col {
		padding-left: 0;
		padding-right: 0;
		position: relative;
		border: none;
	}

	.card-statistics .card-col:first-child:after {
		content: "";
		position: absolute;
		background: #ebedf2;
		bottom: 0;
		width: 1px;
		height: 100%;
		right: 0;
	}

	.card-statistics .card-col:first-child:before {
		content: "";
		position: absolute;
		background: #ebedf2;
		bottom: 0;
		width: 94.2%;
		height: 1px;
		right: 0;
	}

	.card-statistics .card-col:nth-child(2):after {
		content: "";
		position: absolute;
		background: #ebedf2;
	}

	.card-statistics .card-col:nth-child(2):before {
		content: "";
		position: absolute;
		background: #ebedf2;
		bottom: 0;
		width: 94.2%;
		height: 1px;
		left: 0;
	}

	.card-statistics .card-col:nth-child(3):after {
		content: "";
		position: absolute;
		background: #ebedf2;
	}

	.card-statistics .card-col:nth-child(3):before {
		content: "";
		position: absolute;
		background: #ebedf2;
		width: 1px;
		height: 100%;
		right: 0;
	}
}

@media (max-width: 767.98px) {
	.rightbar {
		padding: 26px 11px 0 11px !important;
	}

	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1399.98px) {
	.table-responsive-xxl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (prefers-reduced-motion: reduce) {
	.form-select {
		transition: none;
	}

	.form-switch .form-check-input {
		transition: none;
	}

	.form-range::-webkit-slider-thumb {
		-webkit-transition: none;
		transition: none;
	}

	.form-range::-moz-range-thumb {
		-moz-transition: none;
		transition: none;
	}

	.form-floating > label {
		transition: none;
	}

	.fade {
		transition: none;
	}

	.collapsing {
		transition: none;
	}

	.collapsing.collapse-horizontal {
		transition: none;
	}

	.nav-link {
		transition: none;
	}

	.navbar-toggler {
		transition: none;
	}

	.accordion-button {
		transition: none;
	}

	.accordion-button::after {
		transition: none;
	}

	.page-link {
		font-size: 13px;
		line-height: 19px;
		color: #5a616b;
		transition: none;
	}

	.progress-bar {
		transition: none;
	}

	.progress-bar-animated {
		animation: none;
	}

	.carousel-item {
		transition: none;
	}

	.carousel-fade .active.carousel-item-start {
		transition: none;
	}

	.carousel-fade .active.carousel-item-end {
		transition: none;
	}

	.carousel-control-prev {
		transition: none;
	}

	.carousel-control-next {
		transition: none;
	}

	.carousel-indicators [data-bs-target] {
		transition: none;
	}

	.spinner-border {
		animation-duration: 1.5s;
	}

	.spinner-grow {
		animation-duration: 1.5s;
	}

	.offcanvas {
		transition: none;
	}
}

@media print {
	.d-print-inline {
		display: inline !important;
	}

	.d-print-inline-block {
		display: inline-block !important;
	}

	.d-print-block {
		display: block !important;
	}

	.d-print-grid {
		display: grid !important;
	}

	.d-print-table {
		display: table !important;
	}

	.d-print-table-row {
		display: table-row !important;
	}

	.d-print-table-cell {
		display: table-cell !important;
	}

	.d-print-flex {
		display: flex !important;
	}

	.d-print-inline-flex {
		display: inline-flex !important;
	}

	.d-print-none {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.display-1 {
		font-size: 3rem;
	}

	.display-2 {
		font-size: 2.5rem;
	}

	.display-3 {
		font-size: 2rem;
	}

	.display-4 {
		font-size: 1.5rem;
	}

	.display-5 {
		font-size: 1rem;
	}

	.error-page h1 {
		font-size: 8rem;
	}

	.error-page .h1 {
		font-size: 8rem;
	}

	.footer {
		margin-left: 0;
		width: 100%;
	}

	.main-panel {
		margin-left: 0;
		width: 100%;
	}
}

@media (max-width: 766px) {
	#proBanner .card-body-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	#proBanner .buy-now-text {
		font-size: 10px;
		line-height: 1.5;
	}

	#proBanner .btn.buy-now-btn {
		min-width: 80px;
		padding: 1rem 0.5rem;
		font-size: 12px;
	}

	#proBanner .btn#bannerClose i {
		font-size: 1rem;
		margin-right: 0;
	}

	#proBanner a i {
		font-size: 1rem;
	}
}

@supports (-ms-overflow-style: none) {
	.ps {
		overflow: auto !important;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ps {
		overflow: auto !important;
	}
}

.workbody {
	position: absolute;
	z-index: auto;
	overflow: hidden;
}

.workbody:before {
	border-top: 1px solid #e9e9e9;
}

.form-check-label {
	margin-bottom: 0;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
}

.ordertitle {
	font-weight: 500;
	font-size: 13px;
	line-height: 23px;
	color: #0b0b0b;
	text-align: left;
}

.ordertitle span {
	font-weight: 400;
}

.orderdate {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	color: #5a616b;
}

.ordercard .card {
	min-width: calc(16em - 3px);
	width: 100%;
	text-align: left;
	padding-top: 0;
	transition: 0.1s all;
	margin-bottom: 14px;
	box-sizing: border-box;
	/* height: 12.8em; */
}

.ordercard .card:hover {
	box-sizing: border-box;
	border: 2.9px solid #b8b8b8 !important;
	/* min-width: calc(16em - 3px); */
	width: 100%;
	text-align: left;
	padding-top: 0;
	transition: 0.1s all;
	/* height: 12.8em; */
	margin-bottom: 14px;
	z-index: 999;
}

.ordercard .card-header {
	border-color: #e9e9e9;
	background: rgba(0, 0, 0, 0);
}

.ordercard .card.activecard {
	box-sizing: border-box;
	border: 3px solid #125ecb !important;
	/* min-width: calc(16em - 6px); */
	width: 100%;
	text-align: left;
	padding-top: 0;
	transition: none;
	position: relative;
	min-height: 11em;
	margin-bottom: 14px;
}

.ordercard .card.activecard .card-header {
	background: #f5f5f5;
	border-color: rgba(0, 0, 0, 0);
}
.editororderdetails {
	/* height: 90vh; */
	/* overflow-y: auto; */
	/* padding-bottom: 57px; */
	/* overflow-x: clip; */
	/* width: max-content; */
	width: 42vw !important;
	background: #fff;
}
.editororderdetails_editor {
	/* padding-bottom: 57px; */
	/* width: max-content; */
	background: #fff;
	/* width: fit-content; */
	scrollbar-width: thin;
	scrollbar-color: #d3dae4 #ffffff;
}

.editororderdetails_editor .card {
	width: 100%;
	text-align: left;
	padding-top: 0;
	/* margin-bottom: 14px; */
	position: relative;
}

.editororderdetails_editor .card .checkbox {
	background: rgba(255, 255, 255, 0.44);
	border: 1px solid #fff;
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	left: 13px;
	top: 13px;
}

.editororderdetails_editor .card-text {
	font-weight: 500;
	font-size: 13px !important;
	line-height: 19px !important;
	text-align: center;
	padding: 2px 0 10px;
	color: #0b0b0b;
}

.editororderdetails .card {
	width: 100% !important;
	text-align: left;
	padding-top: 0;
	position: relative;
	min-height: 317px;
	cursor: pointer;
}

.editororderdetails .card .checkbox {
	background: rgba(255, 255, 255, 0.44);
	border: 1px solid #fff;
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	left: 13px;
	top: 13px;
}

.editororderdetails .card-text {
	font-size: 12px !important;
	font-weight: 500;
    line-height: 14px !important;
    padding: 2px 0 0;
    width: auto;
    height: 20px !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    width: 100px !important;
    margin: 0 auto;
}

/* .order-details {
	padding: 10px 0;
} */

.order-details p {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	margin-bottom: 6px;
}

.orange {
	color: #f1a100;
}

.green {
	color: #008e1a;
}

.viewguide {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	color: #125ecb;
	margin-bottom: 10px;
	@media (min-width: 1100px) {
		margin-bottom: 0px;
	}
}

.bothbtn {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	padding: 8px 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	align-items: center;
	color: #0b0b0b;
	text-align: center;
	margin-bottom: 6px;
}
.bothbtn-download {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	padding: 8px 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	align-items: center;
	color: #0b0b0b;
	text-align: center;
	margin-bottom: 6px;
	cursor: pointer;
}
.bothbtn-download:hover {
	-webkit-text-decoration: underline #2c6bcf;
	        text-decoration: underline #2c6bcf; /* Add underline on hover */
	color: #2c6bcf;
}
.uploaded.bothbtn {
	border-radius: 4px 11px 4px 4px !important;
}

.bothbtn span {
	margin-right: 8px;
}

.bothbtn.upload {
	color: #878787;
}

.bothbtn.uploaded {
	color: #205aa7;
	font-weight: bold;
	position: relative;
}

.bothbtn.submitted {
	color: #64b05e;
	font-weight: bold;
}

.uploaded .remove_psd_image {
	position: absolute;
	top: -12px;
	right: -10px;
}

.task_status {
	text-transform: capitalize;
}

.btn-gap {
	grid-column-gap: 5px;
	column-gap: 5px;
}

.editororderdetail {
	/* padding-right: 11px; */
	overflow: auto;
	overflow-x: hidden;
	height: calc(100vh - 192px);
	overflow-y: auto;
	padding-bottom: 0px;
	/* width: max-content; */
	position: relative;
}

.overviewtables .table thead th {
	white-space: nowrap;
}

.table td p {
	font-size: 13px;
}

.detailslist {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(167px, 1fr));
	grid-gap: 15px;
	grid-gap: 15px;
	grid-gap: 12px;
	gap: 12px;
	width: 100%;
}
.detailslist_editor {
	display: grid;
	grid-gap: 10px;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
	overflow: scroll;
	overflow-x: hidden;
	justify-content: start;
	/* height: 71vh; */
	/* grid-column-gap: 20px;
	column-gap: 20px; */
	width: -webkit-fill-available;
	/* width: 49em; */
	height: calc(100vh - 212px);
	scrollbar-width: thin;
	padding-bottom: 15px;
	@media (min-width: 1200px) {
		grid-gap: 15px;
		gap: 15px;
		grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
	}
}

/* .detailslist .card {
	width: 23% !important;
} */

.workspaceform .form-label {
	margin-bottom: 0;
}

ul.treeviewlist li {
	font-weight: 400;
	font-size: 13px;
	color: #5a616b;
	padding: 11px 3px 0;
}

ul.treeviewlist li span {
	margin-right: 6px;
}

ul.treeviewlist li i {
	margin-right: 7px;
	color: #a1a1a1;
	font-size: 16px;
}

ul.treeviewlist li > ul > li {
	padding-left: 0.7rem;
}

.rightsidebar {
	width: 243px;
	flex-basis: 243px;
}

.midpart {
	width: 100%;
}

/* .coordibox .card {
	width: 30%;
} */

.treeviewlist {
	margin-left: -16px;
}

.treelist {
	background: #ecf1f7;
	border-radius: 4px;
	padding: 10px 11px;
}

.treelist i {
	color: #5a616b !important;
}

.css-13cymwt-control {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	color: #5a616b !important;
	font-size: 13px;
	border-color: #e9e9e9 !important;
}

.css-t3ipsp-control {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	height: 41px;
	color: #5a616b !important;
	font-size: 13px;
	border-color: #e9e9e9 !important;
}

.css-1u9des2-indicatorSeparator {
	display: none;
}

.css-1nmdiq5-menu {
	font-size: 13px;
	color: #5a616b !important;
}

.css-qbdosj-Input {
	color: #5a616b !important;
}

@media (min-width: 1026px) and (max-width: 1185px) {
	.detailslist .card {
		/* width: 100%; */
	}

	.coordibox .card {
		/* width: 100%; */
	}
}

@media (max-width: 1025px) {
	.detailslist .card {
		/* width: 100%; */
	}

	.coordibox .card {
		/* width: 100%; */
		margin: 0px;
	}

	ul.treeviewlist li {
		font-size: 10px;
		line-height: 15px;
	}

	ul.treeviewlist li > ul {
		padding-left: 5px;
	}
	@media (min-width: 1100px) {
		ul.treeviewlist li > ul {
			padding-left: 15px;
		}
	}

	ul.treeviewlist li > ul > li {
		line-height: 12px;
	}

	ul.treeviewlist li img {
		width: 10%;
	}
}

@media (max-width: 767px) {
	.detailslist .card {
		width: 100%;
	}

	.treeviewlist {
		margin-left: 5%;
	}

	ul.treeviewlist li img {
		width: auto;
	}
}

.orderdetails-alignment .rightsidebar {
	width: auto;
}

.orderdetails-alignment .rightsidebar .treeviewlist {
	margin-left: 0;
}

.orderdetails-alignment .detailslist {
	justify-content: initial;
}

.orderdetails-alignment .detailslist .card {
	width: 23.3%;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #fff;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.Auth-form {
	box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 10px;
	padding-top: 30px;
	padding-bottom: 30px;
	border-radius: 8px;
	background-color: #fff;
	margin-top: 100px;
	margin-bottom: 40px;
}

.Auth-form-content {
	padding-left: 6%;
	padding-right: 6%;
}

.Auth-form-title {
	text-align: center;
	margin-bottom: 1em;
	font-size: 24px;
	color: #222;
	font-weight: 800;
}

.Auth-form label {
	font-size: 14px;
	font-weight: 600;
	color: #222;
}

.coordinator-dashboard .card {
	width: 30%;
	margin-bottom: 20px;
	text-align: center;
}

.coordinator-dashboard .card .card-img-top {
	width: 34px;
	height: 34px;
	margin: 0 auto;
}

.coordinator-dashboard .detailscard {
	box-shadow: none;
	border: 0 !important;
}

.coordinator-dashboard .detailscard .card-text p {
	font-weight: 400;
	font-size: 13px;
	color: #125ecb;
	margin-top: 23%;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

.eficiency-box {
	border: 1px solid #e9e9e9;
	padding: 24px 38px;
	border-radius: 4px;
}

.viewbntn {
	font-weight: 400;
	font-size: 13px;
	color: #125ecb;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

@media (min-width: 992px) and (max-width: 1108px) {
	.coordinator-dashboard .card {
		width: 29%;
	}
}

@media (max-width: 767px) {
	.coordinator-dashboard .card {
		width: 100%;
	}

	.coordinator-dashboard .detailscard .card-text p {
		margin-top: 0;
	}

	.btn-cls {
		text-align: center;
	}

	.btn-cls button {
		margin-bottom: 12px;
	}
}

.sc-dashboard .card {
	border-color: #5e5e5e !important;
	width: 150px;
	padding: 25px 0;
}

.sc-dashboard .sc-first {
	width: 180px;
	position: relative;
	margin-right: 40px;
}

.sc-dashboard .sc-first:after {
	content: "";
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAcCAMAAAB1Xz6HAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAF1QTFRF/f39/////v7+urq6j4+Pn5+f3t7erKysDw8PMDAw6enpXl5eDg4OcHBwEBAQuLi4MTEx6urq0tLS/f/+/v//8/PzSkpKXV1dubm5kJCQ0dHRcXFx3d3dMjIy9PT071sflAAAAG1JREFUeJyVjgsOgzAMQ+3AqIGu+7QDxhj3P+ZStAsQRXpylEQPoBkJNu2lI4KkfsAYnQFXKTluSbo/YM8QBvN9egPG4465mA9zyYUVpeKo87C/i2f/+WqneSHerqIVo2JV+sRUsX2leQf3dSN/clQEYHJNb5kAAAAASUVORK5CYII=)
		no-repeat center center;
	position: absolute;
	top: 50%;
	width: 5px;
	height: 22px;
	right: -14%;
	transform: translate(-50%, -50%);
	z-index: 9;
	height: 59px;
}

.sc-dashboard .sc-card-top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.sc-dashboard .sc-card-top .card-img-top {
	width: 25px;
	height: 25px;
}

.sc-dashboard .sc-card-top .card-title {
	margin: 0 0 0 10px;
	color: #0b0b0b;
	font-weight: 600;
}

.sc-dashboard .sc-card-top .card-title h5 {
	margin: 0;
}

.supervisortable {
	border-radius: 0;
	border: none;
}

.supervisortable .caret {
	color: #7c80d7;
}

.supervisortable table {
	border-left: none;
	border-right: none;
}

.supervisortable table th {
	padding: 5px 12px !important;
	background-color: rgba(0, 0, 0, 0);
}

.supervisortable table td {
	padding: 10px 12px !important;
	color: #000;
}

.treeviewlist.custom-viewlist {
	margin: 0;
}

.treeviewlist.custom-viewlist .catgory-name {
	margin: 0;
}

.treeviewlist.custom-viewlist li {
	/* width: 47em; */
	height: max-content;
	padding: 10px 6px 10px 0;
	/* margin-top: -5px; */
}

.treeviewlist.custom-viewlist li span {
	margin-right: 7px;
}

.treeviewlist.custom-viewlist i {
	margin: 0 7px 0 12px;
	width: 12px;
	text-align: center;
}

.treeviewlist.custom-viewlist .list-down {
	padding-right: 0;
	padding-left: 0;
}

.treeviewlist.custom-viewlist .list-down i {
	margin: 0 2px 0 0;
}

/* .treeviewlist.custom-viewlist .first-treeview li {
	padding-left: 25px;
} */

.treeviewlist.custom-viewlist .second-treeview li {
	padding-left: 40px;
	position: relative;
}

.treeviewlist.custom-viewlist .second-treeview.m-top {
	margin-top: 0;
}

.treeviewlist.custom-viewlist .second-treeview .hoverlist {
	padding: 10px 4px;
	margin: 10px 0 0 0;
}

.treeviewlist.custom-viewlist .second-treeview .hoverlist:after {
	content: "";
	position: absolute;
	background: #ecf1f7;
	width: 102%;
	height: 93%;
	left: -18px;
	border-radius: 4px;
	z-index: -1;
	top: 2px;
}

.w-60 {
	width: 60%;
}

.card-right-border:before {
	content: "";
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: #e9e9e9;
	right: 0;
}

.rightbar .custom-leftcol {
	width: 80%;
}

.rightbar .custom-rightcol {
	width: 20%;
}

.custom-row-workspace .customcol-2 {
	width: auto;
}

.custom-row-workspace .customcol-3 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

/* .editororderdetails-new {
	overflow: scroll;
} */

.editororderdetails-new .coordibox .card {
	width: auto;
	text-align: left;
	padding-top: 0;
	/* margin-bottom: 14px; */
	position: relative;
	height: 14rem;
	/* min-height: 317px; */
	cursor: pointer;
	@media (min-width: 1100px) {
		height: 17rem;
	}
}
.editororderdetails-new-editor .coordibox .card {
	/* width: 14.8em; */
	text-align: left;
	padding-top: 0;
	/* margin-bottom: 14px; */
	position: relative;
	height: 17rem;
	/* min-height: 317px; */
	cursor: pointer;
}

.editororderdetails-scroll {
	overflow: scroll;
	height: 45vh;
}

.form-label span {
	color: #c71515;
}

.formbold-main-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 48px;
}

.f-sz-13 {
	font-size: 13px;
}

.formbold-form-wrapper {
	margin: 0 auto;
	max-width: 550px;
	width: 100%;
	background: #fff;
}

.formbold-form-label {
	display: block;
	font-weight: 500;
	font-size: 16px;
	color: #07074d;
	margin-bottom: 12px;
}

.formbold-form-label-2 {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 20px;
}

.formbold-form-input {
	width: 100%;
	padding: 12px 24px;
	border-radius: 6px;
	border: 1px solid #e0e0e0;
	background: #fff;
	font-weight: 500;
	font-size: 16px;
	color: #6b7280;
	outline: none;
	resize: none;
}

.formbold-form-input:focus {
	border-color: #6a64f1;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
	text-align: center;
	font-size: 16px;
	border-radius: 6px;
	padding: 14px 32px;
	border: none;
	font-weight: 600;
	background-color: #6a64f1;
	color: #fff;
	cursor: pointer;
}

.formbold-btn:hover {
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
	margin-left: -12px;
	margin-right: -12px;
}

.formbold-px-3 {
	padding-left: 12px;
	padding-right: 12px;
}

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.w-full {
	width: 100%;
}

.formbold-file-input input {
	display: none;
}

.formbold-file-input label {
	position: relative;
	border: 2.5px dashed #e9e9e9;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px;
	text-align: center;
}

.formbold-drop-file {
	display: block;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: #a1a1a1;
	margin: 27px 0 0;
}

.formbold-mb-5 p {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #a1a1a1;
}

.border-lights-btn {
	border: 1px solid #184298 !important;
	color: #184298 !important;
	font-weight: 600 !important;
}

.formbold-or {
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: #a1a1a1;
	display: block;
	margin: 8px 11px;
}

.formbold-browse {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
	display: inline-block;
	padding: 11px 29px;
	border: 0;
	background: #125ecb;
	border-radius: 4px;
	margin: 8px 15px 18px;
	transition: 0.6s all;
	cursor: pointer;
}

.formbold-browse:hover {
	background: #0001c7 !important;
}

.formbold-file-list {
	border-radius: 6px;
	background: #f5f7fb;
	padding: 16px 32px;
}

.formbold-file-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.formbold-file-item button {
	color: #07074d;
	border: none;
	background: rgba(0, 0, 0, 0);
	cursor: pointer;
}

.formbold-file-name {
	font-weight: 500;
	font-size: 16px;
	color: #07074d;
	padding-right: 12px;
}

.formbold-progress-bars {
	margin-top: 20px;
	position: relative;
	width: 100%;
	height: 6px;
	border-radius: 8px;
	background: #e2e5ef;
}

.formbold-progress {
	position: absolute;
	width: 75%;
	height: 100%;
	left: 0;
	top: 0;
	background: #6a64f1;
	border-radius: 8px;
}

.setting-tab .nav-tabs {
	border-bottom: 0;
}

.setting-tab .nav-tabs .nav-link {
	font-size: 24px;
	color: #343a40;
	font-weight: 500;
	margin: 0 auto;
}

.text-blue {
	color: #184298 !important;
}

.ftp-box {
	background: #f5f5f5;
	border-radius: 4px;
	padding: 25px;
	margin-bottom: 30px;
	margin-top: 20px;
}

ul.ftp-list li {
	display: inline-block;
	width: 40%;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #0b0b0b;
}

ul.ftp-list {
	width: 80%;
	margin-bottom: 0;
}

ul.ftp-list li span {
	font-weight: 400;
	font-size: 15px;
	line-height: 15px;
	color: #0b0b0b;
}

.role-form input,
.role-form textarea.form-control,
.role-form .css-13cymwt-control {
	border: 1px solid #5e5e5e;
}

.role-form .css-13cymwt-control {
	border-color: #5e5e5e !important;
}

.role-form .customer-count {
	color: #a1a1a1;
	font-weight: 400;
}

#tooltip-top > .tooltip-inner {
	background-color: #fff;
	color: #000;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #125ecb;
	box-shadow: 0px 0px 8px #adadad;
	cursor: pointer;
	padding: 6px 24px;
}

.tooltip-inner {
	opacity: 1 !important;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
	border-right-color: #fff !important;
}

.tooltip.bs-tooltip-left .tooltip-arrow::before {
	border-left-color: #fff !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #fff !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: #fff !important;
}

.custom-new-table .btns {
	padding: 5px 22px;
}

.custom-new-table .cpl-gap-13 {
	grid-column-gap: 13px;
	column-gap: 13px;
}

.custom-new-table .form-check-input {
	width: 16px;
	height: 16px;
	border: 1px solid #5e5e5e;
	margin-left: -0.25rem;
}

.custom-new-table table {
	border: 0;
}

.custom-new-table table .edittd {
	visibility: hidden;
}

.custom-new-table table .edittd:hover svg {
	background-color: #000;
}

.custom-new-table table td {
	color: #5e5e5e;
	padding: 17px 12px !important;
}

.custom-new-table table td img {
	width: auto;
	height: auto;
	border-radius: 0;
}

.custom-new-table tr:hover .edittd {
	visibility: visible;
}

.border-box {
	position: relative;
	padding-top: 8px;
}

.border-box:before {
	content: " ";
	position: absolute;
	top: 0;
	left: -3%;
	right: 0;
	border-top: 1px solid #e9e9e9;
	width: -webkit-fill-available;
	height: 1px;
}

.thankyoubox {
	text-align: center;
	padding: 45px;
	margin-top: 35px;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.thankyoubox {
		padding: 20px;
	}
}

.thankyoubox .thankyoubox-sub {
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
	margin: 20px 0;
}

.thankyoubox .thankyoubox-order {
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: #5a616b;
	margin-bottom: 20px;
}

.thankyoubox .thankyoubox-order span {
	font-weight: 600;
	color: #0b0b0b;
}

.progress-bar-wrapper .progress-bars li {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	align-items: center;
	text-align: center;
	display: inline-block;
	color: #e9e9e9;
	position: relative;
	z-index: 1;
	width: 12%;
}

.progress-bar-wrapper .progress-bars li:last-child::before {
	display: none;
}

.progress-bar-wrapper .progress-bars li::before {
	content: "";
	width: 75%;
	height: 1px;
	background: #e9e9e9;
	position: absolute;
	margin: auto;
	left: 62%;
	top: 13px;
	z-index: -1;
}

.progress-bar-wrapper .progress-bars .progressactive {
	color: #636363;
	font-weight: 500;
}

.progress-bar-wrapper .progress-bars .progressactive .progressnumber {
	background: #e9e9e9;
}

.progress-bar-wrapper .progress-bars .progressvisited {
	color: #125ecb;
	font-weight: 500;
}

.progress-bar-wrapper .progress-bars .progressnumber {
	margin: 0 auto;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #e9e9e9;
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	color: #fff;
	text-align: center;
	margin-bottom: 7px;
}

.signup-1 .progress-bars li:first-child:before {
	color: #125ecb;
	background: #125ecb;
}

.signup-2 .progress-bars li:nth-child(2):before {
	color: #125ecb;
	border-color: #125ecb;
}

.signup-2 .progress-bars li:first-child:after {
	background: #215ace;
}

.signup-3 .progress-bars li:nth-child(3):before {
	color: #125ecb;
	border-color: #125ecb;
}

.signup-3 .progress-bars li:nth-child(2):after {
	background: #215ace;
}

.signup-4 .progress-bars li:nth-child(4):before {
	color: #125ecb;
	border-color: #125ecb;
}

.signup-4 .progress-bars li:nth-child(3):after {
	background: #215ace;
}

.imagesetting {
	width: 486px;
}

.imagesetting .form-group {
	margin-bottom: 10px;
}

.heads .section-title h2 {
	margin-bottom: 0;
}

.heads .section-title .date {
	font-weight: 400;
	font-size: 11px !important;
	line-height: 16px !important;
	color: #0b0b0b;
}

.heads .model-title {
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	align-items: center;
	color: #0b0b0b;
}

.custom-check {
	margin-left: 22px;
}

.sub-head {
	background: #f5f5f5;
	border-radius: 4px;
	padding: 11px;
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #0b0b0b;
}

.confirm-box {
	background: #fff;
	padding: 20px;
	position: sticky;
	bottom: 0;
	border-top: 1px solid #e9e9e9;
}

.confirm-box .confirm-box-price {
	grid-column-gap: 60px;
	column-gap: 60px;
}

.confirm-box .confirm-box-price .total-text p {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	margin: 0 19px 0 0;
}

.confirm-box .confirm-box-price .total-text p span {
	font-weight: 600;
	color: #0b0b0b;
	margin-left: 12px;
}

.confirm-box .confirm-box-price .total-text p .pricelist {
	font-size: 18px;
}

.confirm-box .total-text-link {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	color: #125ecb;
}

.custom-confirm-table .table {
	border: 0;
}

.custom-confirm-table .table tr th {
	background-color: rgba(0, 0, 0, 0);
	color: #000;
	border-top: 2px solid #ebedf2;
	border-bottom-width: 2px;
	padding: 11px 12px !important;
	font-size: 14px;
}

.custom-confirm-table .table tr td {
	padding: 11px 12px !important;
	color: #000;
}

@media (max-width: 767px) {
	.btn-cls {
		text-align: center;
	}

	.btn-cls button {
		margin-bottom: 12px;
	}

	ul.ftp-list {
		width: 100%;
	}

	ul.ftp-list li {
		width: 100%;
		text-align: center;
	}
}

.search-icon {
	background-image: url(/static/media/icon-search.b43b804c.svg);
	background-position: 15px center;
	background-repeat: no-repeat;
	padding: 0.375rem 0.75rem 0.375rem 2.5rem !important;
}

.editor.search-icon {
	background-image: url(/static/media/icon-search.b43b804c.svg);
	background-position: 15px center;
	background-repeat: no-repeat;
	padding: 0.375rem 0.75rem 0.375rem 2rem !important;
	width: 10%;
}

.editor.search-icon.focused {
	padding: 0.375rem 0.75rem 0.375rem 2.5rem !important;
	width: 85.5%;
}

.btn-edit {
	background-color: #ecf1f7 !important;
	color: #0b0b0b !important;
	font-size: 13px !important;
	padding: 10px 22px 10px 19px !important;
}

.btn-edit:hover {
	background-color: #ecf1f7 !important;
}

.btn-edit span {
	margin: 0px 7px 0 0;
}

@media (max-width: 575px) {
	.supervisor-overviewtab .customer-search {
		margin-bottom: 15px;
	}
}

@media (max-width: 575px) {
	.supervisor-overviewtab .tab-content h4 {
		font-size: 20px;
	}
}

@media (max-width: 575px) {
	.supervisor-overviewtab .detailstab .card-img-overlay {
		position: initial;
	}
}

@media (min-width: 576px) and (max-width: 1024px) {
	.supervisor-overviewtab .profile-details-list li {
		display: block;
		text-align: center;
	}

	.supervisor-overviewtab .profile-details-list li .titletext {
		width: 100%;
	}

	.supervisor-overviewtab .profile-details-list li .titlecontent {
		width: 100%;
	}
}

.supervisor-overviewtab .overviewtab {
	justify-content: space-between;
}

@media (min-width: 576px) and (max-width: 960px) {
	.supervisor-overviewtab .overviewtab {
		justify-content: space-between;
	}
}

.supervisor-overviewtab .overviewtab .card {
	width: 15.3%;
	margin-right: 0;
}

@media (min-width: 961px) and (max-width: 1024px) {
	.supervisor-overviewtab .overviewtab .card {
		width: 15% !important;
	}

	.supervisor-overviewtab .overviewtab .card:after {
		right: -15%;
	}
}

@media (min-width: 576px) and (max-width: 960px) {
	.supervisor-overviewtab .overviewtab .card {
		width: 33% !important;
		margin-right: 0 !important;
	}
}

@media (max-width: 575px) {
	.supervisor-overviewtab .overviewtab .card {
		width: 100% !important;
	}
}

.supervisor-overviewtab .overviewtab .bg-card {
	width: 17% !important;
	margin-right: 13px !important;
}

@media (min-width: 961px) and (max-width: 1024px) {
	.supervisor-overviewtab .overviewtab .bg-card {
		width: 18% !important;
	}
}

@media (min-width: 576px) and (max-width: 960px) {
	.supervisor-overviewtab .overviewtab .bg-card {
		width: 33% !important;
		margin-right: 0 !important;
	}

	.supervisor-overviewtab .overviewtab .bg-card .card-img-overlay {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: initial;
	}

	.supervisor-overviewtab .overviewtab .bg-card:after {
		display: none;
	}

	.supervisor-overviewtab .overviewtab .bg-card .card-img {
		height: 100px;
	}
}

@media (max-width: 575px) {
	.supervisor-overviewtab .overviewtab .bg-card {
		width: 100% !important;
		margin-right: 0 !important;
	}

	.supervisor-overviewtab .overviewtab .bg-card .card-img {
		height: 150px;
	}

	.supervisor-overviewtab .overviewtab .bg-card .card-img-overlay {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: initial;
		position: absolute;
	}
}

.supervisor-overviewtab .ftp-table-img {
	width: 20px;
	height: 20px;
	margin: 0 2px;
}

.hover-blue:hover {
	filter: invert(25%) sepia(93%) saturate(1478%) hue-rotate(196deg)
		brightness(103%) contrast(92%);
	cursor: pointer;
}

.assigned-team-table .hover-ftp {
	display: none;
}

.assigned-team-table table tr:hover .hover-ftp {
	display: inline-block;
}

.supervisor-staff .ftp-table-img {
	width: 20px;
	height: 20px;
	margin: 0 2px;
}

.supervisor-staff .hover-ftp {
	display: none;
}

.supervisor-staff table tr:hover .hover-ftp {
	display: inline-block;
}

.sub-heading {
	font-family: "Heebo";
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	color: #0b0b0b;
}

.supervisor-performance-tab {
	justify-content: space-between;
}

.supervisor-performance-tab .card {
	margin-right: 0;
	width: 13.5%;
}

.grey-btn {
	background-color: #ecf1f7 !important;
	border-color: 1px solid #ecf1f7 !important;
}

.workspace .right-toggle {
	text-align: right;
	padding-right: 0;
}

.workspace .right-toggle img {
	width: 30px;
}

.workspace .right-toggle .togler-menu {
	position: initial;
}

.toggleSwitch span span {
	display: none;
}

@media only screen {
	.toggleSwitch {
		display: inline-block;
		height: 26px;
		position: relative;
		overflow: visible;
		padding: 0;
		margin: 5px 0 0 16px;
		cursor: pointer;
		width: 54px;
		-webkit-user-select: none;
		        user-select: none;
		vertical-align: middle;
	}

	.toggleSwitch * {
		box-sizing: border-box;
	}

	.toggleSwitch label,
	.toggleSwitch > span {
		line-height: 26px;
		height: 26px;
		vertical-align: middle;
	}

	.toggleSwitch input:focus ~ a,
	.toggleSwitch input:focus + label {
		outline: none;
	}

	.toggleSwitch label {
		position: relative;
		z-index: 3;
		display: block;
		width: 100%;
	}

	.toggleSwitch input {
		position: absolute;
		opacity: 0;
		z-index: 5;
	}

	.toggleSwitch > span {
		position: absolute;
		left: 0;
		width: 100%;
		margin: 0;
		padding-right: 0;
		text-align: left;
		white-space: nowrap;
	}

	.toggleSwitch > span span {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		display: block;
		width: 50%;
		margin-left: 0;
		text-align: left;
		font-size: 13px;
		font-weight: 400;
		width: 100%;
		left: 15%;
		top: -1px;
		opacity: 0;
	}

	.toggleSwitch a {
		position: absolute;
		right: 50%;
		z-index: 4;
		display: block;
		height: 100%;
		padding: 0;
		left: 2px;
		width: 20px;
		height: 20px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 100%;
		transition: all 0.2s ease-out;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	}

	.toggleSwitch > span span:first-of-type {
		color: #ccc;
		opacity: 1;
		left: 45%;
	}

	.toggleSwitch > span:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: -2px;
		background-color: #fafafa;
		border: 1px solid #ccc;
		border-radius: 30px;
		transition: all 0.2s ease-out;
	}

	.toggleSwitch input:checked ~ a {
		border-color: #fff;
		left: 100%;
		margin-left: -22px;
	}

	.toggleSwitch input:checked ~ span:before {
		border-color: #008e1a;
		box-shadow: inset 0 0 0 30px #008e1a;
	}

	.toggleSwitch input:checked ~ span span:first-of-type {
		opacity: 0;
	}

	.toggleSwitch input:checked ~ span span:last-of-type {
		opacity: 1;
		color: #fff;
	}
}

.workbody .txt-date {
	font-weight: 400;
	font-size: 10px !important;
	line-height: 15px !important;
	color: #5a616b;
	margin: 0 0 10px;
}

.supervisor-qc-canvas .qc-big-img {
	width: 100%;
}

.supervisor-qc-canvas .qc-canvas-big {
	border-top: none;
}

.supervisor-qc-canvas .mobile-edit-canvas {
	border-right: none;
}

.supervisor-qc-canvas .qc-edit-canvas {
	padding: 0;
}

.supervisor-qc-canvas .qc-pageitem {
	margin: 0;
	padding: 0 20px 0 0;
	justify-content: right;
}

.supervisor-qc-canvas .qc-row {
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #c8c8c8;
}

.supervisor-qc-canvas .canvas-tool-main {
	justify-content: initial;
}

.txt-left {
	padding-left: 0;
}

.supervisor-chat .customertab {
	max-width: 150px;
	margin: 0 0 0 12px;
}

.supervisor-chat .time {
	font-size: 10px !important;
}

.supervisor-list {
	overflow-y: scroll;
	height: 45vh;
}

.supervisor-list .profilepic {
	margin-left: 20px;
}

.supervisor-list li span {
	font-weight: 500;
}

.online-active {
	position: relative;
}

.online-active:after {
	content: "";
	position: absolute;
	background-color: #ecf1f7;
	z-index: -1;
	width: 106%;
	height: 59px;
	right: 0;
}

.s-left-0 {
	padding-left: 0 !important;
}

.supervisor-chat-wrap {
	padding-left: 0 !important;
}

.supervisor-chat-wrap .chatsidebar {
	padding-right: 0;
}

.supervisor-chat-wrap .s-list {
	padding-right: 0;
}

.supervisor-chat-wrap .section-title {
	padding-left: 20px;
}

.supervisor-chat-wrap .customertab {
	margin-left: 20px !important;
}

.supervisor-chat-wrap .chatsidebar h4.mb-3 {
	padding-left: 20px;
}

.supervisor-chat-wrap .chatsidebar form {
	padding-left: 20px;
	padding-right: 20px;
}

.supervisor-group-heading {
	padding: 0px 8px 0 23px;
}

@media (max-width: 768px) {
	.supervisor-dash-card.pipeline .card {
		border-bottom: 1px solid #e9e9e9 !important;
	}

	.supervisor-dash-card.pipeline .card-img-overlay {
		top: 0;
		transform: initial;
		left: 0;
		right: 0;
		position: absolute;
		margin: 0 auto;
	}
}

@media (min-width: 576px) and (max-width: 768px) {
	.supervisor-dash-card.pipeline .bg-card:after {
		display: none !important;
	}

	.supervisor-dash-card.pipeline .card:after {
		content: "";
		position: absolute;
		top: 50%;
		width: 5px;
		left: 101%;
		transform: translate(-50%, -50%);
		z-index: 9;
		border-right: 1px solid #dedede;
		height: 59px;
		display: block;
	}

	.supervisor-dash-card.pipeline .card:last-child:after {
		display: none;
	}
}

@media (min-width: 767px) and (max-width: 1200px) {
	.supervisor-dash-card {
		width: 80% !important;
	}
}

@media (max-width: 1024px) {
	.supervisor-dash-table .order,
	.supervisor-dash-table .react-bootstrap-table-sort-order {
		display: none;
	}
}

@media (max-width: 768px) {
	.supervisor-report .card-img-overlay {
		position: initial;
	}
}

@media (max-width: 1400px) {
	.supervisor-report-table .order,
	.supervisor-report-table .react-bootstrap-table-sort-order {
		display: none;
	}
}

@media (max-width: 767px) {
	.customer-report-search .col-2,
	.customer-report-search .col-3 {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.customer-report-search .col-2 {
		width: 24%;
	}
}

@media (max-width: 767px) {
	.customer-count {
		font-size: 12px !important;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.chart-filter .col-2 {
		width: 24%;
	}
}

@media (min-width: 1024px) and (max-width: 1379px) {
	.custom-workspace-body .custom-row-workspace .customcol-2 {
		width: 27%;
	}

	.custom-workspace-body .custom-row-workspace .customcol-7 {
		width: 44%;
	}

	.custom-workspace-body .custom-row-workspace .customcol-3 {
		width: 28%;
	}

	.custom-workspace-body .editororderdetails-new {
		padding-bottom: 30px;
	}
	.custom-workspace-body .editororderdetails-new-editor {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new .coordibox .card {
		width: 43%;
	}
	.custom-workspace-body .editororderdetails-new-editor .coordibox .card {
		width: 43%;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.custom-workspace-body .custom-row-workspace .customcol-2 {
		width: 25%;
	}

	.custom-workspace-body .custom-row-workspace .customcol-7 {
		width: 47%;
	}

	.custom-workspace-body .custom-row-workspace .customcol-3 {
		width: 28%;
	}

	.custom-workspace-body .editororderdetails-new {
		padding-bottom: 30px;
	}
	.custom-workspace-body .editororderdetails-new-editor {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new .coordibox .card {
		width: 46%;
	}
	.custom-workspace-body .editororderdetails-new-editor .coordibox .card {
		width: 46%;
	}
}

@media (max-width: 768px) {
	.custom-workspace-body .treeviewlist.custom-viewlist li {
		font-size: 13px;
	}

	.custom-workspace-body .custom-flex {
		display: flex;
	}

	.custom-workspace-body .custom-row-workspace .customcol-2 {
		width: 100%;
	}

	.custom-workspace-body .custom-row-workspace .customcol-3 {
		width: 100%;
	}

	.custom-workspace-body .editororderdetail {
		padding-bottom: 10px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.custom-workspace-body .editororderdetails-new {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new .coordibox .card {
		width: 31.2%;
	}
	.custom-workspace-body .editororderdetails-new-editor {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new-editor .coordibox .card {
		width: 31.2%;
	}
}

@media (max-width: 575px) {
	.custom-workspace-body .editororderdetails-new {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new .coordibox .card {
		width: 100%;
	}
	.custom-workspace-body .editororderdetails-new-editor {
		padding-bottom: 30px;
	}

	.custom-workspace-body .editororderdetails-new-editor .coordibox .card {
		width: 100%;
	}
}

@media (max-width: 575px) {
	.custom-btn {
		flex: initial !important;
		margin: 20px 0 0 0 !important;
	}
}

@media (max-width: 767px) {
	.custom-flex-btn {
		display: flex;
	}
}

.uploadimg img {
	max-width: 154px;
	width: 100%;
}

@media (max-width: 575px) {
	.custom-overview .stafftab {
		display: block;
	}

	.custom-overview .stafftab .nav-link {
		display: block;
	}

	.custom-overview .bg-card .card-img {
		height: 145px;
	}

	.custom-overview .supervisor-performance-tab .card {
		width: 100%;
	}
}

@media (min-width: 575px) and (max-width: 960px) {
	.custom-overview .supervisor-performance-tab {
		display: flex;
		flex-wrap: wrap;
	}

	.custom-overview .supervisor-performance-tab .card {
		width: 33%;
	}
}

@media (min-width: 575px) and (max-width: 768px) {
	.custom-overview .bg-card .card-img {
		height: 145px;
	}

	.custom-overview .titletext,
	.custom-overview .titlecontent {
		width: 100%;
	}

	.custom-overview .profile-details-list li {
		display: block;
		text-align: center;
	}
}

@media (min-width: 768px) and (max-width: 960px) {
	.custom-overview .staffoverview .card {
		width: 100px;
	}

	.custom-overview .staffoverview .bg-card {
		width: 130px;
	}

	.custom-overview .titletext,
	.custom-overview .titlecontent {
		width: 100%;
	}

	.custom-overview .profile-details-list li {
		display: block;
		text-align: center;
	}
}

@media (max-width: 576px) {
	.supervisor-chat-wrap .supervisor-group-heading {
		display: flex;
	}

	.supervisor-chat-wrap .customertab .nav-item .nav-link {
		font-size: 10px;
	}

	.supervisor-chat-wrap .chatmessage {
		position: relative !important;
	}

	.supervisor-chat-wrap .chatmessage .chatmsg {
		display: flex;
	}
}

@media (max-width: 769px) {
	.supervisor-chat-wrap .chatmessage {
		position: relative !important;
	}

	.supervisor-chat-wrap .chatmessage .chatmsg {
		display: flex;
	}
}

@media (min-width: 575px) {
	.supervisor-chat-wrap .s-list::after,
	.supervisor-chat-wrap .u-list::after {
		content: "";
		position: absolute;
		width: 1px;
		height: 100vh;
		background: #e9e9e9;
		right: 0;
		top: 0;
	}
}

.chattabcontent.s-left-0 {
	padding-right: 0;
}

.main-panel .customrightbar {
	padding-top: 0;
	width: -webkit-fill-available;
	margin-left: 8px;
	margin-right: 8px;
}

.main-panel .customrightbar .custom-chat-row .tab-content {
	top: 0;
}

.main-panel .customrightbar .section-title {
	position: fixed;
	top: 86px;
	z-index: 12;
	width: 100%;
}

.main-panel .customrightbar .doticon {
	top: 145px;
	z-index: 2;
	position: fixed;
	right: 20px;
}

.main-panel .customrightbar .stafftab {
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 6;
	padding: 92px 73px 0px 0px;
	height: auto;
	margin-top: 0;
}

.main-panel .customrightbar .tab-content {
	top: 135px;
}

.custom-scroll-bar {
	overflow: scroll;
	height: 75vh;
	scrollbar-width: thin;
}
.scroll-y--axis {
	height: 20.85rem;
	scroll: auto;
	overflow-y: auto;
	width: 100%;
	overflow-x: hidden;
	margin-left: 6px;
}
.coordinator-negative--margin {
	margin-top: -4em;
	/* position: fixed; */
	/* z-index: 2; */
}

.width-94 {
	/* width: 91%; */
}
.width-auto {
	width: auto;
}
.position--absolute {
	position: absolute;
}
/* Define a CSS class for the hover effect */
.hovered-edit {
	fill: blue; /* Initial color */
	transition: fill 0.3s; /* Add a smooth transition effect */
}

/* Apply the hover effect when hovering over the SVG */
svg:hover .hovered-edit {
	fill: red; /* Color to change to on hover */
}
.css-1jqq78o-placeholder {
	grid-area: 1/1/2/3;
	color: hsl(0, 0%, 50%);
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box;
	width: max-content;
}
.css-3iigni-container {
	width: max-content;
	pointer-events: none;
	position: relative;
	box-sizing: border-box;
	visibility: visible;
	flex: 1 1 auto;
	display: contents;
	grid-area: 1/1/2/3;
	grid-template-columns: 0 min-content;
	margin: 2px;
	padding-bottom: 2px;
	padding-top: 2px;
	color: hsl(0, 0%, 20%);
	box-sizing: border-box;
}
.checkbox-order--duplicate {
	border-radius: 2px;
	width: 16px;
	height: 15px;
	position: absolute;
	right: 12px;
	top: 9px;
}
/* SimpleDropdown.css */
/* Style for the dropdown container */
.dropdown--duplicate {
	position: relative;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	height: 3rem;
}
.dropdown-button--duplicate {
	/* max-width: 23rem; */
	width: 100%;
	display: flex;
	/* margin-top: 9px; */
	height: 2rem;
	align-items: center;
}

/* Style for the dropdown content */
/* SimpleDropdown.css */
/* Style for the dropdown container */
.dropdown--duplicate {
	position: relative;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	height: 3rem;
}
.dropdown-button--duplicate {
	/* max-width: 23rem; */
	width: 100%;
	display: flex;
	/* margin-top: 9px; */
	height: 2rem;
}

/* Style for the dropdown content */
.dropdown-content {
	position: absolute;
	background-color: #f9f9f9;
	/* min-width: 160px; */
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-top: 15px;
	/* max-width: 22.2rem; */
	width: 100%;
	margin-left: -9px;
	overflow: scroll;
	height: 10rem;
}

/* Style for dropdown items */
.dropdown-content span {
	padding: 12px 16px;
	text-decoration: none;
	display: flex;
	color: #333;
	justify-content: space-between;
}

/* Style for dropdown items on hover */
.dropdown-content span:hover {
	background-color: #ddd;
}

/* Show the dropdown content when the button is clicked */
.dropdown.open .dropdown-content {
	display: block;
}
.dropdown-duplicate-image--modal {
	position: relative;
	background-clip: border-box;
	height: 3rem;
	display: flex;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #efefef;
	margin-left: 12px;
	color: #5a5a5a;
}
.dropdown-duplicate-image--newmodal {
	height: 2.7rem !important;
	justify-content: center;
}
.dropdown-content-duplicate-image--modal {
	position: absolute;
	background-color: black;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-top: 7px;
	max-width: 49rem;
	width: -webkit-fill-available;
	overflow-y: scroll;
	height: 10rem;
	scrollbar-width: thin;
}
/* Style for dropdown items */
.dropdown-content-duplicate-image--modal span {
	padding: 13px 16px;
	text-decoration: none;
	display: flex;
	color: white;
	justify-content: space-between;
}

/* Style for dropdown items on hover */
.dropdown-content-duplicate-image--modal span:hover {
	background-color: #ddd;
	color: black;
}

/* Show the dropdown content when the button is clicked */
.dropdown.open .dropdown-content-duplicate-image--modal {
	display: block;
}
.custom-column {
	width: 50%;
}
.sidebar-specifications {
	/* max-width: 22.5em; */
	width: auto;
}
.coordinator .sidebar-specifications {
	/* width: 22em; */
	/* border-top: 1px solid rgba(0, 0, 0, 0.2) !important; */
}
.coordinator .border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 23px;
	width: 100%;
}
.edit-setting--select {
	position: absolute;
	max-width: 22.3em;
	width: auto;
}
.sidebar--select {
	/* width: fit-content; */
	height: 90vh;
	padding: 0px;
}
.sidebar-select--editType {
	/* height: 67vh; */
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
	padding-top: 18px;
	padding-left: 10px;
	/* padding-right: 10px; */
	height: calc(100vh - 330px);
	scrollbar-width:thin;
}
.sidebar-select--editType .form-group{
	margin-bottom: 10px;
}
.sidebar-select--editType.cs {
	height: 75vh !important;
}
.sidebar-select--editType.editSettings {
	padding-top: 6px !important;
}

.edit-setting-sidebar {
	margin-left: auto;
	margin-right: auto;
	width: auto;
}
.custom-row-workspace {
	width: 100%;
	display: grid !important;
	grid-template-columns: 3fr 9fr;
	grid-gap: 15px;
	gap: 15px;
}
.ml-auto {
	margin-left: auto;
}
.tabs-wrap--no-wrap {
	overflow-x: scroll;
	overflow-y: hidden;
	position: relative;
	width: 35em;
	flex-wrap: nowrap !important;
}
.btn-secondary--bg {
	background-color: #ecf1f7 !important;
	border: 0 !important;
	transition: 0.6s all;
	color: #0b0b0b;
}
.sidebar-select--header {
	width: -moz-fit-content;
	width: fit-content;
	justify-content: end;
	display: flex;
	padding-bottom: 0;
	margin-bottom: -10px;
	margin-left: auto;
	margin-top: 11px;
}
@media (min-width: 1980px) {
	.col-sm-7 {
		flex: 0 0 auto;
		width: 67.333333%;
	}
}
@media (max-width: 1479px) {
	/* Apply styles for screen widths below 1480 pixels */

	.sidebar-image--name {
		/* max-width: fit-content;
		width: fit-content; */
		/* margin-left: 5px; */
	}
	.sidebar-specifications {
		position: absolute;
		/* width: 20.5em; */
	}
	.edit-setting--select {
		position: absolute;
		max-width: 21.5em;
		width: auto;
	}

	.col-gap-201 {
		flex-direction: column;
	}
}
.mr-auto {
	margin-right: auto;
}
.btn-pending {
	margin: 3px;
	color: #f7f7f7;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}

.switch > .row {
	padding-bottom: 2rem;
	vertical-align: middle;
	text-align: center;
	border-bottom: 1px solid rgba(189, 193, 200, 0.5);
}

.switch h3 {
	font-weight: 400;
}
.switch h3 > small {
	font-weight: 200;
	font-size: 0.75em;
	color: #939aa5;
}
.switch h6 {
	font-weight: 700;
	font-size: 0.65rem;
	letter-spacing: 3.32px;
	text-transform: uppercase;
	color: #bdc1c8;
	margin: 0;
	line-height: 5rem;
}
.switch .btn-toggle {
	top: 50%;
	transform: translateY(-50%);
}
.btn-toggle {
	margin: 3px;
	color: #f7f7f7;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	border: 0;
	white-space: nowrap;
	line-height: 19px;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
	outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
	line-height: 1.5rem;
	width: 4rem;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle:before {
	content: "Off";
	left: -4rem;
}
.btn-toggle:after {
	content: "On";
	right: -4rem;
	opacity: 0.5;
}
.btn-toggle > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.active {
	transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
	left: 1.6875rem;
	transition: left 0.25s;
}
.btn-toggle.active:before {
	opacity: 0.5;
}
.btn-toggle.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
	line-height: -0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.4125rem;
	width: 2.325rem;
}
.btn-toggle.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
	display: none;
}
.btn-toggle:before,
.btn-toggle:after {
	color: #6b7381;
}
.btn-toggle.active {
	background-color: hsl(132.94deg 100% 50%);
}
.btn-toggle.btn-lg {
	margin: 0 5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 2.5rem;
	width: 5rem;
	border-radius: 2.5rem;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
	outline: none;
}
.btn-toggle.btn-lg:before,
.btn-toggle.btn-lg:after {
	line-height: 2.5rem;
	width: 5rem;
	text-align: center;
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-lg:before {
	content: "Off";
	left: -5rem;
}
.btn-toggle.btn-lg:after {
	content: "On";
	right: -5rem;
	opacity: 0.5;
}
.btn-toggle.btn-lg > .handle {
	position: absolute;
	top: 0.3125rem;
	left: 0.3125rem;
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 1.875rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
	left: 2.8125rem;
	transition: left 0.25s;
}
.btn-toggle.btn-lg.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-lg.active:after {
	opacity: 1;
}
.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
	line-height: 0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.6875rem;
	width: 3.875rem;
}
.btn-toggle.btn-lg.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-lg.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-lg.btn-xs:before,
.btn-toggle.btn-lg.btn-xs:after {
	display: none;
}
.btn-toggle.btn-sm {
	margin: 0 0.5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 2em;
	width: 8em;
	border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
	outline: none;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
	line-height: 1.5rem;
	width: 0.5rem;
	text-align: center;
	font-weight: 800;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-sm:before {
	content: "Off";
	left: -1.5rem;
}
/* .btn-toggle.btn-sm:after {
	content: "Pending";
	left: 1.88rem;
	opacity: 0.5;
} */
.btn-toggle.btn-sm > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-sm.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-sm.active > .handle {
	left: 4.9875rem;
	transition: left 0.25s;
}
.btn-toggle.btn-sm.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
	line-height: -0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.4125rem;
	width: 2.325rem;
}
.btn-toggle.btn-sm.btn-sm:before {
	text-align: -webkit-right;
	left: 4em;
	margin: 0 0px 1px 2px;
}
.btn-toggle.btn-sm.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
	display: none;
}
.btn-toggle.btn-xs {
	margin: 0 0;
	padding: 0;
	position: relative;
	border: none;
	height: 1rem;
	width: 2rem;
	border-radius: 1rem;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
	outline: none;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
	line-height: 1rem;
	width: 0;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle.btn-xs:before {
	content: "Off";
	left: 2.87em;
}
.btn-toggle.btn-xs:after {
	content: "On";
	right: 0;
	opacity: 0.5;
}
.btn-toggle.btn-xs > .handle {
	position: absolute;
	top: 0.125rem;
	left: 0.125rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.75rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle.btn-xs.active {
	transition: background-color 0.25s;
}
.btn-toggle.btn-xs.active > .handle {
	left: 1.125rem;
	transition: left 0.25s;
}
.btn-toggle.btn-xs.active:before {
	opacity: 0.5;
}
.btn-toggle.btn-xs.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
	line-height: -1rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.275rem;
	width: 1.55rem;
}
.btn-toggle.btn-xs.btn-sm:before {
	text-align: right;
}
.btn-toggle.btn-xs.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
	display: none;
}
.btn-toggle.btn-secondary {
	color: #6b7381;
	background: #bdc1c8;
}
.btn-toggle.btn-secondary:before,
.btn-toggle.btn-secondary:after {
	color: #6b7381;
}
.btn-toggle.btn-secondary.active {
	background-color: #ff8300;
}
.margin-18 {
	margin: 18px;
}
.padding-left-3 {
	padding-left: 3px;
}
.padding-left-4 {
	padding-left: 4px;
}

