@media (min-width: 200px) and (max-width: 900px) {
	h1 {
		font-size: 3em;
	}

	h2 {
		font-size: 2em;
	}
	.mobile-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 50%;
		margin-bottom: 50%;
	}

	.mobile--text {
		/* Your text styles */
		text-align: center;
		background: #fff;
		position: relative;
		padding-top: 12px;
		width: max-content;
	}
	.mobile--text h2 {
		font-size: 13px !important;
		font-weight: 600;
	}
	.detailslist {
		/* grid-template-columns: repeat(auto-fit, 14.8rem) !important; */
		overflow: scroll;
		overflow-x: hidden;
		/* width: 17em !important; */
	}
	/* .custom-row-workspace {
		width: 990px !important;
	} */
	.col-md-7 {
		width: 17em !important;
	}
	/* .editororderdetails {
		width: 34em !important;
	} */
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 16.8rem);
		overflow: scroll;
		overflow-x: hidden;
		justify-content: start;
		height: 71vh;
		grid-column-gap: 20px;
		grid-column-gap: 20px;
		column-gap: 20px;
		width: 50em;
	}
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 15.9rem);
		width: 53em;
	}
}
