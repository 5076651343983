#date_range {
  width: 0px;
  height: 0px;
  background: #fff;
  border-color: #fff;
  visibility: hidden;
  display: none;
  margin-top: 4px;
}


 .duplicate-compulsory-field span , .compulsory-field span {
  color: #3073f5 !important;
  font-weight: bold !important;
  font-size: 18px;
  padding-left: 5px;
  vertical-align : -webkit-baseline-middle;
} 

.duplicate-compulsory-field-error span , .compulsory-field-error span {
  color: #a92322 !important;
  font-weight: bold !important;
  font-size: 18px;
  padding-left: 5px;
  vertical-align : -webkit-baseline-middle;
}

ul.duplicate-compulsory-field-error li input[value=""] , div.duplicate-compulsory-field-error input[value=""] 
, .compulsory-field-error + div input.form-control[value=""] , .compulsory-field-error + div input.field_error {
  border:  1px solid #a92322 !important;
}


.phoneNumberParent .PhoneInputCountry {
    display: inline-block;
    margin-top: 6px;
}

.PhoneInputInput {
  position: absolute;
  border: none;
  margin-top: -3px;
  width: calc(90% - 15%);
  margin-top: 3px;
  padding-left: 5px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.phoneNumberParent .PhoneInput:focus-within {
  border: 1px solid #3073f5;
}

.PhoneInputCountrySelect {
  display: none;
}

.dynamic-country-list {
    margin-top: -41px;
}

.duplicate-footer-custom-height {
  height: 4px;
}
.time .react-time-picker__wrapper {
  border : none !important;
}

.react-time-picker__inputGroup__input:focus {
  background: #fff !important;
}

.react-time-picker__inputGroup__input:invalid {
  background: #fff !important;
}

.modalHeight .success {
  white-space: pre;
  padding-top: 2.90rem;
}


.modalHeight .err {
  white-space: pre;
  padding-top: 2rem;
}

