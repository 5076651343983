.ml_4_percent {
  margin-left: 4.8%;
}

.ml_3_percent {
  margin-left: 3%;
}

.ml_2_percent {
  margin-left: 2%;
}

.ml_1_percent {
  margin-left: 1%;
}

.mbn_16 {
  margin-bottom: 16px;
}

.show_left_border {
  border-left: 2px solid #ececec !important;
  background: #fff;
}

.no-border {
  border-right: unset !important;
}

.mg-bottom15 {
  margin-bottom: -15px;
}

.mg-bottom16 {
  margin-bottom: -16px;
}

.pl-7{
  padding-left : 7px;
}

.ml-7{
  margin-left : 7px;
}
.ml-8{
  margin-left : 8px;
}

.ml-12{
  margin-left : 12px;
}

.ml-32{
  margin-left : 2rem;
}

.css-olqui2-singleValue {
 font-size: 13px !important;
}

.coordinator input:disabled { /* WebKit browsers */
  color: #A1A1A1;
  background-color: #F2F2F2 !important;
}
.coordinator input:disabled::-webkit-input-placeholder { /* WebKit browsers */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:#A1A1A1;
  opacity: 0.6;
}
.coordinator input:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:#A1A1A1;
  opacity: 0.6;
}

div[class*="-control"] {
  font-size: 13px !important;
  height: 41px !important;
}

.right_img_corner {
  float: right;
  position: inherit;
  margin-left: 18px;
  margin-top: 3.9px;
  width: 14px;
}

.client-order-settings {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 35px 35px 0;
  border-color: transparent #746DAD transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}