@media only screen and (min-width: 800px) and (max-width: 1024px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 17.2em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 32em !important;
	}
	.editororderdetail {
		height: 90vh;
		/* width: 14em; */
	}
	.editororderdetails_editor {
		height: 90vh;
	}
	.edit-setting--select {
		position: absolute;
		max-width: 16.5em;
		width: auto;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 15em !important;

		height: 24rem !important; */
		cursor: pointer !important;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 20.5em !important;
		height: 24rem !important;
		cursor: pointer !important;
	}
	/* .detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 16.6rem) !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1025px) and (max-width: 1025px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}
}
@media (min-width: 1026px) and (max-width: 1362px) {
	.col-md-7 {
		flex: none !important;
		width: 28.5em !important;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 27.5em !important;
	}

	.col-md-6 {
		flex: 0 0 auto;
		width: 30%;
	}
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 11.6rem);
		overflow: scroll;
		overflow-x: hidden;
		justify-content: start;
		height: 71vh;
		grid-column-gap: 20px;
		grid-column-gap: 20px;
		column-gap: 47px;
		width: 50em;
	}
	.detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 17.9rem) !important;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 13.2em !important;
	}
}
@media (min-width: 900px) and (max-width: 1199px) {
	.col-md-7 {
		flex: none !important;
		width: 16em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 16em !important;
	}
	/*  {
		width: 16em !important;
	} */
	.detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 12.6rem) !important;
		column-gap: 50px !important;
	}
	.col-sm-10 {
		flex: 0 0 auto;
		/* width: 68.333333% !important; */
	}
	.modal-image--order {
		min-height: 83vh;
		height: auto;
	}
	/* .detailslist_editor {
		width: 53em !important;
		grid-template-columns: repeat(auto-fit, 12.9rem) !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 13.8em !important;
	} */
}
@media (min-width: 1400px) and (max-width: 1500px) {
	.coordinator .sidebar-specifications {
		width: 19em !important;
	}
	.col-sm-9 {
		flex: 0 0 auto;
		width: 69%;
	}
	.col-md-7 {
		width: 39em !important;
	}
	.col-md-9 {
		width: 39em !important;
	}
	/* .detailslist {
		grid-template-columns: repeat(auto-fit, 11.2rem) !important;
		width: 41em !important;
		grid-column-gap: 20px !important;
		column-gap: 28px !important;
	} */
	.detailslist_editor {
		grid-template-columns: repeat(auto-fit, 18.2rem) !important;
		width: 60em !important;
		grid-column-gap: 20px !important;
		column-gap: 28px !important;
	}

	/* .editororderdetails-new .coordibox .card {
		 width: 12.5em !important; 
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 19.5em !important;
	}
}
@media (min-width: 1200px) and (max-width: 1279px) {
	.col-md-7 {
		width: 26em !important;
	}
	.col-md-9 {
		flex: none !important;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 12.666667% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 12em !important;
		height: 20rem !important; */
		cursor: pointer !important;
	}
	/* .detailslist {
		width: 32em !important;
		grid-template-columns: repeat(auto-fit, 11.8rem) !important;
	} */
	.detailslist_editor {
		display: grid;
		grid-template-columns: repeat(auto-fit, 14.8rem) !important;
		width: 50em;
	}
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1280px) and (max-width: 1400px) {
	.col-md-7 {
		width: 26em !important;
	}
	.col-md-9 {
		flex: none !important;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 12.666667% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 12em !important;
		height: 24rem !important; */
		cursor: pointer !important;
	}
	/* .detailslist {
		width: 26em !important;
		grid-template-columns: repeat(auto-fit, 11.5rem) !important;
	} */
	/* .detailslist_editor {
		grid-template-columns: repeat(auto-fit, 14.6em) !important;
		column-gap: 35px !important;
	} */
}
@media (min-width: 1280px) and (max-width: 1367px) {
	.col-sm-2 {
		flex: 0 0 auto;
	}
	.col-sm-6 {
		flex: 0 0 auto;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 16.2em !important;
	} */
}
@media (min-width: 1501px) and (max-width: 1600px) {
	.col-sm-9 {
		flex: 0 0 auto;
		width: 69%;
	}
	.col-md-7 {
		width: 41em !important;
	}
	.col-md-9 {
		width: 41em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: auto;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 213px !important;
		height: 20rem !important; */
		column-gap: 20px !important;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 240px !important;
		height: 20rem !important;
		column-gap: 20px !important;
	} */
	/* .detailslist {
		width: 46em !important;
		grid-template-columns: repeat(auto-fit, 12.6rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 18px !important;
	} */
	/* .detailslist_editor {
		width: 72em !important;
		grid-template-columns: repeat(auto-fit, 14.6rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 18px !important;
	} */
}
@media (min-width: 1601px) and (max-width: 1799px) {
	.col-md-7 {
		flex: none !important;
		width: 48em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 48em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	/* .editororderdetails-new .coordibox .card {
		width: 15.4em !important;
		height: 23rem !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18.4em !important;
		height: 23rem !important;
	} */
	/* .detailslist {
		width: 51em !important;
		grid-template-columns: repeat(auto-fit, 14.8rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 20px !important;
	} */
	/* .detailslist_editor {
		width: 77em !important;
		grid-template-columns: repeat(auto-fit, 17.9rem) !important;
		grid-column-gap: 27px !important;
		column-gap: 20px !important;
	} */
}
@media (min-width: 1800px) and (max-width: 1898px) {
	.col-md-7 {
		width: 56em !important;
	}
	.col-md-9 {
		width: 56em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	/* .detailslist {
		width: 69em !important;
		grid-template-columns: repeat(auto-fit, 17.4rem) !important;
	} */
	/* .detailslist_editor {
		width: 85em !important;
		grid-template-columns: repeat(auto-fit, 19.8rem) !important;
	} */
	/* .editororderdetails-new .coordibox .card {
		width: 18em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 20em !important;
		height: 24rem !important;
	} */
}
@media (min-width: 1899px) and (max-width: 1980px) {
}
@media (min-width: 1981px) and (max-width: 2080px) {
}
@media (min-width: 1899px) and (max-width: 3000px) {
	.col-md-7 {
		flex: none !important;
		width: 67em !important;
	}
	.col-md-9 {
		flex: none !important;
		width: 67em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 18em !important;
		height: 26rem !important; */
		column-gap: 20px;

		cursor: pointer;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18em !important;
		height: 26rem !important;
		column-gap: 20px;

		cursor: pointer;
	} */

	/* .detailslist {
		width: 66em !important;
		grid-template-columns: repeat(auto-fit, 20.9rem) !important;
	} */

	/* .editororderdetails-new .coordibox .card {
		width: 21em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 21em !important;
	} */
	/* .detailslist_editor {
		width: 116em !important;
        grid-template-columns: repeat(auto-fit, 19.9rem) !important;
		grid-column-gap: 26px !important;
		column-gap: 26px !important;
	} */
}
@media (min-width: 3001px) and (max-width: 5000px) {
	.col-md-7 {
		width: 69% !important;
	}
	.col-md-9 {
		width: 67em !important;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 85% !important;
	}
	.editororderdetails-new .coordibox .card {
		/* width: 18em !important;
		height: 26rem !important; */
		column-gap: 20px;

		cursor: pointer;
	}
	/* .editororderdetails-new-editor .coordibox .card {
		width: 18em !important;
		height: 26rem !important;
		column-gap: 20px;

		cursor: pointer;
	} */
	/* .detailslist {
		width: 120em !important;
		grid-template-columns: repeat(auto-fit, 21rem) !important;
	} */
	/* .detailslist_editor {
		width: 158em !important;
		grid-template-columns: repeat(auto-fit, 20.98rem) !important;
	} */
	/* .editororderdetails-new .coordibox .card {
		width: 20em !important;
	} */
	/* .editororderdetails-new-editor .coordibox .card {
		width: 21.5em !important;
	} */
}
@media (min-width: 800px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 27.5em;
	}
	.col-md-9 {
		width: 27.5em;
	}
}
@media (min-width: 400px) {
	.col-md-2 {
		flex: 0 0 auto;
		width: 13.666667% !important;
	}
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1024px) and (max-width: 1100px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 1101px) and (max-width: 1122px) {
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 15em !important;
	}
}
@media (min-width: 300px) and (max-width: 799px) {
	.col-md-7 {
		flex: 0 0 auto;
		width: 19em !important;
	}
	/* .editororderdetails-new .coordibox .card {
		width: 15em !important;
	} */
	.editororderdetails-new-editor .coordibox .card {
		width: 17em !important;
	}
}
@media (max-width: 991px) {
	.main-panel {
		width: 1200px !important;
		overflow: scroll;
	}
}
