/* Sidebar */
.sidebar {
	position: fixed;
	/* top: 0; */
	right: 0;
	left: 0;
	z-index: 1030;
	min-height: 100vh;
	background: #ffffff;
	border-right: 1px solid #e9e9e9;
	padding: 0;
	width: 203px;
	z-index: 11;
	transition: width 0.25s ease, background 0.25s ease;
	-webkit-transition: width 0.25s ease, background 0.25s ease;
	-moz-transition: width 0.25s ease, background 0.25s ease;
	-ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar .nav {
	overflow: hidden;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 60px;
	margin-top: 28px;
}
.sidebar .nav .nav-item {
	padding: 14px 20px;
	-webkit-transition-duration: 0.25s;
	transition-duration: 0.25s;
	transition-property: background;
	-webkit-transition-property: background;
}
.sidebar .nav .nav-item .collapse {
	z-index: 999;
}
.sidebar .nav .nav-item .nav-links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	white-space: nowrap;
	-webkit-transition-duration: 0.45s;
	transition-duration: 0.45s;
	transition-property: color;
	-webkit-transition-property: color;
}
.sidebar .nav .nav-item .nav-links i {
	color: inherit;
}
.menu-icon {
	font-size: 1.125rem;
	line-height: 1;
	margin-left: auto;
	color: #bba8bff5;
}
.menu-icon {
	margin-left: 0;
	margin-right: 12px;
}
:before {
	vertical-align: middle;
}
.menu-arrow {
	line-height: 1;
	font-size: 1.125rem;
	margin-left: auto;
	color: #9e9da0;
	position: absolute;
	right: 15px;
}
.menu-arrow {
	margin-left: 65px;
}
.sidebar .nav .nav-item .nav-links i.menu-arrow:before {
	/* content: "\f107";
  font-size: inherit;
  color: #5a616b; */
}
.sidebar .nav .nav-item .nav-links i.menu-arrow + .menu-icon {
	margin-left: 0.25rem;
}
.menu-arrow + .menu-icon {
	margin-left: 0;
	margin-right: 0.25rem;
}
.sidebar .nav .nav-item .nav-links .menu-title {
	color: inherit;
	width: 36%;
	display: inline-block;
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	line-height: 1;
	vertical-align: middle;
}
.sidebar .nav .nav-item .nav-links .badge {
	margin-right: auto;
	margin-left: 1rem;
}
.sidebar .nav .nav-item .nav-links[aria-expanded="true"] .menu-arrow:before {
	/* content: "\f106"; */
}
.sidebar .nav .nav-item.active {
	background: #0b0b0b;
}
.sidebar .nav .nav-item.active:hover {
	pointer-events: none;
	background: #0b0b0b !important;
}
.bg--black {
	background: #0b0b0b;
	color: white;
}
.sidebar .nav .nav-item.active > .nav-links .menu-title {
	color: #fff;
}
.sidebar .nav .nav-item.active > .nav-links i {
	color: #b66dff;
}
/* .sidebar .nav .nav-item:hover {
  background: #efefef;
} */
.sidebar .nav .nav-item.nav-profile .nav-links {
	height: auto;
	line-height: 1;
	border-top: 0;
	padding: 1.25rem 0;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image {
	width: 44px;
	height: 44px;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-image img {
	width: 44px;
	height: 44px;
	border-radius: 100%;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: 1rem;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-text {
	margin-left: auto;
	margin-right: 1rem;
}
.sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	font-size: 1.125rem;
	margin-left: auto;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-links .nav-profile-badge {
	margin-left: 0;
	margin-right: auto;
}
.sidebar .nav .nav-item.sidebar-actions {
	margin-top: 1rem;
}
.sidebar .nav .nav-item.sidebar-actions .nav-links {
	border-top: 0;
	display: block;
	height: auto;
}
.sidebar .nav .nav-item.sidebar-actions:hover {
	background: initial;
}
.sidebar .nav .nav-item.sidebar-actions:hover .nav-links {
	color: initial;
}
.sidebar
	.nav:not(.sub-menu)
	> .nav-item:hover:not(.nav-category):not(.nav-profile)
	> .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #fff;
}
.sidebar .nav.sub-menu {
	margin-bottom: 0px;
	margin-top: 0px;
	list-style: none;
}
.sidebar .nav.sub-menu .nav-item {
	padding: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-links {
	font-weight: 400;
	font-size: 13px;
	line-height: 19px;
	color: #5a616b;
	padding: 14px 20px;
	position: relative;
	height: auto;
	border-top: 0;
	white-space: normal;
}
/* .sidebar .nav.sub-menu .nav-item .nav-links:before {
              content: "\f0da";;
              font-family: "Fontawesome";
              display: block;
              position: absolute;
              left: 0px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              color: #a2a2a2;
              font-size: .75rem; } */
.rtl .sidebar .nav.sub-menu .nav-item .nav-links:before {
	left: auto;
	right: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	color: #b66dff;
	background: #0b0b0b !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links:hover {
	/* color: #555555; */
}
.sidebar .nav.sub-menu .nav-item:hover {
	background: transparent;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
	.sidebar-offcanvas {
		position: fixed;
		max-height: calc(100vh - 70px);
		top: 60px;
		bottom: 0;
		overflow: auto;
		right: -260px;
		-webkit-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}
	.sidebar-offcanvas.active {
		right: 0;
	}

	.sidebar {
		width: 54px;
	}
	.sidebar .nav .nav-item {
		padding-left: 0;
		padding-right: 0;
	}
	.sidebar .nav .nav-item .nav-links .menu-title {
		display: none;
	}
	.menu-icon {
		margin: 0 auto !important;
	}
	.menu-arrow {
		margin-left: 1px;
		font-size: 15px;
		position: absolute;
		right: 5px;
	}
	i.mdi.mdi-crosshairs-gps.menu-icon {
		display: none;
	}
	.main-panel {
		padding-left: 54px !important;
	}
}
.sidefull {
	position: relative;
}
.bg-highlighter {
	background: #efefef !important;
	color: #0001c7 !important;
}
.p-b--0 {
	padding-bottom: 0 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links {
	padding: 15px 35px !important;
}
.sidebar .nav .nav-item .collapse {
	margin-left: -13%;
	margin-right: -13%;
	margin-bottom: -9%;
}
.sidebar .nav.sub-menu .nav-item .nav-links.active {
	background: #0b0b0b !important;
	width: 100%;
	color: white !important;
}
.sidebar .nav.sub-menu .nav-item .nav-links:hover {
	background: #efefef !important;
}
.sub-menu--blue {
	color: #125ecb !important;
}
.menu-arrow--highlight {
	line-height: 1;
	font-size: 1.125rem;
	margin-left: auto;
	position: absolute;
	right: 15px;
	color: #125ecb !important;
}
.menu-arrow--highlight {
	margin-left: 65px;
}
